
import { RestService } from '../../shared/rest.service';
//import { ToastsManager } from 'ng2-toastr';
import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ItineraryService } from '../../shared/itinerary.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
//import { DxVectorMapModule, DxVectorMapComponent } from 'devextreme-angular';
//import { projection } from 'devextreme/viz/vector_map/projection';
import { FeatureCollection, Service } from './service';
//import * as mapsData from 'devextreme/dist/js/vectormap-data/usa.js';

import { UserService } from '../../pages/com.usblick.common/auth/user.service';

import * as Chart from 'chart.js';
import { HttpErrorResponse } from '@angular/common/http';
import { setting } from '../../../../setting';

@Component({
  selector: 'usb-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  multi: any[];
  scale = 0;
  view = [1500, 120];
  viewpie = [700, 500];
  viewsalesa = [800, 450];
  gradient: any;
  results = [];
  colorScheme = {
    domain: [ '#272c42', '#1e97cc', '#B70D35', '#4caf50'],


  };
  sales: any[];
  annualsales: any[];
  // pie
  showLabels = true;
  showLegend = true;
  explodeSlices = false;
  doughnut = true;

  //for cards
  totalForCards: any;
  totalItineraries: number = 0;
  totalAgents: number = 0;
  userIsAmin: boolean = true;
  clients: any[] = [];

  //for maps
  //worldMap: any = mapsData.usa;
  markers: FeatureCollection;
  populations: Object;
  //@ViewChild(DxVectorMapComponent) vectorMap: DxVectorMapComponent
  totalSavedAndDraft = 0;
  totalReserve = 0;
  totalPartial = 0;
  totalCompleted = 0;
  textColor: string;
  dataWithYear = [];
  totalPriceYear: any;
  totalSelling: any;
  totalSellingPerMounth: any;
  totalSellingPrice: any;
  totalSellingQty: any;
  qtyShow: boolean = false;
  selectedCategoryGraphic: boolean = true;
  products: any[]= [];
  countriesData: any[] = [];


  yearsPie: boolean = false
  yearsBar: boolean = false

  ngchartExample:any = {
    chartType:"line",
    datasets:{
      2016:[{label:"Sales",data:[1.9,3,3.4,2.2,2.9,3.9,2.5,3.8,4.1,3.8,3.2,2.9],fill:"start"}],
      2017:[{label:"Sales",data:[2.2,2.9,3.9,2.5,3.8,3.2,2.9,1.9,3,3.4,4.1,3.8],fill:"start"}],
      2018:[{label:"Sales",data:[3.9,2.5,3.8,4.1,1.9,3,3.8,3.2,2.9,3.4,2.2,2.9],fill:"start"}]
    },
    labels:["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"],
    colors:[
      {"borderColor":"#42a5f5","backgroundColor":"#42a5f5","pointBackgroundColor":"#1e88e5","pointHoverBackgroundColor":"#1e88e5","pointBorderColor":"#ffffff","pointHoverBorderColor":"#ffffff"}
      ],
    options:{
      "spanGaps":false,"legend":{"display":false},"maintainAspectRatio":false,"layout":{"padding":{"top":32,"left":32,"right":32}},"elements":{"point":{"radius":4,"borderWidth":2,"hoverRadius":4,"hoverBorderWidth":2},"line":{"tension":0}},"scales":{"xAxes":[{"gridLines":{"display":false,"drawBorder":false,"tickMarkLength":18},"ticks":{"fontColor":"#ffffff"}}],"yAxes":[{"display":false,"ticks":{"min":1.5,"max":5,"stepSize":0.5}}]},"plugins":{"filler":{"propagate":false},"xLabelsOnTop":{"active":true}}}
  }


  ngchartExample2:any = {"conversion":{"value":492,"ofTarget":13},"chartType":"bar","datasets":[{"label":"Conversion","data":[221,428,492,471,413,344,294]}],"labels":["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],"colors":[{"borderColor":"#42a5f5","backgroundColor":"#42a5f5"}],"options":{"spanGaps":false,"legend":{"display":false},"maintainAspectRatio":false,"layout":{"padding":{"top":24,"left":16,"right":16,"bottom":16}},"scales":{"xAxes":[{"display":false}],"yAxes":[{"display":false,"ticks":{"min":100,"max":500}}]}}}




  dataSales: any [] = [
    {
      "label":"2020",
      "data":[354,485,232,324,678,444,294,512,428,745,325,894]
    },
    {
      "label":"2019",
      "data":[221,428,492,471,413,344,294,221,428,492,471,413]
    }
  ];

  // dataSalesSelected:any[] = [this.dataSales[0]];
  // Data auxiliar para primer grafico

  dataSalesAux: any [] = [

  ];

  dataSalesSelected:any[] = [this.dataWithYear[0]];

  ngchartExampleAux:any = {
    "conversion":{"value":492,"ofTarget":13},
    "chartType":"bar",
    "datasets":[
      {
        "label":"2018",
        "data":[3.9,2.5,3.8,4.1,1.9,3,3.8,3.2,2.9,3.4,2.2,2.9]
      },
      {
        "label":"2019",
        "data":[221,428,492,471,413,344,294,221,428,492,471,413]
      },
      {
        "label":"2020",
        "data":[354,485,232,324,678,444,294,512,428,745,325,894]
      }],
    "labels":["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
    "colors":[{"borderColor":"#00a3fe","backgroundColor":"#00a3fe"}],
    "options":{
      "spanGaps":true,
      "legend":{"display":false},
      "maintainAspectRatio":false,
      "scales":{
        "xAxes":[{"display":true}],
        "yAxes":[{"display":true,"ticks":{"min":100,"max":1000}}]
      }
    }
  };

      //Data para primer grafico
      ngchartExample3:any = {
        "conversion":{"value":492,"ofTarget":13},
        "chartType":"bar",
        "datasets": [],
        "labels":["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
        "colors":[{"borderColor":"#00a3fe","backgroundColor":"#00a3fe"}],
        "options":{
          "spanGaps":true,
          "legend":{"display":false},
          "maintainAspectRatio":false,
          "scales":{
            "xAxes":[{"display":true}],
            "yAxes":[{"display":true,"ticks":{"min":0,"max":10}}]
          }
        }
      };

       //Data para primer grafico
       ngchartExample4:any = {
        "conversion":{"value":492,"ofTarget":13},
        "chartType":"bar",
        "datasets": [],
        "labels":["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
        "colors":[{"borderColor":"#00a3fe","backgroundColor":"#00a3fe"}],
        "options":{
          "spanGaps":true,
          "legend":{"display":false},
          "maintainAspectRatio":false,
          "scales":{
            "xAxes":[{"display":true}],
            "yAxes":[]
          }
        }
      };

  ///////////////////////////////////////////////////////////////////////
  categoryLabels: any[] = ["HOTELES", "AUTOS", "TRANSP", "TOURS"];

  public categoryChart1: any;
  categoryLabels1: any[] = [];
  categoryValues1: any[] = [];
  categoryColors1: any[] = [
    ['#2d2f40', '#00db97'],
    ['#2d2f40', '#00db97']
  ]
  //categoryLabels: any[] = [];

  categoryColors: any[] = [
    ['#2d2f40', '#00db97', '#db0049', '#006244'],
    ['#2d2f40', '#00db97', '#db0049', '#006244']
  ]
  categoryValues: any[] = [
    {
      year: 2020,
      value: [2478,5267,734,784]
    },
    {
      year: 2019,
      value: [1782,3892,540,336]
    }
  ]

  //Category Values  Leo
  public categoryChart2: any;
  categoryLabels2: any[] = [];
  categoryValues2: any[] = [];
  categoryColors2: any[] = [
    ['#2d2f40', '#ff0048'],
    ['#2d2f40', '#00db97']
  ]

  public categoryChart3: any;
  categoryLabels3: any[] = []
  categoryValues3: any[] = [];
  categoryColors3: any[] = [
    ['#2d2f40', '#ff0048', '#db0049', '#b0003a'],
    ['#2d2f40', '#00db97', '#00a270', '#006244']
  ]



  //Datos numericos
  totalItinerary:number = 0;
  totalItineraryCompleted:number = 0;
  totalItineraryPartial:number = 0;
  totalItineraryReserve:number = 0;
  totalItinerarySave:number = 0;

  totalBlickoins;
  totalClients;
  creditsLimits;
  itineraryVencimientoPropio;

  //datos de listas
  reportListClients:any;
  reportListProductBilling:any;
  reportListProductProvider:any
  listPages1 = 1;
  listPages2 = 1;
  listPages3 = 1;
  listPagesTotal1 = 1;
  listPagesTotal2 = 1;
  listPagesTotal3 = 1;

  request = {
    agency_id: 1,
    agent_id: 7
  };

  constructor(private _itineraryService: ItineraryService,

    service: Service,
    private _router: Router,
    private restService: RestService,
    public userService : UserService
  ) {
    this.markers = service.getMarkers();
    this.populations = service.getPopulations();
    this.customizeLayers = this.customizeLayers.bind(this);

    this.totalItineraries = 0;
    this.totalAgents = 0;
    this.userIsAmin = true;
    this.setResults();
    //this.getTotalItineraries();
    //this.getClients();
    this.getTotalAgents();
    this.setSingle();
    this.setAnnualSales();
    this.textColor = '#ffffff';
  }


  ngOnInit() {
    this.getCountries();
    this.getProducts();
    let itinerary$ = this._itineraryService.getItinerary$();
    itinerary$.subscribe((data:any) => {
      // console.log("data subscription.subscribe category cintillo", data);
      if ((data !== undefined) && (data !== null)) {

        //this.getClients();

        this.request.agency_id = this.userService._agencyID;
        this.request.agent_id = this.userService._agentID;


        this.getTotalGeneral();
        this.getSalesPerCategory();
        this.getReportSalesYear();

        this.getReportListClients();
        this.getReportListProductsProviders();
        this.getReportListProducts();
        // console.log("this request ", this.request);

      }
    },
    error => {
      console.log('Error: ', error);
    });


    if(this.userService._agencyID){

      this.request.agency_id = this.userService._agencyID;
      this.request.agent_id = this.userService._agentID;

      this.getTotalGeneral();

      this.getSalesPerCategory();
      this.getReportSalesYear();

      this.getReportListClients();
      this.getReportListProductsProviders();
      this.getReportListProducts();
    }

    //no funciona
    //this.getTotalItineraries();

    // this.getSalesPerCategory();
    // this.getReportSalesYear();

    // this.getReportListClients();
    // this.getReportListProductsProviders();
    // this.getReportListProducts();


    //console.log(this.ngchartExample);

  }

  setSingle() {
    this.sales = [
      {
        'name': 'Hoteles',
        'value': 859
      },
      {
        'name': 'Tours',
        'value': 15
      },
      {
        'name': 'Cruises',
        'value': 7
      },
      {
        'name': 'Rent a Car',
        'value': 70
      },
      {
        'name': 'Transports',
        'value': 170
      },
      {
        'name': 'Packages',
        'value': 70
      },
      {
        'name': 'Disney',
        'value': 120
      },
      {
        'name': 'Pthemes',
        'value': 20
      },
      {
        'name': 'Insurance',
        'value': 20
      }
    ];
  }

  setResults() {
    console.log('inicial files');
  }

  setAnnualSales() {
    this.annualsales = [
      {
        'name': '2018',
        'value': 25000
      },
      {
        'name': '2017',
        'value': 1500
      }
    ];
  }

  onSelect(event: any) {
    let id;
    switch (event.name) {
      case 'Saved Draft': id = 1;
      break;
      case 'Partial Reserved': id = 3;
      break;
      case 'Reserved': id = 4;
      break;
      case 'Completed': id = 8;
      break;

    }
    this._router.navigate(['/management', id]);
  }


  goTo(value: any) {
    let url;
   switch (value) {
      case 'pointxagent': url = '/fidelity';
      break;
    }
     this._router.navigate([url]);
  }



  getTotalGeneral(){
    // console.log("empieza general");
    this._itineraryService._restService.doPost('app', 'reportTotalGeneral', this.request).subscribe((response:any) => {
      // console.log('itineraries info general response', response);
      if(response){
        this.totalItinerary = response.totalItinerary;
        this.totalItineraryCompleted = response.totalItineraryCompleted;
        this.totalItineraryPartial = response.totalItineraryPartial;
        this.totalItineraryReserve = response.totalItineraryReserve;
        this.totalItinerarySave = response.totalItinerarySave;

        this.totalClients = response.totalClients;

        this.totalBlickoins = response.balanceCreditsBlickoins * 10;
        this.totalBlickoins = Math.floor(this.totalBlickoins);
        this.creditsLimits = response.balanceCreditsLimits;
        this.itineraryVencimientoPropio = response.itineraryVencimientoPropio;
      }
    }, err => {
      console.log('No existe informacion, total itinearios!');
    });
  }



  returnNumberPage(oneMore , number):number{
    if(oneMore == '+'){
      switch (number) {
        case 1:
          this.listPages1++;
          return this.listPages1;
        case 2:
          this.listPages2++;
          return this.listPages2;
        case 2:
          this.listPages3++;
          return this.listPages3;
        default:
          break;
      }
    }else{
      switch (number) {
        case 1:
          this.listPages1--;
          return this.listPages1;
        case 2:
          this.listPages2--;
          return this.listPages2;
        case 2:
          this.listPages3--;
          return this.listPages3;
        default:
          break;
      }
    }
  }

  getReportSalesYear(){
    const url = 'reportListSalesYear/' + this.request.agent_id;
    this._itineraryService._restService.doGet_('app', url).subscribe((response:any) => {
      // console.log('response reportListSalesYear', response);
      if(response){

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const previousYear = currentYear - 1;

        // const listSalesCurrentYearObj = response.find(c => c.year === )

        // Iterar a través de las claves del objeto
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            // Extraer el año de la clave
            const year = parseInt(key.split('-')[0], 10);

            // Buscar si ya existe un objeto para ese año en el array
            const existingYearData = this.dataWithYear.find(data => data.year === year);

            // Si ya existe, agregar los datos al objeto existente
            if (existingYearData) {
              existingYearData.data.push(...this.getDatesSalesGraphics(response[key], year));
            }
            // Si no existe, crear un nuevo objeto
            else {
              const data = {
                year: year,
                data: this.getDatesSalesGraphics(response[key], year)
              };
              this.dataWithYear.push(data);
            }
          }
        }

        // dataWithYear ahora contendrá los datos agrupados por año en un array anidado
        // console.log('Data agrupada por año:', this.dataWithYear);
        this.totalSelling = this.dataWithYear[0].data.length;

        // Primer Gráfico

        // Total en valor de ventas del año
        this.dataPerYear(this.dataWithYear);

        // Total ventas por mes en cantidad
        // this.dataGraphicsQty(this.dataWithYear);

        // Total de ventas por mes en valor
        this.dataGraphicsPrice(this.dataWithYear);


        if(this.dataWithYear.length > 0){
          // this.selectDataSales(0);
          // console.log("charts dates ", this.dataWithYear, this.dataSalesSelected);
        }

      }
    }, err => {
      console.log('No existe informacion! reportListSalesYear');
    });
  }

  dataGraphicsQty(data: any) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthCounts = {};

    // Inicializa el objeto monthCounts con 0 para cada mes
    months.forEach((month) => {
      monthCounts[month] = 0;
    });

    // Itera a través de los datos y cuenta la cantidad de objetos con valores distintos de 0 para cada mes
    this.dataWithYear.forEach((item) => {
      item.data.forEach((monthData) => {
        for (const month in monthData) {
          if (monthData.hasOwnProperty(month) && monthData[month] > 0) {
            monthCounts[month]++;
          }
        }
      });
    });

    // console.log(monthCounts);

    this.totalSellingPrice = monthCounts;
    const values = Object.values(monthCounts);
    // console.log(values);
    this.totalSellingPrice = values;
    // Calcular el valor máximo de this.totalSellingPerMounth
  const maxSellingValue = Math.max(...this.totalSellingPrice);
  // console.log(maxSellingValue)
  // Calcular el nuevo valor máximo para el eje Y
  const newYAxisMax = this.adjustMaxValue(maxSellingValue);
    this.totalSellingPrice = { label: "Cantidad", data: this.totalSellingPrice};
    // console.log(this.totalSellingPrice)

    // Asignar el nuevo valor máximo a las opciones del gráfico
    if(newYAxisMax < 10) {
      this.ngchartExample3.options.scales.yAxes = {"display":true,"ticks":{"min":0,"max":newYAxisMax}};
    }
    // console.log(this.ngchartExample3.options.scales.yAxes)
//Data para primer grafico
this.ngchartExample3.datasets.push(this.totalSellingPrice);
  }

  dataGraphicsPrice(data: any) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Inicializamos un objeto para almacenar las sumas de cada mes
    const sumData = {};

     // Itera a través de los datos y cuenta la cantidad de objetos con valores distintos de 0 para cada mes
     this.dataWithYear.forEach((item) => {
      item.data.forEach((monthData) => {
        for (const month in monthData) {
          if (monthData.hasOwnProperty(month) && month !== 'year') {
            // Verificamos si el mes ya está en sumData
            if (sumData[month]) {
              // Si existe, sumamos el valor
              sumData[month] += monthData[month];
            } else {
              // Si no existe, creamos la clave y asignamos el valor
              sumData[month] = monthData[month];
            }
          }
        }
      });
    });

    // Redondeamos los valores a dos decimales
    for (const month in sumData) {
      if (sumData.hasOwnProperty(month)) {
        sumData[month] = parseFloat(sumData[month].toFixed(2));
      }
    }
// console.log(sumData)
    this.totalSellingPrice = sumData;
    const values = Object.values(sumData);
    // console.log(values);
    this.totalSellingPrice = values;
    // Calcular el valor máximo de this.totalSellingPerMounth
  const maxSellingValue = Math.max(...this.totalSellingPrice);
  // console.log(maxSellingValue)
  // Calcular el nuevo valor máximo para el eje Y
  const newYAxisMax = this.adjustMaxValue(maxSellingValue);
    this.totalSellingPrice = { label: "Valor", data: this.totalSellingPrice};
    // console.log(this.totalSellingPrice)
    // console.log(this.ngchartExample4.options.scales.yAxes)
    // Asignar el nuevo valor máximo a las opciones del gráfico
    this.ngchartExample4.options.scales.yAxes = {};
    // console.log(this.ngchartExample4.options.scales.yAxes)
    this.ngchartExample4.options.scales.yAxes = {"display":true,"ticks":{"min":0,"max":newYAxisMax}};
    // console.log(this.ngchartExample4.options.scales.yAxes)
    // Ahora sumData contiene la suma de los valores de cada mes en todos los objetos
    this.ngchartExample4.datasets.push(this.totalSellingPrice);
  }

   // Función para ajustar el número máximo en base al valor más alto
    adjustMaxValue(maxValue) {
    if (maxValue < 10) {
      return 10;
    } else if (maxValue < 100) {
      return 100;
    } else if (maxValue < 1000) {
      return 1000;
    } else {
      return 10000;
    }
  }


  dataPerYear(data: any) {
    const sumByYear = {};

    data.forEach((item) => {
      const dataArr = item.data;

      // Itera a través del array de datos de cada año y suma los valores
      for (const dataOfYear of dataArr) {
        for (const key in dataOfYear) {
          if (dataOfYear.hasOwnProperty(key)) {
            if (!sumByYear["0"]) {
              sumByYear["0"] = 0;
            }
            sumByYear["0"] += dataOfYear[key];
          }
        }
      }
    });

    // console.log(sumByYear);
    this.totalPriceYear = sumByYear[0];
    return sumByYear;
  }

  getProducts() {
    this._itineraryService._restService.doGet_('app', 'products').subscribe({
      next: (res: any) => {
        this.products = res.data;
        this.products = this.products.filter(c => c.status_id === 2);
        console.log(this.products);
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
      }
    })
  }


  getReportListClients(oneMore?){
    this._itineraryService._restService.doPost('app', 'reportListClientsBilling?page=' + (oneMore ? this.returnNumberPage(oneMore, 1) : this.listPages1), this.request).subscribe((response:any) => {
      console.log('response 1', response);
      if(response.data){
        this.reportListClients = response.data;
        this.listPagesTotal1 = response.last_page;
      }
    }, err => {
      console.log('No existe informacion!');
    });
  }

  getReportListProducts(oneMore?){
    this._itineraryService._restService.doPost('app', 'reportListProductsBilling?page='  + (oneMore ? this.returnNumberPage(oneMore, 2) : this.listPages2),this.request).subscribe((response:any) => {
      console.log('response 2', response);
      if(response.data){
        this.reportListProductBilling = response.data;
        this.listPagesTotal2 = response.last_page;
      }
    }, err => {
      console.log('No existe informacion!');
    });
  }

  getReportListProductsProviders(oneMore?){
    this._itineraryService._restService.doPost('app', 'reportListProductsProviders?page='  + (oneMore ? this.returnNumberPage(oneMore, 3) : this.listPages3),this.request).subscribe((response:any) => {
      console.log('response 3', response);
      if(response.data){
        this.reportListProductProvider = response.data;
        this.listPagesTotal3 = response.last_page;
      }
    }, err => {
      console.log('No existe informacion!');
    });
  }

  getSalesPerCategory(){
console.log(this.request)
    this._itineraryService._restService.doPost('app', 'reportListSalesYearCategory',this.request).subscribe((response:any) => {
      console.log('response reportListSalesYearCategory', response);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const previousYear = currentYear - 1;

      console.log(response[currentYear]);
      if(response){

        if(response[currentYear]){
          this.categoryValues2.push({
            year: currentYear,
            value:this.getDatesGraphics(response[currentYear], currentYear)
          });
          this.initGraphicOne();
        }

        if(response[currentYear]){
          this.categoryValues3.push({
            year: currentYear,
            value:this.getDatesGraphicsTwo(response[currentYear], currentYear)
          });
          this.initGraphicTwo(response);
          this.initGraphicThree();
        }

        console.log("charts dates 2", this.categoryValues2, this.categoryLabels2);
        console.log("charts dates 3", this.categoryValues3, this.categoryLabels3);
      }
    }, err => {
      console.log('No existe informacion, total getSalesPerCategory!');
    });
  }


  // getTotalItineraries() {
  //   this._itineraryService._restService.doGet('app', 'itineraries/totals/qty?agent_id='+this.userService._agentID).subscribe((response:any) => {
  //     console.log('response totalItineraries: ', response);

  //     if (response.length > 0) {
  //       this.totalForCards = response[0];
  //       this.totalItineraries = this.totalForCards.total;

  //       this.totalSavedAndDraft = ((response[0].id2) ? response[0].id2 : 0) + ((response[0].id1) ? response[0].id1 : 0);
  //       this.totalReserve = ((response[0].id4) ? response[0].id4 : 0);
  //       this.totalPartial = ((response[0].id3) ? response[0].id3 : 0);
  //       this.totalCompleted = ((response[0].id8) ? response[0].id8 : 0);
  //       this.results = [
  //         {
  //           'name': 'Saved Draft',
  //           'value': this.totalSavedAndDraft * 10 ** this.scale
  //         },
  //         {
  //           'name': 'Partial Reserved',
  //           'value': this.totalPartial * 10 ** this.scale
  //         },
  //         {
  //           'name': 'Reserved',
  //           'value': this.totalReserve * 10 ** this.scale
  //         },
  //         {
  //           'name': 'Completed',
  //           'value': this.totalCompleted * 10 ** this.scale
  //         }
  //       ];
  //       console.log('Valores de results', this.results);

  //     }
  //   }, err => {
  //     console.log('No existe informacion, total itinearios!');
  //   });
  // }

  getClients() {
    this._itineraryService._restService.doGet('app', 'clients?agent_id='+ this.userService._agentID ).subscribe((response:any) => {

      console.log("response clients", response);

      if (response.data.length > 0) {
        this.clients = response.data;
      }
    }, err => {
      console.log('No existe informacion, clientes!');
    });
  }

  getTotalAgents() {
    //pasamos a agencia manual 1
    this._itineraryService._restService.doGet('app', 'agencies/1').subscribe((response:any) => {
      if (response.data.id !== undefined) {
        this.totalAgents = response.data.users.length;
        console.log('agents: ', response.data);
        console.log('total agents: ', response.data.users.length);
      }
    }, err => {
      console.log('No existe informacion, total agentes!');
    });
  }

  //Metodos para el mapa
  customizeText(arg) {
    if (arg.index === 0) {
      return "< 0.5%";
    } else if (arg.index === 5) {
      return "> 3%";
    } else {
      return arg.start + "% to " + arg.end + "%";
    }
  }

  customizeTooltip(arg) {
    return {
      text: arg.attribute("text")
    };
  }

  customizeLayers(elements) {
    elements.forEach((element) => {
      let name = element.attribute("name"),
        population = this.populations[name];
      if (population) {
        element.attribute("population", population);
      }
    });
  }

  customizeMarkers(arg) {
    return ["< 8000K", "8000K to 10000K", "> 10000K"][arg.index];
  }

  zoomInit() {
    // this.vectorMap.instance.showLoadingIndicator();
    // this.vectorMap.instance.center([-97.7127837, 37.761105]);
    // this.vectorMap.instance.zoomFactor(8);
    // this.vectorMap.instance.hideLoadingIndicator();
  }

  ngAfterViewInit() {
    this.zoomInit();

    // this.categoryChart1 = new Chart('categoryChart1', {
    //   type: 'doughnut',
    //   data: {
    //     labels: this.categoryLabels,
    //     datasets: [
    //       {
    //         label: "Population (millions)",
    //         backgroundColor: this.categoryColors[0],
    //         data: this._porcentCalculator(this.categoryValues[0].value),
    //         borderWidth: 0
    //       }
    //     ]
    //   },
    //   options: {
    //     title: {
    //       display: false,
    //       text: ''
    //     },
    //     legend: false,
    //   }
    // });

    // this.categoryChart2 = new Chart('categoryChart2', {
    //   type: 'doughnut',
    //   data: {
    //     labels: this.categoryLabels,
    //     datasets: [
    //       {
    //         label: "Population (millions)",
    //         backgroundColor: this.categoryColors[1],
    //         data: this._porcentCalculator(this.categoryValues[1].value),
    //         borderWidth: 0
    //       }
    //     ]
    //   },
    //   options: {
    //     title: {
    //       display: false,
    //       text: ''
    //     },
    //     legend: false,
    //   }
    // });
  }

  resetClick() {

  }

  selectDataSales(e) {
    console.log(e)
    this.totalSelling = e.data.length;
    // this.dataSalesSelected = [this.dataSales[e]]
    // this.dataSalesSelected = [this.dataWithYear[e]]
  }

  _totVentas(){
    console.log(this.dataSalesSelected)
    let tot = 0
    this.dataSalesSelected[0].data.map( e => tot += e)
    return tot
  }

  _porcentCalculator(e: number[]) {
    let val = [];
    const totalizar = (accumulator, currentValue) => accumulator + currentValue;
    const tot = e.reduce(totalizar);

    e.forEach(x => {
      const percentage = Math.round((x * 100) / tot);
      val.push(percentage);
    });

    return val;
  }


  initGraphicOne(){
    this.categoryChart2 = new Chart('categoryChart2', {
      type: 'doughnut',
      data: {
        labels: this.categoryLabels2,
        datasets: [
          {
            label: "Population (millions)",
            backgroundColor: this.categoryColors2[1],
            data: this._porcentCalculator(this.categoryValues2[0].value),
            borderWidth: 0
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: ''
        },
        legend: false,
      }
    });



    // this.categoryChart3 = new Chart('categoryChart3', {
    //   type: 'doughnut',
    //   data: {
    //     labels: this.categoryLabels3,
    //     datasets: [
    //       {
    //         label: "Population (millions)",
    //         backgroundColor: this.categoryColors3[0],
    //         data: this._porcentCalculator(this.categoryValues2[0].value),
    //         borderWidth: 0
    //       }
    //     ]
    //   },
    //   options: {
    //     title: {
    //       display: false,
    //       text: ''
    //     },
    //     legend: false,
    //   }
    // });

  }


  initGraphicTwo(data: any){
    console.log(data)
    //if(this.categoryValues3.length > 0){
      this.categoryChart3 = new Chart('categoryChart3', {
        type: 'doughnut',
        data: {
          labels: this.categoryLabels3,
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: this.categoryColors3[1],
              data:  this._CalculatorQty(data),
              borderWidth: 0
            }
          ]
        },
        options: {
          title: {
            display: false,
            text: ''
          },
          legend: false,
        }
      });
    //}

    console.log(this.categoryChart3.data.datasets[0].data)
  }

  _CalculatorQty(data: any) {
    console.log(data);
    if (!data) {
      return [];
    }

    const categoryCountMap = {};

    // Iterar sobre las claves del objeto (que representan los años)
    for (const year in data) {
      if (data.hasOwnProperty(year) && Array.isArray(data[year])) {
        // Para cada año, contar las veces que aparece cada categoría
        data[year].forEach(item => {
          console.log(item);
          const category = item.category;

          if (!categoryCountMap[category]) {
            categoryCountMap[category] = 1;
          } else {
            // Si ya existe la categoría, incrementar el contador
            categoryCountMap[category]++;
          }
        });
      }
    }

    // Obtener un array de las cantidades
    const result = Object.values(categoryCountMap);

    return result;
  }

  initGraphicThree() {

    console.log(this.products);
    this._qtyGraphicCountry(this.products);

    this.categoryChart1 = new Chart('categoryChart1', {
      type: 'doughnut',
      data: {
        labels: this.categoryLabels1,
        datasets: [
          {
            label: "Population (millions)",
            backgroundColor: this.categoryColors1[1],
            data: this._qtyGraphicCountry(this.products),
            borderWidth: 0
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: ''
        },
        legend: false,
      }
    });
  }


  _qtyGraphicCountry(data: any) {
    if (!data) {
      return [];
    }

    let iata = [];

    // Obtener los códigos IATA y almacenarlos en la lista iata
    this.products.forEach(c => {
      const dataIata = JSON.parse(c.item);
      const Iata = dataIata.form.destination.countryIataCode;

      // Agregar el código IATA a la lista iata
      iata.push(Iata);
    });

    const total = iata.length;

    const iataPercentageMap: { [key: string]: number } = {};

    // Calcular el porcentaje de veces que aparece cada código IATA
    iata.forEach((code: any) => {
      const occurrences = iata.filter(c => c === code).length;
      iataPercentageMap[code] = Math.round((occurrences / total) * 100);
    });

    // Obtener los ZoneName correspondientes a los códigos IATA
    this.categoryLabels1 = Array.from(new Set(iata)).map(code => {
      const country = this.countriesData.find(c => c.IataCode === code);
      return country ? country.iata3Code : code;
    });

    // Ordenar ambos arrays en base a los porcentajes
    const sortedResult = Object.values(iataPercentageMap).sort((a, b) => (b as number) - (a as number));
    const sortedLabels = this.categoryLabels1.map((label, index) => ({ label, percentage: sortedResult[index] }));

    // Actualizar this.categoryLabels1 con los nombres de países ordenados
    this.categoryLabels1 = sortedLabels.map(item => item.label);

    return sortedResult;
  }





  getDatesGraphics(response, year){
    let array=[];
    //     value: [2478,5267,734,784]

    let resultHotels = 0;
    let resultTours = 0;
    //let resultHotels;

    response.forEach(element => {
      switch (element.category) {
        case 'Hotels':
          resultHotels =  resultHotels + parseFloat(element.Sales);
          break;

        case 'Tours':
          resultTours = resultHotels + parseFloat(element.Sales);
          break;

        default:
          break;
      }
    });

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const previousYear = currentYear - 1;

    // console.log("resultHotels", resultHotels);
    // console.log("resultTours", resultTours);
    if(resultHotels > 0){
      array.push(resultHotels);
      if(year == currentYear){
        this.categoryLabels2.push('HOTELES');
      }

    }

    if(resultTours > 0){
      array.push(resultTours);
      if(year == 2021){
        this.categoryLabels2.push('TOURS');
      }
    }

    //console.log("array dates", array);
    return array;
  }

  getDatesGraphicsTwo(response, year){
        let array=[];
        //     value: [2478,5267,734,784]

        let resultHotels = 0;
        let resultTours = 0;
        //let resultHotels;

        response.forEach(element => {
          switch (element.category) {
            case 'Hotels':
              resultHotels =  resultHotels + parseFloat(element.Sales);
              break;

            case 'Tours':
              resultTours = resultHotels + parseFloat(element.Sales);
              break;

            default:
              break;
          }
        });

          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const previousYear = currentYear - 1;

        // console.log("resultHotels", resultHotels);
        // console.log("resultTours", resultTours);
    // console.log(resultHotels)
        if(resultHotels > 0){
          array.push(resultHotels);


          if(year == currentYear){
            this.categoryLabels3.push('HOTELES');
          }

        }

        if(resultTours > 0){
          array.push(resultTours);

          if(year == 2020){
            this.categoryLabels3.push('TOURS');
          }
        }

        //console.log("array dates", array);
        return array;
      }



  getDatesSalesGraphics(response, year){
// console.log(response  )
    // dataSalesAux: any [] = [
    //   {
    //     "label":"2020",
    //     "data":[354,485,232,324,678,444,294,512,428,745,325,894]
    //   },
    //   {
    //     "label":"2019",
    //     "data":[221,428,492,471,413,344,294,221,428,492,471,413]
    //   }
    // ];

    let array = [];

  let monthTotals = {
    'January': 0,
    'February': 0,
    'March': 0,
    'April': 0,
    'May': 0,
    'June': 0,
    'July': 0,
    'August': 0,
    'September': 0,
    'October': 0,
    'November': 0,
    'December': 0
  };

  response.forEach(element => {
    switch (element.month) {
      case '01':
        monthTotals['January'] += parseFloat(element.Sales);
        break;
      case '02':
        monthTotals['February'] += parseFloat(element.Sales);
        break;
      case '03':
        monthTotals['March'] += parseFloat(element.Sales);
        break;
      case '04':
        monthTotals['April'] += parseFloat(element.Sales);
        break;
      case '05':
        monthTotals['May'] += parseFloat(element.Sales);
        break;
      case '06':
        monthTotals['June'] += parseFloat(element.Sales);
        break;
      case '07':
        monthTotals['July'] += parseFloat(element.Sales);
        break;
      case '08':
        monthTotals['August'] += parseFloat(element.Sales);
        break;
      case '09':
        monthTotals['September'] += parseFloat(element.Sales);
        break;
      case '10':
        monthTotals['October'] += parseFloat(element.Sales);
        break;
      case '11':
        monthTotals['November'] += parseFloat(element.Sales);
        break;
      case '12':
        monthTotals['December'] += parseFloat(element.Sales);
        break;
      default:
        break;
    }
  });

  // Luego, empuja el objeto monthTotals al array
  array.push(monthTotals);

  // console.log("array dates", array);
  return array
  }


  selectGraphicsTotalSelling(value: any) {
    if(value === 'qty') {
      this.ngchartExample3.datasets = [];
      this.dataGraphicsQty(this.dataWithYear);
      this.qtyShow = true;
    }
    if(value === 'price') {
      this.ngchartExample4.datasets = [];
      this.dataGraphicsPrice(this.dataWithYear);
      this.qtyShow = false;
    }
  }

  selectCategoryOrQTY(value: any) {
    if(value === 'qty') {
      this.selectedCategoryGraphic = false;
    }
    if(value === 'category') {
      this.selectedCategoryGraphic = true;
      this.initGraphicOne();
    }
  }

  getCountries() {
    // const = `${setting.octopusUrl}`
    this._itineraryService._restService.doGet("api", "/utilities/countries").subscribe({
      next: (res: any) => {
        this.countriesData = res.Countrys;
      }
    })
  }

}


// <div class="dashboard-container">
//     <div class="top-summary">
//         <div class="d-flex flex-column align-items-center p-3 pt-5">
//             <p class="m-0 title-2 font-poppins">BIENVENIDO A</p>
//             <p class="m-0 title-1 mt-1">Tu panel</p>
//         </div>

//         <div class="d-flex flex-row justify-content-center">
//             <div class="d-flex flex-row card-dash-resume p-3 mr-2">
//                 <div class="d-flex pr-3">
//                     <object data="assets/img/app/little-icons/placeholder-2.png" width="38" height="50"></object>
//                 </div>
//                 <div class="d-flex flex-column justify-content-between pl-3 py-2 w-100" style="border-left: solid 1px #00d397;">
//                     <div class="d-flex flex-column justify-content-between">
//                         <p class="m-0 font-poppins total-text">TOTAL</p>
//                         <p class="m-0 font-poppins title-item">FILES</p>
//                     </div>
//                     <div class="d-flex flex-row justify-content-between align-items-center">
//                         <p class="m-0 value-item">{{totalItinerary}}</p>
//                         <object data="assets/img/app/little-icons/recycle.svg" width="25" height="25" style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(214deg) brightness(103%) contrast(103%);"></object>
//                     </div>
//                 </div>
//             </div>
//             <div class="d-flex flex-row card-dash-resume p-3 mr-2">
//                 <div class="d-flex pr-3">
//                     <object data="assets/img/app/little-icons/premium-badge-green.svg" width="45" height="45"></object>
//                 </div>
//                 <div class="d-flex flex-column justify-content-between pl-3 py-2 w-100" style="border-left: solid 1px #00d397;">
//                     <div class="d-flex flex-column justify-content-between">
//                         <p class="m-0 font-poppins total-text">TOTAL</p>
//                         <p class="m-0 font-poppins title-item">BLICKOINS</p>
//                     </div>
//                     <div class="d-flex flex-row justify-content-between align-items-center">
//                         <p class="m-0 value-item">{{totalBlickoins ? totalBlickoins : 0 }}</p>
//                         <object data="assets/img/app/little-icons/recycle.svg" width="25" height="25" style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(214deg) brightness(103%) contrast(103%);"></object>
//                     </div>
//                 </div>
//             </div>
//             <div class="d-flex flex-row card-dash-resume p-3 mr-2">
//                 <div class="d-flex pr-3">
//                     <i class="i-users"></i>
//                 </div>
//                 <div class="d-flex flex-column justify-content-between pl-3 py-2 w-100" style="border-left: solid 1px #00d397;">
//                     <div class="d-flex flex-column justify-content-between">
//                         <p class="m-0 font-poppins total-text">TOTAL</p>
//                         <p class="m-0 font-poppins title-item">CLIENTES</p>
//                     </div>
//                     <div class="d-flex flex-row justify-content-between align-items-center">
//                         <p class="m-0 value-item">{{totalClients}}</p>
//                         <object data="assets/img/app/little-icons/recycle.svg" width="25" height="25" style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(214deg) brightness(103%) contrast(103%);"></object>
//                     </div>
//                 </div>
//             </div>
//             <div class="d-flex flex-row card-dash-resume p-3">
//                 <div class="d-flex pr-3">
//                     <object data="assets/img/app/little-icons/itinerary.svg" width="50" height="42" style="filter: invert(64%) sepia(67%) saturate(2609%) hue-rotate(118deg) brightness(96%) contrast(101%);"></object>
//                 </div>
//                 <div class="d-flex flex-column justify-content-between pl-3 py-2 w-100" style="border-left: solid 1px #00d397;">
//                     <div class="d-flex flex-column justify-content-between">
//                         <p class="m-0 font-poppins total-text">FILES</p>
//                         <p class="m-0 font-poppins title-item">VENCIMIENTO PROPIO</p>
//                     </div>
//                     <div class="d-flex flex-row justify-content-between align-items-center">
//                         <p class="m-0 value-item">{{itineraryVencimientoPropio}}</p>
//                         <object data="assets/img/app/little-icons/recycle.svg" width="25" height="25" style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(214deg) brightness(103%) contrast(103%);"></object>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>

//     <div class="data-dashboard">
//         <div class="d-flex flex-column justify-content-center mx-auto" style="width: 1240px;">
//             <p class="m-0 title-data font-poppins">files</p>
//             <div class="d-flex flex-row justify-content-center mt-3">
//                 <div class="d-flex justify-content-between itinerary-data-save mr-2">
//                     <div class="d-flex flex-column align-items-start justify-content-center">
//                         <p class="m-0 status-name">GUARDADOS</p>
//                     </div>
//                     <div class="d-flex align-items-center pl-3" style="border-left: 1px solid #ffffff;">
//                         <p class="m-0 status-cant">{{totalItinerarySave}}</p>
//                     </div>
//                 </div>
//                 <div class="d-flex justify-content-between itinerary-data-partial mr-2">
//                     <div class="d-flex flex-column align-items-start justify-content-center">
//                         <p class="m-0 status-name">Parcial</p>
//                         <p class="m-0 status-name">Reserva</p>
//                     </div>
//                     <div class="d-flex align-items-center pl-3" style="border-left: 1px solid #ffffff;">
//                         <p class="m-0 status-cant">{{totalItineraryPartial}}</p>
//                     </div>
//                 </div>
//                 <div class="d-flex justify-content-between itinerary-data-reserve mr-2">
//                     <div class="d-flex flex-column align-items-start justify-content-center">
//                         <p class="m-0 status-name">Reservar</p>
//                     </div>
//                     <div class="d-flex align-items-center pl-3" style="border-left: 1px solid #ffffff;">
//                         <p class="m-0 status-cant">{{totalItineraryReserve}}</p>
//                     </div>
//                 </div>
//                 <div class="d-flex justify-content-between itinerary-data-complete">
//                     <div class="d-flex flex-column align-items-start justify-content-center">
//                         <p class="m-0 status-name">Completo</p>
//                     </div>
//                     <div class="d-flex align-items-center pl-3" style="border-left: 1px solid #ffffff;">
//                         <p class="m-0 status-cant">{{totalItineraryCompleted}}</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div class="d-flex flex-column justify-content-center mx-auto mt-5" style="width: 1240px;">
//             <p class="m-0 title-data font-poppins">estadísticas</p>
//             <div class="d-flex flex-row justify-content-between mt-2">
//                 <div class="d-flex flex-column content-gr" style="width: calc(35% - 5px); background-color: #ffffff;" *ngIf="dataWithYear.length > 0">
//                     <div class="d-flex title-chart font-poppins mb-0">
//                         <span class="d-flex align-items-center" style="cursor: pointer; position: relative;" (click)="yearsBar=!yearsBar">
//                             VENTAS TOTAL POR AÑO
//                             <object data="assets/img/app/little-icons/caret.svg" width="10" height="10" class="ml-2"></object>
//                             <span class="d-flex flex-column select-options ml-2" *ngIf="yearsBar">
//                                 <div *ngFor="let yearData of dataWithYear">
//                                   <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectDataSales(yearData)">{{yearData.year}}</span>
//                                 </div>
//                                 <!-- <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectDataSales(0)">2021</span>
//                                 <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectDataSales(1)">2020</span> -->
//                                 <!-- <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectDataSales">2019</span> -->
//                             </span>
//                         </span>
//                     </div>
//                     <p class="totales-chart d-flex flex-row mb-0 align-items-center font-poppins mt-2" *ngIf="dataWithYear.length>0">
//                       <span *ngIf="!qtyShow" class="total-venta">U$D {{ totalPriceYear | number: '1.1-2' }}</span>
//                       <span *ngIf="qtyShow" class="total-venta"> {{ totalSelling }}</span>
//                       <!-- <i class="i-rendimiento mx-2"></i> -->
//                     </p>
//                     <div class="d-flex flex-column mt-2" style="height:200px; width: 100%;">
//                       <div *ngIf="qtyShow" class="d-flex flex-row justify-content-between mt-2">
//                         <canvas  baseChart
//                             [datasets]="ngchartExample3.datasets"
//                             [labels]="ngchartExample3.labels"
//                             [colors]="ngchartExample3.colors"
//                             [options]="ngchartExample3.options"
//                             [chartType]="ngchartExample3.chartType">
//                         </canvas>

//                       </div>

//                       <div  *ngIf="!qtyShow" class="d-flex flex-row justify-content-between mt-2">

//                         <canvas  baseChart
//                           [datasets]="ngchartExample4.datasets"
//                           [labels]="ngchartExample4.labels"
//                           [colors]="ngchartExample4.colors"
//                           [options]="ngchartExample4.options"
//                           [chartType]="ngchartExample4.chartType">
//                         </canvas>

//                       </div>
//                       <div class="d-flex justify-content-center mt-4">
//                         <button type="btn" (click)="selectGraphicsTotalSelling('price')" class="btn bg-info mr-1 d-flex justify-content-center align-items-center" style="font-size: 12px; color: white; width: 95px; height: 28px; border-radius: 50rem;">Valor</button>
//                         <button type="btn" (click)="selectGraphicsTotalSelling('qty')" class="btn bg-warning ml-1 d-flex justify-content-center align-items-center" style="font-size: 12px; color: white; width: 95px; height: 28px; border-radius: 50rem;">Cantidad</button>
//                       </div>
//                     </div>
//                     <!-- <div class="d-flex flex-row justify-content-between mt-auto" style="padding-left: 40px;">
//                       <a class="btn-calendar d-flex flex-row align-items-center font-poppins mr-3"
//                       *ngFor="let item of dataSales; let i = index"
//                       [ngClass]="{'inactive-btn-calendar' : item.label != dataSalesSelected[0].label}"
//                       (click)="selectDataSales(i)">
//                         <i class="i-calendar mr-1" [ngClass]="{'i-calendar-inactive' : item.label != dataSalesSelected[0].label}"></i>
//                         {{item.label}}
//                       </a>
//                     </div> -->

//                     <!-- <div class="d-flex flex-row justify-content-between mt-auto" style="padding-left: 40px;" *ngIf="dataWithYear.length>0">
//                         <a class="btn-calendar d-flex flex-row align-items-center font-poppins mr-3"
//                         *ngFor="let item of dataWithYear; let i = index"
//                         [ngClass]="{'inactive-btn-calendar' : item.label != dataSalesSelected[0].label}"
//                         (click)="selectDataSales(i)">
//                           <i class="i-calendar mr-1" [ngClass]="{'i-calendar-inactive' : item.label != dataSalesSelected[0].label}"></i>
//                           {{item.label}}
//                         </a>
//                     </div> -->
//                 </div>
//                 <div class="d-flex flex-column content-gr" style="width: 100%; background-color: #ffffff;">
//                     <div class="row title-chart font-poppins mb-0">
//                         <span class="col-6 align-items-center" style="cursor: pointer; position: relative; margin-left: 5.5em;">
//                             VENTAS POR CATEGORÍA - PORCENTAJE / CANTIDAD
//                             <!-- <object data="assets/img/app/little-icons/caret.svg" width="10" height="10" class="ml-2"></object>
//                             <span class="d-flex flex-column select-options ml-2" *ngIf="yearsPie">
//                                 <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('category')">Porcentaje</span>
//                                 <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('qty')">Cantidad</span>
//                             </span> -->
//                         </span>
//                         <span class="col-3 align-items-center" style="cursor: pointer; position: relative; margin-left: 2.5em;">
//                           VENTAS POR PAÍS
//                           <!-- <object data="assets/img/app/little-icons/caret.svg" width="10" height="10" class="ml-2"></object>
//                           <span class="d-flex flex-column select-options ml-2" *ngIf="yearsPie">
//                               <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('category')">Porcentaje</span>
//                               <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('qty')">Cantidad</span>
//                           </span> -->
//                       </span>
//                     </div>

//                     <!-- GRAFICOS FUNCIONALES LEO //////////////////////////-->

//                     <div class="d-flex flex-row justify-content-between mt-4" style="height: 100%;" >


//                         <div   class="d-flex flex-column align-items-center" style="width: calc(50% - 5px);">
//                           <div class="d-flex flex-column align-items-center w-100" style="max-width: 300px;height: 100%; position: relative;">
//                               <!-- <p class="yearPie m-0 font-poppins" *ngIf="categoryChart2">{{ categoryValues2[0].year }}</p> -->
//                               <div class="d-flex">
//                                   <canvas width="200" height="200" id="categoryChart2">{{categoryChart2}}</canvas>
//                               </div>
//                               <div class="d-flex flex-row w-100 mt-auto">
//                                   <ul class="nav w-100 d-flex justify-content-between" *ngIf="categoryChart2">
//                                       <li class="nav-items" style="width: 62px;" *ngFor="let item of categoryLabels2; let i = index">
//                                           <span class="d-flex flex-column align-items-center">
//                                               <span class="d-flex w-100" style="height: 10px;" [ngStyle]="{'background-color': categoryColors[0][i]}"></span>
//                                               <span class="d-flex categoryLabelsChartPie font-poppins">{{ item }}</span>
//                                               <span class="d-flex categoryValueChartPie font-poppins text-nowrap">{{ _porcentCalculator(categoryValues2[0].value)[i]  ? _porcentCalculator(categoryValues2[0].value)[i] : 0 }} %</span>
//                                           </span>
//                                       </li>
//                                   </ul>
//                               </div>
//                           </div>
//                       </div>




//                         <div class="d-flex flex-column align-items-center" style="width: calc(50% - 5px);" >
//                             <div class="d-flex flex-column align-items-center w-100" style="max-width: 300px;height: 100%; position: relative;">
//                                 <!-- <p class="yearPie m-0 font-poppins" *ngIf="categoryChart3">{{ categoryValues3[0].year }}</p> -->
//                                 <div class="d-flex">
//                                     <canvas width="200" height="200" id="categoryChart3">{{categoryChart3}}</canvas>
//                                 </div>
//                                 <div class="d-flex flex-row w-100 mt-auto">
//                                     <ul class="nav w-100 d-flex justify-content-between" *ngIf="categoryChart3">
//                                         <li class="nav-items" style="width: 62px;" *ngFor="let item of categoryLabels3; let i = index">
//                                             <span class="d-flex flex-column align-items-center">
//                                                 <span class="d-flex w-100" style="height: 10px;" [ngStyle]="{'background-color': categoryColors[1][i]}"></span>
//                                                 <span class="d-flex categoryLabelsChartPie font-poppins">{{ item }}</span>
//                                                 <span class="d-flex categoryValueChartPie font-poppins text-nowrap">{{ categoryChart3.data.datasets[0].data[i] ? categoryChart3.data.datasets[0].data[i] : 0 }}</span>
//                                             </span>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>



//                          <div  class="d-flex flex-column align-items-center" style="width: calc(50% - 5px);">
//                             <div class="d-flex flex-column align-items-center w-100" style="max-width: 300px;height: 100%; position: relative;">
//                                 <!-- <p class="yearPie m-0 font-poppins" *ngIf="categoryChart2">{{ categoryValues2[0].year }}</p> -->
//                                 <div class="d-flex">
//                                     <canvas width="200" height="200" id="categoryChart1">{{categoryChart1}}</canvas>
//                                 </div>
//                                 <div class="d-flex flex-row w-100 mt-auto">
//                                     <ul class="nav w-100 d-flex justify-content-between" *ngIf="categoryChart1">
//                                         <li class="nav-items" style="width: 62px;" *ngFor="let item of categoryLabels1; let i = index">
//                                             <span class="d-flex flex-column align-items-center">
//                                                 <span class="d-flex w-100" style="height: 10px;" [ngStyle]="{'background-color': categoryColors[0][i]}"></span>
//                                                 <span class="d-flex categoryLabelsChartPie font-poppins">{{ item }}</span>
//                                                 <span class="d-flex categoryValueChartPie font-poppins text-nowrap">{{ _qtyGraphicCountry(products)[i]  ? _qtyGraphicCountry(products)[i] : 0 }} %</span>
//                                             </span>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 <!-- </div> -->
//             </div>
//         </div>
//         <div class="d-flex flex-column justify-content-center mx-auto mt-5" style="width: 1240px;">
//             <div class="d-flex flex-row justify-content-between">
//                 <div class="d-flex flex-column" style="width: calc((85% / 3) - 7px);">
//                     <div class="d-flex flex-column bg-titleContainer justify-content-center align-items-start pl-3">
//                         <p class="m-0 font-poppins" style="font-size: 13px; color: #ffffff; font-weight: 700;">Listado de</p>
//                         <p class="m-0 font-poppins" style="font-size: 20px; color: #ffffff; font-weight: 700;">Clientes por Facturación</p>
//                     </div>
//                     <table class="table w-100" style="border-top-left-radius: 6px; border-top-right-radius: 6px; overflow: hidden; background-color: #ffffff; margin-top: 10px;">
//                         <thead>
//                             <tr class="bg-titleTable">
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">nombre<br>cliente</p></th>
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">monto<br>total</p></th>
//                                 <th class="titleTable font-poppins text-center">% tot</th>
//                             </tr>
//                         </thead>
//                         <tbody *ngIf="reportListClients">
//                             <tr class="rowTable" *ngFor="let client of reportListClients">
//                                 <td class="nameClient font-poppins">{{client.nameClient}}</td>
//                                 <td class="dataClient font-poppins">${{client.total | number: '1.2-2'}}</td>
//                                 <td class="dataClient font-poppins text-center">%{{client.porcent }}</td>
//                             </tr>
//                         </tbody>
//                         <tfoot>
//                             <tr>
//                                 <td colspan="3" class="font-poppins px-3" >
//                                     <p class="pagination text-right mb-0 d-flex justify-content-end">
//                                         <a class="cursorPagination" title="back" (click)="getReportListClients('-')">&lt;&nbsp;&nbsp;</a>
//                                         <span style="font-weight: 900 !important;">{{listPages1}}&nbsp;</span>- {{listPagesTotal1}}
//                                         <a class="cursorPagination" title="next" (click)="getReportListClients('+')">&nbsp;&nbsp;&gt;</a>
//                                     </p>
//                                 </td>
//                             </tr>
//                         </tfoot>
//                     </table>
//                 </div>
//                 <div class="d-flex flex-column" style="width: calc((120% / 3) - 7px);">
//                     <div class="d-flex flex-column bg-titleContainer justify-content-center align-items-start pl-3">
//                         <p class="m-0 font-poppins" style="font-size: 13px; color: #ffffff; font-weight: 700;">Listado de</p>
//                         <p class="m-0 font-poppins" style="font-size: 20px; color: #ffffff; font-weight: 700;">Productos por Facturación</p>
//                     </div>
//                     <table class="table w-100" style="border-top-left-radius: 6px; border-top-right-radius: 6px; overflow: hidden; background-color: #ffffff; margin-top: 10px;">
//                         <thead>
//                             <tr class="bg-titleTable">
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">nombre<br>producto</p></th>
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">veces<br>vend</p></th>
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">monto<br>total</p></th>
//                                 <th class="titleTable font-poppins text-center">% tot</th>
//                             </tr>
//                         </thead>
//                         <tbody *ngIf="reportListProductBilling">
//                             <tr class="rowTable" *ngFor="let product of reportListProductBilling">
//                                 <td class="nameClient font-poppins">{{product.nameProduct}}</td>
//                                 <td class="dataClient font-poppins text-center">{{product.quantitySold}}</td>
//                                 <td class="dataClient font-poppins">${{product.total | number: '1.2-2'}}</td>
//                                 <td class="dataClient font-poppins text-center">{{product.porcent}}</td>
//                             </tr>
//                         </tbody>
//                         <tfoot>
//                             <tr>
//                                 <td colspan="4" class="font-poppins px-3">
//                                     <p class="pagination text-right mb-0 d-flex justify-content-end">
//                                         <a class="cursorPagination" title="back"  (click)="getReportListProducts('-')">&lt;&nbsp;&nbsp;</a>
//                                         <span style="font-weight: 900 !important;">{{listPages2}}&nbsp;</span>- {{listPagesTotal2}}
//                                         <a class="cursorPagination" title="next"  (click)="getReportListProducts('+')">&nbsp;&nbsp;&gt;</a>
//                                     </p>
//                                 </td>
//                             </tr>
//                         </tfoot>
//                     </table>
//                 </div>
//                 <div class="d-flex flex-column" style="width: calc((95% / 3) - 7px);">
//                     <div class="d-flex flex-column bg-titleContainer justify-content-center align-items-start pl-3">
//                         <p class="m-0 font-poppins" style="font-size: 13px; color: #ffffff; font-weight: 700;">Listado de</p>
//                         <p class="m-0 font-poppins" style="font-size: 20px; color: #ffffff; font-weight: 700;">Productos por Proveedor</p>
//                     </div>
//                     <table class="table w-100" style="border-top-left-radius: 6px; border-top-right-radius: 6px; overflow: hidden; background-color: #ffffff; margin-top: 10px;">
//                         <thead>
//                             <tr class="bg-titleTable">
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">nombre<br>proveedor</p></th>
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">prod.<br>reser.</p></th>
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">cat.<br>reser.</p></th>
//                                 <th class="titleTable font-poppins"><p class="m-0" style="line-height: 1;">monto<br>total</p></th>
//                                 <th class="titleTable font-poppins text-center">% tot</th>
//                             </tr>
//                         </thead>
//                         <tbody *ngIf="reportListProductProvider">
//                             <tr class="rowTable" *ngFor="let provider of reportListProductProvider">
//                                 <td class="nameClient font-poppins">{{provider.nameProviders}}</td>
//                                 <td class="dataClient font-poppins text-center">{{provider.quantityProdReserve}}</td>
//                                 <td class="dataClient font-poppins text-center">{{provider.quantityReserve}}</td>
//                                 <td class="dataClient font-poppins">${{provider.total | number: '1.2-2'}}</td>
//                                 <td class="dataClient font-poppins text-center">{{provider.porcent}}</td>
//                             </tr>
//                         </tbody>
//                         <tfoot>
//                             <tr>
//                                 <td colspan="5" class="font-poppins px-3">
//                                     <p class="pagination text-right mb-0 d-flex justify-content-end">
//                                         <a class="cursorPagination" title="back" (click)="getReportListProductsProviders('-')">&lt;&nbsp;&nbsp;</a>
//                                         <span style="font-weight: 900 !important;">{{listPages3}}&nbsp;</span>- {{listPagesTotal3}}
//                                         <a class="cursorPagination" title="next" (click)="getReportListProductsProviders('+')">&nbsp;&nbsp;&gt;</a>
//                                     </p>
//                                 </td>
//                             </tr>
//                         </tfoot>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     </div>


//     <div class="dashboard-content justify-content-center d-none">
//         <div class="d-xg-flex d-lg-flex flex-row justify-content-center marco">
//             <div [ngClass]="!userIsAmin ? 'col-xg-4 col-lg-4 col-md-6 col-sm-6 alig-card-sup' : 'col-xg-3 col-lg-3 col-md-4 col-sm-4 alig-card-sup'">
//                 <div class="card card-stats">
//                     <div class="card-body ">
//                         <div class="row">
//                             <div class="col-5">
//                                 <div class="icon-big text-center icon-warning">
//                                     <i class="icon-itinerary text-warning"></i>
//                                 </div>
//                             </div>
//                             <div class="col-7">
//                                 <div class="numbers">
//                                     <p class="card-category">Total Itineraries</p>
//                                     <h4 class="card-title">{{totalItineraries}}</h4>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="card-footer ">
//                         <div class="stats"> <!-- (click)="getTotalItineraries()" -->
//                             <a><i class="fa fa-refresh"></i> Update Now</a>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div [ngClass]="!userIsAmin ? 'col-xg-4 col-lg-4 col-md-6 col-sm-6 alig-card-sup' : 'col-xg-3 col-lg-3 col-md-4 col-sm-4 alig-card-sup'">

//                 <div class="card card-stats">
//                     <div class="card-body ">
//                         <div class="row">
//                             <div class="col-5">
//                                 <div class="icon-big text-center icon-success">
//                                     <i class="icon-point-agent"></i>
//                                 </div>
//                             </div>
//                             <div class="col-7">
//                                 <div class="numbers">
//                                     <p class="card-category" (click)="goTo('pointxagent')" >My Blickoins</p>
//                                     <h4 class="card-title">15000</h4>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="card-footer ">
//                         <div class="stats">
//                             <i class="fa fa-refresh"></i> Update Now
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div [ngClass]="!userIsAmin ? 'col-xg-4 col-lg-4 col-md-6 col-sm-6 alig-card-sup' : 'col-xg-3 col-lg-3 col-md-4 col-sm-4 alig-card-sup'">
//                 <div class="card card-stats">
//                     <div class="card-body ">
//                         <div class="row">
//                             <div class="col-5">
//                                 <div class="icon-big text-center icon-danger">
//                                     <i class="icon-user-tie text-danger"></i>
//                                 </div>
//                             </div>
//                             <div class="col-7">
//                                 <div class="numbers">
//                                     <p class="card-category">Clients</p>
//                                     <h4 class="card-title">{{clients.length}}</h4>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="card-footer ">
//                         <div class="stats">
//                             <a (click)="getClients()"><i class="fa fa-refresh"></i> Update Now</a>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div *ngIf="userIsAmin" [ngClass]="!userIsAmin ? 'col-xg-4 col-lg-4 col-md-6 col-sm-6 alig-card-sup' : 'col-xg-3 col-lg-3 col-md-4 col-sm-4 alig-card-sup'">
//                 <div class="card card-stats">
//                     <div class="card-body ">
//                         <div class="row">
//                             <div class="col-5">
//                                 <div class="icon-big text-center icon-warning">
//                                     <i class="icon-agent-i text-warning"></i>
//                                 </div>
//                             </div>
//                             <div class="col-7">
//                                 <div class="numbers">
//                                     <p class="card-category">Agents</p>
//                                     <h4 class="card-title">{{totalAgents}}</h4>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="card-footer ">
//                         <div class="stats">
//                             <i class="fa fa-refresh"></i> Update Now
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </div>

//         <div class="row justify-items-center mx-4 px-3 mt-5 pt-1 font-title-sep">
//             Itineraries
//         </div>

//         <div class="row justify-items-center marco">
//             <div class="d-xg-flex d-lg-flex flex-row justify-content-center w-100 mr-0 pl-3">
//                 <!-- <ngx-charts-number-card [scheme]="colorScheme" [results]="results" [textColor]="textColor" (select)="onSelect($event)">
//                 </ngx-charts-number-card> -->
//             </div>
//         </div>

//         <div class="row justify-items-center mx-4 px-3 mt-5 pt-1 font-title-sep">
//             Stadistics
//         </div>

//         <div class="row justify-items-center marco">
//             <div class="d-xg-flex d-lg-flex flex-row justify-content-center w-100">
//                 <div class="col-xg-6 col-lg-6 col-md-6 col-sm-6 alig-card-sup">
//                     <div class="card card-stats">
//                         <div class="card-body ">
//                             <div class="row">
//                                 <!-- <ngx-charts-advanced-pie-chart [view]="viewsalesa" [scheme]="colorScheme" [results]="annualsales" [gradient]="gradient" (select)="onSelect($event)">
//                                 </ngx-charts-advanced-pie-chart> -->
//                             </div>
//                         </div>
//                         <div class="card-footer ">
//                             <div class="stats text-center pt-2">
//                                 Venta total por año en curso y anteriores
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div class="col-xg-6 col-lg-6 col-md-6 col-sm-6 alig-card-sup">
//                     <div class="card card-stats">
//                         <div class="card-body ">
//                             <div class="row">
//                                 <!-- <ngx-charts-advanced-pie-chart [view]="viewsalesa" [scheme]="colorScheme" [results]="annualsales" [gradient]="gradient" (select)="onSelect($event)">
//                                 </ngx-charts-advanced-pie-chart> -->

// <!--
//                                 <canvas baseChart
//                                         [datasets]="ngchartExample.datasets['2016']"
//                                         [labels]="ngchartExample.labels"
//                                         [colors]="ngchartExample.colors"
//                                         [options]="ngchartExample.options"
//                                         [chartType]="ngchartExample.chartType">
//                                 </canvas>

//                                 <canvas baseChart
//                                     [datasets]="ngchartExample2.datasets"
//                                     [labels]="ngchartExample2.labels"
//                                     [colors]="ngchartExample2.colors"
//                                     [options]="ngchartExample2.options"
//                                     [chartType]="ngchartExample2.chartType">
//                                 </canvas> -->

//                             </div>
//                         </div>
//                         <div class="card-footer ">
//                             <div class="stats text-center pt-2">
//                                 Venta total x categoria
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <!-- <div class="col-xg-8 col-lg-8 col-md-12 col-sm-12 alig-card-sup">
//                     <div class="card card-stats">
//                         <div class="card-body ">
//                             <div class="row d-flex justify-content-end mx-3">
//                                 <span>Sales by City</span>
//                             </div>
//                             <div class="row">
//                                 <div class="col-12">
//                                     <div class="numbers">

//                                         <div class="dx-viewport mb-1">
//                                             <dx-vector-map
//                                               id="vector-map"
//                                               [bounds]="[-180, 85, 180, -75]"
//                                               [zoomingEnabled]="false">
//                                               <dxo-control-bar [enabled]=false></dxo-control-bar>
//                                               <dxo-tooltip
//                                                   [enabled]="true"
//                                                   [customizeTooltip]="customizeTooltip"></dxo-tooltip>
//                                               <dxi-layer
//                                                   [dataSource]="worldMap"
//                                                   name="areas"
//                                                   palette="Violet"
//                                                   [colorGroups]="[0, 0.5, 0.8, 1, 2, 3, 100]"
//                                                   colorGroupingField="population"
//                                                   minSize=13
//                                                   title=false
//                                                   [hoverEnabled]="true"
//                                                   hoveredBorderColor="#af1111"
//                                                   hoveredColor="#af1111"
//                                                   [customize]="customizeLayers">
//                                                   <dxo-label [enabled]="false" dataField="name"></dxo-label>
//                                               </dxi-layer>
//                                               <dxi-layer
//                                                   [dataSource]="markers"
//                                                   name="markers"
//                                                   elementType="bubble"
//                                                   dataField="value"
//                                                   [sizeGroups]="[0, 8000, 10000, 50000]"
//                                                   minSize=13
//                                                   title=false
//                                                   [hoverEnabled]="true"
//                                                   opacity="0.8">
//                                                   <dxo-label [enabled]="false"></dxo-label>
//                                               </dxi-layer>
//                                           </dx-vector-map>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="card-footer ">
//                             <hr>
//                             <div class="stats">
//                                 <i class="fa fa-refresh"></i> Update Now
//                             </div>
//                         </div>
//                     </div>
//                 </div> -->
//             </div>
//         </div>
//         <div class="row justify-items-center marco" *ngIf="clients">
//             <div class="d-xg-flex d-lg-flex flex-row justify-content-center marco mb-5">
//                 <div class="col-xg-4 col-lg-4 col-md-12 col-sm-12 alig-card-sup">
//                     <div class="card card-stats">
//                         <div class="card-body">
//                             <div class="x_title numbers">
//                                 <p class="card-category">My Clients</p>
//                                 <h4 class="card-title">{{clients.length}}</h4>
//                                 <div class="clearfix"></div>
//                             </div>
//                             <div class="x_content table table-responsive" style="display: block;">

//                                 <table class="table">
//                                     <thead>
//                                         <tr>
//                                             <th>#</th>
//                                             <th>Name</th>
//                                             <th>Email</th>
//                                             <th>Phone</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr *ngFor="let client of clients; let i = index">
//                                             <th scope="row">{{i}}</th>
//                                             <td>{{client.first_name}} {{(client.last_name) ? client.last_name : ''}}</td>
//                                             <td>{{client.email}}</td>
//                                             <td>{{client.phone}}</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>

//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div class="col-xg-4 col-lg-4 col-md-12 col-sm-12 alig-card-sup">
//                     <div class="card card-stats">
//                         <div class="card-body">
//                             <div class="x_title numbers">
//                                 <p class="card-category">My Clients</p>
//                                 <h4 class="card-title">{{clients.length}}</h4>
//                                 <div class="clearfix"></div>
//                             </div>
//                             <div class="x_content table table-responsive" style="display: block;">

//                                 <table class="table">
//                                     <thead>
//                                         <tr>
//                                             <th>#</th>
//                                             <th>Name</th>
//                                             <th>Email</th>
//                                             <th>Phone</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr *ngFor="let client of clients; let i = index">
//                                             <th scope="row">{{i}}</th>
//                                             <td>{{client.first_name}} {{(client.last_name) ? client.last_name : ''}}</td>
//                                             <td>{{client.email}}</td>
//                                             <td>{{client.phone}}</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>

//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div class="col-xg-4 col-lg-4 col-md-12 col-sm-12 alig-card-sup" >
//                     <div class="card card-stats">
//                         <div class="card-body">
//                             <div class="x_title numbers">
//                                 <p class="card-category">My Clients</p>
//                                 <h4 class="card-title">{{clients.length}}</h4>
//                                 <div class="clearfix"></div>
//                             </div>
//                             <div class="x_content table table-responsive" style="display: block;">

//                                 <table class="table">
//                                     <thead>
//                                         <tr>
//                                             <th>#</th>
//                                             <th>Name</th>
//                                             <th>Email</th>
//                                             <th>Phone</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr *ngFor="let client of clients; let i = index">
//                                             <th scope="row">{{i}}</th>
//                                             <td>{{client.first_name}} {{(client.last_name) ? client.last_name : ''}}</td>
//                                             <td>{{client.email}}</td>
//                                             <td>{{client.phone}}</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <!-- <div class="col-xg-7 col-lg-7 col-md-12 col-sm-12 alig-card-sup">
//                     <div class="card card-stats">
//                         <div class="card-body ">
//                             <div class="row">
//                                 <div class="col-12">
//                                     <div class="numbers">
//                                         <p class="card-category">Sales Distribution</p>
//                                         <h4 class="card-title">150</h4>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="card-footer bg-card">
//                             <div class="stats d-flex justify-content-center">
//                                 <ngx-charts-pie-chart [view]="viewpie" [legend]="showLegend" [explodeSlices]="explodeSlices" [labels]="showLabels" [doughnut]="doughnut"
//                                     [gradient]="gradient" [results]="sales" (select)="onSelect($event)">
//                                 </ngx-charts-pie-chart>
//                             </div>
//                         </div>
//                     </div>
//                 </div> -->
//             </div>
//         </div>



//     </div>

// </div>
