<div class="d-flex w-100 justify-content-between" [ngClass]="{ darkAir: invoke == 0 && modeView == 'MULTIDESTINATION' }" *ngIf="(_formFlights && invoke === 0)" >
  <!-- -->
  <div class="flex-column d-flex justify-content-between" *ngIf="flightIn; else Please">
    <div class="row pl-2 pt-2 pr-1 pb-2">
      <div class="col-10 pt-1"></div>
      <div class="col-2 p-0 pl-2 align-items-center d-flex">
        <div class="p-0 m-0">
          <div class="p-0 mt-2 d-flex justify-content-end" id="clear-flight" (click)="_removeFlight()">
            <button class="btn btn-usblick btn-sm" style="margin-bottom: -10px">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <span class="dx-progressbar-anima ting-segment-2 d-flex"></span>
    <div class="row pl-2 pt-2 pr-1 pb-2">
      <div class="col-2 pt-1">
        <i class="pl-5 icon icon-air-inn" aria-hidden="true"></i>
      </div>
      <div class="col-8">
        <h6 class="font-air"><strong> Llegada </strong></h6>
        <span class="px-1 font-air-desc">
          {{ flightIn.airlineIn }} -
          <span class="text-uppercase">{{ flightIn.airlineIataCodeOut }}{{ flightIn.flightNumberIn }}</span>
        </span>
        <span class="px-1 font-air-desc"
          >Dep: <span class="text-uppercase">{{ flightIn.departureDateIn | date : "dd MMM yyyy, HH:mm" }}</span>
          - Arr:
          <span class="text-uppercase">{{ flightIn.arrivalDateIn | date : "dd MMM yyyy, HH:mm" }}</span></span
        >
      </div>
      <div class="col-2 p-0 pl-2 align-items-center d-flex">
        <div class="p-0 m-0">
          <button
            type="button"
            class="btn btn-sm pr-1 btn-edit"
            style="background-color: black"
            data-placement="bottom"
            title="{{ _formFlights.value['id'] === null ? 'New' : 'Edit' }}"
            (click)="openModalWithClass(template)"
            [disabled]="_actualItinerary === null"
          >
            <i
              class="{{ _formFlights.value['id'] === null ? 'icon-edit icon icon-airplane_45' : 'icon-edit icon icon-pencil' }}"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <span style="border-bottom: 1px solid #e3e3e3; width: 100%; margin: 0px; margin-left: 0px"></span>
    <div class="row pl-2 pt-2 pr-1 pb-2">
      <div class="col-2 pt-1">
        <i class="pl-5 icon icon-air-out" aria-hidden="true"></i>
      </div>
      <div class="col-8">
        <h6 class="font-air"><strong> Salida </strong></h6>
        <span class="px-1 font-air-desc">
          {{ flightOut.airlineOut }} -
          <span class="text-uppercase">{{ flightOut.airlineIataCodeOut }}{{ flightOut.flightNumberOut }}</span>
        </span>
        <span class="px-1 font-air-desc"
          >Dep: <span class="text-uppercase">{{ flightOut.departureDateOut | date : "dd MMM yyyy, HH:mm" }}</span></span
        >
      </div>
      <div class="col-2 p-0 pl-2 align-items-center d-flex">
        <div class="p-0 m-0">
          <button
            type="button"
            class="btn btn-sm pr-1 btn-edit"
            style="background-color: black"
            data-placement="bottom"
            title="{{ _formFlights.value['id'] === null ? 'New' : 'Edit' }}"
            (click)="openModalWithClass(template)"
            [disabled]="_actualItinerary === null"
          >
            <i
              class="{{ _formFlights.value['id'] === null ? 'icon-edit icon bi-airplane' : 'icon-edit icon icon-pencil' }}"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <span style="border-bottom: 0px solid #e3e3e3; width: 100%; margin: 0px; margin-left: 0px"></span>
  </div>
</div>

<div *ngIf="_formFlights && invoke === 1" class="d-flex justify-content-between">
  <div class="d-flex flex-row justify-content-between">
    <div class="d-flex align-items-center mr-3">
      <div *ngIf="flightIn">
        <span class="pr-1 font-detalles">
          <i class="icon-air-in-dark" aria-hidden="true"></i> <span class="date-fly">{{ flightIn.arrivalDateIn | date : "dd MMM" }}</span
          >&nbsp;
          <!-- {{subStrings(flightIn.airlineIn)}} - -->
          {{ flightIn.airportIataCodeIn }} -
          <span class="text-uppercase">{{ flightIn.airlineIataCodeOut }}{{ flightIn.flightNumberIn }}</span> Dep:
          <span class="text-uppercase">{{ flightIn.departureDateIn | date : "HH:mm" }}</span> - Arr:
          <span class="text-uppercase">{{ flightIn.arrivalDateIn | date : "HH:mm" }}</span></span
        >
        <br />
        <!--<span class="p-2"><strong><i class="px-1 icon icon-airplane" aria-hidden="true"></i> OUT </strong>  Airl: {{flightOut.airlineOut}} <b class="font-dark px-1">Fn: </b> {{flightOut.flightNumberOut}} <b class="font-dark px-1"> Airp: </b> {{flightOut.airportOut}}   <b class="font-dark px-1"> Dep: </b> {{(flightOut.departureDateOut | date:'MMM dd, yyyy, HH:mm a')}} ->
       </span>-->
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div *ngIf="flightIn; else Please">
        <span class="pl-1 font-detalles">

          <i class="icon-air-out-dark" aria-hidden="true"></i> <span class="date-fly">{{ flightOut.departureDateOut | date : "dd MMM" }}</span
          >&nbsp;
          <!-- {{subStrings(flightOut.airlineOut)}} - -->
          {{ flightOut.airportIataCodeOut }} -
          <span class="text-uppercase">{{ flightOut.airlineIataCodeOut }}{{ flightOut.flightNumberOut }}</span> Dep:
          <span class="text-uppercase">{{ flightOut.departureDateOut | date : "HH:mm" }}</span></span
        >
        <br />
      </div>
    </div>
  </div>

  <div class="mx-3 py-1 align-items-center" *ngIf="flightIn">
    <button
      type="button"
      class="btn btn-sm p-0 btn-editar"
      style="background-color: black"
      data-placement="bottom"
      title="{{ _formFlights.value['id'] === null ? 'New' : 'Edit' }}"
      (click)="openModalWithClass(template)"
      [disabled]="_actualItinerary === null"
    >
      <i class="{{ _formFlights.value['id'] === null ? 'icon-edit icon  bi-airplane' : 'icon-edit icon bi-pencil' }}" aria-hidden="true"></i>
    </button>
    <button class="btn btn-usblick btn-sm" style="width: 29px !important; height: 29px !important; margin-left: 5px;" (click)="_removeFlight()">
      <i class="fa fa-times"></i>
    </button>
  </div>
</div>

<ng-template #template style="background: transparent">
  <!-- *ngIf="_formFlights" -->
  <!--  -->
  <form [formGroup]="_formFlights" (ngSubmit)="checkflight()">
    <input type="hidden" name="valueAirlineIn" ng-model="valueAirlineIn" />
    <div class="modal-body" id="modal-edit-flight">
      <button
        class="btn btn-closed d-flex justify-content-center align-items-center p-0 pb-3"
        style="margin-left: 813px !important"
        (click)="closeModal()"
      >
        <p aria-hidden="true" class="m-0" style="font-weight: 100; font-size: 50px; line-height: 25px">&times;</p>
      </button>
      <div role="document">
        <div style="padding: 0px 15px">
          <div class="modal-title-content px-3 py-2">
            <h6 class="text-left title-fly font-poppins p-0 m-0 text-uppercase">
              {{ _formFlights.value["id"] === null ? "Nuevo" : "Editar" }} Vuelo
            </h6>
          </div>
        </div>

        <div>
          <div class="d-flex text-left pt-0 px-4 fontysizer-15 pt-4 pb-1">
            <div class="circle-icon rounded-circle mr-3" style="background-color: #414141">
              <i class="icon-airplane-green"></i>
            </div>
            <span class="text-plane font-poppins pt-1">Llegada</span>
          </div>
          <div class="form-group m-0 pt-0 pb-0 text-left col-12 d-flex flex-row justify-content-between scroll">
            <input
              [ngClass]="{'is-valid': this._formFlights.value.airportIn != null, 'is-invalid': this._formFlights.value.airportIn == null}"
              (valueChanged)="_myCallbackAirportIn($event)"
              ngui-auto-complete
              formControlName="airportIn"
              type="text"
              name="airportIn"
              class="form-control font-poppins font-inputs"
              value-formatter="name"
              placeholder="Aeropuerto"
              [source]="observableSourceAirportIn.bind(this)"
              display-property-name="name"
              [list-formatter]="_myListFormatterAirport"
              accept-user-input="false"
              autocomplete="off"
              loading-template="Loading..."
              style="width: 279px !important; margin-right: 10px !important; color: red"
            />
            <!---  <div class="ng-autocomplete">
                            <ng-autocomplete
                               formControlName='airportIn'
                               name="airportIn"
                              [data]="observableSourceAirportIn.bind(keyword)"
                              [searchKeyword]="keyword"
                              placeholder="Enter the Country Name"
                              (selected)="selectEvent($event)"
                              (inputChanged)="onChangeSearch($event)"
                              (inputFocused)="onFocused($event)"
                              historyIdentifier="countries"
                              [itemTemplate]="itemTemplate"
                              [notFoundTemplate]="notFoundTemplate"
                              isLoading="isLoadingResult"
                              [minQueryLength]="2"
                            >
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a href="javascript:void(0)">
                                    <div class="row">
                                        <div class="col-2">
                                      <i class="fa fa-plane" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10">
                                            <span [innerHTML]="item.nameAirline"></span><i>({{item.codeIataAirline}})</i>
                                        </div>
                                    </div>
                                </a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                {{errorMsg}}
                            </ng-template>

                         </div>
                        -->

            <input
              [ngClass]="{'is-valid': this._formFlights.value.airlineIn != null, 'is-invalid': this._formFlights.value.airlineIn == null}"
              (valueChanged)="_myCallbackAirlineIn($event)"
              ngui-auto-complete
              formControlName="airlineIn"
              type="text"
              id="airlineIn"
              name="Airline"
              class="form-control font-poppins font-inputs"
              value-formatter="name"
              placeholder="Aerolinea"
              [source]="observableSourceAirlineIn.bind(this)"
              display-property-name="name"
              [list-formatter]="_myListFormatterAirline"
              accept-user-input="false"
              autocomplete="off"
              style="width: 279px !important; margin-right: 10px !important"
            />

            <input
              [ngClass]="{'is-valid': this._formFlights.value._flightNumberIn != null, 'is-invalid': this._formFlights.value._flightNumberIn == null}"
              formControlName="_flightNumberIn"
              [(ngModel)]="_flightNumberIn"
              type="text"
              class="form-control font-poppins font-inputs btn-_numberfly"
              id="_flight-numberIn"
              placeholder=""
              maxlength="2"
              autocomplete="off"
              readonly
              style="width: 65px !important; margin-right: 9px !important"
            />
            <input
              [ngClass]="{'is-valid': this._formFlights.value.flightNumberIn != null, 'is-invalid': this._formFlights.value.flightNumberIn == null}"
              formControlName="flightNumberIn"
              type="text"
              class="form-control font-poppins font-inputs btn-numberfly"
              id="flight-numberIn"
              placeholder="Número de Vuelo"
              maxlength="4"
              style="width: 200px !important"
            />
          </div>
          <div class="form-group m-0 pt-0 pb-0 text-left col-12 d-flex flex-row align-items-center justify-content-between">
            <div class="form-group m-0 pt-0 pb-0 text-left d-flex flex-row align-items-center">
              <input
                [ngClass]="{'is-valid': this._formFlights.value.departureDateIn != null, 'is-invalid': this._formFlights.value.departureDateIn == null}"
                formControlName="departureDateIn"
                type="text"
                class="form-control font-poppins font-inputs px-3"
                id="departureDateIn"
                placeholder="Fecha de salida"
                autocomplete="off"
                bsDatepicker
                [minDate]="minDate"
                [bsConfig]="dpConfig"
                readonly
                style="width: 279px !important; margin-right: 10px !important"
              />
              <timepicker formControlName="departureHourIn" [showMeridian]="!ismeridian"></timepicker>
            </div>
            <div class="form-group m-0 pt-0 pb-0 text-left d-flex flex-row align-items-center">
              <input
                [ngClass]="{'is-valid': this._formFlights.value.arrivalDateIn != null, 'is-invalid': this._formFlights.value.arrivalDateIn == null}"
                formControlName="arrivalDateIn"
                type="text"
                class="form-control font-poppins font-inputs"
                id="arrivalDateIn"
                placeholder="Fecha de llegada"
                autocomplete="off"
                bsDatepicker
                [minDate]="minDate"
                [bsConfig]="dpConfig"
                readonly
                style="width: 279px !important; margin-right: 10px !important"
              />
              <timepicker formControlName="arrivalHourIn" [showMeridian]="!ismeridian"></timepicker>
            </div>
          </div>
          <div class="form-group m-0 pt-0 pb-0 text-left col-12 d-flex flex-row align-items-center justify-content-between">
            <span
              class="help-block text-danger"
              *ngIf="_formFlights.get('departureDateIn').touched && _formFlights.get('departureDateIn').hasError('required')"
              >Este campo es obligatorio</span
            >
            <small
              class="error-label"
              *ngIf="
                _formFlights.get('departureDateIn').touched &&
                _formFlights.get('arrivalDateIn').value !== null &&
                _formFlights.get('departureDateIn').hasError('invalidDate')
              "
            >
              * El check-in debe ser una fecha anterior al check-out!
            </small>
            <span
              class="help-block text-danger"
              *ngIf="_formFlights.get('arrivalDateIn').touched && _formFlights.get('arrivalDateIn').hasError('required')"
              >Este campo es obligatorio</span
            >
            <small
              class="error-label"
              *ngIf="
                _formFlights.get('arrivalDateIn').touched &&
                _formFlights.get('departureDateIn').value !== null &&
                _formFlights.get('arrivalDateIn').hasError('invalidDate')
              "
            >
              * El check-in debe ser una fecha anterior al check-out!
            </small>
          </div>
        </div>
        <div>
          <div class="d-flex text-left pt-0 pb-1 px-4 fontysizer-15">
            <!-- <i class="px-2 icon icon-airplane" aria-hidden="true"></i> -->
            <div class="circle-icon rounded-circle mr-3" style="background-color: #87ca99">
              <i class="icon-airplane-dark"></i>
            </div>
            <span class="text-plane font-poppins pt-1">Salida</span>
          </div>
          <div class="form-group m-0 pt-0 pb-0 text-left col-12 d-flex flex-row justify-content-between scroll">
            <input
             [ngClass]="{'is-valid': this._formFlights.value.airportOut != null, 'is-invalid': this._formFlights.value.airportOut == null}"
              (valueChanged)="_myCallbackAirportOut($event)"
              ngui-auto-complete
              formControlName="airportOut"
              type="text"
              name="airportOut"
              class="form-control font-poppins font-inputs"
              value-formatter="name"
              placeholder="Aeropuerto"
              [source]="observableSourceAirportOut.bind(this)"
              display-property-name="name"
              [list-formatter]="_myListFormatterAirport"
              accept-user-input="false"
              autocomplete="off"
              style="width: 279px !important; margin-right: 10px !important"
            />
            <!-- (valueChanged)="_myCallbackAirlineIn($event)" - en caso de uso -->
            <!-- [list-formatter]="_myListFormatterAirline" - en caso de uso -->
            <input
              [ngClass]="{'is-valid': this._formFlights.value.airlineOut != null, 'is-invalid': this._formFlights.value.airlineOut == null}"
              formControlName="airlineOut"
              ngui-auto-complete
              [(ngModel)]="airlineOut"
              placeholder="Aerolinea"
              value-formatter="name"
              [source]="observableSourceAirlineOut.bind(this)"
              type="text"
              id="airlineOut"
              name="Airline"
              display-property-name="name"
              class="form-control font-poppins font-inputs"
              style="margin-right: 10px !important"

            />
            <input
              [ngClass]="{'is-valid': this._formFlights.value._flightNumberIn != null, 'is-invalid': this._formFlights.value._flightNumberIn == null}"
              formControlName="_flightNumberOut"
              [(ngModel)]="_flightNumberIn"
              type="text"
              class="form-control font-poppins font-inputs btn-_numberfly"
              id="_flight-numberOut"
              placeholder=""
              maxlength="2"
              readonly
              style="width: 75px !important; margin-right: 9px !important"
            />
            <input
            [ngClass]="{'is-valid': this._formFlights.value.flightNumberOut != null, 'is-invalid': this._formFlights.value.flightNumberOut == null}"
              formControlName="flightNumberOut"
              type="text"
              class="form-control font-poppins font-inputs btn-numberfly"
              id="flight-numberOut"
              placeholder="Número de vuelo"
              maxlength="4"
              style="width: 200px !important"
            />
          </div>

          <div class="form-group m-0 pt-0 pb-0 text-left col-6 d-flex flex-row align-items-center justify-content-center">
            <input
              [ngClass]="{'is-valid': this._formFlights.value.departureDateOut != null, 'is-invalid': this._formFlights.value.departureDateOut == null}"
              formControlName="flightNumberOut"
              formControlName="departureDateOut"
              type="text"
              class="form-control font-poppins font-inputs px-3"
              id="departureDateOut"
              placeholder="Fecha de Salida"
              autocomplete="off"
              bsDatepicker
              [minDate]="minDate"
              [bsConfig]="dpConfig"
              readonly
              style="width: 279px !important; margin-right: 10px !important"
            />
            <timepicker [ngClass]="{'is-valid': this._formFlights.value.departureHourOut != null, 'is-invalid': this._formFlights.value.departureHourOut == null}"  formControlName="departureHourOut" [showMeridian]="!ismeridian"></timepicker>
          </div>
        </div>
        <span class="required-marker">* Campos Obligatorios</span>
        <div class="d-flex justify-content-end align-items-center p-2" id="apply-btn-r">
          <button
            type="submit"
            class="btn-sm font-poppins btn-buscar"
            [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_1 }"
            [disabled]="!_formFlights.valid"
          >
            ACEPTAR
          </button>
        </div>
        <div class="form-row">
          <div class="form-group col-xg-5 col-lg-5 col-xs-0 p-0 align-items-center justify-content-between"></div>
          <div class="form-group col-xg-7 col-lg-7 col-xs-0 p-0 align-items-center justify-content-between">
            <p *ngFor="let info of message" class="error-label">* {{ info }}</p>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #Please>
  <div
    class="d-flex align-items-center p-2"
    [ngClass]="{ 'text-dark': invoke === 0 && modeView == 'RESULT', textLight: invoke === 0 && modeView !== 'RESULT' }"
  >
    <span>Por favor ingrese la información de su vuelo </span>
  </div>
  <div class="py-1 mr-3 align-items-center text-white">
    <button
      type="button"
      class="btn btn-sm"
      style="background-color: black"
      data-placement="bottom"
      title="{{ _formFlights?.value['id'] === null ? 'New' : 'Edit' }}"
      (click)="openModalWithClass(template)"
      [disabled]="_actualItinerary === null"
    >
      <i class="{{ _formFlights?.value['id'] === null ? 'bi-edit icon bi-airplane' : 'bi-edit icon bi-pencil' }}" aria-hidden="true"></i>
    </button>
  </div>
</ng-template>
