import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { formatArrivalDate, generateArray } from '../../../../../../utils/Functions';
import { ItineraryService } from '../../../../../../shared/itinerary.service';
import { RestService } from '../../../../../../shared/rest.service';
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap';
import { MaterialModalConfirmComponent } from '../../../../../../shared/material-modal-confirm/material-modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { UserService } from '../../../../../../pages/com.usblick.common/auth/user.service';
import { element } from 'protractor';
import { ModalsComponent } from '../../../../../../shared/modals/modals.component';

@Component({
  selector: 'usb-item-hotel',
  templateUrl: './item-hotel.component.html',
  styleUrls: ['./item-hotel.component.scss']
})
export class ItemHotelComponent implements OnInit {
  @Input() dato: any;
  @Input() isCheckIn: boolean;
  @Output() itemDelete = new EventEmitter<any>();
  @Output() itemToPartial = new EventEmitter<number>();
  @Input() partialReserve: boolean;
  blickoins: any;
  @ViewChild('modalRooms') modalRooms: ModalDirective;

  currencyItinerary: string;
  bsModalRef?: BsModalRef;
  rate:number = 0;

  starValidate:boolean = false;


  public formatDate = formatArrivalDate;
  public generateStars = generateArray;
  constructor(
    private itineraryService: ItineraryService,
    private restService :RestService,
    private dialog: MatDialog,
    public userService : UserService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    // console.log("this.dato hotel", this.dato);
   // this.getProduct();
   console.log(this.dato)
   this.currencyItinerary = this.dato?.currency;
   this.getStarsHotels();
  }


  edit(){

  }

  getProduct(){

    if (this.dato.payloadUpdate) {
      console.log('search/products------------------> getProduct ItemHotelComponent',  this.dato.payloadUpdate);
      this.restService.doPost('app', 'search/products', this.dato.payloadUpdate).subscribe(
        (response:any) => {
console.log(response)
          if(this.isCheckIn && !this.dato.nr){
            this.updateNRProduct();
          }

          if (response && response.status && response.results) {
            if (response.results[0] !== undefined) {
              this.dato.product = response.results[0];
            } else {
              this.dato.product = response.results;
            }
          }
          console.log("this.item actualizado hotel", this.dato);
        }, error => {
          console.log(error.message);
        }
      );
    }
  }

  removeItem(item: any) {
    console.log('inicio')
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Quieres eliminar este elemento?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if(result) {
        this.itineraryService.remove(item);
        this.itemDelete.emit(item);
      } else {

      }
      confirmSubscription.unsubscribe();
    });
  }

  toPartialReserve(event, item: any){
    if ( event.target.checked ) {
      this.itineraryService.updateItineraryPartial(item);
      this.itemToPartial.emit(1);
    } else {
      this.itineraryService.updateItineraryPartial(item,true);
      this.itemToPartial.emit(-1);
    }
  }

  subtractDays(date){
    return moment(moment(date)).subtract(2, 'days').format('YYYY-MM-DD');
  }

  updateNRProduct(){

    let date = this.dato.expirationDate ? moment(new Date()) : null;

    if(date){
      if( (date > moment(this.dato.expirationDate).subtract(2, 'days')) ){

        console.log(" this.userService.itineraryActive",  this.userService.itineraryActive);

        this.userService.itineraryActive[0].items.forEach(element => {
          if(this.dato.id == element.id){
            console.log("consegui item en itineraio");
            element.nr = true;
            console.log(" this.userService.itineraryActive update",  this.userService.itineraryActive);
            this.itineraryService.updateItinerary(this.userService.itineraryActive[0], false);
            return;
          }
        });

      }
    }


  }

  openModal() {

    var dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '800px',
      data: {
        product: this.dato.product,
        productQty: "1",
        //form: this.activeItineraryForm,
        origin: 'HOTELS',
        itineraryItem:this.dato,
        view_info:true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // if(result){


      // }else{

      // }
    });
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  // stingUpperCase(str){
  //   let e = str.toLowerCase()
  //   return e.replace(/\b\w/g, l => l.toUpperCase())
  // }

  fontPrice(price){
    if((price.length - 6) > 0){
      const fact = price.length - 6
      const size = 25 - (1.5 * fact)
      return size + 'px'
    }
  }

  getStarsHotels():void{
    const rateStar = this.dato.categoryHotel;
    if(rateStar != null){
      const numberRate = rateStar?.substring(0,1);
      this.rate = Number(numberRate);
    }
  }
}

