import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, ViewContainerRef, ElementRef, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Router } from "@angular/router";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/map";
import * as moment from "moment";
import { Observable } from "rxjs/Observable";
import { UbicacionesService } from "../../service/ubicaciones.service";
import { RestService } from "../../../../shared/rest.service";
import { BsDatepickerConfig, BsDatepickerDirective, BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap";
import { CustomValidator } from "../../../../shared/customValidators";
import { ItineraryService } from "../../../../shared/itinerary.service";
import { RoomConfigurationItem } from "./roomConfigurationItem";
import { searchPayload } from "../../../../utils/search-functions";
import { UserService } from "../../../../pages/com.usblick.common/auth/user.service";
import { MaterialModalConfirmComponent } from "../../../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../../../shared/material-modal-alert/material-modal-alert.component";
import { MatDialog } from "@angular/material/dialog";
import * as dayjs from "dayjs";
import { setting } from "../../../../../../setting";
import { of, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "app/pages/com.usblick.common/auth/auth.service";
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';


@Component({
  selector: "usb-hotels",
  templateUrl: "./hotels.component.html",
  styleUrls: ["./hotels.component.scss"],
  providers: [UbicacionesService],
})
export class HotelsComponent implements OnInit, AfterViewInit {
  @Input() _close: boolean;

  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() origin = new EventEmitter<any>();
  @Output() modal = new EventEmitter<string>();

  @ViewChild("dpDeparture") datepickerDeparture: BsDatepickerDirective;
  @ViewChild("dpArrival") datepickerArrival: BsDatepickerDirective;

  @ViewChild("destination") myInputField: ElementRef;
  @ViewChild(DaterangepickerDirective) picker!: DaterangepickerDirective;
  _findData: boolean;
  _resultsQuery: any;
  _form: FormGroup;
  _maxAdults: any;
  _maxChildren: any;

  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  isCollapsed = false;
  validate = false;
  selectedDestiny: any;
  _longDestination: string;
  destiny: any;
  roomsAndPassengers: string;
  minDateDeparture: Date;
  maxDateArrival: Date;
  bsConfig: object;
  dateRange: any = { start: null, end: null };
  isLoading_ = false;
  valueQtyChild:number = 0;
  showModal: boolean = false;
  titleModal: string = 'Advertencia';
  messageModal: string = 'Cree un file en la barra superior para poder iniciar una búsqueda';

  minDate: dayjs.Dayjs = dayjs().subtract(0, "days");
  maxDate: dayjs.Dayjs = dayjs().add(12, "month");
  destinationOptions: Observable<any[]> = of([]);
  destinationOptions_: Observable<any[]>= of([]);
  public getClientsSettingAppBooking: any;
  public routeUploadFile:any;
  bgClientColor: string;
  isHoverRoom: boolean;
  isHoverConfirm: boolean;
  public countriesData: any[] = [];
  public filteredCountries: any[] = [];
  public selectNationality: string = '';
  private destinationSubscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  agencyId:any;
  startDate: any;
  country: any;

  bsModalRef?: BsModalRef;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private _restService: RestService,
    private ubicacionesService: UbicacionesService,
    private _itineraryService: ItineraryService,
    public vcr: ViewContainerRef,
    public userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _auth:AuthService
  ) {
    this._maxAdults = Array.from({ length: 10 }, (_, i) => i + 1);
    this._maxChildren = Array.from(Array(11).keys());
    this.minDateDeparture = new Date();
    this.maxDateArrival = new Date();
    this.dpConfig.containerClass = "theme-red";
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.bgClientColor = this.getClientsSettingAppBooking?.clients_setting_global?.color_main_1;


  }



  ngOnInit(): void {

    let agency = JSON.parse(localStorage.getItem("selectedAgency"));
    if(agency) {
      console.log('agency', agency);
      this.agencyId = agency.agency_id;
    }

    // this.agencyId = this.userService._agencyID;

    // console.log('agencyId ->', this.agencyId);
    // this.activeItinerary();
    this.roomsAndPassengers = "Rooms and passengers";
    this._form = this.fb.group({
      destination: [null, Validators.compose([Validators.required, CustomValidator.countryValidator])],
      arrivalDateDepartureDate: ["", Validators.compose([Validators.required, CustomValidator.countryValidator])],
      room: this.fb.array([]),
      serviceType: [],
      nationality: ['', Validators.required],
      qtyProduct: [1, [Validators.required, Validators.min(1), Validators.max(7)]],
    });
    this._addRoom();

    this.dateRange = "";

  this.destinationSubscription = this._form.get("destination").valueChanges.subscribe((selectedValue) => {
      let destination = this._form.get('destination').value;
      if (selectedValue) {
        this.isLoading_ = true;
        this.destinationOptions = null;
        this.destinationOptions_ = null;
        this.destinationOptions = this.ubicacionesService.getCitys(selectedValue);
        this.destinationOptions_ = this.ubicacionesService.getAirports(selectedValue);

        this.isLoading_ = false;
      } else {
        // Emitir un array vacío si el campo de entrada está vacío o tiene menos de 3 caracteres
        this.destinationOptions = of([]);
        this.destinationOptions_ = of([]);

      }
    });

    // this.getCountries();




  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.myInputField.nativeElement.focus();
    }, 500);





  }

  formRoom(form: FormGroup, key: string) {
    return (<FormArray>form.get(key)).controls;
  }

  _addRoom() {
    const room = this._form.get("room") as FormArray;
    room.push(
      this.fb.group({
        qtyAdults: [1, [Validators.required, Validators.min(1)]],
        qtyChilds: [0, Validators.required],
        childsage: this.fb.array([]),
      })
    );
    if (room.length < 1 || !room.length) {
      this._form.get("qtyProduct").setValue(0);
    } else {
      this._form.get("qtyProduct").setValue(room.length);
    }
  }

  _removeRoom(index: number) {
    const room = this._form.get("room") as FormArray;
    room.removeAt(index);
    this._form.get("qtyProduct").setValue(room.length);
  }

  startDateClicked($event) {
    console.log($event);
    this.startDate = $event;
    //  this.maxDate = moment($event.startDate._d).add(1, 'month');
  }

  endDateClicked($event: any): void {
    // Convertimos a Day.js las fechas para garantizar consistencia
    const selectedStartDate = dayjs(this.startDate.startDate.$d).startOf('day');
    const selectedEndDate = dayjs($event.endDate.$d).startOf('day'); // Normaliza al inicio del día

    console.log('Fecha de inicio:', selectedStartDate.toISOString());
    console.log('Fecha de fin:', selectedEndDate.toISOString());

    // Validar si la fecha de fin es inválida, igual o anterior a la fecha de inicio
    if (!selectedEndDate.isValid() || selectedEndDate.isSame(selectedStartDate) || selectedEndDate.isBefore(selectedStartDate)) {

      this.toastr.warning('Debe elegir mínimo un día de diferencia con el día de inicio');


      this._form.get('arrivalDateDepartureDate')?.setValue(null); // Limpia el campo en el formulario

      setTimeout(() => {
        this.picker.clear(); // Limpia cualquier selección previa del datePicker
        this.picker.open(); // Reabre el calendario para seleccionar una nueva fecha
        // Resetea el rango de fechas y el formulario
      }, 100);
    } else {
      this.dateRange = { start: selectedStartDate, end: selectedEndDate };
      console.log('Rango de fechas válido:', { start: selectedStartDate, end: selectedEndDate });
    }
  }





  // realiza la busqueda en el endpoint
  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    console.log(form)
    const _payload = [];
    let nationality = this.selectNationality;
    let country = this.country;
   // console.log("HOTELS", this.dateRange, form, this.selectedDestiny);
    if (this.selectedDestiny !== undefined && this.selectedDestiny !== null) {
      if (event) {
        event.preventDefault();
      }
      const payload = searchPayload("HOTELS", this.dateRange, form, this.selectedDestiny, '','', null, nationality, this.agencyId, country);
      console.log("payload", JSON.stringify(payload));
      // console.log("form", JSON.stringify(form.value));
      localStorage.setItem("form", JSON.stringify(form.value));
      if (localStorage.getItem("payload")) {
        localStorage.removeItem("payload");
      }
      console.log(payload)
      _payload[0] = payload;
      console.log(_payload[0])
      localStorage.setItem("payload", JSON.stringify(_payload));
      this._emitLoading(true);
      this._restService.doPost("app", "search", dummy || payload).subscribe(
        (response: any) => {
          if (response !== null && response.results !== null) {
          console.log("responseInit", response);
            const payloadArray = [searchPayload("HOTELS", this.dateRange, form, this.selectedDestiny,'', '',null, this.selectNationality, this.agencyId, country)];
           console.log("payloadArray", payloadArray);
            this.payload.emit(payloadArray);
            this._colocarSearchIdEnlosHoteles(response);
            this._setArrivalAndDepartureDate(response, form);
            this._handleResponse(response, "HOTELS", form);
            this._emitOrigin("HOTELS");
            this._emitLoading(false);
            this._router.navigate(["/results"]);
          } else {
            const initialState: ModalOptions = {
              initialState: {
                message:'No se encontraron Resultados'
              }
            };
            this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
          }
        },
        (error) => {
          this._emitLoading(false);
          const initialState: ModalOptions = {
            initialState: {
              message: 'Ocurrió un error al intentar encontrar productos. Inténtalo de nuevo'
            }
          };

          this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
          const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
            if(result) {
              if (result) {
                this._onSubmit(form, event);
              } else {
                this.bsModalRef.hide();
                this._router.navigate(["/home"]);
              }
            } else {

            }
            confirmSubscription.unsubscribe();
          });
        }
      );
    } else {
      this._form.get("destination").setErrors({ invalidDestination: true });
    }
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }
  _colocarSearchIdEnlosHoteles(response: any) {

    response.results[0]?.additionalInfo.rooms.forEach((obj) => {
      obj.room.searchId = response.searchId;
    });
  }

  _setArrivalAndDepartureDate(response: any, form) {
      response.results.dateIni = moment(this.dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD");
      response.results.dateEnd = moment(this.dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD");

  }

  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = moment(this.dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD"); // form.value['arrivalDate'];
        this._resultsQuery.destination = this._longDestination;

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = "No results found for the search";
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
        this.results.emit(arrayResults);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = "No results found for the search";
      this.results.emit(this._resultsQuery);
    }
  }
  _confirm() {
    this.isCollapsed = false;
    this.validate = true;
    this.setRoomAndPassanger();
  }
  _openModal() {
    this.isCollapsed = true;
    this.validate = false;
    this._close = false;
    setTimeout(() => {
      this.modal.emit("true");
    }, 100);
  }
  _over() {
    this.modal.emit("false");
  }
  clickOutside(event: any) {
    this.setRoomAndPassanger();
  }

  _openModalNationality() {

  }

  _getRoomDist(form?: any, item?: any) {
    const roomDist = [];
    const rooms = form.value.room;
    for (let i = 0; i < rooms.length; i++) {
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: [],
        boardNameCode: item.boardNameCode,
        condition:  item.rateClassId,
        roomCode:item.roomCode,
        roomId:item.roomId,
        bookingCode:item.bookingCode
      };
      const adults = rooms[i].qtyAdults;
      const children = rooms[i].childsage;
      for (let j = 0; j < children.length; j++) {
        people.push(children[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);
    }
    return roomDist;
  }

  _getAdultsChildrenQty(key: string, room: Array<any>) {
    let qty = 0;
    key === "qtyAdults"
      ? room.map((obj) => obj.qtyAdults).forEach((element) => (qty += +element))
      : room.map((obj) => obj.qtyChilds).forEach((element) => (qty += +element));
    return qty;
  }

  _addChild(item: FormGroup) {
    // console.log('item child ->', item);
    this.valueQtyChild = item.value.qtyChilds;
    setTimeout(() => {
      const ages: any = item.get("childsage") as FormArray;
      const qty: number = +item.value["qtyChilds"];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(
            this.fb.group({
              id: [i],
              age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
            })
          );
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }
  compareDate(event: any) {
    const getArrivalDate = moment(event.toString().split(",")[0]).format("YYYY-MM-DD");
    const getDepartureDate = moment(event.toString().split(",")[1]).format("YYYY-MM-DD");
    if (getArrivalDate === getDepartureDate) {
      return false;
    }
  }

  updateButtonColorRoom() {
    const button = document.querySelector('.btn-choice-add') as HTMLElement;
    if (this.isHoverRoom) {
      button.style.backgroundColor = this.bgClientColor;
    } else {
      button.style.backgroundColor = 'white';
    }
  }

  updateButtonColorConfirm() {
    const button = document.querySelector('.btn-choice-confirm') as HTMLElement;
    if (this.isHoverConfirm) {
      button.style.backgroundColor = this.bgClientColor;
    } else {
      button.style.backgroundColor = 'white';
    }
  }

  /***********************************************Auto Complete********************************************/
  _myListFormatter = (data: any) => {};

  capitalize(val: any) {

    if(val !== undefined) {
      return val
        .toLowerCase()
        .trim()
        .split(" ")
        .map((v) => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    }
  }

  observableSource = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getCitys(keyword);
  };

  displayFn(name: any) {
    if (name) {
      if (name.city_name != undefined) {
        return name.city_name;
      }
      if (name.name_airport != undefined) {
        return name.name_airport;
      }
    }
  }

  updateMySelection(event: any) {
    if (event) {
      this.destiny = event.city_name ? event.city_name : "";
      this.selectedDestiny = event.city_iata_code ?  event.city_iata_code : "";
      this._longDestination = event.city_name ? event.city_name : "";
      /// this.destiny['cityName'] =  event.cityName;
      this.selectedDestiny =  event.city_iata_code ?  event.city_iata_code : "";
      this.country = event.country_iata_code ? event.country_iata_code : "";
    }
  }

  updateMySelectionNationality(event: any) {
    if(event) {
      this.selectNationality = event;
    }
  }

  /***********************************************Auto Complete********************************************/

  setRoomAndPassanger() {
    let rooms = this._form.value["room"].length.toString();
    let adults = this._getAdultsChildrenQty("qtyAdults", this._form.value["room"]).toString();
    let childs = this._getAdultsChildrenQty("qtyChilds", this._form.value["room"]).toString();
    let noSelected = parseInt(adults) === 0 && parseInt(childs) === 0;
    rooms = rooms == 1 ? rooms + " Habitación" : rooms + " Habitaciones"
    // rooms = rooms != undefined ? rooms + " Habitaciones " : null;
    adults = adults != undefined ? " - " + adults + " Adultos " : null;
    childs = childs != undefined ? " - " + childs + " Niños " : null;

    this.roomsAndPassengers = noSelected ? "Rooms and passengers" : rooms + adults + childs;
  }

  async setDepartureDate(event: any) {
   // console.log(event);

    if (event !== null) {
      this.minDateDeparture = await new Date(event);
      await this.setOptions("departure");
      //this.datepickerDeparture.toggle();

      setTimeout(() => {
        this.datepickerDeparture.toggle();
      }, 200);
      return;
    }
    //this.minDateDeparture = new Date();
  }

  setArrivalDate(event: any) {
    if (event !== null) {
      this.maxDateArrival = new Date(event);
      this.setOptions("arrival");
      return;
    }
    //this.maxDateArrival = new Date();
  }

  setOptions(tipo: String): void {
    if (tipo != "departure") {
      this.bsConfig = Object.assign({}, { maxDate: this.maxDateArrival });
      //this.datepickerArrival.setConfig();
      return;
    }

    this.bsConfig = Object.assign({}, { minDate: this.minDateDeparture });
    //this.datepickerDeparture.setConfig();
  }

  getCountries() {
    this._itineraryService._restService.doGet("api", "/utilities/countries")
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe({
      next: (res: any) => {
        this.countriesData = res.Countrys;
        this.filteredCountries = this.countriesData;
      }
    })
  }

  selectedNationality(nationalitySelected: string) {
    this.selectNationality = nationalitySelected;
  }

  fileActive() {
      this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  confirmAction() {
    this.closeModal();
  }

  filterCountries(searchText: string) {
    if (searchText) {
      this.filteredCountries = this.countriesData.filter(country =>
        country.country_name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredCountries = this.countriesData;
    }
  }

  displayCountry = (country: any) => {
    if (!this.filteredCountries) {
      console.error('filteredCountries is not defined');
      return '';
    }
    let countryData = this.filteredCountries.find(c => c.country_code === country);
    if(country === null) {
      countryData = null;
    }
    return countryData ? `${countryData.country_name}` : '';
  }

}
