import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'usb-confirm-items',
  templateUrl: './confirm-items.component.html',
  styleUrls: ['./confirm-items.component.scss']
})
export class ConfirmItemsComponent implements OnInit {

  @Input() items:any;
  @Input() currencyItinerary: string;
  rate
  @Output() nextStep = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


  goStepThree(){
    this.items.forEach(element => {
      element.selected = false;
    });
    this.nextStep.emit(3);
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }
}

