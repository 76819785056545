import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RangeDateItinerary } from '../../../shared/interfaz/rangeDateItinerary';
import { ItineraryService } from '../../../shared/itinerary.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { RestService } from '../../../shared/rest.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class UserService {

  _name: string;
  _email: string;
  _agentID: number;
  _agencyName: string;
  _agencyID: number;
  _imagen_logotype: string;
  _channel_id:number;
  _licenseId: string;
  _marketId: number;
  _profile: any;
  _agent: any;
  _clientsApplications: any;

  url = "http://usblick-api-dev.usblick.com/api/v1";

  //activeUser: Observable<any>;
  activeUserSubject = new Subject<any>();
  userActive: any;
  itineraryActive: any = [];

  origin: any;
  multidestination: any;
  public itinerary: any;

  stepReservation: any = "two";
  itineraryReserveId: any;
  from: any = "total-reserve";

  items: any;



  private prices$ = new Subject<any>();

  isActivePartial:boolean = false;

  priceRightFeePercentage: any = 0;
  priceRightFee: any = 0;
  totalPriceNotFee: any = 0;
  totalPriceNotFeeByReserved: any = 0;
  totalPriceYesFee: any = 0;
  totalPriceYesFeeByReserved: any = 0;
  itinerayDetailFee: any;
  totalNetReserved: any = 0;
  totalReserved: any = 0;
  totalPriceNoFeePartial: any = 0;
  totalPriceYesFeePartial: any = 0;
  blickoins: any = 0;
  creditBlickoins: any = 0;
  priceGeneralData:number = 0;
  public creditsDeposits: any = 0;
  public credits: any = 0;
  public creditLimits: any = 0;
  public balanceBlickoins: any   = 0;
  depositWarranty: any;

  public totalBlickoins: any = 0;



  private globalFee$ = new Subject<any>();
  globalFee: any = 0;
  public  activeGlobalFee: any;

  itineraryArrivalDate: any;
  itineraryArrivalDateFull: any;
  itineraryDepartureDate: any;
  itineraryDepartureDateFull: any;

  itineraryQtyNights = 0;
  itineraryQtyDays = 0;

  constructor(
    private datePipe: DatePipe,
    private restService: RestService,
    public spinner:NgxSpinnerService
    ) {}

  getGlobalFee$(): Observable<any> {
    return this.globalFee$.asObservable();
  }


  getBlickoinsCredits(balanceBlickoins: any ) {
    let creditsBlickoins = 0;
    if (balanceBlickoins > 0) {
        creditsBlickoins = (balanceBlickoins / 10);

    }
    return creditsBlickoins;
  }

  sendGlobalFee$(data: any) {
    this.globalFee$.next(data);
  }

  getPrices$(): Observable<any> {
    return this.prices$.asObservable();
  }

  sendPrices$(data: any) {
   // this.getFee();
    this.prices$.next(data);
  }

  depositWarrantyCredit() {
    let url = 'depositsMastersWarrantys/list';
    this.restService.doGet('app', url).subscribe({
      next: (res: any) => {
        this.depositWarranty = res;
      }
    })
  }


  updateProductsPrices(itinerary_id: any) {
     let url = 'products/update/productsPrices/' + itinerary_id;
      this.restService.doPost('app', url, this.itinerayDetailFee).subscribe(
        (response: any) => {
         if (response.data) {
          this.itineraryUpdateProductsTotalPrices(itinerary_id);

         }
        },
        error => {
          console.log("error response", error);
        }
      );
  }

  itineraryUpdateProductsTotalPrices(itinerary_id: any) {
    this.totalPriceNotFeeByReserved = -1;
    this.totalPriceYesFeeByReserved = -1;
    this.totalPriceNotFee = -1;
    this.totalPriceYesFee = -1;
   // this.totalBlickoins = -1;
    this.priceRightFee = 0;
    this.priceRightFeePercentage = 0;
console.log(this.itinerary)
        // tslint:disable-next-line:max-line-length

        this.restService.doPut('app', 'itineraries/update/updateProductsTotalPrices/' + itinerary_id, this.itinerary).subscribe((response: any) => {
console.log(response.data)
          if (response.data) {
              // tslint:disable-next-line:max-line-length
              if ((response.data['totalByReservedNotFeePartial'] > 0) || (response.data['totalByReservedYesFeePartial'] > 0) ){
                this.totalPriceNotFeeByReserved = response.data['totalByReservedNotFeePartial'];
                this.totalPriceYesFeeByReserved = response.data['totalByReservedYesFeePartial'];
              } else {
                this.totalPriceNotFeeByReserved = response.data['totalByReservedNotFee'];
                this.totalPriceYesFeeByReserved = response.data['totalByReservedYesFee'];
              }
                this.totalPriceNotFee =  response.data['totalPriceNotFee'];
                this.totalPriceYesFee =  response.data['totalPriceYesFee'];
                this.priceRightFee =  response.data['totalFee'];
                this.priceRightFeePercentage =  response.data['priceRightFeePercentage'];
            // this.totalBlickoins =  response.data['totalBlickoins'];
            }
            console.log(response.data['totalByReservedYesFee'])
        },
        error => {
          console.log("error response", error);
        }
      );
  }

  postNewPrice(item: any) {
    console.log(JSON.stringify(item))
    let url = 'products/update/newPrice';
    this.restService.doPost('app', url, item).subscribe(
      (response: any) => {
        if (response) {
            this.itineraryUpdateProductsTotalPrices(item.item.itinerary_id);
        }
      },
      error => {
        console.log("error response", error);
      }
    );
  }

  fillCart(itinerary: any) {
    //console.log("recibo fill cart", itinerary);
    if (itinerary !== null && itinerary !== undefined) {
      if (this.origin !== undefined && this.origin === 'MULTIDESTINATION') {
        //console.log("Fill Multidestino");
        this.fillMultidestination(itinerary);
        this.spinner.hide();
      } else {
        //console.log("Fill fillItinerary");
        this.fillItinerary(itinerary);
        this.fillMultidestination(itinerary);
        this.spinner.hide();
      }
    }
  }

  fillMultidestination(itinerary: any) {
    this.multidestination = undefined;
    let departureDate;
    let arrivalDate;
    if (itinerary[0] !== undefined) {
      for (let i = 0; i < itinerary[0].destination.length; i++) {

        let items: any[] = [];
        const destiny = itinerary[0].destination[i];

        if (destiny.destination_info.arrival !== null && destiny.destination_info.departure !== null) {
          arrivalDate = destiny.destination_info.arrival.split('-');
          departureDate = destiny.destination_info.departure.split('-');
        } else {
          const dateRange: RangeDateItinerary =
          this.evaluateDateItineraryRange(itinerary[0].items);
          arrivalDate = dateRange.arrivalDate.toString().split('-');
          departureDate = dateRange.departureDate.toString().split('-');
        }

        //cantidad de items
        for (let j = 0; j < itinerary[0].items.length; j++) {
          const item = itinerary[0].items[j];

          //payload se le asigna el producto si existe
          let payload = item.payloadUpdate && item.payloadUpdate.products ? item.payloadUpdate.products[0] : item.payload;
          if (payload && (payload.destination === destiny.iata_code)) {

            //console.log('entro a payload && payload.destination === destiny.iata_code');
            //se añade item a items array
            items[j] = {
              adult: payload.qtyPax,
              children: payload.others.child,
              item: item,
              product: null,
              categoryName: item.categoryName,
            };

            if (this.multidestination === undefined) {
              this.multidestination = [];
            }
            let multiExist;

            if (this.multidestination.lenght > 0) {
              //busca si existe ya un destino que le pertenece a ese producto
              multiExist = this.multidestination.find((element: any) => element.city == destiny.destination_info.cityName);
              //console.log('encontro que ya existe un destino en multi con ese nombre?', multiExist);
            }

            if (multiExist) {
              let index = this.multidestination.indexOf(multiExist);
              this.multidestination[index].items.push(items[j]);
            } else {
              const anioArrival = arrivalDate[0].substring(2);
              const anioDeparture = departureDate[0].substring(2);

              this.multidestination[i] = {
                dd: arrivalDate[2].length > 2 ? arrivalDate[2].substring(0, 2) : arrivalDate[2],
                ddd: departureDate[2].length > 2 ? departureDate[2].substring(0, 2) : departureDate[2],
                mm: this.getMonthName(arrivalDate[1]),
                yy: anioArrival,
                city: destiny.destination_info.cityName,
                items: items.filter(Boolean)
              };

            }
          }
        }

        if (itinerary[0].items.length < 1) {
          this.multidestination = undefined;
        }
      }
    }

    //console.log("final", this.multidestination);
  }

  getMonthName(month: number) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Octr', 'Nov', 'Dec'];
    if (month !== null) {
      return monthNames[month - 1];
    }
  }

  evaluateDateItineraryRange(items: any): RangeDateItinerary {
    let dateRange: RangeDateItinerary;
    let arrivalDate = items.arrivalDate;
    let departureDate = items.departureDate;
    dateRange = { arrivalDate: new Date(), departureDate: new Date };
    dateRange.arrivalDate = arrivalDate;
    dateRange.departureDate = departureDate;
    return dateRange;
  }

  fillItinerary(itinerary: any) {
    let departureDate;
    let arrivalDate;

    if (itinerary.length > 0) {
      this.itinerary = [];
      //console.log("itinerary[0].items", itinerary[0].items);
      for (let i = 0; i < itinerary[0].items.length; i++) {
        let arrival;
        arrivalDate = (itinerary[0].items[i].categoryName == 'DISNEY') ? itinerary[0].items[i].hotel.dateIni.split('-') : itinerary[0].items[i].arrivalDate.split('-');
        departureDate = (itinerary[0].items[i].categoryName == 'DISNEY') ? itinerary[0].items[i].hotel.dateEnd.split('-') : itinerary[0].items[i].departureDate?.split('-');
        arrival = moment(itinerary[0].items[i].arrivalDate);

        const item = itinerary[0].items[i];

        const anioArrival = arrivalDate !== null ? arrivalDate[0].substring(2) : null;

        const anioDeparture = departureDate !== null ? departureDate[0]?.substring(2) : null;

        this.itinerary[i] = item['categoryName'] === 'DISNEY' ? this.getDisneyItem(item) : {
          orderDateDestiny: this.datePipe.transform(arrival, 'yy') + '-' + this.datePipe.transform(arrival, 'MM') + '-' + this.datePipe.transform(arrival, 'dd'),
          dd: arrivalDate[2].length > 2 ? arrivalDate[2].substring(0, 2) : arrivalDate[2],
          mm: this.getMonthName(arrivalDate[1]),
          yy: anioArrival,
          city: item.payloadUpdate ? item.payloadUpdate.products[0].destination : item.payload.destination,
          adult: item.payloadUpdate ? item.payloadUpdate.products[0].qtyPax : null,
          children: item.payloadUpdate ? item.payloadUpdate.products[0].others.child : null,
          item: item,
          ddd: departureDate[2].length > 2 ? departureDate[2].substring(0, 2) : departureDate[2],
          mmm: this.getMonthName(departureDate[1]),
          yyy: anioDeparture,
          product: null,
          categoryName: item.categoryName
        };

      }
      //console.log("this.itinerary -------------- fill itinerary", this.itinerary);

      this.spinner.hide();
    } else {
      this.itinerary = [];
      this.spinner.hide();
    }
  }

  getDisneyItem(_item: any): object {
    const arrival:any = moment(_item['form']['arrivalDate']);
    const departure :any  = moment(_item['form']['departureDate']);
    const nights:any = departure.diff(arrival, 'days');
    const item = {
      orderDateDestiny: this.datePipe.transform(arrival, 'yy') + '-' + this.datePipe.transform(arrival, 'MM') + '-' + this.datePipe.transform(arrival, 'dd'),
      itemInfo: _item,
      city: 'ORL',
      id: _item['id'],
      idProduct: _item['hotel']['id'],
      dd: this.datePipe.transform(arrival, 'dd'),
      mm: this.datePipe.transform(arrival, 'MMM'),
      yy: this.datePipe.transform(arrival, 'yy'),
      ddd: this.datePipe.transform(departure, 'dd'),
      mmm: this.datePipe.transform(departure, 'MMM'),
      yyy: this.datePipe.transform(departure, 'yy'),
      destination: _item['hotel']['destination'],
      nights: nights,
      categoryName: 'DISNEY'
    }
    return item;
  }


  reCalcularPrices(id) {
      this.totalPriceNotFeeByReserved = -1;
      this.totalPriceYesFeeByReserved = -1;
    //  this.totalPriceNotFee = -1;
     // this.totalPriceYesFee = -1;
       this.priceRightFee = 0;
       this.priceRightFeePercentage = 0;

       // tslint:disable-next-line:max-line-length itineraries/update/updateProductsTotalPrices/
        this.restService.doPut('app', 'itineraries/update/updateProductsTotalPrices/' + id, this.itinerary).subscribe((response: any) => {

        if (response.data) {
          console.log(response.data)
          // tslint:disable-next-line:max-line-length
          if ((response.data['totalByReservedNotFeePartial'] > 0) || (response.data['totalByReservedYesFeePartial'] > 0) ){
            this.totalPriceNotFeeByReserved = response.data['totalByReservedNotFeePartial'];
            this.totalPriceYesFeeByReserved = response.data['totalByReservedYesFeePartial'];
          } else {
            this.totalPriceNotFeeByReserved = 0;
            this.totalPriceYesFeeByReserved = 0;
          }
        }
        this.priceRightFee =  response.data['totalFee'];
        this.priceRightFeePercentage =  response.data['priceRightFeePercentage'];
        this.totalPriceNotFee =  response.data['totalPriceNotFee'];
        this.totalPriceYesFee =  response.data['totalPriceYesFee'];
             console.log(response.data)
        }, error => {
          console.log('Save-Error: ' + error);
      });
  }


  searchPrice(id) {
    const product = this.items.find(element => element.id == id);
    return parseFloat(product.netPriceBooking);
  }


  calculateDates() {

    // console.log("itineraryActive", this.itineraryActive);
    if (this.itineraryActive.length > 0) {

      if (this.itineraryActive[0].items.length > 0) {
        let arrivalDate;
        for (let index = 0; index < this.itineraryActive[0].items.length; index++) {
          if (this.itineraryActive[0].items[index].arrivalDate) {
          //en el primer for i guardo la primera asrrival
            if (index == 0) {
              arrivalDate = this.itineraryActive[0].items[index].arrivalDate;
            }

            //hsgo el segundo for y comparo //2020-12-12 con todos.
            for (let j = 0; j < this.itineraryActive[0].items.length; j++) {

              // 2020-12-12                              2021-03-21
              if (moment(this.itineraryActive[0].items[j].arrivalDate) <= moment(this.itineraryActive[0].items[index].arrivalDate)) {
                if (moment(this.itineraryActive[0].items[j].arrivalDate) <= moment(arrivalDate)) {
                  arrivalDate = this.itineraryActive[0].items[j].arrivalDate;
                }
              }
            }
          }
        }
        this.itineraryArrivalDateFull = moment(arrivalDate).format('YYYY-MM-DD').toString();
        //console.log("this.itineraryArrivalDate ----------------------------------", this.itineraryArrivalDate);
        let dateSplitAux = this.itineraryArrivalDateFull.split("-");
        this.itineraryArrivalDate = '' + dateSplitAux[2] + '-' + moment().month(parseInt(dateSplitAux[1]) - 1).format('MMMM-Y');

        let departureDate;
        for (let index = 0; index < this.itineraryActive[0].items.length; index++) {
          if (this.itineraryActive[0].items[index].departureDate) {

            //en el primer for i guardo la primera asrrival
            if (index == 0) {
              departureDate = this.itineraryActive[0].items[index].departureDate;
            }

            //hsgo el segundo for y comparo con todos.
            for (let j = 0; j < this.itineraryActive[0].items.length; j++) {

              // 2020-12-12                              2021-03-21
              if (moment(this.itineraryActive[0].items[j].departureDate) >= moment(this.itineraryActive[0].items[index].departureDate)) {
                if (moment(this.itineraryActive[0].items[j].departureDate) >= moment(departureDate)) {
                  //console.log("2) " + this.itineraryActive[0].items[j].departureDate + ' es mayor a ' + departureDate);
                  //console.log("se cambia y es ", this.itineraryActive[0].items[j].departureDate);
                  departureDate = this.itineraryActive[0].items[j].departureDate;
                }
              }
            }
          }
        }
        this.itineraryDepartureDateFull = moment(departureDate).format('YYYY-MM-DD').toString();

        let dateSplit = this.itineraryDepartureDateFull.split("-");
        this.itineraryDepartureDate = '' + dateSplit[2] + '-' + moment().month(parseInt(dateSplit[1]) - 1).format('MMMM-Y');
      } else {
        this.itineraryArrivalDate = null;
        this.itineraryDepartureDate = null;
      }

      //calcular las noches y dias
      const arrival:any = moment(this.itineraryArrivalDate);
  //    const arrival:any = moment(this.itineraryActive[0].arrivalDate);
      // console.log("arrival", this.itineraryActive[0].arrivalDate);
      const departure :any  = moment(this.itineraryDepartureDate);
    //  const departure :any  = moment(this.itineraryActive[0].departureDate);
      // console.log("departure",this.itineraryActive[0].departureDate);

      this.itineraryQtyNights = departure.diff(arrival, 'days');




      this.itineraryQtyDays = this.itineraryQtyNights + 1;
    }

    // 	let arrivalDate;

    // 	if(this.itineraryActive.length > 0){

    // 		for (let index = 0; index < this.itineraryActive[0].destination.length; index++) {
    // 		if(this.itineraryActive[0].destination[index].destination_info.arrival){
    // 			for (let j = 0; j < this.itineraryActive[0].destination.length; j++) {
    // 			if(index == 0){
    // 				arrivalDate = this.itineraryActive[0].destination[index].destination_info.arrival;
    // 			}
    // 			if(moment(this.itineraryActive[0].destination[index].destination_info.arrival) <
    // 				moment(this.itineraryActive[0].destination[j].destination_info.arrival) ){
    // 				arrivalDate = this.itineraryActive[0].destination[index].destination_info.arrival;
    // 			}
    // 			}
    // 		}
    // 		}
    // 		this.itineraryArrivalDate = moment(arrivalDate).format('YYYY-MM-DD').toString();
    // 		console.log("this.itineraryService.itineraryArrivalDate", this.itineraryArrivalDate);

    // 		let departureDate;
    // 		for (let index = 0; index < this.itineraryActive[0].destination.length; index++) {
    // 		if(this.itineraryActive[0].destination[index].destination_info.departure){
    // 			for (let j = 0; j < this.itineraryActive[0].destination.length; j++) {
    // 			if(index == 0){
    // 				departureDate = this.itineraryActive[0].destination[index].destination_info.departure;
    // 			}
    // 			if(moment(this.itineraryActive[0].destination[index].destination_info.departure) >
    // 				moment(this.itineraryActive[0].destination[j].destination_info.departure) ){
    // 				departureDate = this.itineraryActive[0].destination[index].destination_info.departure;
    // 			}
    // 			}
    // 		}
    // 		}
    // 		this.itineraryDepartureDate = moment(departureDate).format('YYYY-MM-DD').toString();
    // 		console.log("this.itineraryService.itineraryDepartureDate ", this.itineraryDepartureDate);

    // 	}
  }


  verifyCategory(category): boolean {

    let aux;

    if (this.itineraryActive[0]) {
      switch (category) {
        case "HOTELS":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'HOTELS');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "TOURS":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'TOURS');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "RENT-A-CAR":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'RENT-A-CAR');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "TRANSPORTS":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'TRANSPORTS');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "PTHEMES":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'PTHEMES');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "RENT-A-CAR":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'RENT-A-CAR');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "CRUISES":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'CRUISES');
          if (aux) {
            return true;
          } else {
            return false;
          }

        case "DISNEY":
          aux = this.itineraryActive[0].items.find(element => element.categoryName == 'DISNEY');
          if (aux) {
            return true;
          } else {
            return false;
          }

        default:
          break;
      }
    } else {
      return false;
    }
  }

  verifyQuantityCategory(category): number {
    let aux = 0;
    switch (category) {
      case "HOTELS":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'HOTELS') {
            aux++;
          };
        });
        break;
      case "TOURS":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'TOURS') {
            aux++;
          };
        });
        break;

      case "TRANSPORTS":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'TRANSPORTS') {
            aux++;
          };
        });
        break;

      case "PTHEMES":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'PTHEMES') {
            aux++;
          };
        });
        break;

      case "RENT-A-CAR":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'RENT-A-CAR') {
            aux++;
          };
        });
        break;
      case "CRUISES":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'CRUISES') {
            aux++;
          };
        });
        break;

      case "DISNEY":
        this.itineraryActive[0].items.forEach(element => {
          if (element.categoryName == 'DISNEY') {
            aux++;
          };
        });
        break;

      default:
        break;
    }

    return aux;
  }




}
