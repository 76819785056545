<ng-container *ngIf="items">
  <div class="card mb-2" *ngFor="let item of items">

    <!-- <div class="row">

      <div class="col-9">
        <div class="row p-2">
          <div class="col-9 item-descrption">
            <div class="row">

              <div class="col-1 p-0 d-flex justify-content-center">
                <div class="d-flex circle align-items-center justify-content-center"
                  [ngClass]="{'green-background': item.status_id != 5, 'red-background': item.status_id == 5}">
                  <i class="icon-small icon-alojamiento iconstart text-center pl-1" style="font-size: 25px;"
                    *ngIf="item.categoryName=='HOTELS'"></i>
                  <i class="icon-small icon-actividades_exterior iconstart text-center pl-1" style="font-size: 25px;"
                    *ngIf="item.categoryName=='TOURS'"></i>
                  <i class="icon-small icon-bus iconstart text-center pl-1" style="font-size: 25px;"
                    *ngIf="item.categoryName=='TRANSPORTS'"></i>
                </div>
              </div>

              <div class="col-11">
                <h5 class="font-weight-bold m-0 text-capitalize" style="font-size: 16px;">{{item.title}}</h5>
                <p class="font-weight-bold m-0" style="font-size: 13px;">{{item.destination.iata_code}} - US</p>
              </div>

            </div>
          </div>

          <div class="col-3"> -->
    <!-- <div class="p-2 d-flex justify-content-between align-items-center"
            [ngClass]="{'green-background': item.status_id != 5, 'red-background': item.status_id == 5}" style="border-radius: 5px;">
              <h5 class="m-0 font-weight-bold text-white"> Save </h5>
              <span class="icon-check"></span>
              <h5 class="m-0 font-weight-bold text-white" style="font-size: 14px;">
                {{itineraryItems.state.name}}
              </h5>
            </div> -->
    <!-- <div class="text-right mt-2" style="font-size: small;color: #d20358;">
              <p class="font-weight-light m-0"> EXPIRATION DATE </p>
              <p class="font-weight-bold m-0"> {{item.expirationDate ? (item.expirationDate | date:'mediumDate') : '' }}
              </p>
            </div>
          </div>
        </div>

      </div>

      <div class="col-3 text-center solid-left" style="margin-left: 1px grey;">

        <p class="green-color m-0">
          TARIFA NETA
        </p>
        <p class="green-color m-0">
          {{item.totalPrice}}
        </p>
      </div>
    </div> -->

    <div class="d-flex data-item">
      <div class="d-flex content-icon justify-content-center">
        <div class="d-flex circle align-items-center justify-content-center"
          [ngClass]="{'green-background': item.status_id != 5, 'red-background': item.status_id == 5}">
          <i class="icons-hotel" *ngIf="item.categoryName=='HOTELS'"></i>
          <i class="icons-adventure" *ngIf="item.categoryName=='TOURS'"></i>
          <i class="icons-transporte" *ngIf="item.categoryName=='TRANSPORTS'"></i>
          <i class="icons-car" *ngIf="item.categoryName=='RENT-A-CAR'"></i>
          <i class="icon-small icon-pthemes iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='PTHEMES'"></i>
          <i class="icon-small icon-seguro iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='INSURANCE'"></i>
          <i class="icon-small icon-crucero iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='CRUISES'"></i>
          <i class="icon-small icon-disney iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='DISNEY'"></i>
        </div>
      </div>
      <div class="d-flex flex-column title-item">
        <div class="d-flex mb-2" style="margin-left: 5px;">
          <h5 class="font-poppins m-0">{{stingUpperCase(item.title)}}</h5>
        </div>
        <div class="d-flex mb-2 pl-1">
          <p class="font-poppins m-0">
            <i class="bi bi-geo-alt-fill" aria-hidden="true" style="font-size: 14px; color:#00d398"></i>
            &nbsp;&nbsp;{{item.destination.iata_code}}
          </p>
        </div>
        <div class="d-flex flex-row justify-content-start">
          <img _ngcontent-c35="" alt="" class="img-fluid icon-premium-point" src="/assets/img/app/little-icons/premium-badge-coral.svg">
          <p _ngcontent-c35="" class="font-point text-left f-16 m-0 pt-2">&nbsp; {{item.blickoins}} Bks.</p>
        </div>
      </div>
      <!-- <div class="d-flex stars-item">
        <bar-rating [(rate)]="rate" [max]="6" [theme]="'fontawesome'" class="ml-auto stars" [readOnly]="true">
        </bar-rating>
      </div> -->
      <div class="d-flex flex-column ml-auto status-item justify-content-between">
        <div class="d-flex align-self-center">
          <div class="state" [ngClass]="{
            'product-status-saved': item.status_id == 1,
            'product-status-reserved': item.status_id == 2,
            'product-status-locked': item.status_id == 3,
            'product-status-denied': item.status_id == 4,
            'product-status-cancelled': item.status_id == 5,
            'product-status-paid-agency': item.status_id == 6,
            'product-status-paid-provider': item.status_id == 7,
            'product-status-billed': item.status_id == 8,
            'product-status-pending-approve-pay': item.status_id == 9,
            'product-status-completed': item.status_id == 10 }">
            <p class="text-status m-0">
              {{ item.status }}
            </p>
          </div>
        </div>
        <div *ngIf="item?.cancellation_policy && item?.cancellation_policy[0]?.from" class="d-flex align-self-center">
          <p class="text-expiration m-0 p-0">Día de expiración<br> <span
              class="date-expiration">{{item?.cancellation_policy && item?.cancellation_policy[0]?.from | date : "dd/MM/yyyy" }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex price-item flex-column justify-content-start align-self-center">
      <div class="d-flex rows-price">
        <p class="font-poppins m-0 p-0 text-price">TARIFA NETA</p>
      </div>
      <div class="d-flex rows-price">
        <p class="font-poppins m-0 p-0 nro-price">{{currencyItinerary}} {{item.netPriceAgent | number:'1.2-2'}}</p>
      </div>

    </div>

  </div>

</ng-container>

<!-- <button class="btn btn-primary" (click)="goStepThree()">
  Next
</button> -->
