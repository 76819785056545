import { Observable } from 'rxjs';
import { setting } from './../../../../../setting';
import { routes } from './form-paxes-reservation-data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormPaxesReservationService {

  private route = routes;

  constructor(private http: HttpClient) { }

  getCountry(): Observable<any> {
    const url = `${setting.octopusUrl}/utilities/countries`;

    return this.http.get<any>(url);
  }

  getStates(countryId: number): Observable<any> {

    const url = `${setting.octopusUrl}/utilities/destinations/${countryId}`;

    return this.http.get<any>(url);
  }
}
