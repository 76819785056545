import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { setting } from '../../../../../setting';
import { map, takeUntil } from 'rxjs/operators';

@Injectable()
export class AddressesService {

  private endpoint: string;
  private endpointLocationsAll: string = '';
  private url = setting.apiOctopusUrl;
  private urlDev = setting.apiOctopusUrlDev;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {
   // this.endpoint = this.url + '/address';
    //  this.endpoint = this.url + '/searchaddress';
     this.endpoint = this.url + '/utilities/getLocationTransfer';
     this.endpointLocationsAll = `${this.urlDev}/utilities/getLocationTransfer/`
  }

  getAddress(iataCode: any, keyword: any, categoryId: any): Observable<any[]> {
    if (keyword) {
      const url = this.endpoint + '/' + keyword + '/' + categoryId + '/' + iataCode;
      return this.http.get(url, { headers: this._headers })
        .map((res:any) => {
          const json = res.data;
          return json;
        });
    } else {
      return Observable.of([]);
    }
  }

  getLocationsAll(keyword: string): Observable<any[]> {
    if (keyword) {
      const url = `${this.endpointLocationsAll}${keyword}`;

      return this.http.get<any>(url, { headers: this._headers }).pipe(
        takeUntil(this._unsubscribeAll),
        map((res: any) => {
          // Verificar que las claves existen antes de hacer map
          const cities = res.cities?.map((city: any) => ({
            ...city,
            type: 'city',
            pic_mini: '../../../../assets/icons/search-box/ubicacion_gris.svg'
          })) || [];

          const airports = res.airports?.map((airport: any) => ({
            ...airport,
            type: 'airport',
            pic_mini: '../../../../assets/icons/search-box/avion_gris.svg'
          })) || [];

          const hotels = res.hotels?.map((hotel: any) => ({
            ...hotel,
            type: 'hotel',
            pic_mini: '../../../../assets/icons/search-box/hotel_gris.svg'
          })) || [];

          // Combinar en un solo array
          const mergedArray = [...cities, ...airports, ...hotels];
          console.log(mergedArray); // Verifica que el array contenga datos
          return mergedArray;
        })
      );
    } else {
      return of([]); // Retorna un array vacío si no hay keyword
    }
  }



  _getAddress(iataCode: any, keyword: any, categoryId: any, type: any): Observable<any[]> {
    if (keyword) {
      const url = this.endpoint + '/' + keyword + '/' + categoryId + '/' + iataCode;
      return this.http.get(url, { headers: this._headers })
      .map((res:any) => {
        const json = res.data;
          if (type) {
            const resu = json.filter(d => d.type !== type);
            return resu;
          } else {
            return json;
         }
        });
    } else {
      return Observable.of([]);
    }
  }

  _getLocations(iataCode:string):Observable<any[]>{
    if (iataCode) {
      const url = this.endpoint + '/' + iataCode + '/' + "HOTEL";
      console.log('url locations', url);
      return this.http.get(url)
      .map((res:any) => {
        console.log('res nuevo endpoint transfer', res);
        const json = res;
          return json;
        });
    } else {
      return Observable.of([]);
    }
  }


  private get _headers() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('client-id', environment.client_id);
    headers.append('client-secret', environment.client_secret);
    if (localStorage.getItem('bearer')) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('bearer'));
    }
    return headers;
  }
}
