<div style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div id="modalvehicles">
    <div class="modal-content p-4">
      <div class="flex-row">
      </div>
      <div class="flex-column px-1 py-0 d-flex ">
        <h3 class="d-flex pt-2 mb-0 ">{{service.title}}</h3>
        <span class="address-resumen">
          <!-- <i class="icon icon-placeholder" aria-hidden="true"></i> -->
          <i class="bi bi-geo-alt" aria-hidden="true"></i>
          <!-- <strong class="px-1"> 1201 NW LEJEUNE ROAD, MIAMI - AIRPORT 33126</strong> -->
          <strong class="px-1">{{service.additionalInfo.location !== '' ? service.additionalInfo.location : service.origin + ' - ' + service.destination}}</strong>
        </span>
      </div>
      <hr>
      <div class="modal-body py-2">
        <div>
          <tabset class="tabcontainer">
            <!--***********************************-->
            <!--***********TABS FOR VEHICLE**********-->
            <!--***********************************-->
            <tab heading="VEHÍCULOS" id="tab-vehicles" *ngIf="(service['additionalInfo'] !== undefined) && (service['additionalInfo']['transfers'] !== undefined)">
              <ng-template ngFor let-vehicle let-i="index" [ngForOf]="service['additionalInfo']['transfers']['vehicles']">
                <usb-vehicle [vehicle]="vehicle" [selected]="selected" [select]="select" [productQty]="productQty"
                  (selectRoom)="selectRoom($event)" [result]="service"></usb-vehicle>
              </ng-template>
              <div class="card card-total-price mb-5 text-right">
                <!-- {{service['additionalInfo']['transfers']['totalPriceFlag']}} -->
                <!-- service['additionalInfo']['transfers']['totalPriceProf'] -->
                Total: ${{ totalQty | number:'1.2-2'}}
              </div>
            </tab>
          </tabset>
        </div>
        <div class="flex-row py-4 px-4">
          <button type="button" class="pull-right btn btn-sm btn-usblick" (click)="continue()" [disabled]="disableButton">CONTINUAR</button>
        </div>
      </div>
    </div>
  </div>
</div>