<div class="data-dashboard">
  <!-- <div class="d-flex flex-column justify-content-center mx-auto" style="width: 1240px;">
    <p class="m-0 title-data font-poppins">files</p>
    <div *ngIf="itinerariesDataStatus && itinerariesDataStatus.length > 0" class="d-flex flex-row justify-content-center mt-3">
      <ng-container *ngFor="let itineraries of itinerariesDataStatus; let i = index">
        <div class="d-flex justify-content-between"
             [ngClass]="{
                          'itinerary-data-save': i === 0,
                          'itinerary-data-partial': i === 1,
                          'itinerary-data-reserve': i === 2,
                          'itinerary-data-complete': i === 3,
                          'mr-2': i === 0 || i === 1 || i === 2
                        }">
          <div class="d-flex flex-column align-items-start justify-content-center">
              <p class="m-0 status-name">{{itineraries.title}}</p>
          </div>
          <div class="d-flex align-items-center pl-3" style="border-left: 1px solid #ffffff;">
              <p class="m-0 status-cant">{{itineraries.value}}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div> -->

  <div class="d-flex flex-column justify-content-center mx-auto mt-5" style="width: 1240px;">
    <p class="m-0 title-data font-poppins">estadísticas</p>
    <div class="d-flex flex-row justify-content-between mt-2">
      <div class="d-flex flex-column content-gr" style="width: calc(35% - 5px); background-color: #ffffff;">
        <div class="d-flex title-chart font-poppins mb-0">
          <span class="d-flex align-items-center" style="cursor: pointer; position: relative;">
            VENTAS TOTAL POR AÑO
            <i class="bi bi-caret-down-fill ml-2"></i>
            <span class="d-flex flex-column select-options ml-2" >
                <div >
                  <span class="d-flex justify-content-center align-items-center options font-poppins" >2024</span>
                </div>
            </span>
          </span>
        </div>
        <p class="totales-chart d-flex flex-row mb-0 align-items-center font-poppins mt-2">
            <span *ngIf="!qtyShow && totalPriceYear"  class="total-venta">U$D {{totalPriceYear.toFixed(2)}}</span>
            <span *ngIf="qtyShow && totalSelling" class="total-venta"> {{totalSelling}}</span>
        </p>
        <div class="d-flex flex-column mt-2" style="height:200px; width: 100%;">
            <div class="d-flex flex-row justify-content-between mt-2" style="gap: 10px;">
                    <!-- <div id="chart">
                      <apx-chart
                        [series]="chartOptions?.series"
                        [chart]="chartOptions?.chart"
                        [dataLabels]="chartOptions?.dataLabels"
                        [plotOptions]="chartOptions?.plotOptions"
                        [yaxis]="chartOptions?.yaxis"
                        [xaxis]="chartOptions?.xaxis"
                        [fill]="chartOptions?.fill"
                        [title]="chartOptions?.title"
                      ></apx-chart>
                    </div> -->
            </div>
            <div class="d-flex justify-content-center">
                    <button type="btn" (click)="selectGraphicsTotalSelling('price')" class="btn bg-info mr-1 d-flex justify-content-center align-items-center" style="font-size: 12px; color: white; width: 95px; height: 28px; border-radius: 50rem;">Valor</button>
                    <button type="btn" (click)="selectGraphicsTotalSelling('qty')" class="btn bg-warning ml-1 d-flex justify-content-center align-items-center" style="font-size: 12px; color: white; width: 95px; height: 28px; border-radius: 50rem;">Cantidad</button>
            </div>
          </div>


      </div>

      <div class="d-flex flex-column content-gr" style="width: 100%; background-color: #ffffff;">
        <div class="row title-chart font-poppins mb-0">
            <span class="col-6 align-items-center" style="cursor: pointer; position: relative; margin-left: 5.5em;">
                VENTAS POR CATEGORÍA - PORCENTAJE / CANTIDAD
                <!-- <object data="assets/img/app/little-icons/caret.svg" width="10" height="10" class="ml-2"></object>
                <span class="d-flex flex-column select-options ml-2" *ngIf="yearsPie">
                    <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('category')">Porcentaje</span>
                    <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('qty')">Cantidad</span>
                </span> -->
            </span>
            <span class="col-3 align-items-center" style="cursor: pointer; position: relative; margin-left: 2.5em;">
              VENTAS POR PAÍS
              <!-- <object data="assets/img/app/little-icons/caret.svg" width="10" height="10" class="ml-2"></object>
              <span class="d-flex flex-column select-options ml-2" *ngIf="yearsPie">
                  <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('category')">Porcentaje</span>
                  <span class="d-flex justify-content-center align-items-center options font-poppins" (click)="selectCategoryOrQTY('qty')">Cantidad</span>
              </span> -->
          </span>
        </div>

        <!-- GRAFICOS FUNCIONALES LEO //////////////////////////-->

        <div class="d-flex flex-row justify-content-between mt-4" style="height: 100%;" >


            <div   class="d-flex flex-column align-items-center" style="width: calc(50% - 5px);">
              <div class="d-flex flex-column align-items-center w-100" style="max-width: 300px;height: 100%; position: relative;">
                  <p class="yearPie m-0 font-poppins" *ngIf="categoryChart2">{{ categoryValues2[0].year }}</p>
                  <div class="d-flex">
                      <canvas width="200" height="200" id="categoryChart2">{{categoryChart2}}</canvas>
                  </div>
                  <div class="d-flex flex-row w-100 mt-auto">
                      <ul class="nav w-100 d-flex justify-content-between" *ngIf="categoryChart2">
                          <li class="nav-items" style="width: 62px;" *ngFor="let item of categoryLabels2; let i = index">
                              <span class="d-flex flex-column align-items-center">
                                  <span class="d-flex w-100" style="height: 10px;" [ngStyle]="{'background-color': categoryColors[0][i]}"></span>
                                  <span class="d-flex categoryLabelsChartPie font-poppins">{{ item }}</span>
                                  <span class="d-flex categoryValueChartPie font-poppins text-nowrap">{{ _porcentCalculator(categoryValues2[0].value)[i]  ? _porcentCalculator(categoryValues2[0].value)[i] : 0 }} %</span>
                              </span>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>




            <div class="d-flex flex-column align-items-center" style="width: calc(50% - 5px);" >
                <div class="d-flex flex-column align-items-center w-100" style="max-width: 300px;height: 100%; position: relative;">
                    <p class="yearPie m-0 font-poppins" *ngIf="categoryChart3">{{ categoryValues3[0].year }}</p>
                    <div class="d-flex">
                        <canvas width="200" height="200" id="categoryChart3">{{categoryChart3}}</canvas>
                    </div>
                    <div class="d-flex flex-row w-100 mt-auto">
                        <ul class="nav w-100 d-flex justify-content-between" *ngIf="categoryChart3">
                            <li class="nav-items" style="width: 62px;" *ngFor="let item of categoryLabels3; let i = index">
                                <span class="d-flex flex-column align-items-center">
                                    <span class="d-flex w-100" style="height: 10px;" [ngStyle]="{'background-color': categoryColors[1][i]}"></span>
                                    <span class="d-flex categoryLabelsChartPie font-poppins">{{ item }}</span>
                                    <span class="d-flex categoryValueChartPie font-poppins text-nowrap">{{ categoryChart3.data.datasets[0].data[i] ? categoryChart3.data.datasets[0].data[i] : 0 }}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



             <div  class="d-flex flex-column align-items-center" style="width: calc(50% - 5px);">
                <div class="d-flex flex-column align-items-center w-100" style="max-width: 300px;height: 100%; position: relative;">
                    <p class="yearPie m-0 font-poppins" *ngIf="categoryChart2">{{ categoryValues2[0].year }}</p>
                    <div class="d-flex">
                        <canvas width="200" height="200" id="categoryChart1">{{categoryChart1}}</canvas>
                    </div>
                    <div class="d-flex flex-row w-100 mt-auto">
                        <ul class="nav w-100 d-flex justify-content-between" *ngIf="categoryChart1">
                            <li class="nav-items" style="width: 62px;" *ngFor="let item of categoryLabels1; let i = index">
                                <span class="d-flex flex-column align-items-center">
                                    <span class="d-flex w-100" style="height: 10px;" [ngStyle]="{'background-color': categoryColors[0][i]}"></span>
                                    <span class="d-flex categoryLabelsChartPie font-poppins">{{ item }}</span>
                                    <span class="d-flex categoryValueChartPie font-poppins text-nowrap">{{ _qtyGraphicCountry(products)[i]  ? _qtyGraphicCountry(products)[i] : 0 }} %</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
        <!-- </div> -->
      </div>


    </div>





  </div>





<!-- FIN -->
</div>
