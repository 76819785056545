// tslint:disable-next-line:max-line-length
import { Component, OnInit, EventEmitter, Output, Input, ViewContainerRef, ViewChild, ViewChildren, ElementRef, ComponentFactoryResolver, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap";
import { TimepickerModule } from "ngx-bootstrap";

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { RestService } from "../../../../shared/rest.service";
import { Observable } from "rxjs/Observable";
import { CustomValidator } from "../../../../shared/customValidators";
import { AddressesService } from "../../service/addresses.service";
import { UbicacionesService } from "../../service/ubicaciones.service";
import { IAddreSelect } from "./interface/IAddreSelect";
import { ILocationSelect } from "./interface/ILocationSelect";
//import { ToastsManager } from 'ng2-toastr';
import { RENTACAR } from "./response/searchResponseRentACar";

import { searchPayload } from "../../../../utils/search-functions";
import { UserService } from "../../../../pages/com.usblick.common/auth/user.service";
import { MaterialModalConfirmComponent } from "../../../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../../../shared/material-modal-alert/material-modal-alert.component";
import { MatDialog } from "@angular/material/dialog";
import * as dayjs from 'dayjs';
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { setting } from "../../../../../../setting";
import { of } from "rxjs";
import { first } from "rxjs-compat/operator/first";

@Component({
  selector: "usb-rentcars",
  templateUrl: "./rentcars.component.html",
  styleUrls: ["./rentcars.component.scss"],
  providers: [AddressesService, UbicacionesService],
})
export class RentcarsComponent implements OnInit {
  @Output() origin = new EventEmitter<any>();
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() modal = new EventEmitter<string>();
  @Input() _close: boolean;

  @ViewChild("destination", {static:true}) myInputField: ElementRef;

  @ViewChildren(DaterangepickerDirective) pickerDirectiveAll;

  ngSelectHourOrigin = '10:00';
  ngSelectHourDestination = '10:00';
  ageDefault = '25';
  selectedDate: { startDate: moment.Moment; endDate: moment.Moment; };

  bgClientColor: string;
  _form: FormGroup;
  //minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  isMeridian: boolean;
  _findData: boolean;
  isCollapsed: boolean;
  mytime: any;
  _resultsQuery: any;
  selectedDestiny: any;
  selectedOrigin: any;
  _longDestination: string;
  _longOrigin: string;
  _origin: any;
  destiny: any;

  returnDestinations: boolean;
  _addressOriginRentaCar: IAddreSelect[];
  addreOriginSelected: IAddreSelect;
  _addressDestinyRentaCar: IAddreSelect[];
  addreDestinySelected: IAddreSelect;
  hourDestiny: Date;
  hourOrigin: Date;

  originOptions: Observable<any[]>;
  originOptions_: Observable<any[]>;

  destinationOptions: Observable<any[]>;
  destinationOptions_: Observable<any[]>;


  isLoading_ = false;

  minDate: dayjs.Dayjs = dayjs().subtract(0, 'days');
  maxDate: dayjs.Dayjs = dayjs().add(12, 'month');
  dateRange: any = { start: null, end: null };

  dateStarEnd:any;
  dateStart: any;
  dateEnd: any;
  times = []; // time array
  agencyId:any;


  public getClientsSettingAppBooking: any;

  start_: any;
  end_: any;

  constructor(
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private _router: Router,
    private _restService: RestService,
    private _addressService: AddressesService,
    private ubicacionesService: UbicacionesService,
    public vcr: ViewContainerRef,
    private dialog: MatDialog,
    public userService: UserService,
    private cdr: ChangeDetectorRef
  ) {
    this.dpConfig.containerClass = "theme-red";
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.bgClientColor = this.getClientsSettingAppBooking?.clients_setting_global?.color_main_1;
    let agency = JSON.parse(localStorage.getItem("selectedAgency"));
    if(agency) {
      console.log('agency', agency);
      this.agencyId = agency.agency_id;
    }
   // this.minDate = new Date();
    //this.toastr.setRootViewContainerRef(this.vcr);
  }

  openDatepicker(datePiker: any ) {
    console.log("this.pickerDirectiveAll", this.pickerDirectiveAll);
    console.log(" datePiker", datePiker);
    this.pickerDirectiveAll[datePiker].open();

    if(datePiker == "last"){
      this.pickerDirectiveAll["first"].hide();
    }else{
      this.pickerDirectiveAll["last"].hide();
    }

  }


  ngOnInit() {


    // tslint:disable-next-line:max-line-length
  //  this.interval = setInterval(() => { this.selectedDate = { startDate: moment().subtract(3, "days").startOf("day"), endDate: moment(), };
  //  this.datePicker.value = this.selectedDate;


    this.inicializedAtributtes();
    this.inicialzedForm();
  /// this.dateRange.startDate = moment().subtract(4, 'days').startOf('day');
    const quarterHours = ['00', '30'];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        this.times.push(i + ':' + quarterHours[j]);
      }
    }

    (<HTMLInputElement>document.getElementById('dateStart')).value = '';
    (<HTMLInputElement>document.getElementById('dateEnd')).value = '';
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.myInputField?.nativeElement.focus();
    }, 500);

    this.cdr.detectChanges();
  }

  _openModal() {
    this.isCollapsed = true;
    this._close = false;
    setTimeout(() => {
      this.modal.emit('true');
    }, 100);
  }

  inicializedAtributtes() {
    this._findData = false;
    this.isMeridian = false;
    this.isCollapsed = false;
    this.returnDestinations = true;
    this.inicializedArraySelect();
  }

  inicializedArraySelect() {
    this._addressDestinyRentaCar = [];
    this._addressOriginRentaCar = [];
    this._addressDestinyRentaCar.push(this.getSelectValueDefault());
    this._addressOriginRentaCar.push(this.getSelectValueDefault());
  }

  getSelectValueDefault(): IAddreSelect {
    return {
      id: "",
      address: "Select Pickup location",
    };
  }

  inicialzedForm() {
    this._form = this.fb.group({
      origin: ['', Validators.required],
      destination:  ['',  Validators.required],
      dateStarEnd: [''],
      dateStar: ['',],
      dateEnd: ['',Validators.required],
      age: ['', Validators.required],
      hourOrigin: ['', Validators.required],
      hourDestination: ['', Validators.required],
      returnDestinations: [true]
    });




    this._form.get("origin").valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.isLoading_ = true;
        this.originOptions = null || of([]);
        this.originOptions_ = null || of([]);
        this.originOptions = this.ubicacionesService.getCitys(selectedValue);
        this.originOptions_ = this.ubicacionesService.getAirports(selectedValue);
        this.isLoading_ = false;
      } else {
        // maybe figure out something wiser here ;)
        this.originOptions = this.ubicacionesService.getDestinations("something else");
      }
    });

    this._form.get("destination").valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.isLoading_ = true;
        this.destinationOptions = null;
        this.destinationOptions_ = null;
        this.destinationOptions = this.ubicacionesService.getCitys(selectedValue);
        this.destinationOptions_ = this.ubicacionesService.getAirports(selectedValue);
        this.isLoading_ = false;
      } else {
        // maybe figure out something wiser here ;)
        this.destinationOptions = this.ubicacionesService.getDestinations("something else");
      }
    });

        this.dateRange = "";
  }



  change(event: any) {

  }

  change_(event: any) {
  }

  choosedDate($event) {
    console.log("choosedDate", $event);
  }
  startDateClicked($event) {

    console.log("startDateClicked", $event);
    
    // tslint:disable-next-line:max-line-length
    (<HTMLInputElement>document.getElementById('dateStart')).value = moment($event.startDate.$d.toString().split(",")[0]).format("DD-MM-YYYY");

    // tslint:disable-next-line:max-line-length
    this._form.controls['dateStar'].setValue( moment($event.startDate.$d.toString().split(",")[0]).format("DD-MM-YYYY"));
  }


  datesUpdated($event) {
    console.log("datesUpdated", $event);

  }

  rangeClicked($event) {
    console.log("rangeClicked", $event);
  }

  endDateClicked($event) {
   console.log("endDateClicked", $event);
 // tslint:disable-next-line:max-line-length
   (<HTMLInputElement>document.getElementById('dateEnd')).value = moment($event.endDate.$d.toString().split(",")[0]).format("DD-MM-YYYY");
   this._form.controls['dateEnd'].setValue( moment($event.endDate.$d.toString().split(",")[0]).format("DD-MM-YYYY"));
  }

  numberAge():void{
    this._form.controls['age'].setValue(String(this.ageDefault))
  }

  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    const _payload = [];

    if (event) {
      event.preventDefault();
    }
    this._emitLoading(true);

    this.numberAge();

    const extras = {
      returnDestinations: this.returnDestinations,
    };

    // tslint:disable-next-line:max-line-length
    const payload = searchPayload("RENT-A-CAR", this.dateRange, form, this.selectedDestiny, this.selectedOrigin, extras, null,'', this.agencyId);
    console.log("payload", payload);
    console.log("form", JSON.stringify(form.value));
    localStorage.setItem("form", JSON.stringify(form.value));
    if (localStorage.getItem("payload")) {
      localStorage.removeItem("payload");
    }
    _payload[0] = payload;
    localStorage.setItem("payload", JSON.stringify(_payload));
    this._emitLoading(true);
    this._restService.doPost('app', 'search', dummy || payload).subscribe(
      (response: any) => {
        if (response !== null && response.results !== null) {
          const payloadArray = [payload];
          this.payload.emit(payloadArray);
          this._setSearchIdInResults(response);
          this._setArrivalAndDepartureDate(response, form);
          this._handleResponse(response, "RENT-A-CAR", form);
          this._emitOrigin("RENT-A-CAR");
          this._emitLoading(false);
          this._router.navigate(["/results"]);
        } else {
          var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
            height: "auto",
            width: "350px",
            data: "No se encontraron Resultados",
            disableClose: true,
          });
        }
      },
      (error) => {
        const message = "Ocurrió un error al intentar encontrar productos. Inténtalo de nuevo";
        this._emitLoading(false);
        var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
          width: "465px",
          data: {message:message},
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log("The dialog was closed", result);
          if (result) {
            this._onSubmit(form, event);
          } else {
            this._router.navigate(["/home"]);
          }
        });
      }
    );
  }

  _colocarSearchIdEnRentaCAr(response: any) {
    response.results.forEach((obj) => {
      obj.searchId = response.searchId;
    });
    return response;
  }

  validateSelectLocations() {
    let valido = false;
    if (this.selectedOrigin !== undefined && this.selectedOrigin !== null) {
      if (!this.returnDestinations) {
        if (this.selectedDestiny !== undefined && this.selectedDestiny !== null) {
          valido = true;
        } else {
          valido = false;
        }
        return valido;
      } else {
        valido = true;
      }
      return valido;
    }
    return valido;
  }

  _setSearchIdInResults(response: any) {
    response.results.forEach((obj) => {
      obj.searchId = response.searchId;
    });
  }

  onReturnChange() {
    this.returnDestinations = !this.returnDestinations;
  }

  _setArrivalAndDepartureDate(response: any, form) {
    response.results.forEach((obj) => {
      obj.dateIni = moment(this.dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD");
      obj.dateEnd = moment(this.dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD");
    });
  }

  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.destination = this._longDestination;
        this._resultsQuery.origin = this._longOrigin;

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = "No results found for the search";
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
        this.results.emit(arrayResults);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = "No results found for the search";
      this.results.emit(this._resultsQuery);
    }
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }

  _payload(form: FormGroup, dest: string, orig: string) {
    const payload = {
      providersId: [],
      categoriesId: [2],
      origin: orig,
      destination: dest !== undefined ? dest : orig,
      priceFrom: null,
      priceTo: null,
      arrivalDate: moment(form.value["arrivalDate"].toString().split(",")[0]).format("YYYY-MM-DD"),
      departureDate: moment(form.value["departureDate"].toString().split(",")[0]).format("YYYY-MM-DD"),
      qtyProduct: 1,
      others: {
        maxRequestTimeOnSeconds: 20,
        minNumResults: 10,
        carRental: {
          pickupLocation: {
            id: this.addreOriginSelected.id, // 'QDrQ6XlzSdoJWW3YuCk0zv6DePiEnevJFhtX7dmQqJ8=.'
            address: this.addreOriginSelected.address,
          },
          dropoffLocation: {
            // tslint:disable-next-line:max-line-length
            id: !this.returnDestinations ? this.addreDestinySelected.id : this.addreOriginSelected.id, // 'QDrQ6XlzSdoJWW3YuCk0zv6DePiEnevJFhtX7dmQqJ8=.'
            address: !this.returnDestinations ? this.addreDestinySelected.address : this.addreOriginSelected.address,
          },
        },
      },
    };
    return payload;
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  /***********************************************Auto Complete********************************************/
  _myListFormatter = (data: any) => {
  }

  capitalize(val: any) {
    
    if(val !== undefined){
      return val
        .toLowerCase()
        .trim()
        .split(" ")
        .map((v) => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    }
  }

  _myCallback(data: any) {

  }

  observableSource = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getCitys(keyword);
  };

  displayFn(name: any) {
    if (name) {
      if (name.city_name != undefined) {
        return name.city_name;
      }
      if (name.name_airport != undefined) {
        return name.name_airport;
      }
    }
  }

  updateMySelectionOrigin(event: any) {
    if (event) {
      this._origin = event.city_name ? event.city_name : "";
      this.selectedOrigin = event.city_iata_code ? event.city_iata_code : "";
      this._longOrigin = event.city_name ? event.city_name : "";
      this.selectedOrigin = event.city_iata_code ? event.city_iata_code : "";
      if (this.returnDestinations) {
          // tslint:disable-next-line:max-line-length
          this._form.controls['destination'].setValue( this._form.controls['origin'].setValue);
      }
    }
  }


  updateMySelectionDestination(event: any) {
    if (event) {
      this.destiny = event.city_name ? event.city_name : "";
      this.selectedDestiny = event.city_iata_code ? event.city_iata_code : "";
      this._longDestination = event.city_name ? event.city_name : "";
      this.selectedDestiny = event.city_iata_code ? event.city_iata_code : "";

    }
  }

  /***********************************************Auto Complete********************************************/

  fillDirectionsByIataCode(iataCode: any, addre: any, isOrigin: boolean) {
    let response;
    let type: any;
    //this._addressService.getAddress(iataCode, addre, 2).subscribe(
    this._addressService._getAddress(iataCode, addre, 2, type).subscribe(
      (response: any) => {
        if (response) {
          if (isOrigin) {
            this._addressOriginRentaCar = [];
            this._addressOriginRentaCar.push(this.getSelectValueDefault());
          } else {
            this._addressDestinyRentaCar = [];
            this._addressDestinyRentaCar.push(this.getSelectValueDefault());
          }
          response.forEach((obj) => {
            if (isOrigin) {
              if (!this._addressOriginRentaCar.includes(obj)) {
                this._addressOriginRentaCar.push(obj);
              }
            } else {
              if (!this._addressDestinyRentaCar.includes(obj)) {
                this._addressDestinyRentaCar.push(obj);
              }
            }
          });
        }
      },
      (error) => {
        console.log("Error consulting Address in rentacar");
      }
    );
  }

  fillObject(id: any, origin: boolean) {
    //console.log("id.value", id.value);
    if (origin) {
      this.addreOriginSelected = this._addressOriginRentaCar.find((obj) => obj.id === id.value);
    } else {
      this.addreDestinySelected = this._addressDestinyRentaCar.find((obj) => obj.id === id.value);
    }
  }

  changueReturnDestinations() {
    this.returnDestinations = !this.returnDestinations;
    if (!this.returnDestinations) {
      this._form.controls['destination'].setValue('');
    } else {
      // tslint:disable-next-line:max-line-length
      this._form.controls['destination'].setValue( this._form.controls['origin'].setValue);
    }
  }
}
