export const environment = {
  production: false,
  version: '1.0.0',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR',
    'es-AR'
  ],

  agedriver: 31,
  serialized: false,
  maxRequestTimeOnSeconds: 120,
  minNumResults: 4,
  maxNumResultsPerAdapter: "",
  utilities : '/utilities',
  hotels: '/hotels',
  rentacar : '/rentacar',
  tours : '/tours',
  transport : '/transfers',


  // apiUrl: 'http://octopus-apis.com/api',
  apiUrl: 'https://dev.octopus-apis.com/api',
   apiUsbUrl: 'http://localhost/USBlick-API/public/api/v1',
  // apiUsbUrl: 'https://booking-api.moebius.tur.ar/api/v1',

  client_id:  'QA7kxgtk2eKrJDwK',
  client_secret: 'mL6SNux7pD8p8FoQRneYz0BP9NI9AP1519938479',

  ApiUser_ : "MOEV254135",
  ApiKey_  : "7245M841O01EV25",


  ApiUser : "ZCO754232X2",
  ApiKey  : "750ZCO1S02B000",

  // Auth URL cambio
  module: 'USBLICK',

  authApiUrl: 'https://auth-api.usblick.com/api/',
 // authApiUrl: 'http://localhost/Auth API/public/api/',
 // authAppUrl: 'http://localhost:4200/#/home/',
   authAppUrl: 'https://moebius.tur.ar/#/home/',
  ActivatedGuard: true
}

