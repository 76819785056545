import { HttpClient , HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import { setting } from '../../setting';
import { Observable } from 'rxjs';

@Injectable()

export class AppService {
	public baseUrl: string;
	public apiBookingUrl: string;
	constructor(private _http: HttpClient) {
		this.baseUrl = setting.apiBTIUrl;
		this.apiBookingUrl = setting.apiBookingUrl
	}
	getClientsSettingAppBooking() {
		let params: HttpParams = new HttpParams();
		params = params.set('name', setting.name);
    const url = `${this.baseUrl}getClientsSettingAppBooking?name=${setting.name}`
    console.log(url)
		return this._http.get(url);
	}

  clientsApplications(clientId: any): Observable<any> {
    const url = `${setting.apiBTIUrl}application/clients?client_id=${clientId}`;

    return this._http.get<any>(url);
  }
}
