<!-- <div class="section-topextend" *ngIf="!loaded">
  <a class="loader text-center justify-content-center p-2">
    <i class="fa fa-spinner fa-spin"></i>Cargando Puntos, por favor espere...
  </a>
</div> -->

<div
      usbLoadingSplash
      [appLoading]="appLoading"
      [fullscreen]="true"
      [component]="'detailSummary'">
    </div>

<div id="bg-resumen" style="background-color: #f0eef4 !important;">
  <div class="top-summary" style="font-family: Poppins;">
    <div class="container pt-5" id="container-summary">
      <div class="steps" style="margin-bottom: -60px;">
        <usb-steps-reservation>
        </usb-steps-reservation>
      </div>
      <div class="mt-4" style="border-radius: 5px;" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
        <div class="d-flex" style="padding-top: 50px;">
          <div style="width: 100%; margin-top: 30px; padding-left: 60px; margin-right: 30px;">
            <div >
              <h5 class="itinerary-nro" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">FILE N° {{id}} </h5>
              <p class="m-0 itinerary-date">Fecha de Creación: {{ itineraryItems?.created_at| date: 'dd/MM/yyy' }}</p>
              <p class="itinerary-date">Modificación: {{ itineraryItems?.updated_at | date:'dd/MM/yyy' }}</p>
              <h4 class="client-data" *ngIf="itineraryClient?.first_name">
                {{ itineraryClient?.first_name + ' ' + itineraryClient?.last_name }}</h4>
              <div class="row mt-5 ml-0 mr-0 py-1 bar-detail">
                <p class="m-0 ml-3 dark-1 d-flex align-items-center">
                  <!-- <object type="image/svg+xml" data="/assets/img/app/little-icons/moon_grisclaro.svg" width="10px" height="10px"></object> -->
                  <!-- <span class="i-luna"></span> -->
                  <i class="icon i-bed"></i>
                  <span class="text-bar-detail">&nbsp;&nbsp;{{ userService.itineraryQtyNights }} Noches</span>
                </p>
                <p class="m-0 ml-3 dark-1 d-flex align-items-center">
                  <!-- <object type="image/svg+xml" data="/assets/img/app/little-icons/plane1.svg" width="15px" height="15px"></object> -->
                  <img src="/assets/img/app/little-icons/user.svg" class="icon-adult-people">
                  <span class="text-bar-detail">&nbsp;&nbsp;{{itineraryItems.totalAdultsMax}} Adultos</span>
                </p>
                <p class="m-0 ml-3 dark-1 d-flex align-items-center">
                  <!-- <object type="image/svg+xml" data="/assets/img/app/little-icons/plane1.svg" width="15px" height="15px"></object> -->
                  <img src="/assets/img/app/little-icons/child.svg" class="icon-adult-people">
                  <span class="text-bar-detail">&nbsp;&nbsp;{{itineraryItems.totalChildrenMax}} Niños</span>
                </p>
                <!-- <p class="m-0 ml-3 dark-1 d-flex align-items-center">
                  <object type="image/svg+xml" data="/assets/img/app/little-icons/plane1.svg" width="15px" height="15px"></object>
                  <span class="i-air"></span>
                  <span class="text-bar-detail">&nbsp;&nbsp;0 Vuelos</span>
                </p> -->
                <p class="m-0 ml-3 dark-1 d-flex align-items-center" *ngIf="userService.verifyCategory('TOURS')">
                  <span class="icon i-ticket"></span>
                  <span class="text-bar-detail">&nbsp;&nbsp;{{userService.verifyQuantityCategory('TOURS')}} Tickets Tours</span>
                  <!-- <object type="image/svg+xml" data="/assets/img/app/little-icons/moon_grisclaro.svg" width="10px" height="10px"></object> -->
                  <!--  <span class="i-ticket"></span>
                  <span class="text-bar-detail">&nbsp;&nbsp;2 Tickets Tours</span>-->
                </p>
              </div>
            </div>
          </div>
          <div  style="border-left:solid 3px white;">
            <div class="d-flex flex-column" style="margin-left: 20px; margin-right: 12px; margin-top: 30px;">
              <div class="row">
                <div class="col-2">
                  <!-- <object type="image/svg+xml" data='/assets/img/app/little-icons/check-mark-green.svg' width="35px"
                  height="35px" class="check-white"></object> -->
                  <span class="icon-check-green"></span>
                </div>
                <div class="col-10" *ngIf="userService.itineraryActive.length > 0">
                  <h5 class="succesfully-text" *ngIf="itineraryItems.status_id == 1">EL FILE FUE COTIZADO CON ÉXITO EN MODO QUOTE</h5>
                  <h5 class="succesfully-text" *ngIf="itineraryItems.status_id == 2">EL FILE FUE COTIZADO CON ÉXITO EN MODO PARCIAL </h5>
                  <h5 class="succesfully-text" *ngIf="itineraryItems.status_id == 3">EL FILE FUE COTIZADO CON ÉXITO EN MODO RESERVADO</h5>
                  <h5 class="succesfully-text" *ngIf="itineraryItems.status_id == 4">EL FILE FUE COTIZADO CON ÉXITO EN MODO DENEGADO</h5>
                  <h5 class="succesfully-text" *ngIf="itineraryItems.status_id == 5">EL FILE FUE COTIZADO CON ÉXITO EN MODO CANCELADO</h5>
                  <h5 class="succesfully-text" *ngIf="itineraryItems.status_id == 6">EL FILE FUE COTIZADO CON ÉXITO EN MODO COMPLETADO</h5>
                </div>
              </div>

              <div class="d-flex flex-column justify-content-center">
              <div class="row ml-1" style="margin-top: 80px;">
                <div class="col-2">
                  <img class="icon-premium" src="../../assets/img/app/little-icons/badge-bks.png" alt="logo bks"
                  style="margin-top: 5px; margin-left: 5px;">
                </div>
                <div class="col-10">
                  <div class="" style="color:#ff9900 !important;">
                    <div *ngIf="!loaded">
                      <a class="loader text-center justify-content-center" *ngIf="!pointCategory">
                        <i class="fa fa-spinner fa-spin"></i>
                      </a>
                    </div>
                    <p class="text-points col-6 pl-0" style="color: #facb49; margin-bottom: -5px"> Sumaste </p>
                    <p class="text-points col-6 pl-0" style="color: #facb49">{{pointCategory | number: '1.2-2'}}&nbsp;Blickoins </p>
                  </div>
                </div>
              </div>
              <div class="row mx-auto">
                  <div class="pl-2 pr-2 pb-1" style="border-radius: 5px; background-color:#facb49 ; margin-top: -14px; margin-left:35px;">
                    <div *ngIf="!loaded">
                      <a class="loader text-center justify-content-center" *ngIf="!totalPointGlobal">
                        <i class="fa fa-spinner fa-spin"></i>
                      </a>
                    </div>
                    <div class="d-flex flex-column align-items-start">
                    <p class="text-points m-0" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"> Total&nbsp;{{ pointsAvailable | number: '1.2-2' }}&nbsp;Blickoins </p>
                    <p class="text-points-after" style="margin-top: -6px; margin-bottom: 0;" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"> Después de este viaje </p>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div style="border-left:solid 3px white; width: 69%;">

            <div class="adjust-price" style="margin: 30px 40px 30px 35px;">
              <div>
                <h5 class="m-0 text-uppercase text-status" *ngIf="itineraryItems.state" style="color: #00d397;">
                  <span *ngIf="itineraryItems.state.name === 'Quote'">quote</span>
                  <span *ngIf="itineraryItems.state.name === 'Reserve'">reservado</span>
                  <span *ngIf="itineraryItems.state.name === 'Partial Reserve'">reserva parcial</span>
                  <span *ngIf="itineraryItems.state.name === 'Cancelled'">cancelado</span>
                  <span *ngIf="itineraryItems.state.name === 'Denied'">denegado</span>
                  <span *ngIf="itineraryItems.state.name === 'Completed'">completado</span>
                </h5>
              </div>


              <div class="row font-weight-bold mt-2">
                <div class="col-6 d-flex flex-column">
                  <div class="text-price" style="height: 18px;">
                    TARIFA TOTAL:
                  </div>
                  <div style="font-size: 11px; font-weight: 300">
                    incluye impuesto
                  </div>
                </div>
                  <div class="col-6 d-flex justify-content-end align-items-center price text-nowrap">
                    {{currencyItinerary}} {{ this.itineraryItems.totalNetPriceAgent  | number:'1.2-2' }}
                  </div>

              </div>

              <!-- <div class="row font-weight-bold mt-2">
                <div class="col-6 d-flex align-items-center text-price">
                  INCLUYE IMPUESTO:
                </div>
                <div class="col-6 d-flex justify-content-end align-items-center price text-nowrap">
                  $ {{ 3.68  | number:'1.2-2' }}
                </div>
              </div> -->

              <div class="row font-weight-bold mt-2">
                <div class="col-6 d-flex align-items-center text-price">
                  COMISIÓN
                </div>
                <div class="col-6 d-flex align-items-center justify-content-end comision text-nowrap">
                  {{currencyItinerary}} {{ this.itineraryItems.totalFee ? this.itineraryItems.totalFee : 0 | number:'1.2-2'  }}
                </div>
              </div>

              <div class="row font-weight-bold mt-2">
                <div class="col-6 fee-text">
                  FEE:
                </div>
                <div class="col-6 d-flex align-items-center justify-content-end text-right" style="font-size: 20px;">
                  {{ this.itineraryItems.percentageFee ? this.itineraryItems.percentageFee : 0 }} %
                  <!-- <input class="col-10" type="text" style="border-radius: 5px;" > -->
                </div>
              </div>

              <div class="row mt-3 px-1 py-2 ml-0 mr-0" style="border-radius: 5px; border: solid 1px white;">
                <div class="col-7 p-0 pl-2 d-flex align-items-center net-price-text mt-1">
                  TARIFA NETA RESERVA
                </div>
                <div *ngIf="itineraryItems.totalNetPriceBookingFreeTax === 0" class="col-5 p-0 pr-1 justify-content-end net-price text-nowrap mt-1">
                  <!--  $ {{ userService.totalNetReserved | number:'1.2-2'  }}-->
                  {{currencyItinerary}} {{ this.itineraryItems?.totalPriceByNetReserved | number:'1.2-2'  }}
                </div>
                <div *ngIf="itineraryItems.totalNetPriceBookingFreeTax > 0" class="col-5 p-0 pr-1 justify-content-end net-price text-nowrap mt-1">
                  <!--  $ {{ userService.totalNetReserved | number:'1.2-2'  }}-->
                  {{currencyItinerary}} {{ itineraryItems.totalNetPriceBookingFreeTax  | number:'1.2-2'  }}
                </div>

                <div *ngIf="itineraryItems.totalNetPriceBookingFreeTax > 0" class="col-7 p-0 pl-2 d-flex align-items-center net-price-text mt-2">
                  IMPUESTO
                </div>
                <div *ngIf="itineraryItems.totalNetPriceBookingFreeTax > 0" class="col-5 p-0 pr-1 justify-content-end net-price text-nowrap mt-2">
                  <!--  $ {{ userService.totalNetReserved | number:'1.2-2'  }}-->
                  <!-- Descomentar en caso que se vuelva a usar $ {{ itineraryItems.totalTaxPrice | number:'1.2-2'  }} -->

                  {{currencyItinerary}} {{ totalTaxPrice | number:'1.2-2'  }}
                </div>

                <div class="col-7 p-0 pl-2 d-flex align-items-center net-price-text mt-2">
                  TARIFA TOTAL RESERVA
                </div>
                <div class="col-5 p-0 pr-1 justify-content-end net-price text-nowrap mt-2">
                  {{currencyItinerary}} {{  this.itineraryItems?.totalPriceByReserved | number:'1.2-2'  }}
                <!--    $ {{ userService.totalReserved | number:'1.2-2'  }} -->
                </div>

              </div>

              <div class="container">
                <div class="row mt-3" style="align-items: center;">
                   <!-- itineraryItems.status_id == 1 || itineraryItems.status_id == 3 -->
                  <!-- <div class="col-2 d-flex justify-content-center" (click)="liduidationItinerayPdf(itineraryItems?.uid)"> -->
                    <div *ngIf="permissionsMap['booking.itinerarios.liquidacion']" class="col-2 d-flex justify-content-center" >
                    <i tooltip="Descargar liquidación" placement="bottom" class="liquidation" style="cursor: pointer; padding: 23px !important" (click)="downloadLiquidation(itineraryItems.id);"></i>
                  </div>
                  <!-- <div *ngIf="permissionsMap['booking.itinerarios.pagar'] && buttonPay && searchProductReserved()" class="col-10" style="text-align: end;">
                    <button class="btn btn-pay" type="button" (click)="pay()" style=" border-radius: 50px;width:100%"
                    [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
                      Pagar</button>
                  </div> -->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" id="container-detail-reserve" style="font-family: Poppins;">
    <ng-container *ngIf="partialReserve===false">
      <div class="resumen-summary-content justify-content-center resumen pt-5 pl-5 pr-5">
        <h4 class="text-uppercase text-status-service" *ngIf="itineraryItems.state"> PRODUCTOS
          {{itineraryItems.state.name ? itineraryItems.state.name : ''}} LISTADO</h4>
        <div class="mt-4">
          <ng-container *ngFor="let item of itineraryItems.items, let i = index">
            <!-- <div style="display: none">
              <usb-vouchers-reservation [item]=item [itinerary]=itineraryItems [id]=item.id ></usb-vouchers-reservation>
            </div> -->
            <hr class="mt-1" style="border-top: 1px dotted #4e4e4e !important;" *ngIf="i >= 1">
            <div class="row p-2 justify-content-between">
              <div class="col-9 item-descrption">
                <div class="row">
                  <div class="col-1 p-0 d-flex justify-content-center">
                    <div class="d-flex circle align-items-center justify-content-center p-0" [ngClass]="{
                                    'product-status-saved': item.status_id === 1,
                                    'product-status-reserved': item.status_id === 2,
                                    'product-status-locked': item.status_id === 3,
                                    'product-status-denied': item.status_id === 4,
                                    'product-status-cancelled': item.status_id === 5,
                                    'product-status-paid-agency': item.status_id === 6,
                                    'product-status-paid-provider': item.status_id === 7,
                                    'product-status-billed': item.status_id === 8,
                                    'product-status-pending-approve-pay': item.status_id === 9,
                                    'product-status-completed': item.status_id === 10
                                  }">
                      <svg class="icons-hotel" [ngClass]="{
                        'icons-status-saved': item.status_id === 1,
                        'icons-status-reserved': item.status_id === 2,
                        'icons-status-locked': item.status_id === 3,
                        'icons-status-denied': item.status_id === 4,
                        'icons-status-cancelled': item.status_id === 5,
                        'icons-status-paid-agency': item.status_id === 6,
                        'icons-status-paid-provider': item.status_id === 7,
                        'icons-status-billed': item.status_id === 8,
                        'icons-status-pending-approve-pay': item.status_id === 9,
                        'icons-status-completed': item.status_id === 10
                      }" *ngIf="item.categoryName=='HOTELS'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g>
                          <g>
                            <g>
                              <path d="M53.333,202.667h53.333c-3.285,0-6.379-1.515-8.405-4.096c-2.027-2.581-2.731-5.952-1.941-9.152l6.635-26.517
                              c3.563-14.272,16.341-24.235,31.04-24.235h68.672c17.643,0,32,14.357,32,32V192c0,5.888-4.779,10.667-10.667,10.667h64
                              c-5.888,0-10.667-4.779-10.667-10.667v-21.333c0-17.643,14.357-32,32-32h68.672c14.699,0,27.477,9.963,31.04,24.235l6.635,26.517
                              c0.811,3.179,0.085,6.571-1.941,9.152c-2.027,2.581-5.12,4.096-8.405,4.096h53.333c5.888,0,10.667-4.779,10.667-10.667v-64
                              c0-29.397-23.936-53.333-53.333-53.333H96c-29.397,0-53.333,23.936-53.333,53.333v64
                              C42.667,197.888,47.445,202.667,53.333,202.667z" />
                              <path d="M458.667,224H53.333C23.936,224,0,247.936,0,277.333v149.333c0,5.888,4.779,10.667,10.667,10.667
                              s10.667-4.779,10.667-10.667v-32h469.333v32c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V277.333
                              C512,247.936,488.064,224,458.667,224z M490.667,373.333H21.333V352h469.333V373.333z" />
                            </g>
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>
                      <svg class="icons-tours" [ngClass]="{
                        'icons-status-saved': item.status_id === 1,
                        'icons-status-reserved': item.status_id === 2,
                        'icons-status-locked': item.status_id === 3,
                        'icons-status-denied': item.status_id === 4,
                        'icons-status-cancelled': item.status_id === 5,
                        'icons-status-paid-agency': item.status_id === 6,
                        'icons-status-paid-provider': item.status_id === 7,
                        'icons-status-billed': item.status_id === 8,
                        'icons-status-pending-approve-pay': item.status_id === 9,
                        'icons-status-completed': item.status_id === 10
                      }" *ngIf="item.categoryName=='TOURS'" height="461pt" viewBox="-78 0 461 461.344" width="461pt"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m128.179688 180.726562 22.769531 136.617188h2.445312l22.738281-136.398438c-.226562-13.050781-10.863281-23.601562-23.960937-23.601562-13.023437 0-23.664063 10.441406-23.992187 23.382812zm0 0" />
                        <path
                          d="m216.171875 157.34375c-13.230469 0-24 10.769531-24 24l-.113281 1.3125-22.445313 134.6875h17.078125l53.433594-137.398438c-.722656-12.585937-11.183594-22.601562-23.953125-22.601562zm0 0" />
                        <path
                          d="m88.171875 157.34375c-12.769531 0-23.230469 10.015625-23.9609 22.601562l53.433593 137.398438h17.085938l-22.558594-136c0-13.230469-10.769531-24-24-24zm0 0" />
                        <path
                          d="m184.15625 157.566406c7.296875-9.796875 18.886719-16.222656 32.015625-16.222656 8.710937 0 16.71875 2.878906 23.296875 7.625-6.226562-78.585938-55.929688-126.074219-79.296875-144.226562v137.410156c9.800781 2 18.207031 7.671875 23.984375 15.414062zm0 0" />
                        <path d="m71.148438 365.34375h162.046874l-14.222656-32h-133.601562zm0 0" />
                        <path
                          d="m100.476562 317.34375-52.304687-134.496094v-1.503906c0-13.230469-10.769531-24-24-24-12.519531 0-22.839844 9.648438-23.910156 21.894531l84.398437 138.105469zm0 0" />
                        <path
                          d="m179.914062 0c27.800782 23.984375 70.570313 72.976562 75.691407 150.015625 6.804687-5.34375 15.261719-8.671875 24.566406-8.671875 9.039063 0 17.296875 3.128906 24 8.207031v-.207031c0-74.328125-53.664063-136.253906-124.257813-149.34375zm0 0" />
                        <path
                          d="m280.171875 157.34375c-13.230469 0-24 10.769531-24 24v1.503906l-52.304687 134.496094h15.816406l84.402344-138.105469c-1.074219-12.246093-11.394532-21.894531-23.914063-21.894531zm0 0" />
                        <path
                          d="m240.308594 381.34375h-176.265625l-3.4375 7.734375c-2.9375 6.609375-4.433594 13.625-4.433594 20.867187 0 28.34375 23.054687 51.398438 51.390625 51.398438h89.210938c28.34375 0 51.398437-23.054688 51.398437-51.398438 0-7.242187-1.496094-14.257812-4.433594-20.867187zm0 0" />
                        <path
                          d="m64.875 148.96875c6.578125-4.746094 14.585938-7.625 23.296875-7.625 13.128906 0 24.703125 6.449219 32 16.25 5.777344-7.761719 14.191406-13.441406 24-15.441406v-137.410156c-23.367187 18.152343-73.070313 65.640624-79.296875 144.226562zm0 0" />
                        <path
                          d="m48.738281 150.015625c5.128907-77.039063 47.890625-126.03125 75.691407-150.015625-70.59375 13.089844-124.257813 75.015625-124.257813 149.34375v.207031c6.703125-5.078125 14.960937-8.207031 24-8.207031 9.304687 0 17.761719 3.328125 24.566406 8.671875zm0 0" />
                      </svg>
                      <svg class="icons-transport" [ngClass]="{
                        'icons-status-saved': item.status_id === 1,
                        'icons-status-reserved': item.status_id === 2,
                        'icons-status-locked': item.status_id === 3,
                        'icons-status-denied': item.status_id === 4,
                        'icons-status-cancelled': item.status_id === 5,
                        'icons-status-paid-agency': item.status_id === 6,
                        'icons-status-paid-provider': item.status_id === 7,
                        'icons-status-billed': item.status_id === 8,
                        'icons-status-pending-approve-pay': item.status_id === 9,
                        'icons-status-completed': item.status_id === 10
                      }" *ngIf="item.categoryName=='TRANSPORTS'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g>
                          <g>
                            <path
                              d="M53.333,234.667v-64C59.221,170.667,64,165.888,64,160s-4.779-10.667-10.667-10.667C23.915,149.333,0,173.269,0,202.667
                                     V224c0,17.643,14.357,32,32,32h21.333C59.221,256,64,251.221,64,245.333S59.221,234.667,53.333,234.667z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M458.667,149.333c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667v64
                                     c-5.888,0-10.667,4.779-10.667,10.667S452.779,256,458.667,256H480c17.643,0,32-14.357,32-32v-21.333
                                     C512,173.269,488.085,149.333,458.667,149.333z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M181.333,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667
                                     V480c0,17.643,14.357,32,32,32H160c17.643,0,32-14.357,32-32v-21.333C192,452.779,187.221,448,181.333,448z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M416,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667S320,452.779,320,458.667V480
                                     c0,17.643,14.357,32,32,32h42.667c17.643,0,32-14.357,32-32v-21.333C426.667,452.779,421.888,448,416,448z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M416,0H96C66.581,0,42.667,23.936,42.667,53.333V416c0,29.397,23.915,53.333,53.333,53.333h320
                                     c29.419,0,53.333-23.936,53.333-53.333V53.333C469.333,23.936,445.419,0,416,0z M138.667,42.667h234.667c17.643,0,32,14.357,32,32
                                     c0,17.643-14.357,32-32,32H138.667c-17.643,0-32-14.357-32-32C106.667,57.024,121.024,42.667,138.667,42.667z M138.667,405.333
                                     c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32C170.667,390.976,156.309,405.333,138.667,405.333z
                                      M373.333,405.333c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32
                                     C405.333,390.976,390.976,405.333,373.333,405.333z M426.667,266.667c0,17.643-14.357,32-32,32H117.333c-17.643,0-32-14.357-32-32
                                     V160c0-17.643,14.357-32,32-32h277.333c17.643,0,32,14.357,32,32V266.667z" />
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>
                      <svg class="icons-cars" [ngClass]="{
                        'icons-status-saved': item.status_id === 1,
                        'icons-status-reserved': item.status_id === 2,
                        'icons-status-locked': item.status_id === 3,
                        'icons-status-denied': item.status_id === 4,
                        'icons-status-cancelled': item.status_id === 5,
                        'icons-status-paid-agency': item.status_id === 6,
                        'icons-status-paid-provider': item.status_id === 7,
                        'icons-status-billed': item.status_id === 8,
                        'icons-status-pending-approve-pay': item.status_id === 9,
                        'icons-status-completed': item.status_id === 10
                      }" *ngIf="item.categoryName=='RENT-A-CAR'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g>
                          <g>
                            <g>
                              <path _ngcontent-ouu-c28="" inkscape:connector-curvature="0"
                                d="m 447.639,206.032 c 0,-8.805 -1.988,-17.215 -5.578,-24.909 -0.37,-1.956 -0.793,-3.909 -1.322,-5.89 l -38.884,-96.364998 -0.263,-0.867 C 387.987,37.492002 368.629,0 319.543,0 H 131.868 C 81.572,0 63.799,38.421002 49.896,77.776002 L 9.223,174.376 C 3.343,183.797 0,194.574 0,206.032 v 29.986 c 0,4.636 0.548,9.171 1.59,13.539 C 0.577,252.196 0,255.04 0,258.038 v 89.185 c 0,13.078 10.602,23.68199 23.68,23.68199 h 49.14 c 13.070999,0 23.672999,-10.60399 23.672999,-23.68199 V 302.624 H 353.953 v 44.599 c 0,13.078 10.604,23.68199 23.683,23.68199 h 46.326 c 13.083,0 23.683,-10.60399 23.683,-23.68199 v -89.195 c 0,-2.987 -0.583,-5.844 -1.588,-8.474 1.038,-4.375 1.588,-8.905 1.588,-13.54 v -29.981 h -0.01 z M 78.754,87.451002 c 15.482999,-43.683 27.934,-57.018 53.114,-57.018 h 187.664 c 24.995,0 38.913,14.873 53.056,56.83 L 400.963,144.765 c -9.265,-3.431 -19.461,-5.335 -30.173,-5.335 H 76.849 c -9.645,0 -18.862,1.551 -27.366,4.358 z M 103.129,247.406 H 51.281 c -9.335,0 -16.906,-7.578 -16.906,-16.912 0,-9.337 7.571,-16.91 16.906,-16.91 h 51.848 c 9.339,0 16.91,7.573 16.91,16.91 0,9.334 -7.576,16.912 -16.91,16.912 z m 183.155,-3.387 h -120.6 c -5.913,0 -10.704,-4.794 -10.704,-10.704 0,-5.921 4.791,-10.713 10.704,-10.713 h 120.6 c 5.92,0 10.71,4.792 10.71,10.713 0,5.91 -4.79,10.704 -10.71,10.704 z m 108.767,3.387 h -51.846 c -9.343,0 -16.91,-7.578 -16.91,-16.912 0,-9.337 7.573,-16.91 16.91,-16.91 h 51.846 c 9.343,0 16.916,7.573 16.916,16.91 0,9.334 -7.573,16.912 -16.916,16.912 z"
                                id="svg_2"></path>

                            </g>
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div class="col-9" *ngIf="item.categoryName=='HOTELS'">
                    <h5 class="font-weight-bold m-0 text-capitalize title-product">{{item.title}}</h5>
                    <!-- <p class="mt-1 m-0 text-location">{{item.description}}</p> -->
                    <p class="mt-1 m-0 text-location">{{item.roomType}}</p>
                    <p class="mt-1 m-0 text-location">{{item.rateClassId  === "R" ? 'Reembolsable' : 'No Reembolsable'}}</p>
                    <p class="font-weight-bold mt-1 m-0 text-location">
                      <!-- <i class="icon-placeholder font-verde"></i> -->
                      <i class="bi bi-geo-alt-fill" aria-hidden="true" style="font-size: 14px; color:#00d398"></i>
                      {{item.location}}</p>
                  </div>
                  <div class="col-9" *ngIf="item.categoryName=='TOURS'">
                    <h5 class="font-weight-bold m-0 text-capitalize title-product">{{item.title}}</h5>
                    <p class="mt-1 m-0 text-location">{{item.destination.iata_code}}</p>
                    <p class="font-weight-light m-0 provider-name">{{item.providerName}}</p>
                    <div class="row m-0">
                      <p class="m-0 text-pick-up"> <span class="font-weight-bold">Date Pick up:</span>
                        {{ item.arrivalDate | date:'mediumDate' }} </p>
                      <p class="m-0 ml-4 text-pick-up"> <span class="font-weight-bold">Date Pick up:</span>
                        {{ item.departureDate | date:'mediumDate' }} </p>
                    </div>
                  </div>
                  <div class="col-9" *ngIf="item.categoryName=='RENT-A-CAR'">
                    <h5 class="font-weight-bold m-0 text-capitalize title-product">{{item.title}}</h5>
                    <div class="font-poppins location-text m-0 mt-2">
                      <i class="bi bi-geo-alt-fill" aria-hidden="true" style="font-size: 14px; color:#00d398"></i>
                      <!-- <i class="icon-placeholder font-verde"></i> -->
                      <span>{{stingUpperCase(getLocation(item))}}</span>
                    </div>
                    <div class="mr-auto mt-2">
                      <img [src]="item.optionRates?.suplier?.img" onerror="if (this.src != 'assets/img/app/rent-a-car-default.png') this.src = 'assets/img/app/rent-a-car-default.png';" class="marca" style="object-fit:contain;" alt="">
                    </div>
                  </div>
                  <div class="col-9" *ngIf="item.categoryName=='TRANSPORTS'">
                    <h5 class="font-weight-bold m-0 text-capitalize title-product">{{item.title}}</h5>
                    <div class="font-poppins location-text m-0 mt-2">
                      <i class="bi bi-geo-alt-fill" aria-hidden="true" style="font-size: 14px; color:#00d398"></i>
                      <!-- <i class="icon-placeholder font-verde"></i> -->
                      <span>{{stingUpperCase(getLocation(item))}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 px-0 d-flex flex-column align-items-end">
                <span class="d-flex flex-row" style="gap: 1em;">
                  <div *ngIf="permissionsMap['booking.itinerarios.voucher']">
                    <div class="d-flex flex-row justify-content-center container-btn-tickets">
                      <i class="i-voucher mr-2" *ngIf="item.status != 'Quote'" (click)="vouchersReservationPdf(item.id)"
                        visible="item.status_id!=2 && item.status_id!=5"></i>
                        <!-- <i class="i-voucher mr-2" (click)="vouchersReservationPdf(item.id)"
                        visible="item.status_id!=2 && item.status_id!=5"></i> -->
                    </div>
                  </div>
                  <div class="p-2 d-flex justify-content-between align-items-center " [ngClass]="{
                                'product-status-saved': item.status_id === 1,
                                'product-status-reserved': item.status_id === 2,
                                'product-status-locked': item.status_id === 3,
                                'product-status-denied': item.status_id === 4,
                                'product-status-cancelled': item.status_id === 5,
                                'product-status-paid-agency': item.status_id === 6,
                                'product-status-paid-provider': item.status_id === 7,
                                'product-status-billed': item.status_id === 8,
                                'product-status-pending-approve-pay': item.status_id === 9,
                                'product-status-completed': item.status_id === 10
                              }" style="border-radius: 5px; width:8.5em;">
                    <span class="icon-check"></span>
                    <h5 class="m-0 font-weight-bold text-white" style="font-size: 14px;">
                      {{item.status}}
                    </h5>
                  </div>
                </span>
                <div *ngIf="item.categoryName=='HOTELS'" class="d-flex flex-column row m-0 mt-2 align-items-end justify-content-center">
                  <!-- <p class="m-0" style="font-size:16px;color: #454545"> <span class="font-weight-bold">TARIFA <span style="font-size: 11px;">(incluye impuesto): </span></span>
                    USD {{ item.optionNightsNetTotalProf | number:'1.2-2'  }} </p> -->
                    <p class="m-0" style="font-size:16px;color: #454545"> <span class="font-weight-bold">TARIFA: </span>
                      {{currencyItinerary}} {{ item.optionNightsNetTotalProf | number:'1.2-2'  }} </p>
                    <p class="font-weight-bold" style="font-size: 11px;">inc. impuesto</p>
                </div>
                <div *ngIf="item.categoryName=='RENT-A-CAR'" class="d-flex flex-column m-0 mt-2 align-items-end justify-content-center">
                  <!-- <p class="m-0" style="font-size:16px;color: #454545"> <span class="font-weight-bold">TARIFA <span style="font-size: 11px;">(incluye impuesto): </span></span>
                      USD {{ item.totalPriceProf | number:'1.2-2'  }} </p> -->
                      <p class="m-0" style="font-size:16px;color: #454545"> <span class="font-weight-bold">TARIFA: </span>
                        {{currencyItinerary}} {{ item.totalPriceProf | number:'1.2-2'  }} </p>
                    <p class="font-weight-bold" style="font-size: 11px;">inc. impuesto</p>
                </div>
              </div>
            </div>
            <div class="row p-2 mt-2 justify-content-between" *ngIf="item.categoryName=='HOTELS'" style="border-top:solid 1px #272849; margin-left: 70px; margin-right: -4px;">
              <div class="col-3 item-descrption pl-0" style="width: 40rem;">
                <div *ngIf="item.status_id==1" class="row m-0" style="width: 30rem;">
                  <p class="m-0 text-pick-up" style="width: 20rem; color: #d20358; font-weight: 500;"> <span class="font-weight-bold pr-1"> Resultado de reserva: </span>
                    {{ item.resultReserve}} </p>
                </div>
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold pr-1">Fecha Inicio: </span>
                    {{ item.arrivalDate | date:'mediumDate' }} </p>
                </div>
                <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up" style="line-height: 7px;">
                    <div class="row m-0">
                      <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha Fin:</span>
                        {{ item.departureDate | date:'mediumDate' }} </p>
                    </div>
                </div>
                <div class="row m-0 mt-2"  *ngIf="item.status_id==2">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold pr-1">Localizador: </span>
                    {{item?.resultReserve?.providerReservationId }}   <!--Locator -->
                </div>
              </div>
              <div class="col-4 item-descrption px-0" style="margin-left: 20rem;">
                <div class="text-right mt-2" *ngIf="item.rateClassId == 'R'">
                  <p class="m-0 text-expiration text-align-end font-weight-bold pb-1"> POLITICA DE CANCELACIÓN </p>
                  <div class="row m-0 justify-content-end" >
                    <p class="m-0 text-expiration mt-1" [ngClass]="{'canc-gratis': item.rateClassId == 'R'}">{{item?.cancellation_policy[0]?.descript_polecy}}</p>
                    <p class="m-0 text-expiration mt-1" [ngClass]="{'canc-gratis': item.rateClassId == 'R'}">Hasta: {{item?.cancellation_policy[0]?.from | date:'dd/MM/yyyy h:mm ' }}</p>
                    <p class="m-0 text-expiration mt-1 ml-3" [ngClass]="{'canc-gratis': item.rateClassId == 'R'}"> Gratis</p>
                  </div>
                  <div class="row m-0 justify-content-end">
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159">{{item?.cancellation_policy[0]?.descript_polecy}}</p>
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159">Desde: {{item?.cancellation_policy[0]?.from | date:'dd/MM/yyyy h:mm ' }}</p>
                    <p class="m-0 text-expiration mt-1 ml-3" style="font-weight:500;color:#d60159"> {{currencyItinerary}} {{item?.cancellation_policy[0]?.amount | number: '1.2-2'}}</p>
                  </div>
                </div>
                <div class="text-right mt-2" *ngIf="item.rateClassId === 'NR'">
                  <p class="m-0 text-expiration text-align-end font-weight-bold pb-1"> POLITICA DE CANCELACIÓN </p>
                  <!-- <div class="row m-0 justify-content-end">
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159">{{item?.cancellation_policy[0]?.descript_polecy}}</p>
                    <p class="m-0 text-expiration mt-1" [ngClass]="{'canc-gratis': item.rateClass == 'NR'}"> Hasta 1 hora después de la confirmación</p>
                    <p class="m-0 text-expiration mt-1 ml-3" [ngClass]="{'canc-gratis': item.rateClass == 'NR'}"> Gratis</p>
                  </div> -->
                  <div class="row m-0 justify-content-end">
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159">{{item?.cancellation_policy[0]?.descript_polecy}}</p>
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159"> Una vez reservado </p>
                    <p class="m-0 text-expiration mt-1 ml-3" style="font-weight:500;color:#d60159"> {{currencyItinerary}} {{item?.cancellation_policy[0]?.amount | number: '1.2-2'}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row p-2 mt-2" *ngIf="item.categoryName=='TOURS'" style="border-top:solid 1px #272849; margin-left: 70px; margin-right: -4px;">
              <div class="col-4 item-descrption pl-0">
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold pr-1">Fecha Inicio: </span>
                    {{ item.arrivalDate | date:'mediumDate' }} </p>
                </div>
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold pr-1">Localizador: </span>
                    {{item?.resultReserve?.additionalInfo?.reservationId}}
                </div>
                <div class="row m-0">
                  <p class="m-0 text-pick-up" style="line-height: 7px;">
                </div>
              </div>
              <div class="col-5 item-descrption">
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha Fin:</span>
                    {{ item.departureDate | date:'mediumDate' }} </p>
                </div>
              </div>
              <div class="col-4 item-descrption px-0">
                <div class="text-right mt-2">
                  <p class="m-0 text-expiration text-align-end font-weight-bold pb-1"> POLITICA DE CANCELACIÓN </p>
                  <div class="row m-0 justify-content-end" *ngIf="item.categoryName == 'TOURS'">
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159">{{item?.resultReserve?.additionalInfo?.cxlPolicy[0]?.descript_polecy}}</p>
                    <p class="m-0 text-expiration mt-1" style="font-weight:500;color:#d60159">Hasta: {{item?.resultReserve?.additionalInfo?.cxlPolicy[0]?.from | date:'d/M/yyyy h:mm' }}</p>
                    <p class="m-0 text-expiration mt-1 ml-3" style="font-weight:500;color:#d60159"> {{currencyItinerary}} {{item?.resultReserve?.additionalInfo?.cxlPolicy[0]?.cxlCharge}}</p>
                  </div>

                </div>
              </div>
            </div>


            <div class="row p-2 mt-2" *ngIf="item.categoryName=='RENT-A-CAR'"
              style="border-top:solid 1px #272849; margin-left: 70px; margin-right: -4px;">
              <div class="col-3 item-descrption pl-0">
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha de inicio: </span>
                    {{ item.arrivalDate | date:'mediumDate' }} </p>
                </div>

                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Recogida: </span>
                    {{ item.pickupname }} </p>
                </div>
                <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Localizador: </span>
                </div>

                <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up" style="line-height: 7px;">
                    {{ item.resultReserve?.providerReservationId }} </p>
                </div>
              </div>
              <div class="col-6 item-descrption ml-5">
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha de Finalización</span>
                    {{ item.departureDate | date:'mediumDate' }} </p>
                </div>
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Devolución: </span>
                    {{ item.dropoffname }} </p>
                </div>
                <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Conductor Principal: </span>
                </div>
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> {{ item.resultReserve?.additionalInfo?.reservationDetailCarRental?.paxes[0].firstName + " " + item.resultReserve?.additionalInfo?.reservationDetailCarRental?.paxes[0].lastName }}
                  </p>
                </div>
                <!-- <div class="row m-0">
                  <p class="m-0 text-pick-up" style="line-height: 7px;">
                    {{ item.resultReserve?.additionalInfo?.reservationDetailCarRental?.paxes[0].age }} años </p>
                </div> -->
              </div>
              <div class="col-3 item-descrption px-0">
                <div class="text-right mt-2" *ngIf="dateFromCancellation">
                  <p class="m-0 text-expiration text-align-end font-weight-bold pb-1"> POLITICA DE CANCELACIÓN </p>
                  <div class="row m-0 justify-content-end mt-1">
                    <p class="m-0 text-expiration pb-1" style="font-weight:500;color:#d60159">Hasta &nbsp; </p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159 ">
                      {{ item?.resultReserve?.additionalInfo?.cancellation_policy?.PolicyRules[0]?.DateTo }} </p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159"> &nbsp; GRATIS </p>
                  </div>
                  <div class="row m-0 justify-content-end">
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159">Desde &nbsp;</p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159">
                      {{ item?.resultReserve?.additionalInfo?.cancellation_policy?.PolicyRules[0]?.DateFrom }} </p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159">
                      {{ item?.resultReserve?.additionalInfo?.cancellation_policy?.PolicyRules[0]?.PercentPrice }} &nbsp; % </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-2 mt-2" *ngIf="item.categoryName=='TRANSPORTS'"
              style="border-top:solid 1px #272849; margin-left: 70px; margin-right: -4px;">
              <div class="col-3 item-descrption pl-0">
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha de inicio: </span>
                    {{ item.entryDepartureDatetime | date:'mediumDate' }} </p>
                </div>
                <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha de Regreso: </span>
                    {{ item.returnDepartureDatetime | date:'mediumDate' }} </p>
                </div>

                <!-- <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Recogida: </span>
                    {{ item.pickupname }} </p>
                </div> -->
                <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Localizador: </span>
                </div>

                <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up" style="line-height: 7px;">
                    {{ item.resultReserve?.providerReservationId}} </p>
                </div>
              </div>
              <div class="col-6 item-descrption ml-5">
                <!-- <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Fecha de Finalización</span>
                    {{ item.departureDate | date:'mediumDate' }} </p>
                </div> -->
                <!-- <div class="row m-0">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Devolución: </span>
                    {{ item.dropoffname }} </p>
                </div> -->
                <!-- <div class="row m-0 mt-2">
                  <p class="m-0 text-pick-up"> <span class="font-weight-bold">Conductor Principal: </span>
                </div> -->
                <!-- <div class="row m-0">
                  <p class="m-0 text-pick-up"> {{ item.resultReserve?.additionalInfo?.reservationDetailCarRental?.paxes[0].firstName + " " + item.resultReserve?.additionalInfo?.reservationDetailCarRental?.paxes[0].lastName }}
                  </p>
                </div> -->
                <!-- <div class="row m-0">
                  <p class="m-0 text-pick-up" style="line-height: 7px;">
                    {{ item.resultReserve?.additionalInfo?.reservationDetailCarRental?.paxes[0].age }} años </p>
                </div> -->
              </div>
              <div class="col-3 item-descrption px-0">
                <div class="text-right mt-2" *ngIf="dateFromCancellation">
                  <p class="m-0 text-expiration text-align-end font-weight-bold pb-1"> POLITICA DE CANCELACIÓN </p>
                  <div class="row m-0 justify-content-end mt-1">
                    <p class="m-0 text-expiration pb-1" style="font-weight:500;color:#d60159">Hasta &nbsp; </p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159 ">
                      {{ item?.resultReserve?.additionalInfo?.cancellation_policy?.PolicyRules[0]?.DateTo }} </p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159"> &nbsp; GRATIS </p>
                  </div>
                  <div class="row m-0 justify-content-end">
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159">Desde &nbsp;</p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159">
                      {{ item?.resultReserve?.additionalInfo?.cancellation_policy?.PolicyRules[0]?.DateFrom }} </p>
                    <p class="m-0 text-expiration" style="font-weight:500;color:#d60159">
                      {{ item?.resultReserve?.additionalInfo?.cancellation_policy?.PolicyRules[0]?.PercentPrice }} &nbsp; % </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="partialReserve===true">
      <div class="resumen resumen-summary-content pt-5 pl-5 pr-5">
        <usb-services-details-summary [itineraryItems]="itineraryItems.items" [partialReserve]="partialReserve">
        </usb-services-details-summary>
      </div>
    </ng-container>

    <div class="important-container d-flex flex-column justify-content-start"
    [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}" style="margin-top:40px">

      <div class="d-flex justify-content-center align-items-center" style="margin-top: 25px;">
        <i class="icon-important mr-3"></i>
        <span id="important-title">
          IMPORTANTE
        </span>
      </div>
      <br>
      <div class="row justify-content-center adjust-important-information" style="margin-left: 110px; width: 999px;">

        <div class="col-9">
          <ul class="numbered-list-circle" style="color:white !important;">
            <li class="d-flex align-items-center">
              <div class="d-flex number-circle justify-content-center align-items-center mr-3">
                <p class="m-0">01</p>
              </div>
              <!-- You can re-edit, cancel or make a reservation for this itinerary, go to -->
              <p class="text-list-important m-0">Puede volver a editar, cancelar o hacer una reserva para este file, ir a
                <a (click)="goToItinerary()"
                  style="cursor: pointer !important;color:white !important; font-weight: bold; text-transform: uppercase;">VER MIS FILES.</a>
              </p>
            </li>

            <li class="d-flex align-items-center mt-4" *ngIf="dateFromCancellation">
              <div class="d-flex number-circle justify-content-center align-items-center mr-3">
                <p class="m-0">02</p>
              </div>
              <!-- This itinerary may suffer price changes from the date -->
              <p *ngIf="dateFromCancellation" class="text-list-important m-0">Este file puede sufrir cambios de tarifa a partir del {{dateFromCancellation | date:'dd/MM/yyyy'  }}
                <span style="color:white !important; font-weight: bold;"></span>
              </p>
            </li>



            <!-- <li class="d-flex align-items-center mt-3">
              <div class="d-flex number-circle justify-content-center align-items-center mr-3">
                <p class="m-0">03</p>
              </div> -->
                <!-- This action does not represent a reservation of the services -->
                <!-- to confirm or reserve go
                to My Itineraies and update. -->
              <!-- <p class="text-list-important m-0">
                <span style="font-weight: bold;">Esta acción no representa una reserva de los servicios, para confirmar o reservar ingresa a Mis Itinerarios y actualiza</span>,
                para confirmar o reservar vaya a Mis Itinerarios y actualice.
              </p> -->
            <!-- </li> -->

          </ul>
        </div>
      </div>

    </div>


    <ng-container *ngIf="partialReserve===true">
      <!-- Next Saved Draf -->
      <!-- <div class="row d-flex justify-content-end mt-4">
        <div class="d-flex pl-4 pr-3" style="border-left: solid 3px #353535;">
          <button class="btn btn-block btn-usblick btn-continue pt-4 pb-4" type="submit" (click)="goToReserve()">
            Continue to Reserve
          </button>
        </div>
      </div> -->
    </ng-container>
    <div class="resumen p-4 mt-4" *ngIf="partialReserve===false" style="margin-bottom: 255px;">
      <ng-container>

        <div class="row mb-4">

          <div class="col-12">
            <p id="important-title" class="d-flex justify-content-center" style="color: #000000 !important">¿QUÉ TE GUSTARÍA HACER?</p>
          </div>

        </div>

        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col text-center" style="border-right: solid #000000  3px;">
                <div class="d-flex justify-content-center mb-2">
                  <!-- <div class="d-flex circle align-items-center justify-content-center">
                  </div> -->
                  <a (click)="goManagament()" style="cursor: pointer;">
                    <span  class="go-itinerary"></span>
                  </a>
                </div>
                <a class="mt-2" (click)="goManagament()" style="font-family: 'Poppins'; font-size: 14px; font-weight: 400;cursor: pointer !important;color: #00d397;">IR A FILE</a>
              </div>

              <div class="col text-center">
                <div class="d-flex justify-content-center">
                  <!-- <div class="d-flex circle align-items-center justify-content-center">
                  </div> -->
                  <a (click)="liduidationItinerayPdf()"  style="cursor: pointer;">
                    <span class="liquidation" style="padding: 36px !important"></span>
                  </a>
                </div>
                <a class="mt-2" (click)="liduidationItinerayPdf()" style="font-size: 14px !important; cursor: pointer !important;color: #00d397;"> LIQUIDACIÓN
                </a>
              </div>

              <div style="display: none;" class="col text-center">
                <div class="d-flex justify-content-center">
                  <!-- <div class="d-flex circle align-items-center justify-content-center">

                  </div> -->
                  <span class="go-pdf"></span>
                </div>

                <a type="submit" class="btn-pdf-email mt-2" (click)="goToDownloadPdf()" style="font-family: 'Poppins'; font-size: 14px; font-weight: 400; color: #d20358;" name="btn-pdf"
                  tooltip="Download PDF" placement="bottom">
                  DESCARGAR PDF
                </a>
              </div>

              <div  class="col text-center" style="border-left: solid #000000  3px; display: none;" >
                <div class="d-flex justify-content-center">
                  <!-- <div class="d-flex circle align-items-center justify-content-center">
                  </div> -->
                  <span class="go-mail"></span>
                </div>

                <a type="submit" class="btn-pdf-email mt-2" (click)="openModal(template)" name="btn-mail" style="font-family: 'Poppins'; font-size: 14px; font-weight: 400; color: #00d397;"
                  tooltip="Send Mail" placement="bottom">
                  ENVIAR MAIL
                </a>
              </div>

            </div>

          </div>

          <div class="col-2">


          </div>
        </div>

      </ng-container>
    </div>
  </div>



</div>

<ng-template #template>
  <form method="POST">
    <div class="modal-body text-center">
      <p>"Esto guardará su file actual y comenzará un nuevo borrador de file. ¿Está seguro?"</p>
      <button type="button" data-placement="bottom" class="btn btn-sm btn-usblick" title="Yes" (click)="sendMail()">SI</button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeModal()">NO</button>
    </div>
  </form>
</ng-template>




