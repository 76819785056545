import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


////import { SharedModule } from '../shared/shared.module';
import { ResultsSearchService } from '../home/results-search.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { TruncateModule } from 'ng2-truncate';
import { ItinerarySummaryComponent } from './itinerary-summary.component';
import { SuggestionsBigSummaryComponent } from './content-summary/suggestions-big-summary/suggestions-big-summary.component';
import { YourtripSummaryComponent } from './content-summary/yourtrip-summary/yourtrip-summary.component';
import { FlightComponent } from './content-summary/body-summary/flight/flight.component';
import { DestinySummaryComponent } from './content-summary/body-summary/destiny-summary/destiny-summary.component';
import { ItemRentacarComponent } from './content-summary/body-summary/destiny-summary/categories/item-rentacar/item-rentacar.component';
import { ItemToursComponent } from './content-summary/body-summary/destiny-summary/categories/item-tours/item-tours.component';
import { ItemDisneyComponent } from './content-summary/body-summary/destiny-summary/categories/item-disney/item-disney.component';
import { ItemHotelComponent } from './content-summary/body-summary/destiny-summary/categories/item-hotel/item-hotel.component';
import { ItemPthemesComponent } from './content-summary/body-summary/destiny-summary/categories/item-pthemes/item-pthemes.component';
import { ItemTransportComponent } from './content-summary/body-summary/destiny-summary/categories/item-transport/item-transport.component';

import { ItinerarySummaryRoutingModule } from './itinerary-summary-routing.module';
import { SuggestionsSmallSummaryComponent } from './content-summary/suggestions-small-summary/suggestions-small-summary.component';
import { SuggestionsItemComponent } from './content-summary/body-summary/destiny-summary/categories/suggestions-item/suggestions-item.component';
import { ItemInsuranceComponent } from './content-summary/body-summary/destiny-summary/categories/item-insurance/item-insurance.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ResearchService } from '../shared/research.service';
//import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared/shared.module';
import { BarRatingModule } from 'ngx-bar-rating';

// import { OrderByPipe } from '../itinerary/itinerary.pipes';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ItinerarySummaryRoutingModule,
        NgxPaginationModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        BarRatingModule,
        //BsDropdownModule.forRoot(),

        BrowserAnimationsModule,
        //NgxChartsModule,
        FormsModule,
        TruncateModule,
        NgxSpinnerModule,
        SharedModule,
    ],
    declarations: [
        ItinerarySummaryComponent,
        SuggestionsBigSummaryComponent,
        YourtripSummaryComponent,
        FlightComponent,
        DestinySummaryComponent,
        ItemRentacarComponent,
        ItemToursComponent,
        ItemDisneyComponent,
        ItemHotelComponent,
        ItemPthemesComponent,
        ItemTransportComponent,
        SuggestionsSmallSummaryComponent,
        SuggestionsItemComponent,
        ItemInsuranceComponent,
    ],
    exports: [
        ItinerarySummaryComponent
      ],

    providers: [
        ResultsSearchService,
        ResearchService
    ]
})
export class ItinerarySummaryModule { }
