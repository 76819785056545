import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';
//import { Event } from '@angular/router/src/events';


@Component({
  selector: 'usb-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {


  @Input() vehicle: any;
  @Input() select: any[]; // : [0,1,2,3,4,5];
  @Input() index: number;
  @Input() selected: any[];
  @Input() productQty: number;
  @Input() reserveMultiHab: boolean;
  @Input() tabActive?: any;
  @Input() payloadUpdate:any;
  @Input() result:any;
  //@Input() toastr: ToastsManager;
  @Output() selectRoom = new EventEmitter();

  payload:any;
  tab: string = "";

  constructor() { }

  ngOnInit() {
    this.tab = this.tabActive ? this.tabActive : "vehicle";
    console.log('vehiculo ' + this.vehicle);

    // console.log('payloadUpdate vehicle', this.payloadUpdate);
    // console.log('result', this.result);
    // this.payload = JSON.parse(localStorage.getItem('payload'));
    // console.log('payload vehicle', this.payload);
  }

  /**
   * La funcion se encarga de evaluar la cantidad que se pide.
   * Se le manda 1 para que los demas select aparezcan con 1 elemento que sera el 0.
   * @param select Recibe el elemento colocado por el usuario al seleccionar la cantidad.
   */
 /* public selectRoom(select: any, qty: any) {
    if (!this.reserveMultiHab && this.select.some(obj => obj.selectValue > 0) && !(select.selectValue > 0)) {
      //this.toastr.warning('This hotel does not support the selection of several vehicle types.');
    }
    select.selectValue = qty;
    this.addRoomToSelect();
  }*/
  // sendRoom(vehicle: any){
  //   console.log('emitted', this.vehicle);
  //   this.selectRoom.emit(this.vehicle);
  // }

  /*private addRoomToSelect() {
    const productQtyNowSelect = this.productQtyNowSelect(this.select);
    const productQtyLeft = this.productQty - productQtyNowSelect;
    this.select.forEach((obj, index) => {
      obj.selectArray = Array.from(Array(+obj.selectValue + productQtyLeft + 1).keys());
    });
    this.calculateTotalPrice.emit();
  }*/

  /**
   *
   * @param vehicle Se le pasa el vehiculo seleccionado.
   */
  selectVehicle(vehicle:any):void{
    //Validamos si se encuentra el vehículo.
    console.log('selected vehicle', vehicle);
    if(vehicle){
      this.selectRoom.emit(vehicle);
    }
  }

  getRandomUniqueId(){
    return Math.floor(Math.random() * 20000000000000) + Date.now();
  }


  private productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach(obj => total += +obj.selectValue);
    return total;
  }

}
