<div  usbLoadingSplash
[appLoading]="appLoading"
[fullscreen]="true"
[component]="'formPaxes'"
>

<div *ngIf="data.typeAction" class="row" style="height: 70px;background-color: black;">
  <h3 style="font-family: 'Poppins', sans-serif;font-size: 24px; padding: .9em 1.8em; color: #00d096" *ngIf="data.typeAction === 'New'">CREAR CLIENTE</h3>
  <h3 style="font-family: 'Poppins', sans-serif;font-size: 24px; padding: .9em 1.8em;color: #00d096" *ngIf="data.typeAction === 'Edit'">EDITAR CLIENTE</h3>
</div>

<form [formGroup]="_form"  autocomplete="random-string">
  <div class="row font-poppins">
    <div class="col-8 col-md-8 col-lg-8 col-xl-8" [ngClass]="{'col-12': data.typeAction, 'col-lg-12': data.typeAction, 'col-md-12': data.typeAction, 'col-xl-12': data.typeAction}">
      <span *ngIf="!data" class="mb-3" style="font-size: 1.65rem" [ngStyle]="{ color: formPaxesReservationData.getClientsSettingAppBooking?.clients_setting_global?.color_main_2 }"
        >SELECCIONA HABITACIÓN PARA PASAJERO
      </span>
      <accordion [closeOthers]="true" class="mt-3 client-modal" >
        <accordion-group
          formArrayName="paxes"
          *ngFor="let adult of paxes; let i = index"
          [isOpen]="(i == 0 && loadedClientInfoForm ? true : false) && !data.typAction"
          style="margin-bottom: 10px"
          >
          <!-- (click)="expandedValueForm(i)" -->
          <div accordion-heading >
            <div class="row ml-0 mr-0 mb-1" *ngIf="!data.typeAction">
              <div class="col-8 pl-0 pr-1">
                <div
                  class="p-2 d-flex align-items-center justify-content-between pax-header"
                  style="height: 53px"
                  [ngStyle]="{ 'background-color': formPaxesReservationData.getClientsSettingAppBooking?.clients_setting_global?.color_main_2 }"
                  >
                  <div class="pull-left float-left text-uppercase ml-4 title-accord">{{ adult.title }}</div>
                  <i class="fas fa-caret-down mr-2"></i>
                </div>
              </div>
              <div
                class="col-4 p-2 d-flex justify-content-between align-items-center checker"
                style="border-radius: 5px; cursor: pointer"
                [ngClass]="{ 'checker-green': checkProductConfirm(i) }"
              >
                <object
                  *ngIf="!checkProductConfirm(i)"
                  type="image/svg+xml"
                  data="/assets/img/app/little-icons/timer_bco.svg"
                  width="30px"
                  height="30px"
                ></object>
                <span *ngIf="checkProductConfirm(i)" class="icon-check"></span>
                <span class="state" *ngIf="checkProductConfirm(i)">Listo</span>
                <span class="state" *ngIf="!checkProductConfirm(i)">Pendiente</span>
              </div>
            </div>
          </div>
          <div [formGroupName]="i" class="pr-3 adjust-container-form-paxes" [ngClass]="{'mx-4': data.typeAction}" >
            <div class="row mb-2"></div>
             <!-- <div class="form-group" > -->
            <div class="row" style="padding-bottom: 40px;" *ngIf="formPaxesReservationData.booleanHeadline &&
                                                                  adult.title !== 'Adulto 1 - Titular' &&
                                                                  selectClientFormPaxes &&
                                                                  formPaxesReservationData.clientsAssociated &&
                                                                  formPaxesReservationData.clientsAssociated.length > 0">

                <div class="form-group col-4 sm-gutters" *ngIf="clientExist(formPaxesReservationData.clientsAssociated)">
                  <label class="font-weight-bold font-poppins" for="title" style="font-size: small">Asociados</label>
                  <select
                    #selectClient
                    id="my-select"
                    class="form-control custom-select select-clientes"
                    (change)="selectClientFunction(selectClient.value, i)"
                    formControlName="clientSelected"
                  >
                  <!-- <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option> -->
                    <option [value]="null" disabled selected>Seleccionar Cliente</option>
                    <ng-container *ngFor="let client of formPaxesReservationData.clientsAssociated">
                      <ng-container *ngIf="adult.type !== 'adult' && listAssociatedAge(client.birth_date) > formPaxesReservationData.maxDateChild">
                        <option *ngIf="client.selected && client.paxSelect == i" selected disabled>
                          {{ client.first_name }} {{ client.last_name ? client.last_name : "" }}
                        </option>
                        <option *ngIf="!client.selected" [value]="client.id">
                          {{ client.first_name }} {{ client.last_name ? client.last_name : "" }}
                        </option>
                      </ng-container>
                      <ng-container *ngIf="adult.type === 'adult' && listAssociatedAge(client.birth_date) < formPaxesReservationData.minDateAdult">
                        <option *ngIf="client.selected && client.paxSelect == i" selected disabled>
                          {{ client.first_name }} {{ client.last_name ? client.last_name : "" }}
                        </option>
                        <option *ngIf="client.selected === false" [value]="client.id">
                          {{ client.first_name }} {{ client.last_name ? client.last_name : "" }}
                        </option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
                <div class="d-flex col-2 sm-gutters">
                  <button
                    (click)="resetClient(i)"
                    *ngIf="formPaxesReservationData.booleanHeadline && adult.title !== 'Adulto 1 - Titular'"
                    [disabled]="!verifyClientExist(i)"
                    class="btn float-right p-0 d-flex justify-content-center align-items-center ml-1"
                    style="
                      cursor: pointer;
                      width: 44px;
                      height: 42px;
                      border-radius: 4px;
                      background-color: #ffffff;
                      border: 1px solid #ced4da;
                      margin-top: 30px;
                    "
                  >
                    <i class="icon-cesto"></i>
                  </button>
                </div>
            </div>

<!--

  TITULO, NOMBRE, APELLIDO Y GENERO

-->

<!--
  TITULO
-->
            <div class="row">
              <div class="col-5 sm-gutters" >
                <div class="form-group">
                  <label
                          for="title"
                          class="font-weight-bold font-poppins"
                          style="font-size: small"
                    >
                      Título y nombre/s
                    <sup class="required-marker"> * </sup>
                  </label>
                  <div class="row d-flex justify-content-between m-0" style="position: relative">
                    <select
                            id="title"
                            name="title"
                            class="form-control-sm custom-select"
                            style="width: 41%"
                            [ngClass]="{
                                        'is-invalid': _form?.value?.paxes[i]?.title === null,
                                        'is-valid': _form?.value?.paxes[i]?.title !== null
                                      }"
                            [ngStyle]="getStyles(i)"
                            formControlName="title"
                            aria-label="title"
                            aria-required="true"
                            required>
                      <option
                              selected
                              disabled
                              value="null"
                      >
                        Título
                      </option>
                      <option *ngFor="let title of titles"
                              [value]="title.id">
                        {{ title.name }}
                      </option>
                    </select>

<!--
  NOMBRE
-->



                    <input
                          id="first_name"
                          name="first_name"
                          class="form-control"
                          style="width: 56%;"
                          [ngClass]="{
                                      'is-invalid':
                                                    _form.value.paxes[i].first_name === '' ||
                                                    _form.value.paxes[i].first_name === null ||
                                                    _form.get(['paxes', i, 'first_name'])?.errors?.pattern,
                                      'is-valid':
                                                    (_form.value.paxes[i].first_name !== '' ||
                                                    _form.value.paxes[i].first_name !== null) &&
                                                    !_form.get(['paxes', i, 'first_name'])?.errors?.pattern
                                    }"
                          [ngStyle]="getStyles(i)"
                          type="text"
                          formControlName="first_name"
                          aria-label="first_name"
                          aria-required="true"
                          autocapitalize="none"
                          autocomplete="random-string"
                          placeholder="Nombre/s"
                          required
                      />
                      <span
                            style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 10em;"
                            *ngIf="_form.get(['paxes', i, 'first_name'])?.errors?.pattern"
                          >
                            Ingresar letras
                      </span>
                  </div>
                </div>
              </div>

<!--
  APELLIDO
-->
              <div class="col-3 sm-gutters">
                <div class="form-group" style="position: relative">
                  <label for="last_name"
                          class="font-weight-bold font-poppins"
                          style="font-size: small"
                  >
                    Apellido/s
                    <sup class="required-marker"> * </sup></label
                  >
                  <input
                    id="last_name"
                    name="last_name"
                    class="form-control"
                    [ngClass]="{
                                'is-invalid':
                                              _form.value.paxes[i].last_name == null ||
                                              _form.value.paxes[i].last_name == '' ||
                                              _form.get(['paxes', i, 'last_name'])?.errors?.pattern,
                                'is-valid':
                                              (_form.value.paxes[i].last_name !== null ||
                                              _form.value.paxes[i].last_name !== '') &&
                                              !_form.get(['paxes', i, 'last_name'])?.errors?.pattern
                              }"
                    [ngStyle]="getStyles(i)"
                    type="text"
                    formControlName="last_name"
                    aria-label="last_name"
                    aria-required="last_name"
                    placeholder="Apellido/s"
                    autocapitalize="none"
                    autocomplete="random-string"
                    required
                  />
                  <span
                    style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 10px;"
                    *ngIf="_form.get(['paxes', i, 'last_name'])?.errors?.pattern"
                  >
                  Ingresar letras
                  </span>
                </div>
              </div>


<!--
  GENERO
-->

              <div class="col-4 sm-gutters">
                <div class="form-group">
                  <label
                        for="gender"
                        class="font-weight-bold font-poppins"
                        style="font-size: small"
                  >
                    Género
                    <sup class="required-marker"> * </sup></label
                  >
                  <select
                          id="gender"
                          name="gender"
                          class="form-control-sm custom-select"

                          [ngClass]="{
                                      'is-invalid':  _form.value.paxes[i].gender == null,
                                      'is-valid': _form.value.paxes[i].gender != null
                                    }"
                          [ngStyle]="getStyles(i)"
                          formControlName="gender"
                          aria-label="gender"
                          aria-required="gender"
                          autocomplete="off"
                          required>
                    <option value="null" selected disabled>Género</option>
                    <option value="F">Femenino</option>
                    <option value="M">Masculino</option>
                    <option value="X">No Especificado</option>
                    <option value="U">Prefiero no decirlo</option>
                  </select>
                </div>
              </div>
            </div>

<!--

  FECHA DE NACIMIENTO

-->

            <div class="row">
              <ng-container *ngIf="i > 0 || data.fromAssociates">
                <div class="col-6 sm-gutters" style="margin-top: 40px" [ngStyle]="{'margin-top': data.typeAction ? '10px' : '40px'}">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins" style="font-size: small" for="birth_date"
                      >Fecha de nacimiento <sup class="required-marker"> * </sup></label
                    >
                    <input
                          *ngIf="adult.type == 'adult'"
                          id="birth_date"
                          name="birth_date"
                          class="form-control"
                          [ngClass]="{
                                      'is-invalid':
                                                  !_form.get('paxes').get(i.toString()).get('type').value && !_form.get('paxes').get(i.toString()).get('birth_date').touched ||
                                                  _form.get('paxes').get(i.toString()).get('birth_date').value === null ||
                                                  (_form.get('paxes.' + i + '.birth_date').errors && _form.get('paxes.' + i + '.birth_date').errors.customErrorBirthDate),
                                      'is-valid':
                                                  _form.get('paxes').get(i.toString()).get('type').value ||
                                                  _form.get('paxes').get(i.toString()).get('birth_date').touched ||
                                                  _form.get('paxes').get(i.toString()).get('birth_date').value !== null
                                    }"
                          [ngStyle]="getStyles(i)"
                          type="text"
                          formControlName="birth_date"
                          aria-label="birth_date"
                          aria-required="true"
                          bsDatepicker
                          readonly
                          [maxDate]="formPaxesReservationData.minDateAdult"
                          [bsConfig]="dpConfig"
                          autocomplete="off"
                          (bsValueChange)="changeDateClientAssociated($event)"
                          placeholder="Fecha de Nacimiento"
                          required
                    />
                    <input
                          *ngIf="adult.type == 'children'"
                          id="birth_date"
                          name="birth_date"
                          class="form-control"
                          [ngClass]="{
                                      'is-invalid':
                                                    !_form.get('paxes').get(i.toString()).get('type').value && !_form.get('paxes').get(i.toString()).get('birth_date').touched ||
                                                    (_form.get('paxes.' + i + '.birth_date').errors && _form.get('paxes.' + i + '.birth_date').errors.customErrorBirthDate),
                                      'is-valid':
                                                    _form.get('paxes').get(i.toString()).get('type').value ||
                                                    _form.get('paxes').get(i.toString()).get('birth_date').touched
                                    }"
                          [ngStyle]="getStyles(i)"
                          type="text"
                          formControlName="birth_date"
                          aria-label="birth_date"
                          aria-required="true"
                          bsDatepicker
                          readonly
                          [minDate]="formPaxesReservationData.maxDateChild"
                          [bsConfig]="dpConfig"
                          autocomplete="off"
                          placeholder="Fecha de Nacimiento"
                          required
                    />
                  </div>
                </div>
                <div class="col-6 sm-gutters" style="margin-top: 40px" [ngStyle]="{'margin-top': data.typeAction ? '10px' : '40px'}">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins" for="last_name" style="font-size: small"
                      >Relación<sup class="required-marker"> * </sup></label
                    >
                    <select

                            id="relation_ship"
                            name="relation_ship"
                            class="form-control-sm custom-select"
                            [ngClass]="{
                                        'is-invalid': _form.value.paxes[i].relation_ship == null,
                                        'is-valid':_form.value.paxes[i].relation_ship != null
                                      }"
                            [ngStyle]="getStyles(i, 'relation_ship')"
                            formControlName="relation_ship"
                            aria-label="relation_ship"
                            (change)="setRelationShip($event.target.value)"
                    >
                    <option value="null" disabled selected>Seleccionar</option>
                    <ng-container *ngIf="adult.type === 'adult'">
                      <ng-container *ngFor="let relation of formPaxesReservationData.relationShips">
                        <option [value]="relation.id">
                          {{ relation.relation_ship_name }}
                        </option>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="adult.type === 'children'">
                      <ng-container *ngFor="let relation of formPaxesReservationData.relationChild">
                        <option [value]="relation.id"
                          >
                          {{ relation.relation_ship_name }}
                        </option>
                      </ng-container>
                    </ng-container>
                    </select>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row" style="margin-top: 50px" [ngStyle]="{'margin-top': data.typeAction ? '10px' : '50px'}">
              <ng-container *ngIf="i > 0 || data.fromAssociates === true ">
                <div class="col-6 sm-gutters" >
                  <div class="form-group position-relative">
                    <label class="font-weight-bold font-poppins" for="document_type_id" style="font-size: small"
                      >Documento y N° de Documento <sup class="required-marker" *ngIf="dataClientBefore"> * </sup></label
                    >

                    <div class="row d-flex ml-0 position-relative">
                      <select
                        *ngIf="adult.type === 'adult'"
                        formControlName="document_type_id"
                        id="document_type_id"
                        #document_type_id
                        class="form-control-sm custom-select"
                        [ngStyle]="getStyles(i)"
                        (change)="verifyExist($event.target.value, 'type_document', i)"
                        [ngClass]="{'is-invalid': _form.value.paxes[i].document_type_id == null, 'is-valid':_form.value.paxes[i].document_type_id !== null}"
                        style="width: 45%"
                      >
                        <option value="null" selected disabled>Tipo Doc</option>
                        <option value="4">DNI</option>
                        <option value="5">PASAPORTE</option>
                      </select>
                      <input
                        *ngIf="adult.type == 'adult'"
                        #document_number
                        formControlName="document_number"
                        (keydown)="blockInvalidKeys($event)"
                        (input)=" verifyExist($event.target.value, 'document_number', i);"
                        [ngClass]="{'is-invalid': _form.value.paxes[i].document_number == null ||
                                                  _form.value.paxes[i].document_number == '' ||
                                                  (_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.customErrorKey) ||
                                                  (_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.duplicateErrorKey) ,
                                    'is-valid':_form.value.paxes[i].document_number !== null || _form.value.paxes[i].document_number !== null || _form.get('paxes.' + i + '.document_number').valid
                                     }"
                        [ngStyle]="getStyles(i)"
                        type="number"
                        class="form-control is-invalid"
                        style="width: 50%; margin-left: 7px"
                        id="document_input_emit_missmatch"
                        autocapitalize="none"
                        autocomplete="random-string"
                        placeholder="N° de Documento"
                        min="0"
                        required
                      />
                      <select
                        *ngIf="adult.type === 'children'"
                        formControlName="document_type_id"
                        #document_type_id
                        [ngClass]="{'is-invalid': _form.value.paxes[i].document_type_id == null, 'is-valid':_form.value.paxes[i].document_type_id !== null}"
                        (change)="verifyExist($event.target.value, 'type_document', i)"
                        id="document_type_id"
                        [ngStyle]="getStyles(i)"
                        class="form-control custom-select"
                        style="width: 45%"
                      >
                        <option value="null" selected disabled>Tipo Doc</option>
                        <option value="4">DNI</option>
                        <option value="5">PASAPORTE</option>
                      </select>
                      <input
                          *ngIf="adult.type == 'children'"
                          formControlName="document_number"
                          [ngClass]="{'is-invalid': _form.value.paxes[i].document_number == null ||
                                                    _form.value.paxes[i].document_number == '' ||
                                                    (_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.customErrorKey) ||
                                                    (_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.duplicateErrorKey)
                                                    ,
                                      'is-valid':_form.value.paxes[i].document_number !== null && _form.value.paxes[i].document_number !== '' && _form.get('paxes.' + i + '.document_number').valid
                                     }"
                          (keydown)="blockInvalidKeys($event)"
                          (input)="verifyExist($event.target.value, 'document_number', i);"
                          type="number"
                          class="form-control"
                          style="width: 50%; margin-left: 7px"
                          #document_number_child
                          id="document_number_child"
                          autocapitalize="none"
                          autocomplete="random-string"
                          [ngStyle]="getStyles(i)"
                          placeholder="N° de Documento"
                          required
                      />
                      <span
                            *ngIf="_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.customErrorKey"
                            style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 20px;"
                            >Este número ya fue registrado</span
                      >
                      <span
                      *ngIf="_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.duplicateErrorKey"
                      style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 20px;"
                      >Duplicado en formularios</span>
                      <span
                            style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 10px;"
                            *ngIf="_form.get(['paxes', i, 'document_number'])?.hasError('nonNegative') || _form.get(['paxes', i, 'document_number'])?.hasError('pattern') "
                          >
                         Ingresar números. No puede ser negativo
                  </span>
                    </div>
                  </div>
                </div>

                <div class="col-6 sm-gutters" style="position: relative;">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins" for="email" style="font-size: small"
                      >Email <sup class="required-marker"> * </sup></label
                    >
                    <input
                            *ngIf="adult.type === 'adult'"
                            id="email"
                            name="email"
                            class="form-control"
                            [ngClass]="{
                                        'is-invalid':
                                                      !_form.get('paxes').get(i.toString()).get('email').valid,
                                        'is-valid':
                                                    _form.get('paxes').get(i.toString()).get('email').valid
                                      }"
                            [ngStyle]="getStyles(i)"
                            (input)="verifyExist($event.target.value, 'email', i); "
                            type="email"
                            formControlName="email"
                            aria-label="email"
                            aria-required="true"
                            autocapitalize="none"
                          autocomplete="random-string"
                            placeholder="Email"
                            required
                    />
                    <input
                          *ngIf="adult.type === 'children'"
                          id="email"
                          name="email"
                          #email_children
                          class="form-control"
                          [ngClass]="{
                                      'is-invalid':
                                                    !_form.get('paxes').get(i.toString()).get('email').valid,
                                      'is-valid':
                                                  _form.get('paxes').get(i.toString()).get('email').valid
                                    }"
                          [ngStyle]="getStyles(i)"
                          (input)="verifyExist($event.target.value, 'email', i)"
                          type="email"
                          formControlName="email"
                          aria-label="email"
                          aria-required="true"
                          autocapitalize="none"
                          autocomplete="random-string"
                          placeholder="Email"
                          required
                  />
                  </div>
                  <span
                  *ngIf="_form.get('paxes.' + i + '.email').errors && _form.get('paxes.' + i + '.email').errors.customErrorKey"
                  style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                  >Este email ya fue registrado</span
                  >
                  <span style="font-size: 14px; color: #e93a55; position: absolute; bottom: -8px; left: 10px;" *ngIf="_form.get('paxes').get(i.toString()).get('email').errors?.email">Formato invalido</span>
                </div>

              </ng-container>


              <ng-container *ngIf="i == 0 && (clientData.type === 'adult' && clientData.clientAssociated === true)">
                <div class="col-3 sm-gutters">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins"
                           style="font-size: small"
                           for="birth_date"
                      >Fecha de nacimiento <sup class="required-marker"> * </sup></label
                    >
                    <input
                          *ngIf="adult.type == 'adult'"
                          id="birth_date"
                          name="birth_date"
                          class="form-control"
                          [ngClass]="{
                                      'is-invalid': _form.value.paxes[i].birth_date == null,
                                      'is-valid':_form.value.paxes[i].birth_date != null
                                    }"
                          [ngStyle]="getStyles(i)"
                          type="text"
                          formControlName="birth_date"
                          aria-label="birth_date"
                          bsDatepicker
                          readonly
                          [maxDate]="formPaxesReservationData.minDateAdult"
                          [bsConfig]="dpConfig"
                          autocomplete="off"
                          placeholder="Fecha de Nacimiento"
                    />
                    <input
                          *ngIf="adult.type == 'children'"
                          id="birth_date"
                          name="birth_date"
                          class="form-control"
                          [ngClass]="{
                                      'is-invalid': _form.value.paxes[i].birth_date == null,
                                      'is-valid':_form.value.paxes[i].birth_date != null
                                    }"
                          [ngStyle]="getStyles(i)"
                          type="text"
                          formControlName="birth_date"
                          aria-label="birth_date"
                          bsDatepicker
                          readonly
                          [minDate]="formPaxesReservationData.maxDateChild"
                          [bsConfig]="dpConfig"
                          autocomplete="off"
                          placeholder="Fecha de Nacimiento"
                    />
                  </div>
                </div>
                <div class="col-5 sm-gutters adjust-input-document">
                  <div class="form-group position-relative">
                    <label class="font-weight-bold font-poppins"
                           for="document_type_id"
                           style="font-size: small"
                    >Tipo y N° documento <sup class="required-marker"> * </sup>
                    </label>
                    <div class="row d-flex ml-0 position-relative "
                         style="margin-right: -10px;">
                      <select
                              id="document_type_id"
                              #document_type_id
                              name="document_type_id"
                              class="form-control-sm custom-select "
                              style="width: 46%"
                              [ngClass]="{
                                          'is-invalid': _form.value.paxes[i].document_type_id == null,
                                          'is-valid':_form.value.paxes[i].document_type_id != null
                                        }"
                              [ngStyle]="getStyles(i)"
                              formControlName="document_type_id"
                              arial-label="document_type_id"
                              aria-required="true"
                              required
                              (change)="verifyExist($event.target.value, 'type_document', i)"
                      >
                        <option value="null" selected disabled>Tipo Doc</option>
                        <option value="4">DNI</option>
                        <option value="5">PASAPORTE</option>
                      </select>
                      <input
                        id="document_input_emit"
                        name="document_input_emit"
                        #document_number
                        class="form-control"
                        style="width: 50%; margin-left: 7px"
                        [ngClass]="{
                                    'is-invalid': _form.value.paxes[i].document_number == null ||
                                                  _form.value.paxes[i].document_number == '' ||
                                                  (_form.get('paxes.' + i + '.document_number').errors &&
                                                  _form.get('paxes.' + i + '.document_number').errors.customErrorKey) ||
                                                  (_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.duplicateErrorKey) ,
                                    'is-valid': _form.value.paxes[i].document_number !== null ||
                                               _form.value.paxes[i].document_number !== null ||
                                               _form.get('paxes.' + i + '.document_number').valid
                                    }"
                        [ngStyle]="getStyles(i)"
                        type="number"
                        formControlName="document_number"
                        aria-label="document_input_emit"
                        aria-required="document_input_emit"
                        autocapitalize="none"
                        autocomplete="random-string"
                        min="0"
                        required
                        placeholder="N° documento"
                        (keydown)="blockInvalidKeys($event)"
                        (input)=" verifyExist($event.target.value, 'document_number', i)"
                      />
                      <span
                           *ngIf="_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.customErrorKey"
                            class="position-absolute"
                            style="font-size: 14px; color: #e93a55; position: absolute; bottom: -20px; left: 10px;"
                      >
                            Este número ya fue registrado</span
                      >
                      <span
                      *ngIf="_form.get('paxes.' + i + '.document_number').errors && _form.get('paxes.' + i + '.document_number').errors.duplicateErrorKey"
                      style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 20px;"
                      >Duplicado en formularios</span>
                      <span
                          style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 10px;"
                          *ngIf="_form.get(['paxes', i, 'document_number'])?.hasError('nonNegative') || _form.get(['paxes', i, 'document_number'])?.hasError('pattern') "
                        >
                             Ingresar números. No puede ser negativo
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-4 sm-gutters" style="position: relative;">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins"
                           for="email"
                           style="font-size: small"
                      >Email <sup class="required-marker"> * </sup></label
                    >
                    <input
                          id="email"
                          name="email"
                          class="form-control"
                          [ngClass]="{
                                      'is-invalid': !_form.get('paxes').get(i.toString()).get('email').valid,
                                      'is-valid': _form.get('paxes').get(i.toString()).get('email').valid
                                    }"
                          [ngStyle]="getStyles(i)"
                          type="email"
                          formControlName="email"
                          aria-label="email"
                          aria-required="true"
                          aria-autocomplete="none"
                          autocapitalize="none"
                          autocomplete="random-string"
                          placeholder="Email"
                          required
                          (input)="onChangeParentData($event.target.value, 'email'); verifyExist($event.target.value, 'email', i)"
                    />
                  </div>
                    <span
                    *ngIf="_form.get('paxes.' + i + '.email').errors && _form.get('paxes.' + i + '.email').errors.customErrorKey"
                    style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                    >Este email ya fue registrado</span
                    >
                  <span style="font-size: 14px; color: #e93a55; position: absolute; bottom: -8px; left: 10px;" *ngIf="_form.get('paxes').get(i.toString()).get('email').errors?.email">Formato invalido</span>
                </div>
              </ng-container>
            </div>

<!--
    CLIENTE O PASAJERO === 1
      select país
-->
            <div class="row"
                  style="margin-top: 40px"
                  [ngStyle]="{'margin-top': data.typeAction ? '10px' : '40px'}">
              <ng-container *ngIf="i == 0">
                <div class="col-4 sm-gutters" style="position: relative">
                  <div
                        *ngIf="formPaxesReservationData.countries"
                        class="form-group position-relative">
                    <label
                          class="font-weight-bold font-poppins"
                          for="country_id"
                          style="font-size: small"
                      >País <sup class="required-marker"> * </sup></label
                    >

                    <div *ngIf="countryCheck[i] && !selectedClientAssociated" class="selected-country">
                      <input class="chip"
                             formControlName="country_id"
                             [ngStyle]="getStyles(i, 'country')"
                             value="{{ _form.get('paxes').value[i].country_id }}"
                             readonly>

                        <button
                                type="button"
                                class="close-chip"
                                tooltip="Eliminar País"
                                placement="bottom"
                                (click)="clearCountry(i, 'country')">
                          ×
                        </button>
                    </div>
                    <input *ngIf="!countryCheck[i] || selectedClientAssociated"
                            id="country_id"
                            name="country_id"
                            class="form-control"
                            [ngClass]="{
                                        'is-invalid': !countryCheck[i],
                                        'is-valid': countryCheck[i]
                                       }"
                            [ngStyle]="getStyles(i, 'country')"
                            type="text"
                            [(ngModel)]="selectedIsCountry[i]"
                            formControlName="country_id"
                            aria-label="country_id"
                            aria-required="true"
                            typeaheadOptionField="country_name"
                            [typeahead]="filteredCountries"
                            placeholder="País"
                            autocapitalize="none"
                            autocomplete="random-string"
                            (typeaheadOnSelect)="
                                                getStatesData(i);
                                                onChangeParentData($event, 'country')
                                               "
                           required
                      >
                  </div>
                  <span
                  *ngIf="_form.get('paxes.' + i + '.country_id').touched && !countryCheck[i]"
                  style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                  >Seleccione un país de la lista</span
                  >
                </div>
              </ng-container>


<!--
    CLIENTE O PASAJERO MAYOR A 1
        select país
-->
              <ng-container *ngIf="i > 0">
                <div class="col-4 sm-gutters"
                      style="position: relative"
                      >
                  <div class="form-group position-relative" >
                    <label class="font-weight-bold font-poppins"
                            for="country_id"
                            style="font-size: small"
                      >País <sup class="required-marker"> * </sup></label
                      >
                      <div *ngIf="countryCheck[i] && !selectedClientAssociated" class="selected-country">
                        <input class="chip"
                               formControlName="country_id"
                               [ngStyle]="getStyles(i, 'country')"
                               value="{{ adult.type === 'adult' ? _form.get('paxes').value[i].country_id : formPaxesReservationData?.country_parent}}"
                               readonly>

                          <button
                                  type="button"
                                  class="close-chip"
                                  tooltip="Eliminar País"
                                  placement="bottom"
                                  (click)="clearCountry(i, 'country')">
                            ×
                          </button>
                      </div>
                      <ng-container *ngIf="adult.type === 'adult'">
                        <input *ngIf="!countryCheck[i] || selectedClientAssociated"
                            id="country_id"
                            name="country_id"
                            class="form-control"
                            [ngClass]="{
                                          'is-invalid': !countryCheck[i],
                                          'is-valid': countryCheck[i]
                                        }"
                            [ngStyle]="getStyles(i)"
                          type="text"
                          [(ngModel)]="selectedIsCountry[i]"
                          formControlName="country_id"
                          aria-label="country_id"
                          aria-required="true"
                          typeaheadOptionField="country_name"
                          [typeahead]="filteredCountries"
                          placeholder="País"
                          autocapitalize="none"
                          autocomplete="random-string"
                          (typeaheadOnSelect)="getStatesData(i)"
                          required
                      >
                      </ng-container>
                      <ng-container  *ngIf="adult.type === 'children'">
                        <input *ngIf="!countryCheck[i] || selectedClientAssociated"
                              id="country_id"
                              name="country_id"
                              class="form-control"
                              #countryChild
                              [ngClass]="{
                                            'is-invalid': !countryCheck[i],
                                            'is-valid': countryCheck[i]
                                          }"
                              [ngStyle]="getStyles(i)"
                              type="text"
                              [(ngModel)]="formPaxesReservationData?.country_parent ? formPaxesReservationData?.country_parent : selectedIsCountry[0]"
                              formControlName="country_id"
                              aria-label="country_id"
                              aria-required="true"
                              typeaheadOptionField="country_name"
                              [typeahead]="filteredCountries"
                              placeholder="País"
                              autocapitalize="none"
                              autocomplete="random-string"
                              (typeaheadOnSelect)="
                                                  getStatesData(i);
                                                "
                              required
                      >
                    </ng-container>
                  </div>
                  <span
                  *ngIf="_form.get('paxes.' + i + '.country_id').touched && !countryCheck[i]"
                  style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                  >Seleccione un país de la lista</span
                  >
                </div>
              </ng-container>

<!--
    CLIENTE O PASAJERO === 1
      select provincia

-->

              <div class="col-8 sm-gutters">
                <ng-container *ngIf="i == 0">
                  <div class="form-group" style="position: relative">
                    <label class="font-weight-bold font-poppins"
                            for="state_id"
                            style="font-size: small"
                      >Región <sup class="required-marker"> * </sup></label
                    >
                    <div *ngIf="stateCheck[i] && !selectedClientAssociated" class="selected-country">
                      <input class="chip"
                             formControlName="state_id"
                             [ngStyle]="getStyles(i)"
                             value="{{ _form.get('paxes').value[i].state_id }}"
                             readonly>

                        <button type="button"
                                class="close-chip"
                                tooltip="Eliminar Región"
                                placement="bottom"
                                (click)="clearCountry(i, 'state')">
                          ×
                        </button>
                    </div>
                      <input *ngIf="!stateCheck[i] || selectedClientAssociated"
                              id="state_id"
                              name="state_id"
                              class="form-control"
                              [ngClass]="{
                                          'is-invalid': !stateCheck[i],
                                          'is-valid': stateCheck[i]
                                        }"
                              [ngStyle]="getStyles(i)"
                              type="text"
                              [(ngModel)]="selectedIsState[i]"
                              formControlName="state_id"
                              aria-label="state_id"
                              aria-required="true"
                              typeaheadOptionField="destination_name"
                              [typeahead]="data.typeAction ? clientData?.states :  formPaxesReservationData?.states[i]?.states "
                              placeholder="Región"
                              autocapitalize="none"
                              autocomplete="random-string"
                              (typeaheadOnSelect)="getCitiesData(i);
                                                   onChangeParentData($event, 'destination')"
                              required
                    >
                    <span
                    *ngIf="_form.get('paxes.' + i + '.state_id').touched && _form.get('paxes.' + i + '.country_id')?.valid && !stateCheck[i]"
                    style="font-size: 14px; color: #e93a55; position: absolute; bottom: -22px; left: 20px;"
                    >Seleccione una región de la lista</span
                    >
                    <span
                    *ngIf="_form.get('paxes.' + i + '.state_id').touched && _form.get('paxes.' + i + '.country_id')?.invalid && !stateCheck[i]"
                    style="font-size: 14px; color: #e93a55; position: absolute; bottom: -22px; left: 20px;"
                    >Seleccione un país para ver la lista de regiones</span
                    >
                  </div>
                </ng-container>



<!--
    CLIENTE O PASAJERO MAYOR A 1
        select provincia
-->

                <ng-container *ngIf="i > 0">
                  <div class="form-group"
                        style="position: relative"
                  >

                  <label class="font-weight-bold font-poppins"
                  for="state_id"
                              style="font-size: small"
                        >Región <sup class="required-marker"> * </sup></label
                      >
                      <div *ngIf="stateCheck[i] && !selectedClientAssociated && adult.type === 'adult'" class="selected-country">
                        <input class="chip"
                               formControlName="state_id"
                               [ngStyle]="getStyles(i)"
                               value="{{ _form.get('paxes').value[i].state_id }}"
                               readonly>

                          <button type="button"
                                  class="close-chip"
                                  tooltip="Eliminar Región"
                                  placement="bottom"
                                  (click)="clearCountry(i, 'state')">
                            ×
                          </button>
                      </div>
                      <div *ngIf="stateCheck[i] && !selectedClientAssociated && adult.type === 'children'" class="selected-country">
                        <input class="chip"
                               formControlName="state_id"
                               [ngStyle]="getStyles(i)"
                               value="{{ formPaxesReservationData?.state_parent ? formPaxesReservationData?.state_parent: selectedIsState[i]}}"
                               readonly>

                          <button type="button"
                                  class="close-chip"
                                  tooltip="Eliminar Región"
                                  placement="bottom"
                                  (click)="clearCountry(i, 'state')">
                            ×
                          </button>
                      </div>
                        <input *ngIf="adult.type === 'adult' && (!stateCheck[i] || selectedClientAssociated)"
                                id="state_id"
                                name="state_id"
                                class="form-control"
                                [ngClass]="{
                                              'is-invalid': !stateCheck[i],
                                              'is-valid': stateCheck[i]
                                            }"
                                [ngStyle]="getStyles(i)"
                                type="text"
                                [(ngModel)]="selectedIsState[i]"
                                formControlName="state_id"
                                aria-label="state_id"
                                aria-required="true"
                                typeaheadOptionField="destination_name"
                                [typeahead]="formPaxesReservationData?.states[i]?.states"
                                placeholder="Región"
                                autocapitalize="none"
                                autocomplete="random-string"
                                (typeaheadOnSelect)="getCitiesData(i);"
                                required
                        >

                      <input  *ngIf="adult.type === 'children' && (!stateCheck[i] || selectedClientAssociated)"
                                id="state_id"
                                name="state_id"
                                class="form-control"
                                [ngClass]="{
                                              'is-invalid': !stateCheck[i],
                                              'is-valid': stateCheck[i]
                                            }"
                                          [ngStyle]="getStyles(i)"
                                type="text"
                                [(ngModel)]="this.formPaxesReservationData?.state_parent ? this.formPaxesReservationData?.state_parent: selectedIsState[i]"
                                formControlName="state_id"
                                aria-label="state_id"
                                aria-required="true"
                                typeaheadOptionField="destination_name"
                                [typeahead]="formPaxesReservationData?.states[i]?.states"
                                placeholder="Región"
                                autocapitalize="none"
                                autocomplete="random-string"
                                (typeaheadOnSelect)="getCitiesData(i);"
                                required
                        >
                    <span
                        *ngIf="_form.get('paxes.' + i + '.state_id').touched && _form.get('paxes.' + i + '.country_id')?.valid && !stateCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -22px; left: 20px;"
                        >Seleccione una región de la lista</span
                        >
                    <span
                        *ngIf="_form.get('paxes.' + i + '.state_id').touched && _form.get('paxes.' + i + '.country_id')?.invalid && !stateCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -22px; left: 20px;"
                        >Seleccione un país para ver la lista de regiones</span
                    >
                  </div>
                </ng-container>
              </div>
        </div>




<!--
    CLIENTE O PASAJERO === 1
      input dirección
-->




            <div class="row"
                  style="margin-top: 40px"
                  [ngStyle]="{'margin-top': data.typeAction ? '10px' : '40px'}">

                  <!--
    CLIENTE O PASAJERO === 1
      input ciudad
-->


              <ng-container *ngIf="i == 0">
                <div class="col-5 sm-gutters" style="position: relative">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins"
                            for="city"
                            style="font-size: small"
                      >Ciudad <sup class="required-marker"> * </sup></label
                    >
                    <div *ngIf="cityCheck[i] && !selectedClientAssociated" class="selected-country">
                      <input class="chip"
                             formControlName="city"
                             [ngStyle]="getStyles(i)"
                             value="{{ _form.get('paxes').value[i].city }}"
                             readonly>

                        <button type="button"
                                class="close-chip"
                                tooltip="Eliminar Ciudad"
                                placement="bottom"
                                (click)="clearCountry(i, 'city')">
                          ×
                        </button>
                    </div>
                    <input  *ngIf="!cityCheck[i] || selectedClientAssociated"
                            id="city"
                            name="city"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': !cityCheck[i],
                              'is-valid': cityCheck[i]
                            }"
                            [ngStyle]="getStyles(i)"
                           type="text"
                           [(ngModel)]="selectedIsCity[i]"
                           formControlName="city"
                           aria-label="city"
                           aria-required="true"
                           typeaheadOptionField="city_name"
                           [typeahead]="data.typeAction ? citiesFiltered :  formPaxesReservationData?.cities[i].cities"
                           placeholder="Ciudad"
                           autocapitalize="none"
                           autocomplete="random-string"
                           (typeaheadOnSelect)="
                           changeCity(i);
                           onChangeParentData($event, 'city')"
                           required
                      >
                  </div>
                  <span
                        *ngIf="_form.get('paxes.' + i + '.city').touched &&
                                countryCheck[i] &&
                                !stateCheck[i] &&
                                !cityCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        >Seleccione una región</span
                        >
                  <span
                        *ngIf="_form.get('paxes.' + i + '.city').touched &&
                                !countryCheck[i] &&
                                !stateCheck[i] &&
                                !cityCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        >Seleccione un país</span
                    >
                    <span
                        *ngIf="_form.get('paxes.' + i + '.city').touched &&
                                 countryCheck[i] &&
                                 stateCheck[i] &&
                                !cityCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        >Seleccione una ciudad de la lista</span
                        >
                </div>
              </ng-container>


<!--
    CLIENTE O PASAJERO MAYOR A 1
        input ciudad
-->

              <ng-container *ngIf="i > 0">
                <div class="col-5 sm-gutters" style="position: relative">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins"
                            for="city"
                            style="font-size: small"
                      >Ciudad <sup class="required-marker"> * </sup></label
                    >
                    <div *ngIf="cityCheck[i] && !selectedClientAssociated" class="selected-country">
                      <input class="chip"
                             formControlName="city"
                             [ngStyle]="getStyles(i)"
                             value="{{ adult.type === 'adult' ? _form.get('paxes').value[i].city : formPaxesReservationData?.city_parent}}"
                             readonly>

                        <button type="button"
                                class="close-chip"
                                tooltip="Eliminar Ciudad"
                                placement="bottom"
                                (click)="clearCountry(i, 'city')">
                          ×
                        </button>
                    </div>
                    <ng-container *ngIf="!cityCheck[i] || selectedClientAssociated">


                      <input
                            *ngIf="adult.type === 'adult'"
                            id="city"
                            name="city"
                            class="form-control"
                          [ngClass]="{
                            'is-invalid': !cityCheck[i],
                            'is-valid': cityCheck[i]
                          }"
                            [ngStyle]="getStyles(i)"
                          type="text"
                          [(ngModel)]="selectedIsCity[i]"
                          formControlName="city"
                          aria-label="city"
                          aria-required="true"
                          typeaheadOptionField="city_name"
                          [typeahead]="formPaxesReservationData?.cities[i].cities"
                          placeholder="Ciudad"
                          autocapitalize="none"
                          autocomplete="random-string"
                          (typeaheadOnSelect)="changeCity(i)"
                          required
                      >
                      <input
                            *ngIf="adult.type === 'children'"
                            id="city"
                            name="city"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': !cityCheck[i],
                              'is-valid': cityCheck[i]
                            }"
                            [ngStyle]="getStyles(i)"
                          type="text"
                          [(ngModel)]="this.formPaxesReservationData?.city_parent ? this.formPaxesReservationData?.city_parent : selectedIsCity[i]"
                          formControlName="city"
                          aria-label="city"
                          aria-required="true"
                          typeaheadOptionField="city_name"
                          [typeahead]="formPaxesReservationData?.cities[i].cities"
                          placeholder="Ciudad"
                          autocapitalize="none"
                          autocomplete="random-string"
                          (typeaheadOnSelect)="changeCity(i)"
                          required
                      >
                    </ng-container>
                    </div>
                    <span
                        *ngIf="_form.get('paxes.' + i + '.city').touched &&
                                countryCheck[i] &&
                                !stateCheck[i] &&
                                !cityCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        >Seleccione una región</span
                        >
                  <span
                        *ngIf="_form.get('paxes.' + i + '.city').touched &&
                                !countryCheck[i] &&
                                !stateCheck[i] &&
                                !cityCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        >Seleccione un país</span
                    >
                    <span
                        *ngIf="_form.get('paxes.' + i + '.city').touched &&
                                 countryCheck[i] &&
                                 stateCheck[i] &&
                                !cityCheck[i]"
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        >Seleccione una ciudad de la lista</span
                        >
                  </div>
                </ng-container>

                <div class=" col-7">
                    <ng-container *ngIf="i == 0">
                      <div class="row" style="position: relative">
                        <div class="col-12 sm-gutters">
                          <div class="form-group">
                            <label class="font-weight-bold font-poppins"
                                   for="address"
                                   style="font-size: small"
                              >Dirección <sup class="required-marker"> * </sup></label
                            >
                            <input
                                    id="address"
                                    name="address"
                                    class="form-control"
                                    [ngClass]="{
                                                  'is-invalid':
                                                                _form.value.paxes[i].address == null ||
                                                                _form.value.paxes[i].address == '' ||
                                                                _form.get(['paxes', i, 'address'])?.hasError('minlength'),
                                                  'is-valid':
                                                               ( _form.value.paxes[i].address != null ||
                                                                _form.value.paxes[i].address !== '') &&
                                                                !_form.get(['paxes', i, 'address'])?.hasError('minlength')
                                              }"
                                              [ngStyle]="getStyles(i)"
                                    type="text"
                                    formControlName="address"
                                    aria-label="address"
                                    aria-required="true"
                                    autocapitalize="none"
                                    autocomplete="random-string"
                                    placeholder="Dirección"
                                    required
                                    (input)="onChangeParentData($event.target.value, 'address')"
                            />
                          </div>
                        </div>
                        <span
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        *ngIf="_form.get(['paxes', i, 'address'])?.hasError('minlength')"
                      >
                        Mínimo 5 caracteres
                      </span>
                      </div>
                    </ng-container>
    <!--
        CLIENTE O PASAJERO === 1
          input dirección
    -->

                    <ng-container *ngIf="i > 0">
                      <div class="row" style="position: relative;">
                        <div class="col-12 sm-gutters">
                          <div class="form-group">
                            <label class="font-weight-bold font-poppins"
                                    for="address"
                                    style="font-size: small"
                              >Dirección <sup class="required-marker"> * </sup></label
                            >
                            <input
                                  *ngIf="adult.type === 'adult'"
                                  id="address"
                                  name="address"
                                  class="form-control"
                                  [ngClass]="{
                                    'is-invalid':
                                                  _form.value.paxes[i].address == null ||
                                                  _form.value.paxes[i].address == '' ||
                                                  _form.get(['paxes', i, 'address'])?.errors?.minlength,
                                    'is-valid':
                                                  (_form.value.paxes[i].address != null ||
                                                  _form.value.paxes[i].address !== '') &&
                                                  !_form.get(['paxes', i, 'address'])?.errors?.minlength
                                  }"
                                            [ngStyle]="getStyles(i)"
                                  type="text"
                                  formControlName="address"
                                  aria-label="adress"
                                  aria-required="true"
                                  autocapitalize="none"
                                  autocomplete="random-string"
                                  placeholder="Dirección"
                                  required
                            />
                            <input
                                  *ngIf="adult.type === 'children'"
                                  id="address_children"
                                  #address_children
                                  name="address"
                                  class="form-control"
                                  [ngClass]="{
                                    'is-invalid':
                                                  _form.value.paxes[i].address == null ||
                                                  _form.value.paxes[i].address == '' ||
                                                  _form.get(['paxes', i, 'address'])?.errors?.minlength,
                                    'is-valid':
                                                  (_form.value.paxes[i].address != null ||
                                                  _form.value.paxes[i].address !== '') &&
                                                  !_form.get(['paxes', i, 'address'])?.errors?.minlength
                                  }"
                                            [ngStyle]="getStyles(i)"
                                  type="text"
                                  formControlName="address"
                                  aria-label="adress"
                                  aria-required="true"
                                  autocapitalize="none"
                                  autocomplete="random-string"
                                  placeholder="Dirección"
                                  required
                            />
                          </div>
                        </div>
                        <span
                        style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                        *ngIf="_form.get(['paxes', i, 'address'])?.hasError('minlength')"
                      >
                        Mínimo 5 caracteres
                      </span>
                      </div>
                    </ng-container>
                  </div>
                </div>



<!--

    CLIENTE O PASAJERO TELEFONO OBLIGATORIO Y OPCIONAL

-->

            <div class="row"
                  style="margin-top: 40px;"
                  [ngStyle]="{'margin-top': data.typeAction ? '10px' : '40px'}"
            >


<!--

    select e input de código de país + telefono 1

-->

              <div class="col-6 sm-gutters">
                <div class="form-group"
                     [ngStyle]="{
                                  'margin-bottom': data.typeAction === 'New' &&
                                  clientData.fromAssociates === true ? '0' : '15px'
                                }"
                >
                  <label class="font-weight-bold font-poppins"
                         for="phone"
                         style="font-size: small;"
                  >Cod + Telefono <sup class="required-marker"> * </sup></label>
                  <div class="row" style="position: relative;">

                    <div *ngIf="i === 0" class="col-7"  style="padding-right: 4px;">
                      <div class="form-group" style="position:relative"
                            >
                            <div *ngIf="phoneCodeCheck[i] && !selectedClientAssociated" class="selected-country">
                              <input class="chip"
                                     formControlName="phone_code"
                                     [ngStyle]="getStyles(i, 'phone_code')"
                                     value="{{ phone_code[i] }}"
                                     readonly>

                                <button
                                        type="button"
                                        class="close-chip"
                                        tooltip="Eliminar Código telefónico"
                                        placement="bottom"
                                        (click)="clearCountry(i, 'phone_code')">
                                  ×
                                </button>
                            </div>
                            <ng-container *ngIf="!phoneCodeCheck[i] || selectedClientAssociated">
                              <input
                              #phoneCode
                              id="phoneCode"
                              name="phone_code"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid': !phoneCodeCheck[i],
                                'is-valid': phoneCodeCheck[i]
                              }"
                              [ngStyle]="getStyles(i)"
                              type="text"
                              [(ngModel)]="phone_code[i]"
                              formControlName="phone_code"
                              aria-label="phone_code"
                              aria-required="true"
                              typeaheadOptionField="displayValue"
                              [typeahead]="filteredCountries"
                              placeholder="País + cód. telefónico"
                              (typeaheadOnSelect)="onChangeParentData($event, 'phone_code'); changePhoneCode(i, $event.item.country_code, 'phone_code')"
                              autocapitalize="none"
                              autocomplete="random-string"
                              required
                        >
                            </ng-container>
                            <span
                                  *ngIf="_form.get('paxes.' + i + '.phone_code').touched && !phoneCodeCheck[i]"
                                  style="font-size: 14px; color: #e93a55; position: absolute; bottom: -24px; left: 20px;"
                                  >Seleccione un código</span
                                  >
                            </div>
                    </div>
                    <div *ngIf="i > 0" class="col-7"  style="padding-right: 4px;">
                      <div class="form-group"
                      >
                        <div *ngIf="phoneCodeCheck[i] && !selectedClientAssociated && adult.type === 'children'" class="selected-country">
                          <input class="chip"
                                formControlName="phone_code"
                                [ngStyle]="getStyles(i, 'phone_code')"
                                value="{{ this.formPaxesReservationData.phone_code_parent ? this.formPaxesReservationData.phone_code_parent : phone_code[i] }}"
                                readonly>

                            <button
                                    type="button"
                                    class="close-chip"
                                    tooltip="Eliminar Código telefónico"
                                    placement="bottom"
                                    (click)="clearCountry(i, 'phone_code')">
                              ×
                            </button>
                        </div>
                        <div *ngIf="phoneCodeCheck[i] && !selectedClientAssociated && adult.type === 'adult'" class="selected-country">
                          <input class="chip"
                                formControlName="phone_code"
                                [ngStyle]="getStyles(i, 'phone_code')"
                                value="{{ _form.get('paxes').value[i].phone_code }}"
                                readonly>

                            <button
                                    type="button"
                                    class="close-chip"
                                    tooltip="Eliminar Código telefónico"
                                    placement="bottom"
                                    (click)="clearCountry(i, 'phone_code')">
                              ×
                            </button>
                        </div>
                      <ng-container *ngIf="!phoneCodeCheck[i] || selectedClientAssociated">
                        <input
                              *ngIf="formPaxesReservationData.countries && adult.type === 'children'"
                              id="phone_code"
                              name="phone_code"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid': !phoneCodeCheck[i],
                                'is-valid': phoneCodeCheck[i]
                              }"
                              [ngStyle]="getStyles(i)"
                              type="text"
                              [(ngModel)]="this.formPaxesReservationData.phone_code_parent ? this.formPaxesReservationData.phone_code_parent : phone_code[i]"
                              formControlName="phone_code"
                              aria-label="phone_code"
                              aria-required="true"
                              typeaheadOptionField="displayValue"
                              [typeahead]="filteredCountries"
                              placeholder="País + cód. telefónico"
                              (typeaheadOnSelect)="changePhoneCode(i, '', 'phone_code')"
                              autocapitalize="none"
                              autocomplete="random-string"
                              required
                        >
                        <input
                              *ngIf="formPaxesReservationData.countries && adult.type === 'adult'"
                              id="phone_code"
                              #phoneChildren
                              name="phone_code"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid': !phoneCodeCheck[i],
                                'is-valid': phoneCodeCheck[i]
                              }"
                              [ngStyle]="getStyles(i)"
                              type="text"
                              [(ngModel)]="phone_code[i]"
                              formControlName="phone_code"
                              aria-label="phone_code"
                              aria-required="true"
                              typeaheadOptionField="displayValue"
                              [typeahead]="filteredCountries"
                              placeholder="País + cód. telefónico"
                              (typeaheadOnSelect)="changePhoneCode(i, '', 'phone_code')"
                              autocapitalize="none"
                              autocomplete="random-string"
                              required
                        >
                      </ng-container>
                      </div>

                    </div>
                    <div class="col-5"
                          style="padding-left: 4px;">
                      <input
                            *ngIf="adult.type === 'adult'"
                            id="phone"
                            name="phone"
                            (keydown)="blockInvalidKeys($event)"
                            class="form-control"
                            [ngClass]="{
                                        'is-invalid':
                                                      _form.value.paxes[i].phone == null ||
                                                      _form.value.paxes[i].phone == '' ||
                                                      _form.get(['paxes', i, 'phone'])?.errors?.pattern,
                                        'is-valid':
                                                      (_form.value.paxes[i].phone !== null ||
                                                      _form.value.paxes[i].phone !== '') &&
                                                      !_form.get(['paxes', i, 'phone'])?.errors?.pattern
                                      }"
                                      [ngStyle]="getStyles(i)"
                            type="number"
                            formControlName="phone"
                            aria-label="phone"
                            aria-required="true"
                            autocapitalize="none"
                            autocomplete="random-string"
                            placeholder="Phone"
                            required
                      />
                      <input
                            *ngIf="adult.type === 'children'"
                            id="phone"
                            name="phone"
                            class="form-control"
                            (keydown)="blockInvalidKeys($event)"
                            [ngClass]="{
                                        'is-invalid':
                                                      _form.value.paxes[i].phone == null ||
                                                      _form.value.paxes[i].phone == '' ||
                                                      _form.get(['paxes', i, 'phone'])?.errors?.pattern,
                                        'is-valid':
                                                      (_form.value.paxes[i].phone !== null ||
                                                      _form.value.paxes[i].phone !== '') &&
                                                      !_form.get(['paxes', i, 'phone'])?.errors?.pattern
                                      }"
                                      [ngStyle]="getStyles(i)"
                            type="number"
                            formControlName="phone"
                            aria-label="phone"
                            aria-required="true"
                            autocapitalize="none"
                            autocomplete="random-string"
                            placeholder="Phone"
                            required
                      />
                    </div>
                    <span
                          style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                          *ngIf="_form.get(['paxes', i, 'phone'])?.errors?.pattern">
                          Mínimo 6 caracteres y sin valores negativos
                    </span>
                  </div>
                </div>

              </div>

<!--

    select e input de código de país + telefono 2

-->


              <div class="col-6 sm-gutters">
                <div class="form-group"
                     [ngStyle]="{
                                 'margin-bottom': data.typeAction === 'New' &&
                                 clientData.fromAssociates === true ? '0' : '15px'
                               }"
                >
                  <label class="font-weight-bold font-poppins"
                         for="phone_2"
                         style="font-size: small;">Cod + Telefono 2</label>
                  <div class="row" style="position: relative;">
                            <div class="col-7" style="padding-right: 4px;">
                              <div class="form-group" style="position: relative">
                                <div *ngIf="phoneCode2Check[i] && !selectedClientAssociated" class="selected-country">
                                  <input class="chip"
                                         formControlName="phone_code"
                                         [ngStyle]="getStyles(i, 'phone_2_code')"
                                         value="{{ _form.get('paxes').value[i].phone_2_code }}"
                                         readonly>

                                    <button
                                            type="button"
                                            class="close-chip"
                                            tooltip="Eliminar Código telefónico"
                                            placement="bottom"
                                            (click)="clearCountry(i, 'phone_2_code')">
                                      ×
                                    </button>
                                </div>
                                <input *ngIf="!phoneCode2Check[i] || selectedClientAssociated"

                                      id="phone_2_code"
                                      name="phone_2_code"
                                      class="form-control"
                                      type="text"
                                      [(ngModel)]="phone_2_code[i]"
                                      formControlName="phone_2_code"
                                      aria-label="phone_2_code"
                                      aria-required="true"
                                      [ngStyle]="getStyles(i)"
                                      typeaheadOptionField="displayValue"
                                      [typeahead]="filteredCountries"
                                      (typeaheadOnSelect)="changePhoneCode(i, '', 'phone_2_code')"
                                      placeholder="País + cód. telefónico"
                                      autocapitalize="none"
                                      autocomplete="random-string"
                                >
                      </div>
                    </div>
                    <div class="col-5" style="padding-left: 4px;">
                      <input
                              id="phone_2"
                              name="phone_2"
                              class="form-control"
                              (keydown)="blockInvalidKeys($event)"
                              [ngStyle]="getStyles(i)"
                              type="number"
                              formControlName="phone_2"
                              aria-label="phone_2"
                              autocapitalize="none"
                              autocomplete="random-string"
                              placeholder="Phone 2"
                       />
                      </div>
                      <span
                         style="font-size: 14px; color: #e93a55; position: absolute; bottom: -6px; left: 20px;"
                         *ngIf="_form.get(['paxes', i, 'phone_2'])?.errors?.pattern">
                         Mínimo 6 caracteres y sin valores negativos
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <span class="required-marker">* Campos Obligatorios</span>
            <div class="row">
              <div class="col-4" style="display: flex; gap: 15px;">
                <ng-container *ngIf="clientData.typeAction === 'New' && clientData.fromAssociates === true">
                  <div class="d-flex flex-column">
                    <span>
                      <label
                            class="font-weight-bold font-poppins"
                            style="font-size: small;"
                            for="selectClientExist"
                      >
                      Clientes existentes</label>
                    </span>
                    <span class="d-flex justify-content-center align-items-center">
                        <input
                              id="checkbox_select"
                              name="checkbox_select"
                              style="width: 24px; border: 1.5px solid black"
                              aria-label="checkbox_select"
                              type="checkbox"
                             (change)="toggleSelect($event.target)"
                        />
                        <select
                                id="selectClientExist"
                                name="selectClientExist"
                                class="form-control custom-select"
                                style="margin-right: -15px; margin-left:15px;"
                                formControlName="selectClientExist"
                                aria-label="selectClientExist"
                                autocomplete="off"
                                (change)="clientSelect($event.target.value)">
                          <option selected disabled>Clientes existentes</option>
                          <option *ngFor="let client of clientData.clients" [value]="client.id">{{client.first_name}} {{client.last_name}}</option>
                        </select>
                      </span>
                    </div>
                  </ng-container>
                </div>
              <div *ngIf="data.typeAction" class="col-8 d-flex justify-content-end align-items-end ">
                <button class="btn mr-2 rounded-pill" style="height: 38px;width:150px;background-color: black; color: white;" (click)="closeModal()">
                CERRAR
                </button>

                <button (click)="_onSubmitClient($event)" [disabled]="!_form.valid" class="btn rounded-pill " style="height: 38px;width:150px; color: white;background-color: #00d096;">
                  {{data.typeAction=="New" ? "CREAR" : "ACTUALIZAR"}}
                </button>
              </div>
          </div>
          </div>
        </accordion-group>
      </accordion>
    </div>


    <div class="col-4 col-md-4 col-lg-4 col-xl-4" *ngIf="!data.typeAction">
      <span class="mb-3" style="font-size: 1.65rem" [ngStyle]="{ color: formPaxesReservationData.getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
        >RESUMEN</span
      >
      <div class="pb-5 pt-2 px-2" style="background-color: #e9e9e9; border-radius: 5px">
        <usb-summary-reservation [step]="step" [itineraryItems]="itineraryItems" [itineraryTotal]="itineraryTotal" [itinerary]="itinerary">
        </usb-summary-reservation>
        <button
          (click)="_onSubmit(false, $event)"
          [disabled]="!isFormValid()"
          class="btn rounded-pill btn-block"
          style="height: 70px; cursor: pointer"
          [ngStyle]="{ 'background-color': formPaxesReservationData.getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
        >
          <span *ngIf="permitedConfiguration">CONTINUAR</span>
          <span *ngIf="!permitedConfiguration">RESERVAR</span>
        </button>
      </div>
    </div>
  </div>
</form>
</div>
