<div class="d-flex flex-column">

  <div *ngIf="nuevoObjetoAux && nuevoObjetoAux.length > 0" id="title-itinerary"
      class="d-flex justify-content-center align-items-center"
      [ngStyle]="{
                    'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_2
                 }"
      >
        <p class="mb-0 font-weight-bold"
           style="font-family: 'Rubik', serif;font-size: 32px;"
           [ngStyle]="{
                        'color': getClientsSettingAppBooking?.clients_setting_global?.color_main_3
                      }"
        >
          File
        </p>
  </div>

  <div *ngIf="nuevoObjetoAux && nuevoObjetoAux.length > 0"  style="border-radius: 5px" [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_2 }">

    <!-- Fin Título Itinerario -->
    <div *ngIf="modeView == 'RESULT' && origin !== 'MULTIDESTINATION'" [ngClass]="{ 'mb-5': modeView !== 'RESULT', 'mb-2': modeView !== 'RESULT' }"
        >
      <!-- NO SOY MULTIDESTINATION
      <div *ngIf="userService.itinerary != null && userService.itinerary.length > 0"  class="bg-darks mt-2 m-0 p-0 pb-3" [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_2 }">
        <div class="m-0 content-sidebar" *ngFor="let objetc of userService.itinerary | groupBy : 'yy'; let i = index">
          <div *ngFor="let item of objetc.value | orderBy : 'orderDateDestiny'; let i = index" class="d-flex flex-column justify-content-between m-0 p-0 mt-3"  [ngStyle]="{'border-bottom': isLastIndex(i, objetc.value) ? 'none' : '1px solid ' + getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }" style="padding-bottom: 25px !important">
            <ng-container *ngIf="cities.indexOf(item.item.destination.destination_info.cityName) === i || i === 0">
                <div class="d-flex justify-content-around content-cityName" [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }">
                  <p class="title-cityName text-uppercase">{{ item.item.destination.destination_info.cityName }}</p>
                  <p  class="text-white title-date">
                    {{item.item.destination.destination_info.arrival | date : "dd MMM" }} a {{ item.item.destination.destination_info.departure | date : "dd MMM" }}
                  </p>
                </div>
            </ng-container>
            <div class="d-flex icon-item align-items-center justify-content-between" style="position: relative; margin-left: 5px">
              <div class="d-flex justify-content-start align-item-center">
                <span
                  class="circle-icons"
                  [ngClass]="{
                    'background-icon-status-saved': item.item.status_id === 1,
                    'background-icon-status-reserved': item.item.status_id === 2,
                    'background-icon-status-locked': item.item.status_id === 3,
                    'background-icon-status-denied': item.item.status_id === 4,
                    'background-icon-status-cancelled': item.item.status_id === 5,
                    'background-icon-status-paid-agency': item.item.status_id === 6,
                    'background-icon-status-paid-provider': item.item.status_id === 7,
                    'background-icon-status-billed': item.item.status_id === 8,
                    'background-icon-status-pending-approve-pay': item.item.status_id === 9,
                    'background-icon-status-completed': item.item.status_id === 10
                  }"
                >
                  <svg *ngIf="item.item.categoryName == 'HOTELS'"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }" class="icon-svg" version="1.1"id="Layer_1"  xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512"xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <path d="M53.333,202.667h53.333c-3.285,0-6.379-1.515-8.405-4.096c-2.027-2.581-2.731-5.952-1.941-9.152l6.635-26.517 c3.563-14.272,16.341-24.235,31.04-24.235h68.672c17.643,0,32,14.357,32,32V192c0,5.888-4.779,10.667-10.667,10.667h64 c-5.888,0-10.667-4.779-10.667-10.667v-21.333c0-17.643,14.357-32,32-32h68.672c14.699,0,27.477,9.963,31.04,24.235l6.635,26.517 c0.811,3.179,0.085,6.571-1.941,9.152c-2.027,2.581-5.12,4.096-8.405,4.096h53.333c5.888,0,10.667-4.779,10.667-10.667v-64 c0-29.397-23.936-53.333-53.333-53.333H96c-29.397,0-53.333,23.936-53.333,53.333v64 C42.667,197.888,47.445,202.667,53.333,202.667z"/>
                          <path d="M458.667,224H53.333C23.936,224,0,247.936,0,277.333v149.333c0,5.888,4.779,10.667,10.667,10.667 s10.667-4.779,10.667-10.667v-32h469.333v32c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V277.333 C512,247.936,488.064,224,458.667,224z M490.667,373.333H21.333V352h469.333V373.333z"
                          />
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <svg
                    *ngIf="item.item.categoryName == 'RENT-A-CAR'"
                    class="icon-svg"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }"
                    x="0px"
                    y="0px"
                    viewBox="0 0 447.64499 370.905"
                  >
                    <g id="g32" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g34" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g36" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g38" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g40" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g42" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g44" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g46" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g48" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g50" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g52" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g54" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g56" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g58" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g60" transform="translate(-14.821571,-74.886488)"></g>
                    <path
                      d="m 447.639,206.032 c 0,-8.805 -1.988,-17.215 -5.578,-24.909 -0.37,-1.956 -0.793,-3.909 -1.322,-5.89 l -38.884,-96.364998 -0.263,-0.867 C 387.987,37.492002 368.629,0 319.543,0 H 131.868 C 81.572,0 63.799,38.421002 49.896,77.776002 L 9.223,174.376 C 3.343,183.797 0,194.574 0,206.032 v 29.986 c 0,4.636 0.548,9.171 1.59,13.539 C 0.577,252.196 0,255.04 0,258.038 v 89.185 c 0,13.078 10.602,23.68199 23.68,23.68199 h 49.14 c 13.070999,0 23.672999,-10.60399 23.672999,-23.68199 V 302.624 H 353.953 v 44.599 c 0,13.078 10.604,23.68199 23.683,23.68199 h 46.326 c 13.083,0 23.683,-10.60399 23.683,-23.68199 v -89.195 c 0,-2.987 -0.583,-5.844 -1.588,-8.474 1.038,-4.375 1.588,-8.905 1.588,-13.54 v -29.981 h -0.01 z M 78.754,87.451002 c 15.482999,-43.683 27.934,-57.018 53.114,-57.018 h 187.664 c 24.995,0 38.913,14.873 53.056,56.83 L 400.963,144.765 c -9.265,-3.431 -19.461,-5.335 -30.173,-5.335 H 76.849 c -9.645,0 -18.862,1.551 -27.366,4.358 z M 103.129,247.406 H 51.281 c -9.335,0 -16.906,-7.578 -16.906,-16.912 0,-9.337 7.571,-16.91 16.906,-16.91 h 51.848 c 9.339,0 16.91,7.573 16.91,16.91 0,9.334 -7.576,16.912 -16.91,16.912 z m 183.155,-3.387 h -120.6 c -5.913,0 -10.704,-4.794 -10.704,-10.704 0,-5.921 4.791,-10.713 10.704,-10.713 h 120.6 c 5.92,0 10.71,4.792 10.71,10.713 0,5.91 -4.79,10.704 -10.71,10.704 z m 108.767,3.387 h -51.846 c -9.343,0 -16.91,-7.578 -16.91,-16.912 0,-9.337 7.573,-16.91 16.91,-16.91 h 51.846 c 9.343,0 16.916,7.573 16.916,16.91 0,9.334 -7.573,16.912 -16.916,16.912 z"
                      id="svg_2"
                      inkscape:connector-curvature="0"
                    />
                  </svg>
                  <svg
                    *ngIf="item.item.categoryName == 'TRANSPORTS'"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }"
                    class="icon-svg"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M53.333,234.667v-64C59.221,170.667,64,165.888,64,160s-4.779-10.667-10.667-10.667C23.915,149.333,0,173.269,0,202.667
                        V224c0,17.643,14.357,32,32,32h21.333C59.221,256,64,251.221,64,245.333S59.221,234.667,53.333,234.667z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M458.667,149.333c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667v64
                        c-5.888,0-10.667,4.779-10.667,10.667S452.779,256,458.667,256H480c17.643,0,32-14.357,32-32v-21.333
                        C512,173.269,488.085,149.333,458.667,149.333z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M181.333,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667
                        V480c0,17.643,14.357,32,32,32H160c17.643,0,32-14.357,32-32v-21.333C192,452.779,187.221,448,181.333,448z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M416,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667S320,452.779,320,458.667V480
                        c0,17.643,14.357,32,32,32h42.667c17.643,0,32-14.357,32-32v-21.333C426.667,452.779,421.888,448,416,448z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M416,0H96C66.581,0,42.667,23.936,42.667,53.333V416c0,29.397,23.915,53.333,53.333,53.333h320
                        c29.419,0,53.333-23.936,53.333-53.333V53.333C469.333,23.936,445.419,0,416,0z M138.667,42.667h234.667c17.643,0,32,14.357,32,32
                        c0,17.643-14.357,32-32,32H138.667c-17.643,0-32-14.357-32-32C106.667,57.024,121.024,42.667,138.667,42.667z M138.667,405.333
                        c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32C170.667,390.976,156.309,405.333,138.667,405.333z
                        M373.333,405.333c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32
                        C405.333,390.976,390.976,405.333,373.333,405.333z M426.667,266.667c0,17.643-14.357,32-32,32H117.333c-17.643,0-32-14.357-32-32
                        V160c0-17.643,14.357-32,32-32h277.333c17.643,0,32,14.357,32,32V266.667z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <svg
                    *ngIf="item.item.categoryName == 'TOURS'"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }"
                    class="icon-svg"
                    height="461pt"
                    viewBox="-78 0 461 461.344"
                    width="461pt"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m128.179688 180.726562 22.769531 136.617188h2.445312l22.738281-136.398438c-.226562-13.050781-10.863281-23.601562-23.960937-23.601562-13.023437 0-23.664063 10.441406-23.992187 23.382812zm0 0"
                    />
                    <path
                      d="m216.171875 157.34375c-13.230469 0-24 10.769531-24 24l-.113281 1.3125-22.445313 134.6875h17.078125l53.433594-137.398438c-.722656-12.585937-11.183594-22.601562-23.953125-22.601562zm0 0"
                    />
                    <path
                      d="m88.171875 157.34375c-12.769531 0-23.230469 10.015625-23.960937 22.601562l53.433593 137.398438h17.085938l-22.558594-136c0-13.230469-10.769531-24-24-24zm0 0"
                    />
                    <path
                      d="m184.15625 157.566406c7.296875-9.796875 18.886719-16.222656 32.015625-16.222656 8.710937 0 16.71875 2.878906 23.296875 7.625-6.226562-78.585938-55.929688-126.074219-79.296875-144.226562v137.410156c9.800781 2 18.207031 7.671875 23.984375 15.414062zm0 0"
                    />
                    <path d="m71.148438 365.34375h162.046874l-14.222656-32h-133.601562zm0 0" />
                    <path
                      d="m100.476562 317.34375-52.304687-134.496094v-1.503906c0-13.230469-10.769531-24-24-24-12.519531 0-22.839844 9.648438-23.910156 21.894531l84.398437 138.105469zm0 0"
                    />
                    <path
                      d="m179.914062 0c27.800782 23.984375 70.570313 72.976562 75.691407 150.015625 6.804687-5.34375 15.261719-8.671875 24.566406-8.671875 9.039063 0 17.296875 3.128906 24 8.207031v-.207031c0-74.328125-53.664063-136.253906-124.257813-149.34375zm0 0"
                    />
                    <path
                      d="m280.171875 157.34375c-13.230469 0-24 10.769531-24 24v1.503906l-52.304687 134.496094h15.816406l84.402344-138.105469c-1.074219-12.246093-11.394532-21.894531-23.914063-21.894531zm0 0"
                    />
                    <path
                      d="m240.308594 381.34375h-176.265625l-3.4375 7.734375c-2.9375 6.609375-4.433594 13.625-4.433594 20.867187 0 28.34375 23.054687 51.398438 51.390625 51.398438h89.210938c28.34375 0 51.398437-23.054688 51.398437-51.398438 0-7.242187-1.496094-14.257812-4.433594-20.867187zm0 0"
                    />
                    <path
                      d="m64.875 148.96875c6.578125-4.746094 14.585938-7.625 23.296875-7.625 13.128906 0 24.703125 6.449219 32 16.25 5.777344-7.761719 14.191406-13.441406 24-15.441406v-137.410156c-23.367187 18.152343-73.070313 65.640624-79.296875 144.226562zm0 0"
                    />
                    <path
                      d="m48.738281 150.015625c5.128907-77.039063 47.890625-126.03125 75.691407-150.015625-70.59375 13.089844-124.257813 75.015625-124.257813 149.34375v.207031c6.703125-5.078125 14.960937-8.207031 24-8.207031 9.304687 0 17.761719 3.328125 24.566406 8.671875zm0 0"
                    />
                  </svg>
                </span>
                <div class="d-flex flex-row justify-content-between" style="overflow: hidden; width: 150px">
                  <div class="d-flex flex-column">
                    <p  class="title font-poppins"> {{item.item.destination.destination_info.arrival | date : "dd MMM" }} a {{ item.item.destination.destination_info.departure | date : "dd MMM" }} </p>
                    <p *ngIf="item.categoryName == 'TOURS'"  class="title font-poppins">{{ stingUpperCase(item?.item?.company) }}</p>
                    <p *ngIf="item.categoryName == 'HOTELS'" class="title font-poppins">{{ stingUpperCase(item?.item?.title) }}</p>
                    <p *ngIf="item.categoryName == 'RENT-A-CAR'" class="title font-poppins">{{ stingUpperCase(item?.item?.title) }}</p>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-between align-items-center" style="width: 30%; margin-top: -25px">
                <ul class="nav">
                  <li class="item-nav" *ngIf="item.item.status_id === 1 || item.item.status_id === 4 || item.item.status_id === 5">
                    <a class="item-button" (click)="removeItem(item, i)" title="delete">
                      <i class="icon-delete" aria-hidden="true">&nbsp;&nbsp;</i>
                    </a>
                  </li>
                  <li>
                    <a class="item-button" title="">
                      <svg-icon src="../../../assets/img/app/little-icons/edit.svg"></svg-icon>
                    </a>
                  </li>
                  <li>
                    <a class="item-button" title="">
                      <svg-icon
                        src="../../../assets/img/app/little-icons/clipboard.svg"
                        [ngStyle]="{ fill: getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
                      ></svg-icon>
                    </a>
                  </li>
                  <li
                    class="item-nav"
                    *ngIf="item !== undefined && item.product !== null && isEditable(item.categoryName) && item.categoryName !== 'DISNEY'"
                  >
                    <div class="item-button px-1" title="edit">
                      <i class="icon-edit icon icon-pencil p-1" style="background-color: #272727; margin-right: 3px" aria-hidden="true"></i>
                    </div>
                  </li>
                  <li class="item-nav" *ngIf="item.categoryName === 'DISNEY'"></li>
                </ul>
                <span
                  *ngIf="item.item.status_id === 1"
                  class="status status-sidebar product-status-saved"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 2"
                  class="status status-sidebar product-status-reserved"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 3"
                  class="status status-sidebar product-status-locked"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 4"
                  class="status status-sidebar product-status-denied"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 5"
                  class="status status-sidebar product-status-cancelled"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 6"
                  class="status status-sidebar product-status-paid-agency"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 7"
                  class="status status-sidebar product-status-paid-provider"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 8"
                  class="status status-sidebar product-status-completed"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
              </div>
            </div>
            <div class="d-flex flex-column detail-item">
              <div *ngIf="item.categoryName == 'HOTELS' || item.categoryName == 'TOURS'" class="d-flex flex-row justify-content-start align-items-end" style="max-width: 175px; margin-left: 50px" >
                <i class="icon-placeholder font-verde" [ngStyle]="{ color: getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }" ></i>
                <p class="m-0 font-poppins location-font" style="color: #939295">
                  {{ item?.item?.location }}
                </p>
              </div>
              <div
                *ngIf="item.categoryName == 'RENT-A-CAR'"
                class="d-flex flex-row mt-1"
                class="d-flex flex-row justify-content-start align-items-end"
                style="width: 100%; margin-top: 8px"
              >
                <p class="hotel-date font-poppins m-0">
                  <strong class="font-verde">Pickup: </strong>
                  {{ item.item.payloadUpdate.products[0].others.carRental.pickupLocation.origin }}
                </p>
                <p class="hotel-date font-poppins m-0 ml-1">
                  <strong class="font-verde">Dropoff: </strong>
                  {{ item.item.payloadUpdate.products[0].others.carRental.dropoffLocation.destination }}
                </p>
              </div>

              <div class="d-flex flex-row" style="max-width: 170px; margin-left: 55px; margin-top: 6px; color: #939295">
                <div class="d-flex flex-column" style="width: 70%" [ngClass]="{ 'justify-content-center': item.categoryName == 'TOURS' }">
                  <p *ngIf="item.categoryName == 'TOURS'" class="fechaInn font-poppins m-0 d-flex flex-row mt-1">
                    <span class="dark-1"> <span class="icon icon-user dark-1" style="font-size: 15px"></span>{{ item.item.paxAdults }} </span>
                    <span class="ml-2 dark-1"> <span class="icon icon-child"></span> {{ item.item.paxChildren }} </span>
                  <p *ngIf="item.categoryName == 'HOTELS'" class="hotel-room font-poppins m-0 mt-1">
                    1 Room
                    <span class="ml-2 pl-1 dark-1">
                      <span class="icon icon-user dark-1" style="font-size: 15px"></span> {{ item.item.paxAdults }}</span
                    >
                    <span class="ml-2 dark-1"> <span class="icon icon-child"></span> {{ item.item.paxChildren }} </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div  *ngIf="nuevoObjetoAux != null"  class="bg-darks m-0 p-0 pb-3" [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_2 }"
          >
          <div *ngFor="let cityGroup of nuevoObjetoAux; let i = index" class="m-0 content-sidebar">
            <div class="d-flex flex-column justify-content-between m-0 p-0 mt-1"
              [ngStyle]="{'border-bottom': isLastIndex(i, cityGroup.items) ? 'none' : '1px solid ' + getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
              style="padding-bottom: 25px !important;">
              <div  *ngFor="let dateGroup of cityGroup.items" class="title-cityName text-uppercase" style="width: 100%; margin-top: 10px;">
                <div class="d-flex flex-row justify-content-between">
                  <div class="d-flex flex-column mx-auto" style="width:95%">
                    <ng-container *ngFor="let itemGroup of dateGroup.items">
                      <div class="d-flex flex-column py-2 content-cityName"
                        style="padding-left: 10px; padding-right: 14px;">
                        <p *ngIf="itemGroup.items.length > 0" class="title-cityName" style="margin: auto 0;text-transform: capitalize;">{{ cityGroup.city }}</p>
                          <div *ngIf="itemGroup.items.length > 0 && itemGroup.items[0]">
                            <p class="title-date" style="text-transform: capitalize; margin: auto 0;">
                              {{ itemGroup.items[0].arrivalDate | date : "dd - MMM" }}
                              <span style="text-transform: lowercase;">a</span>
                              {{ itemGroup.items[0].departureDate | date : "dd - MMM" }}
                            </p>
                          </div>
                      </div>

                  <div  *ngFor="let item of itemGroup.items; let lastIndex = last; index as i;" class="mt-3"
                        [ngStyle]="{'border-bottom': lastIndex ? 'none' : '1px solid ' + getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
                        style="padding-bottom: 25px !important">
                    <div class="d-flex justify-content-between">

                      <div class="d-flex icon-item align-items-start justify-content-between" style="position: relative; margin-left: 5px">
                        <span
                          class="circle-icons"
                          [ngClass]="{
                            'background-icon-status-saved': item.status_id === 1,
                            'background-icon-status-reserved': itemGroup.items.status_id === 2,
                            'background-icon-status-locked': item.status_id === 3,
                            'background-icon-status-denied': item.status_id === 4,
                            'background-icon-status-cancelled': item.status_id === 5,
                            'background-icon-status-paid-agency': item.status_id === 6,
                            'background-icon-status-paid-provider': item.status_id === 7,
                            'background-icon-status-billed': item.status_id === 8,
                            'background-icon-status-pending-approve-pay':item.status_id === 9,
                            'background-icon-status-completed': item.status_id === 10
                          }"
                        >
                          <img
                                *ngIf="item.categoryName == 'HOTELS'"
                                width="25"
                                src="../../../assets/img/app/little-icons/bed.svg"
                                [ngClass]="{
                                              'icon-status-saved': itemGroup.items.status_id === 1,
                                              'icon-status-reserved': itemGroup.items.status_id === 2,
                                              'icon-status-locked': itemGroup.items.status_id === 3,
                                              'icon-status-denied': itemGroup.items.status_id === 4,
                                              'icon-status-cancelled': itemGroup.items.status_id === 5,
                                              'icon-status-paid-agency': itemGroup.items.status_id === 6,
                                              'icon-status-paid-provider': itemGroup.items.status_id === 7,
                                              'icon-status-billed': itemGroup.items.status_id === 8,
                                              'icon-status-pending-approve-pay': itemGroup.items.status_id === 9,
                                              'icon-status-completed': itemGroup.items.status_id === 10
                                          }"
                                alt="">

                          <img
                              *ngIf="item.categoryName == 'RENT-A-CAR'"
                              width="25"
                              src="../../../assets/img/app/icon-car-black.svg"
                              [ngClass]="{
                                            'icon-status-saved': itemGroup.items.status_id === 1,
                                            'icon-status-reserved': itemGroup.items.status_id === 2,
                                            'icon-status-locked': itemGroup.items.status_id === 3,
                                            'icon-status-denied': itemGroup.items.status_id === 4,
                                            'icon-status-cancelled': itemGroup.items.status_id === 5,
                                            'icon-status-paid-agency': itemGroup.items.status_id === 6,
                                            'icon-status-paid-provider': itemGroup.items.status_id === 7,
                                            'icon-status-billed': itemGroup.items.status_id === 8,
                                            'icon-status-pending-approve-pay': itemGroup.items.status_id === 9,
                                            'icon-status-completed': itemGroup.items.status_id === 10
                                        }"
                              alt="">

                              <img
                              *ngIf="item.categoryName == 'TRANSPORTS'"
                              width="25"
                              src="../../../assets/img/app/icon-transport.svg"
                              [ngClass]="{
                                            'icon-status-saved': itemGroup.items.status_id === 1,
                                            'icon-status-reserved': itemGroup.items.status_id === 2,
                                            'icon-status-locked': itemGroup.items.status_id === 3,
                                            'icon-status-denied': itemGroup.items.status_id === 4,
                                            'icon-status-cancelled': itemGroup.items.status_id === 5,
                                            'icon-status-paid-agency': itemGroup.items.status_id === 6,
                                            'icon-status-paid-provider': itemGroup.items.status_id === 7,
                                            'icon-status-billed': itemGroup.items.status_id === 8,
                                            'icon-status-pending-approve-pay': itemGroup.items.status_id === 9,
                                            'icon-status-completed': itemGroup.items.status_id === 10
                                        }"
                              alt="">

                              <img
                              *ngIf="item.categoryName == 'TOURS'"
                              width="25"
                              src="../../../assets/img/app/icon-tours.svg"
                              [ngClass]="{
                                            'icon-status-saved': itemGroup.items.status_id === 1,
                                            'icon-status-reserved': itemGroup.items.status_id === 2,
                                            'icon-status-locked': itemGroup.items.status_id === 3,
                                            'icon-status-denied': itemGroup.items.status_id === 4,
                                            'icon-status-cancelled': itemGroup.items.status_id === 5,
                                            'icon-status-paid-agency': itemGroup.items.status_id === 6,
                                            'icon-status-paid-provider': itemGroup.items.status_id === 7,
                                            'icon-status-billed': itemGroup.items.status_id === 8,
                                            'icon-status-pending-approve-pay': itemGroup.items.status_id === 9,
                                            'icon-status-completed': itemGroup.items.status_id === 10
                                        }"
                              alt="">

                        </span>
                      </div>

                      <div class="d-flex flex-column align-content-start" style="text-transform: lowercase; width: 50%">
                        <!-- <p class="title font-poppins"> {{item.arrivalDate | date : "dd MMM" }} a {{item.departureDate | date : "dd MMM" }}  </p> -->
                        <p *ngIf="item.categoryName == 'TOURS'"  class="title mb-0">{{ stingUpperCase(item?.company) }}</p>
                        <p *ngIf="item.categoryName == 'HOTELS'" class="title mb-0">{{ stingUpperCase(item?.title) }}</p>
                        <p *ngIf="item.categoryName == 'RENT-A-CAR'" class="title mb-0">{{ stingUpperCase(item?.title) }}</p>
                        <p *ngIf="item.categoryName == 'TRANSPORTS'" class="title mb-0">{{ stingUpperCase(item?.title) }}</p>
                        <p *ngIf="item?.categoryName == 'HOTELS' || item?.categoryName == 'TOURS'" class="type-room-select mb-0">{{item.roomType}}</p>
                        <p *ngIf="item?.categoryName == 'HOTELS' || item?.categoryName == 'TOURS'" class="type-room mb-0">{{item.boardName}}</p>
                        <p *ngIf="item?.categoryName == 'HOTELS' || item?.categoryName == 'TOURS'"
                           class="regime mb-0"
                           [ngStyle]="{'color': item.rateClassId == 'R' ? '#02d498' : '#d60159'}">{{item.rateClassId == "R" ? 'Reembolsable' : 'No Reembolsable'}}</p>

                        <div class="d-flex flex-column detail-item">
                          <div
                              *ngIf="item?.categoryName == 'RENT-A-CAR'"
                              class="d-flex flex-row mt-1"
                              class="d-flex flex-row justify-content-start align-items-end"
                              style="width: 100%; margin-top: 8px"
                          >
                            <p class="hotel-date font-poppins m-0">
                              <strong class="font-verde">Recogida: </strong>
                              {{ item?.payloadUpdate.products[0].others.carRental.pickupLocation.origin }}
                            </p>
                            <p class="hotel-date font-poppins m-0 ml-1">
                              <strong class="font-verde">Devolución: </strong>
                              {{ item?.payloadUpdate.products[0].others.carRental.dropoffLocation.destination }}
                            </p>
                          </div>

                          <div
                              *ngIf="item?.categoryName == 'TRANSPORTS'"
                              class="d-flex flex-row mt-1"
                              class="d-flex flex-row justify-content-start align-items-center"
                              style="width: 100%; margin-top: 8px"
                          >
                            <p class="hotel-date font-poppins m-0">
                              <strong class="font-verde">Nombre del vehículo: </strong>
                              <br>
                              {{item.vehicleName}}
                            </p>
                          </div>


                          <div class="d-flex"
                                style="color: #939295; width:max-content"
                                [ngClass]="{ 'justify-content-center': item?.categoryName == 'TOURS' }">
                              <p *ngIf="item?.categoryName == 'TOURS'" class="fechaInn font-poppins m-0 d-flex flex-row mt-1">
                                <span class="dark-1" style="display: flex; align-items: center; gap: 3px;">
                                  <!-- <span class="icon icon-user dark-1" style="font-size: 15px"></span> -->
                                  <i class="bi bi-person-fil mt-1" style="font-size: 17px;" aria-hidden="true"></i>
                                  <p class="mb-0" style="font-family: 'Rubik'; font-size: 12px;font-weight: 500; margin-top:3.5px;">{{ item?.paxAdults }} </p>
                                </span>
                                <span class="ml-2 dark-1" style="display: flex; align-items: center; gap: 3px;">
                                  <span class="icon icon-child"></span>
                                  <p class="mb-0" style="font-family: 'Rubik'; font-size: 12px;font-weight: 500; margin-top:3.5px;">{{ item?.paxChildren }}</p>
                                </span>
                              </p>
                              <p *ngIf="item?.categoryName == 'HOTELS'" class="hotel-room m-0 mt-1" style="font-family: 'Rubik', serif;text-transform: lowercase;display: flex; align-items: center; width: 100%">
                                1 Hab
                              </p>
                              <ng-container *ngIf="item.status_id === 1">
                                <span class="ml-2 pl-1 dark-1" style="display: flex; align-items: center; gap: 3px;">
                                    <i class="bi bi-person-fill mt-1" style="font-size: 17px;" aria-hidden="true"></i>
                                    <p class="mb-0" style="font-family: 'Rubik'; font-size: 12px;font-weight: 500; margin-top:3.5px;">{{ item?.paxAdults }}</p>
                                </span>
                                  <span class="ml-2 dark-1" style="display: flex; align-items: center; gap: 3px;">
                                    <span class="icon icon-child"></span>
                                    <p class="mb-0" style="font-family: 'Rubik'; font-size: 12px;font-weight: 500; margin-top:3.5px;">{{ item?.paxChildren }}</p>
                                </span>
                              </ng-container>
                              <ng-container *ngIf="item.status_id !== 1">
                                  <span class="ml-2 pl-1 dark-1" style="display: flex; align-items: center; gap: 3px;">
                                    <!-- <span class="icon icon-user dark-1" style="font-size: 15px">
                                  </span>  -->
                                    <i class="bi bi-person-fill mt-1" style="font-size: 17px;" aria-hidden="true"></i>
                                    <p class="mb-0" style="font-family: 'Rubik'; font-size: 12px;font-weight: 500; margin-top:3.5px;">{{ item?.form?.room?.qtyAdults }}</p>
                                </span>
                                  <span class="ml-2 dark-1" style="display: flex; align-items: center; gap: 3px;">
                                    <span class="icon icon-child"></span>
                                    <p class="mb-0" style="font-family: 'Rubik';font-size: 12px; font-weight: 500; margin-top:3.5px;">{{ item?.payloadUpdate?.products[0]?.others?.hotels?.roomConfiguration[0]?.children?.length ? item?.payloadUpdate?.products[0]?.others?.hotels?.roomConfiguration[0]?.children?.length : 0 }}</p>
                                  </span>
                              </ng-container>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-column justify-content-between">
                        <span
                          *ngIf="item.status_id === 1"
                          class="status status-sidebar product-status-saved"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 2"
                          class="status status-sidebar product-status-reserved"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 3"
                          class="status status-sidebar product-status-locked"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 4"
                          class="status status-sidebar product-status-denied"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 5"
                          class="status status-sidebar product-status-cancelled"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 6"
                          class="status status-sidebar product-status-paid-agency"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 7"
                          class="status status-sidebar product-status-paid-provider"
                          >{{ item.status }}</span
                        >
                        <span
                          *ngIf="item.status_id === 8"
                          class="status status-sidebar product-status-completed"
                          >{{ item.status }}</span
                        >
                        <ul class="nav" style="justify-content: flex-end;">
                          <li class="item-nav" *ngIf="(item?.status_id === 1 ||  item?.status_id === 4) && item?.status_id !== 5">
                            <a *ngIf="deleteItem" class="item-button" (click)="removeItem(item, i)" title="delete">
                              <i class="icon-delete" aria-hidden="true">&nbsp;&nbsp;</i>
                            </a>
                          </li>

                        </ul>
                      </div>

                    </div>


                    <div class="title-cityName text-uppercase" style="width: 100%; margin-top: 20px;">




                      <div *ngIf="item?.categoryName == 'HOTELS'" style="text-align: end;">
                        <div>
                          <p class="font-poppins m-0 mb-1" style="font-family: 'Rubik', serif;font-size: 14px;font-weight: 600;color: #d0d0d0; line-height: 1;">
                            TARIFA TOTAL
                        </p>
                          <p class="font-poppins m-0" style="font-family: 'Rubik', serif;font-size: 22px;font-weight: 600;color: #d0d0d0; line-height: 1;">
                              {{item.currency}} {{item.optionNightsNetTotalProf | number: '1.2-2'}}
                          </p>
                          <p class="font-poppins m-0" style="font-family: 'Rubik', serif;font-size: 12px;font-weight: 600;color: #d0d0d0; line-height: 1; text-transform: capitalize;;">
                            Imp. incluidos
                          </p>
                        </div>

                        <div class="d-flex justify-content-end mt-1">
                          <div class="d-flex align-items-middle">
                            <!-- <i class="icon icon-medal mr-1"></i> -->
                            <img src="../../../assets/img/app/little-icons/badge-bks.png" class="mr-1" style="width: 13px; height: 15px;margin-top: 1px" alt="">
                            <span class="font-point d-flex align-items-center" style="font-family: 'Rubik', serif;">
                              {{item.profility.blickoins }}&nbsp;BKS
                            </span>
                            <span class="font-point d-flex align-items-center">
                              <p class="p-night m-0 pl-2" style="font-family: 'Rubik', serif;">
                                USD&nbsp;{{item.profility.creditBlickoins}}</p>
                            </span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  </ng-container>

                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- <div class="m-0 content-sidebar" *ngFor="let objetc of userService.itinerary | groupBy : 'yy'; let i = index">
          <div *ngFor="let item of objetc.value | orderBy : 'orderDateDestiny'; let i = index" class="d-flex flex-column justify-content-between m-0 p-0 mt-3"  [ngStyle]="{'border-bottom': isLastIndex(i, objetc.value) ? 'none' : '1px solid ' + getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }" style="padding-bottom: 25px !important">
            <ng-container *ngIf="cities.indexOf(item.item.destination.destination_info.cityName) === i || i === 0">
                <div class="d-flex justify-content-around content-cityName" [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }">
                  <p class="title-cityName text-uppercase">{{ item.item.destination.destination_info.cityName }}</p>
                  <p  class="text-white title-date">
                    {{item.item.destination.destination_info.arrival | date : "dd MMM" }} a {{ item.item.destination.destination_info.departure | date : "dd MMM" }}
                  </p>
                </div>
            </ng-container>
            <div class="d-flex icon-item align-items-center justify-content-between" style="position: relative; margin-left: 5px">
              <div class="d-flex justify-content-start align-item-center">
                <span
                  class="circle-icons"
                  [ngClass]="{
                    'background-icon-status-saved': item.item.status_id === 1,
                    'background-icon-status-reserved': item.item.status_id === 2,
                    'background-icon-status-locked': item.item.status_id === 3,
                    'background-icon-status-denied': item.item.status_id === 4,
                    'background-icon-status-cancelled': item.item.status_id === 5,
                    'background-icon-status-paid-agency': item.item.status_id === 6,
                    'background-icon-status-paid-provider': item.item.status_id === 7,
                    'background-icon-status-billed': item.item.status_id === 8,
                    'background-icon-status-pending-approve-pay': item.item.status_id === 9,
                    'background-icon-status-completed': item.item.status_id === 10
                  }"
                >
                  <svg *ngIf="item.item.categoryName == 'HOTELS'"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }" class="icon-svg" version="1.1"id="Layer_1"  xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512"xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <path d="M53.333,202.667h53.333c-3.285,0-6.379-1.515-8.405-4.096c-2.027-2.581-2.731-5.952-1.941-9.152l6.635-26.517 c3.563-14.272,16.341-24.235,31.04-24.235h68.672c17.643,0,32,14.357,32,32V192c0,5.888-4.779,10.667-10.667,10.667h64 c-5.888,0-10.667-4.779-10.667-10.667v-21.333c0-17.643,14.357-32,32-32h68.672c14.699,0,27.477,9.963,31.04,24.235l6.635,26.517 c0.811,3.179,0.085,6.571-1.941,9.152c-2.027,2.581-5.12,4.096-8.405,4.096h53.333c5.888,0,10.667-4.779,10.667-10.667v-64 c0-29.397-23.936-53.333-53.333-53.333H96c-29.397,0-53.333,23.936-53.333,53.333v64 C42.667,197.888,47.445,202.667,53.333,202.667z"/>
                          <path d="M458.667,224H53.333C23.936,224,0,247.936,0,277.333v149.333c0,5.888,4.779,10.667,10.667,10.667 s10.667-4.779,10.667-10.667v-32h469.333v32c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V277.333 C512,247.936,488.064,224,458.667,224z M490.667,373.333H21.333V352h469.333V373.333z"
                          />
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <svg
                    *ngIf="item.item.categoryName == 'RENT-A-CAR'"
                    class="icon-svg"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }"
                    x="0px"
                    y="0px"
                    viewBox="0 0 447.64499 370.905"
                  >
                    <g id="g32" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g34" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g36" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g38" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g40" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g42" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g44" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g46" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g48" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g50" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g52" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g54" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g56" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g58" transform="translate(-14.821571,-74.886488)"></g>
                    <g id="g60" transform="translate(-14.821571,-74.886488)"></g>
                    <path
                      d="m 447.639,206.032 c 0,-8.805 -1.988,-17.215 -5.578,-24.909 -0.37,-1.956 -0.793,-3.909 -1.322,-5.89 l -38.884,-96.364998 -0.263,-0.867 C 387.987,37.492002 368.629,0 319.543,0 H 131.868 C 81.572,0 63.799,38.421002 49.896,77.776002 L 9.223,174.376 C 3.343,183.797 0,194.574 0,206.032 v 29.986 c 0,4.636 0.548,9.171 1.59,13.539 C 0.577,252.196 0,255.04 0,258.038 v 89.185 c 0,13.078 10.602,23.68199 23.68,23.68199 h 49.14 c 13.070999,0 23.672999,-10.60399 23.672999,-23.68199 V 302.624 H 353.953 v 44.599 c 0,13.078 10.604,23.68199 23.683,23.68199 h 46.326 c 13.083,0 23.683,-10.60399 23.683,-23.68199 v -89.195 c 0,-2.987 -0.583,-5.844 -1.588,-8.474 1.038,-4.375 1.588,-8.905 1.588,-13.54 v -29.981 h -0.01 z M 78.754,87.451002 c 15.482999,-43.683 27.934,-57.018 53.114,-57.018 h 187.664 c 24.995,0 38.913,14.873 53.056,56.83 L 400.963,144.765 c -9.265,-3.431 -19.461,-5.335 -30.173,-5.335 H 76.849 c -9.645,0 -18.862,1.551 -27.366,4.358 z M 103.129,247.406 H 51.281 c -9.335,0 -16.906,-7.578 -16.906,-16.912 0,-9.337 7.571,-16.91 16.906,-16.91 h 51.848 c 9.339,0 16.91,7.573 16.91,16.91 0,9.334 -7.576,16.912 -16.91,16.912 z m 183.155,-3.387 h -120.6 c -5.913,0 -10.704,-4.794 -10.704,-10.704 0,-5.921 4.791,-10.713 10.704,-10.713 h 120.6 c 5.92,0 10.71,4.792 10.71,10.713 0,5.91 -4.79,10.704 -10.71,10.704 z m 108.767,3.387 h -51.846 c -9.343,0 -16.91,-7.578 -16.91,-16.912 0,-9.337 7.573,-16.91 16.91,-16.91 h 51.846 c 9.343,0 16.916,7.573 16.916,16.91 0,9.334 -7.573,16.912 -16.916,16.912 z"
                      id="svg_2"
                      inkscape:connector-curvature="0"
                    />
                  </svg>
                  <svg
                    *ngIf="item.item.categoryName == 'TRANSPORTS'"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }"
                    class="icon-svg"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M53.333,234.667v-64C59.221,170.667,64,165.888,64,160s-4.779-10.667-10.667-10.667C23.915,149.333,0,173.269,0,202.667
                        V224c0,17.643,14.357,32,32,32h21.333C59.221,256,64,251.221,64,245.333S59.221,234.667,53.333,234.667z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M458.667,149.333c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667v64
                        c-5.888,0-10.667,4.779-10.667,10.667S452.779,256,458.667,256H480c17.643,0,32-14.357,32-32v-21.333
                        C512,173.269,488.085,149.333,458.667,149.333z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M181.333,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667
                        V480c0,17.643,14.357,32,32,32H160c17.643,0,32-14.357,32-32v-21.333C192,452.779,187.221,448,181.333,448z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M416,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667S320,452.779,320,458.667V480
                        c0,17.643,14.357,32,32,32h42.667c17.643,0,32-14.357,32-32v-21.333C426.667,452.779,421.888,448,416,448z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M416,0H96C66.581,0,42.667,23.936,42.667,53.333V416c0,29.397,23.915,53.333,53.333,53.333h320
                        c29.419,0,53.333-23.936,53.333-53.333V53.333C469.333,23.936,445.419,0,416,0z M138.667,42.667h234.667c17.643,0,32,14.357,32,32
                        c0,17.643-14.357,32-32,32H138.667c-17.643,0-32-14.357-32-32C106.667,57.024,121.024,42.667,138.667,42.667z M138.667,405.333
                        c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32C170.667,390.976,156.309,405.333,138.667,405.333z
                        M373.333,405.333c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32
                        C405.333,390.976,390.976,405.333,373.333,405.333z M426.667,266.667c0,17.643-14.357,32-32,32H117.333c-17.643,0-32-14.357-32-32
                        V160c0-17.643,14.357-32,32-32h277.333c17.643,0,32,14.357,32,32V266.667z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <svg
                    *ngIf="item.item.categoryName == 'TOURS'"
                    [ngClass]="{
                      'icon-status-saved': item.item.status_id === 1,
                      'icon-status-reserved': item.item.status_id === 2,
                      'icon-status-locked': item.item.status_id === 3,
                      'icon-status-denied': item.item.status_id === 4,
                      'icon-status-cancelled': item.item.status_id === 5,
                      'icon-status-paid-agency': item.item.status_id === 6,
                      'icon-status-paid-provider': item.item.status_id === 7,
                      'icon-status-billed': item.item.status_id === 8,
                      'icon-status-pending-approve-pay': item.item.status_id === 9,
                      'icon-status-completed': item.item.status_id === 10
                    }"
                    class="icon-svg"
                    height="461pt"
                    viewBox="-78 0 461 461.344"
                    width="461pt"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m128.179688 180.726562 22.769531 136.617188h2.445312l22.738281-136.398438c-.226562-13.050781-10.863281-23.601562-23.960937-23.601562-13.023437 0-23.664063 10.441406-23.992187 23.382812zm0 0"
                    />
                    <path
                      d="m216.171875 157.34375c-13.230469 0-24 10.769531-24 24l-.113281 1.3125-22.445313 134.6875h17.078125l53.433594-137.398438c-.722656-12.585937-11.183594-22.601562-23.953125-22.601562zm0 0"
                    />
                    <path
                      d="m88.171875 157.34375c-12.769531 0-23.230469 10.015625-23.960937 22.601562l53.433593 137.398438h17.085938l-22.558594-136c0-13.230469-10.769531-24-24-24zm0 0"
                    />
                    <path
                      d="m184.15625 157.566406c7.296875-9.796875 18.886719-16.222656 32.015625-16.222656 8.710937 0 16.71875 2.878906 23.296875 7.625-6.226562-78.585938-55.929688-126.074219-79.296875-144.226562v137.410156c9.800781 2 18.207031 7.671875 23.984375 15.414062zm0 0"
                    />
                    <path d="m71.148438 365.34375h162.046874l-14.222656-32h-133.601562zm0 0" />
                    <path
                      d="m100.476562 317.34375-52.304687-134.496094v-1.503906c0-13.230469-10.769531-24-24-24-12.519531 0-22.839844 9.648438-23.910156 21.894531l84.398437 138.105469zm0 0"
                    />
                    <path
                      d="m179.914062 0c27.800782 23.984375 70.570313 72.976562 75.691407 150.015625 6.804687-5.34375 15.261719-8.671875 24.566406-8.671875 9.039063 0 17.296875 3.128906 24 8.207031v-.207031c0-74.328125-53.664063-136.253906-124.257813-149.34375zm0 0"
                    />
                    <path
                      d="m280.171875 157.34375c-13.230469 0-24 10.769531-24 24v1.503906l-52.304687 134.496094h15.816406l84.402344-138.105469c-1.074219-12.246093-11.394532-21.894531-23.914063-21.894531zm0 0"
                    />
                    <path
                      d="m240.308594 381.34375h-176.265625l-3.4375 7.734375c-2.9375 6.609375-4.433594 13.625-4.433594 20.867187 0 28.34375 23.054687 51.398438 51.390625 51.398438h89.210938c28.34375 0 51.398437-23.054688 51.398437-51.398438 0-7.242187-1.496094-14.257812-4.433594-20.867187zm0 0"
                    />
                    <path
                      d="m64.875 148.96875c6.578125-4.746094 14.585938-7.625 23.296875-7.625 13.128906 0 24.703125 6.449219 32 16.25 5.777344-7.761719 14.191406-13.441406 24-15.441406v-137.410156c-23.367187 18.152343-73.070313 65.640624-79.296875 144.226562zm0 0"
                    />
                    <path
                      d="m48.738281 150.015625c5.128907-77.039063 47.890625-126.03125 75.691407-150.015625-70.59375 13.089844-124.257813 75.015625-124.257813 149.34375v.207031c6.703125-5.078125 14.960937-8.207031 24-8.207031 9.304687 0 17.761719 3.328125 24.566406 8.671875zm0 0"
                    />
                  </svg>
                </span>
                <div class="d-flex flex-row justify-content-between" style="overflow: hidden; width: 150px">
                  <div class="d-flex flex-column">
                    <p  class="title font-poppins"> {{item.item.destination.destination_info.arrival | date : "dd MMM" }} a {{ item.item.destination.destination_info.departure | date : "dd MMM" }} </p>
                    <p *ngIf="item.categoryName == 'TOURS'"  class="title font-poppins">{{ stingUpperCase(item?.item?.company) }}</p>
                    <p *ngIf="item.categoryName == 'HOTELS'" class="title font-poppins">{{ stingUpperCase(item?.item?.title) }}</p>
                    <p *ngIf="item.categoryName == 'RENT-A-CAR'" class="title font-poppins">{{ stingUpperCase(item?.item?.title) }}</p>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-between align-items-center" style="width: 30%; margin-top: -25px">
                <ul class="nav">
                  <li class="item-nav" *ngIf="item.item.status_id === 1 || item.item.status_id === 4 || item.item.status_id === 5">
                    <a class="item-button" (click)="removeItem(item, i)" title="delete">
                      <i class="icon-delete" aria-hidden="true">&nbsp;&nbsp;</i>
                    </a>
                  </li>
                  <li>
                    <a class="item-button" title="">
                      <svg-icon src="../../../assets/img/app/little-icons/edit.svg"></svg-icon>
                    </a>
                  </li>
                  <li>
                    <a class="item-button" title="">
                      <svg-icon
                        src="../../../assets/img/app/little-icons/clipboard.svg"
                        [ngStyle]="{ fill: getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
                      ></svg-icon>
                    </a>
                  </li>
                  <li
                    class="item-nav"
                    *ngIf="item !== undefined && item.product !== null && isEditable(item.categoryName) && item.categoryName !== 'DISNEY'"
                  >
                    <div class="item-button px-1" title="edit">
                      <i class="icon-edit icon icon-pencil p-1" style="background-color: #272727; margin-right: 3px" aria-hidden="true"></i>
                    </div>
                  </li>
                  <li class="item-nav" *ngIf="item.categoryName === 'DISNEY'"></li>
                </ul>
                <span
                  *ngIf="item.item.status_id === 1"
                  class="status status-sidebar product-status-saved"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 2"
                  class="status status-sidebar product-status-reserved"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 3"
                  class="status status-sidebar product-status-locked"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 4"
                  class="status status-sidebar product-status-denied"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 5"
                  class="status status-sidebar product-status-cancelled"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 6"
                  class="status status-sidebar product-status-paid-agency"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 7"
                  class="status status-sidebar product-status-paid-provider"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
                <span
                  *ngIf="item.item.status_id === 8"
                  class="status status-sidebar product-status-completed"
                  style="position: absolute; margin-top: 55px"
                  >{{ item.item.status }}</span
                >
              </div>
            </div>
            <div class="d-flex flex-column detail-item">
              <div *ngIf="item.categoryName == 'HOTELS' || item.categoryName == 'TOURS'" class="d-flex flex-row justify-content-start align-items-end" style="max-width: 175px; margin-left: 50px" >
                <i class="icon-placeholder font-verde" [ngStyle]="{ color: getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }" ></i>
                <p class="m-0 font-poppins location-font" style="color: #939295">
                  {{ item?.item?.location }}
                </p>
              </div>
              <div
                *ngIf="item.categoryName == 'RENT-A-CAR'"
                class="d-flex flex-row mt-1"
                class="d-flex flex-row justify-content-start align-items-end"
                style="width: 100%; margin-top: 8px"
              >
                <p class="hotel-date font-poppins m-0">
                  <strong class="font-verde">Pickup: </strong>
                  {{ item.item.payloadUpdate.products[0].others.carRental.pickupLocation.origin }}
                </p>
                <p class="hotel-date font-poppins m-0 ml-1">
                  <strong class="font-verde">Dropoff: </strong>
                  {{ item.item.payloadUpdate.products[0].others.carRental.dropoffLocation.destination }}
                </p>
              </div>

              <div class="d-flex flex-row" style="max-width: 170px; margin-left: 55px; margin-top: 6px; color: #939295">
                <div class="d-flex flex-column" style="width: 70%" [ngClass]="{ 'justify-content-center': item.categoryName == 'TOURS' }">
                  <p *ngIf="item.categoryName == 'TOURS'" class="fechaInn font-poppins m-0 d-flex flex-row mt-1">
                    <span class="dark-1"> <span class="icon icon-user dark-1" style="font-size: 15px"></span>{{ item.item.paxAdults }} </span>
                    <span class="ml-2 dark-1"> <span class="icon icon-child"></span> {{ item.item.paxChildren }} </span>
                  <p *ngIf="item.categoryName == 'HOTELS'" class="hotel-room font-poppins m-0 mt-1">
                    1 Room
                    <span class="ml-2 pl-1 dark-1">
                      <span class="icon icon-user dark-1" style="font-size: 15px"></span> {{ item.item.paxAdults }}</span
                    >
                    <span class="ml-2 dark-1"> <span class="icon icon-child"></span> {{ item.item.paxChildren }} </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

    </div>

    <!-- MULTIDESTINATION -->
    <div class="mb-5" *ngIf="modeView == 'RESULT' && origin === 'MULTIDESTINATION' && userService.multidestination"
        >
      <!-- SOY MULTIDESTINATION -->
      <div *ngFor="let destiny of userService.multidestination; let i = index">
        <div *ngIf="destiny !== undefined">
          <div class="col-12 bg-darks mb-2" id="info-hotels">
            <div class="row px-2 mb-2">
              <div class="col-12 name-destination bg-green py-1">
                <div class="row">
                  <div class="col-12">
                    <span class="font-poppins f-18 font-dark align-middle text-uppercase text-left" style="font-weight: 500 !important">
                      {{ destiny?.city }}
                    </span>
                    <span class="font-poppins f-14 text-white align-middle date-itinerary" id="date-start">
                      {{ destiny?.dd }} a {{ destiny?.ddd }} {{ destiny?.mm }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-2" *ngIf="destiny.items !== undefined">
              <div class="row" *ngFor="let item of destiny.items; let in = index">
                <div class="col-9">
                  <usb-item-hotel *ngIf="item?.categoryName === 'HOTELS'" [item]="item.item"></usb-item-hotel>

                  <usb-item-tours *ngIf="item?.categoryName == 'TOURS'" [item]="item.item"></usb-item-tours>

                  <usb-item-transports *ngIf="item?.categoryName == 'TRANSPORTS'" [item]="item.item"></usb-item-transports>
                </div>
                <div class="col-3 state-pax-multi">
                  <div class="row pt-2">
                    <ul *ngIf="item.item.status_id === 1 || item.item.status_id === 2" class="nav">
                      <li class="item-nav">
                        <a class="item-button" (click)="removeItem(item, in, i)" title="delete">
                          <i class="icon-delete icon" aria-hidden="true">&nbsp;&nbsp;</i>
                        </a>
                      </li>
                      <li class="item-nav" *ngIf="item?.product !== undefined && item?.product !== null">
                        <!-- <div class="item-button px-1" title="edit" (click)="modalRoom.show()">
                          <i class="icon-edit icon icon-pencil p-1" style="background-color: #272727;" aria-hidden="true"></i>
                        </div>
                        <div>
                          <usb-modals bsModal #modalRoom="bs-modal" class="modal fade" tabindex="-1" role="dialog"
                            aria-hidden="true" [modalRoomRef]="modalRoom" [product]="item.product" [productQty]="1"
                            [form]="activeItineraryForm" [payloadUpdate]="item.payloadUpdate" [view_info]="false"
                            [itineraryItem]="item.item" [origin]="origin">
                          </usb-modals>
                        </div> -->
                      </li>
                      <li class="item-nav" *ngIf="item?.product !== undefined && item?.product !== null">
                        <!-- <a class="item-button" title="info" (click)="modalRoom.show()">
                          <i class="icon-info icon" aria-hidden="true">&nbsp;&nbsp;</i>
                        </a>
                        <div>
                          <usb-modals bsModal #modalRoom="bs-modal" class="modal fade" tabindex="-1" role="dialog"
                            aria-hidden="true" [modalRoomRef]="modalRoom" [product]="item.product" [productQty]="1"
                            [form]="activeItineraryForm" [payloadUpdate]="item.payloadUpdate" [view_info]="true"
                            [itineraryItem]="item.item" [origin]="origin"></usb-modals>
                        </div> -->
                      </li>
                    </ul>
                    <div class="col-12 pt-4">
                      <span
                        *ngIf="item.item.status_id === 1"
                        class="status product-status-saved"
                        style="margin-left: -10px !important; border: none !important"
                        >{{ item.item.status }}</span
                      >
                      <span *ngIf="item.item.status_id === 2" class="status product-status-reserved" style="margin-left: -10px !important">{{
                        item.item.status
                      }}</span>
                      <span *ngIf="item.item.status_id === 3" class="status product-status-locked" style="margin-left: -10px !important">{{
                        item.item.status
                      }}</span>
                      <span *ngIf="item.item.status_id === 4" class="status product-status-denied" style="margin-left: -10px !important">{{
                        item.item.status
                      }}</span>
                      <span *ngIf="item.item.status_id === 5" class="status product-status-cancelled" style="margin-left: -10px !important">{{
                        item.item.status
                      }}</span>
                      <span *ngIf="item.item.status_id === 6" class="status product-status-paid-agency">{{ item.item.status }}</span>
                      <span *ngIf="item.item.status_id === 7" class="status product-status-paid-provider">{{ item.item.status }}</span>
                      <span *ngIf="item.item.status_id === 8" class="status product-status-completed">{{ item.item.status }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <i
                *ngIf="multidestination[i - 1]?.city.length !== '' && i + 1 < multidestination?.length"
                class="icon-separadores d-flex align-items-end text-white"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <hr class="divider-items" />
      <div
        class="ml-auto my-3 d-flex align-items-end justify-content-end"
        id="continue-itinerary-s"
        [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
      >
        <button type="button" class="btn btn-block btn-big btn-usblick rounded-pill text-uppercase" (click)="changeTab()">CONTINUE</button>
      </div>
    </div>

    <div *ngIf="modeView == 'MULTIDESTINATION'">
      <usb-multi-destination [payload]="payload"></usb-multi-destination>
    </div>
  </div>

  <div class="mb-5" style="margin-top: -30px" *ngIf="modeView == 'RESULT' && origin !== 'MULTIDESTINATION'">
    <usb-summary-footer [modeView]="modeView" [buttonDisabled]="buttonDisabled"></usb-summary-footer>
  </div>

</div>
