<div class="card card-info d-flex flex-column my-3" #contentBorderSelect>
  <div class="card card-info d-flex flex-column">
    <div class="d-flex px-2 pt-2 pb-2">
      <span class="card-info-title flex-column mr-2 pt-1">
        <span>OTRAS OPCIONES</span>
      </span>
      <select
        class="form-control description-modal"
        (change)="onChangeBoardName($event.target.value, $event, index)"
        style="width: 415px; text-align: left !important; font-size: 13px;" >
        <option *ngFor="let r of roomBoardName; index as i" [value]="i">
          <span>
          {{ r?.room?.boardName }} - {{ getNameRateClass(r?.room?.rateClass) }} - {{r?.room?.pricing?.currency}}  {{ r?.room?.netPriceProf | number : "1.2-2" }} por noche
          </span>
        </option>
      </select>
      <!--   <span class="card-info-title ml-4 mr-2 pt-1">
        <span>CONDICION</span>
      </span>
      <select
        class="form-control description-modal"
        (change)="onChangeRateClass($event.target.value, $event, index)"
        style="width: 315px; text-align: left !important">
        <option *ngFor="let c of roomRateClass; index as i" [value]="i"  [selected]="c.rateClass === roomSelect.rateClass">
          <span *ngIf="c.rateClass == 'NR'"> NO REEMBOLSABLE           {{ c.boardName }} /  {{ c.rateClass }} /  {{ c.netPrice }} /  {{ c.roomCode }} -  {{i}}</span>
          <span *ngIf="c.rateClass == 'R'"> CANCELA GRATIS {{ c.boardName }} /  {{ c.rateClass }} /  {{ c.netPrice }} /  {{ c.roomCode }} -  {{i}}</span>
        </option>
      </select> -->
    </div>
  </div>
  <div class="d-flex px-4 pt-4 position-relative">

    <div class="flex-column d-flex type-room">
      <!-- *ngIf="imgRoomCover.length != 0"  -->
      <div class="cover-img-room">
        <img [src]="imageRoomById[0]?.images[0]?.url" style="width: 8rem; height: 9rem; object-fit: cover;" alt="" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
      </div>
      <!-- <div class="cover-img-room" *ngIf="imageRoomById[0] === 'undefined'">
        <img src="assets/img/app/hotel_default.png" style="width: 8rem; height: 9rem; object-fit: cover;" alt="">
      </div> -->
      <span class="card-info-title flex-column mb-2 mt-2">
        <span>Tipo Habitación</span>
      </span>
      <span class="flex-column description-modal" style="color: #00d196 !important; font-weight: 500 !important">
        {{ stingUpperCase(roomSelect.room.roomType) }}</span>

    </div>

    <div class="d-flex flex-column">


      <div class="d-flex ml-2" style="gap: 2.2em;"  >
        <div class="d-flex flex-column mb-3" style="min-width: 100px;">
          <span class="card-info-title mb-0">
            <span>Capacidad</span>
          </span>
          <span class="description-modal">
            <!-- <i class="icon icon-multiple-users-silhouette" aria-hidden="true"></i> -->
            <i class="bi bi-people-fill pr-1" style="font-size: 14px;" aria-hidden="true"></i>
            <span>
              {{roomSelect?.room?.availability?.maxAdults}} Adultos
            </span>
            <br>
            <i class="icon icon-child" aria-hidden="true"></i>
            <span>
              {{roomSelect?.room?.availability?.maxChildren}} Niños
            </span>
          </span>
        </div>
        <div class="d-flex flex-column mb-3" >
          <div class="d-flex align-items-center">
            <div class="card-info-title mb-0"> Tarifa por Noche </div>
            <div class="info-tool d-flex flex-column" *ngIf="roomSelect.room.pricing?.dailyPrices.length > 0">i
            <span class="tooltip-info shadow-md" [ngStyle]="{ 'width.px': dailyPricesArr.length > 4 ? 105 : null }">
                <usb-daily-prices [dailyPrices]="dailyPricesArr" style="width: 100%"></usb-daily-prices>
            </span>
            </div>
          </div>
          <span class="description-modal" style="color: #00d196 !important; font-weight: 500 !important" *ngIf="roomSelect?.room?.netPriceProf">
            {{roomSelect?.room?.pricing?.currency}} {{ roomSelect?.room?.netPriceProf | number : "1.2-2" }}
          </span>
          <span style="font-size: 11px;">Imp. incluidos</span>

        </div>
        <div class="d-flex flex-column mb-3" style="margin-left: 1.5em;">
          <span class="card-info-title mb-0">
            <span>Condiciones</span>
          </span>
          <span class="description-modal" aria-hidden="true">
            <i class="fa fa-check"></i>
            <span *ngIf="roomSelect?.room?.rateClass == 'NR'"> NO REEMBOLSABLE</span>
            <span *ngIf="roomSelect?.room?.rateClass == 'R'"> REEMBOLSABLE</span>
          </span>
        </div>
      </div>


      <div class="d-flex ml-2" style="gap: 2.2em;" >
        <div class="d-flex flex-column mb-3" style="max-width: 100px;">
          <span class="card-info-title mb-0">
            <span>Disponible</span>
          </span>
          <span class="description-modal">
            <i class="bi bi-people-fill pr-1" style="font-size: 14px;" aria-hidden="true"></i> {{ roomSelect?.room?.availability?.allotment }} Habitación(es)
          </span>

        </div>
        <div class="d-flex flex-column  mb-3">
          <span class="card-info-title mb-0">
            <span>Tarifa Total Estadia</span>
          </span>
          <span
            class="description-modal mb-0"
            style="color: #00d196 !important; font-weight: 500 !important; height: 15px;"
            *ngIf="roomSelect?.room?.optionNightsNetTotalProf"
          >
            {{roomSelect?.room?.pricing?.currency}} {{ roomSelect?.room?.optionNightsNetTotalProf | number : "1.2-2" }}
          </span>
          <span style="font-size: 11px;">Imp. incluidos</span>
        </div>
        <div class="d-flex flex-column pl-3">
          <span *ngIf="roomSelect?.room?.rateClass == 'R'" class="text-rateclass">
            <p class="mb-0"
              >Hasta:
              <span class="red "> {{ roomSelect?.room?.cancellationPolicy?.from | date : "dd/MM/yyyy" }} Gratis</span>
            </p>
            <p class="mb-0"
              >Desde:
              <span class="red"> {{ roomSelect?.room?.cancellationPolicy?.from | date : "dd/MM/yyyy" }} <span (click)="openPolitics()" style="cursor: pointer; text-decoration: underline;">Ver</span> </span>
              <!-- <span class="red pl-2">USD $ {{ roomSelect.refPrice | number : "1.2-2" }}</span> -->
            </p>
          </span>
          <span *ngIf="roomSelect?.room?.rateClass == 'NR'" class="text-rateclass">
            <p class="mb-0"
              >Hasta:
              <span class="red"> {{ departureDate | date : "dd/MM/yyyy" }} 100%</span>
             </p>
          </span>
        </div>
        <!-- <div>
          <div class="d-flex pt-2">
            <img class="img-logo ml-2" *ngIf="roomSelect?.providerLogo" src="{{ roomSelect.providerLogo }}" alt="logo" />
          </div>
        </div> -->
      </div>


      <div class="d-flex ml-2 justify-content-between" style="gap: 2.2em;" #containingDiv >
        <div class="d-flex flex-column mb-3" style="min-width: 100px;">
          <span class="card-info-title mb-0">
            <span>Regimen</span>
          </span>
          <span class="flex-column description-modal mb-2">
            {{ stingUpperCase(roomSelect?.room?.boardName) }}
          </span>
        </div>
        <div>
          <!-- <span class="card-info-title pt-2" *ngIf="amenitiesRoom">
            <span class="card-info-title mb-1">
              <span>Comodidades</span>
            </span>
            <div class="row">
              <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 font-view text-center">
                <i class="i-wifi"></i>
              </div>
              <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 font-view text-center">
                <i class="i-radio"></i>
              </div>
              <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 text-center">
                <i class="i-cafe"></i>
              </div>
              <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 text-center">
                <i class="i-tv"></i>
              </div>
            </div>
          </span> -->
        </div>
        <div style="display: flex; flex-direction: column ;align-items: flex-end; position: absolute; right: 2em;" #selectedRoomsDiv>
          <div *ngFor="let selectedRoom of selectedRooms; let i = index">
              <p class="mb-0" style="font-size: 12px; font-weight: 600;">
                {{ selectedRoom?.service?.boardName }} - {{ getNameRateClass(selectedRoom?.service?.rateClass) }} - {{roomSelect?.service?.pricing?.currency}} {{ selectedRoom?.service?.netPriceProf | number : "1.2-2"}}

                  <span class=" mr-2" style="font-size: 12px; font-weight: 500;"> / {{ selectedRoom.room.qtyAdults }} Adultos - {{ selectedRoom.room.qtyChilds }} Niños</span>
                  <i class="bi bi-x-square-fill" style="color: red; cursor: pointer" (click)="offSelectionRooms(roomSelect, i)"></i>
                </p>

          </div>
        </div>



      </div>


    </div>

    <div class="d-flex flex-column ml-4">
      <div class="d-flex flex-column">
        <span class="card-info-title mb-1">
          <span>Seleccionar</span>
        </span>
          <span class="mb-0">
            <select
            class="form-control description-modal"
            style="width: 145px; text-align: left !important; font-size: 13px;"
            (change)="onSelectionChange($event, roomSelect)">
            <option [disabled]="false" [selected]="undefined">Habitaciones</option>
            <option *ngFor="let room of roomsSearch; let i = index" [value]="i">
              <span> {{room.qtyAdults}} Adultos - {{room.qtyChilds}} Niños </span>
            </option>
          </select>
            <!-- <ul style="margin-top: .5em;">
              <li *ngFor="let selectedRoom of selectedRooms; let i = index" style="display: flex; justify-content: space-between;">
                <span>{{ selectedRoom.room.qtyAdults }} Adultos - {{ selectedRoom.room.qtyChilds }} Niños</span>
                <i class="bi bi-x-square-fill" style="color: red; cursor: pointer" (click)="offSelectionRooms(roomSelect, i)"></i>
              </li>
            </ul> -->
          </span>
      </div>

      <div>
        <div class="d-flex pt-2">
          <img class="img-logo ml-2" *ngIf="roomSelect?.provider?.logo" src="{{ roomSelect?.provider?.logo }}" alt="logo" />
        </div>
      </div>
    </div>
  </div>



  <div class="md-accordion-custom p-4">
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> DESCRIPCIÓN </mat-panel-title>
          <mat-panel-description class="m-0">
            &nbsp;
            <mat-icon x-small class="white-icon">format_list_bulleted</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p class="text-expand mt-3">
          {{ hotelDescription }}
        </p>
        <div class="d-flex flex-column mt-3" *ngIf="roomAmenits.length > 0">
              <div class="d-flex  service" *ngFor="let amenities of roomAmenits">
                <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                <p class="subtitle-item m-0" style="line-height: 1.5;">{{amenities.amenity_name}}</p>
              </div>
        </div>
        <!-- *ngIf="availableRoom" -->
        <div class="d-flex ">
          <div class="d-flex flex-column w-100 mt-3 mb-5"  *ngIf="availableRoom">
            <div class="d-flex flex-column w-100" >
              <p class="m-0 title-item" >Disponible en el hotel</p>
              <div class="d-flex flex-column justify-content-between w-100">
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/wifi.svg" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1">WIFI</p>
                </div>
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/tv.svg" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1">TV</p>
                </div>
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/piscina.svg" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1">Piscina</p>
                </div>
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/ac.png" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1">Aire Acond.</p>
                </div>
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/cafe.svg" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1">Café</p>
                </div>
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/tel.svg" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1">Teléfono</p>
                </div>
                <div class="d-flex mt-1 align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/parking.svg" alt="" />
                  <p class="m-0 subtitle-item p-0 pb-1 p-0 pb-1">Parking</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <!---------------  COLLAPSABLE - POLÍTICAS DE CANCELACION ---------------------->

      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> POLÍTICAS DE CANCELACIÓN Y PENALIDADES</mat-panel-title>
          <mat-panel-description class="m-0">
            &nbsp;
            <mat-icon x-small class="white-icon">not_interested</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div *ngFor="let cancellation_policy of roomSelect.cancellationPolicy">
          <p class="title-itemmb-1 mt-3 font-weight-bold">{{ cancellation_policy.descript_polecy }}</p>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center" style="width: 100%">
              <img style="width: 20px; height: 20px; margin-right: 5px" src="/assets/img/app/modal/check.svg" alt="" />
              <p class="text-desc m-0 font-weight-bold color-green">Hasta el {{ cancellation_policy.from | date : "dd/MM/yyyy" }}</p>
            </div>
            <div class="d-flex align-items-center" style="width: 30%">
              <p class="text-desc m-0 font-weight-bold color-green">{{cancellation_policy?.currency}} {{ cancellation_policy.amount | number : "1.2-2" }}</p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <!---------------  COLLAPSABLE - IMAGENES ---------------------->


      <mat-expansion-panel class="expansion-panel-images" id="last-ac" [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> IMAGENES </mat-panel-title>
          <mat-panel-description class="m-0">
            &nbsp;
            <mat-icon x-small class="white-icon">photo_camera</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class=" navbar navbar-expand-xg navbar-expand-lg navbar-expand-md align-items-center top-content py-5">
          <div   class="col-xg-12 col-lg-12 col-md-12 col-xs-12 carousel-modal-hotel"  style="background-color: #fff" style="border-radius: 6px;">
            <owl-carousel-o [options]="customOptions" class="shortcuts">
              <ng-container *ngFor="let img of roomSelect.images" >
                <ng-template carouselSlide [width]="120">
                  <img style="height: 365px; border-radius: 6px; width: -webkit-fill-available"  [src]="img.image" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';" alt="">
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>

      </mat-expansion-panel>

    </mat-accordion>

  </div>
</div>
