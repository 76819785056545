<!-- <div class="filter"></div> -->
<div class="d-flex flex-column-reverse">
  <div class="d-flex flex-column bg-white" style="position: relative;overflow: hidden;">
    <div class="row d-flex justify-content-center">
      <div class="col-12 d-flex justify-content-around">
      </div>
    </div>
    <br>
    <div class="loader mx-auto" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"> Buscando {{ (optionsTabName == '' ? (tabNameOrig || '').toUpperCase() : (optionsTabName || '').toLowerCase()) }} en
    </div>
    <div class="d-flex flex-column my-3">
      <div class="d-flex flex-row justify-content-center align-items-end mb-2">
        <!-- <p class="destination ml-2 m-0">Miami</p> -->
        <p class="destination ml-2 m-0">{{ formData && formData.destination ? formData.destination.cityName : '' }} </p>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-end mb-2">
      <!-- <p class="date ml-2 m-0">BUSCAMOS EL MEJOR PRECIO EN {{ data? data.city : '' }} DEL {{ data? data.arrivalDate : '' }} AL
          {{ data? data.departureDate : '' }}</p> -->
          <!-- {{ formData && formData.destination ? formData.destination.cityName : '' }} -->
          <p class="date ml-2 m-0"><span class="text-uppercase"></span>Desde
            <span style="font-weight: bold; font-size: 12px;">
              {{ data.arrivalDate || formData.dateStarEnd.start | date:'dd/MM/yyyy' }},
            </span>
            Hasta
            <span style="font-weight: bold; font-size: 12px;">
              {{data.departureDate || formData?.dateStarEnd?.end | date:'dd/MM/yyyy'}}
            </span>
          </p>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-end mb-2" *ngIf="data.categoriesId[0] == 1" >
        <p class="detail ml-2 m-0">
           {{formData.destination.city_name }} ({{formData.destination.country_iata_code}}) </p>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-end mb-2" *ngIf="data.categoriesId[0] == 1" >
        <p class="detail ml-2 m-0">
            Habitaciones {{data? data.qtyProduct : '' }} | Adultos {{ adults  }} | Niños {{ children }} </p>
      </div>

      <!-- Tours -->
      <div class="d-flex flex-column justify-content-center" *ngIf="data.categoriesId[0] == 6" >
        <p class="detail ml-2 m-0">
          {{formData.destination.city_name }} ({{formData.destination.country_iata_code}})
        </p>
      </div>

      <!-- Rentacar -->
      <div class="d-flex flex-column justify-content-center" *ngIf="data.categoriesId[0] == 2" >
        <span style="font-weight: bold; font-size: 12px;" class="detail ml-2 m-0">
            Lugar de recogida
        </span>
        <p class="detail ml-2 m-0">
          {{formData.origin.city_name }} ({{formData.origin.country_iata_code}})
        </p>
      </div>
      <div class="d-flex flex-column justify-content-center" *ngIf="data.categoriesId[0] == 2" >
        <p class="detail ml-2 m-0">
          {{formData.hourOrigin}} - {{formData.hourDestination}}
        </p>
      </div>
      
    </div>   
  </div>

  <div>
    <span class="carousel-modal-loading">
      <owl-carousel-o [options]="customOptionsModalLoading" #owlElement class="shortcuts">
        <ng-container *ngFor="let slide of imgZonesArr" >
          <ng-template carouselSlide [width]="500">
            <img style="width: 500px; height: 320px; border-top-left-radius: 6px;border-top-right-radius: 6px;" [src]="slide" alt="">
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </span>
  </div>
</div>
