import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserModule } from '@angular/platform-browser';
//import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

//mapa
import { FeatureCollection, Service } from './service';
//import { DxVectorMapModule } from 'devextreme-angular';
import { OrderByPipe } from './dashboard.pipes';
// import { ChartsModule } from 'ng2-charts';



@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    DashboardRoutingModule,
    //NgxChartsModule,

    //BsDropdownModule,
    // ChartsModule
    //DxVectorMapModule,
  ],
  declarations: [
    DashboardComponent,
    OrderByPipe
  ],
  exports: [
    DashboardComponent
  ],
  providers: [FeatureCollection, Service]
})
export class DashboardModule { }
