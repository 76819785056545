 <div class="d-flex  justify-content-center search-box" style="z-index: 1;" id="search-box"
  [ngStyle]="{'background-image': 'url(&quot;' + routeUploadFile+sectionSearchBox?.background_image + '&quot;)'}">

  <div class="mt-4 col-xg-10 col-lg-10 col-md-10 col-xs-12 " id="menu-principal"
  [ngStyle]="{'background-color': sectionSearchBox.background_box}">
      <div class="tabss flex-row col-xg-12 col-lg-12 col-md-12 col-xs-12  justify-content-center">
          <tabset id="search" style="width: 100%; ">
            <!--**********************************
              *-->
              <!--***********Search Hotels***********-->
              <!--***********************************-->
              <tab (select)="selectTab( 'HOTELS') " [active]="tab==='HOTELS' ">
                <ng-template tabHeading >
                  <a class="hotel" (click)="changeActiveBtn('hotels')" >
                    <span class="circle-icon img-fluid mx-auto d-block"  >
                      <svg-icon src="../../../assets/img/app/categories-search-box/ima_hotel.svg" [ngClass]="{'active': activeBtn === 'hotels'}"
                        [ngStyle]="{'fill': activeBtn === 'hotels' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                        style="margin-left: 8px;"></svg-icon>
                    </span>
                      <span><h5 class="title-categories text-uppercase">ALOJAMIENTO</h5></span>
                      </a>
                  </ng-template>
                  <!-- *ngIf="permissionsMap['booking.itinerarios.buscar']" -->
                  <ng-template [ngIf]="tab==='HOTELS' " >
                    <usb-hotels [_close]="_close " (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event) " (origin)="_onOrigin($event) ">
                    </usb-hotels>
                  </ng-template>
                </tab>
                <!--*********Search Activities*********-->
              <!--***********************************-->
              <tab (select)="selectTab( 'TOURS') " [active]="tab==='TOURS' ">
                <!-- (select)="selectTab( 'TOURS') " -->
                <ng-template tabHeading >
                    <a class="tour" (click)="changeActiveBtn('activities')">
                      <span class="circle-icon img-fluid mx-auto d-block"  >
                        <!-- <svg-icon src="../../../assets/img/app/categories-search-box/road-map.svg" style="fill: white;"></svg-icon> -->
                        <svg-icon src="../../../assets/img/app/categories-search-box/road-map.svg" [ngClass]="{'active': activeBtn === 'activities'}"
                        [ngStyle]="{'fill': activeBtn === 'activities' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                        ></svg-icon>
                      </span>
                        <span><h5 class="title-categories text-uppercase">TOURS</h5></span>
                        <!-- <span><h5 class="title-categories text-uppercase">PROXIMAMENTE</h5></span> -->
                    </a>
                </ng-template>
                <ng-template [ngIf]="tab==='TOURS' ">
                    <usb-activities [_close]="_close " [tabName]="tab" (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event) " (origin)="_onOrigin($event) ">
                    </usb-activities>
                </ng-template>
            </tab>
             <!--***********************************-->
                <!--********Search for transfers*******-->
                <!--***********************************-->
                <tab (select)="selectTab( 'TRANSFERS') " [active]="tab==='TRANSFERS' " class="flex-fill">
                  <!-- (select)="selectTab( 'TRANSFERS') " -->
                  <ng-template tabHeading>
                      <a class="bus" (click)="changeActiveBtn('translate')">
                        <span class=" circle-icon img-fluid mx-auto d-block">
                          <svg-icon src="../../../assets/img/app/categories-search-box/bus.svg" [ngClass]="{'active': activeBtn === 'translate'}"
                          [ngStyle]="{'fill': activeBtn === 'translate' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                          ></svg-icon></span>
                          <span><h5 class="title-categories text-uppercase">TRASLADO</h5></span>
                      </a>
                  </ng-template>
                  <ng-template [ngIf]="tab==='TRANSFERS' ">
                      <usb-transports [_close]="_close " (modal)="openModal($event) " (origin)="_onOrigin($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event)" [getClientsSettingAppBooking]="getClientsSettingAppBooking">
                      </usb-transports>
                  </ng-template>
              </tab>
                <!--***********************************-->
                <!--******Multi-destination search*****-->
                <!--***********************************-->
                <tab id="fly" tab (select)="selectTab('MULTIDESTINO')" [active]="tab === 'MULTIDESTINO'" [disabled]="true">
                  <!-- (select)="selectTab('MULTIDESTINO')" -->
                    <ng-template tabHeading >
                        <a class="destinations">
                          <span class="circle-icon img-fluid mx-auto d-block">
                            <svg-icon src="../../../assets/img/app/categories-search-box/worldwide.svg" [ngClass]="{'active': activeBtn === 'multi-destination'}"
                            [ngStyle]="{'fill': activeBtn === 'multi-destination' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                            (click)="changeActiveBtn('multi-destination')" style="margin-top: -4px; margin-left: 3px;"></svg-icon></span>
                            <span><h5 class="title-categories text-uppercase">MULTIDESTINO</h5></span>
                        </a>
                    </ng-template>
                    <ng-template [ngIf]="tab==='MULTIDESTINO' ">
                        <usb-multi-destination [_close]="_close " (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event) " (origin)="_onOrigin($event) ">
                        </usb-multi-destination>
                    </ng-template>
                </tab>

              <!--***********************************-->
              <!--***********************************-->
              <!--***********************************-->
              <!--********Search for P THEMES*******-->
              <!--***********************************-->
               <tab (select)="selectTab( 'PTHEMES') " [active]="tab==='PTHEMES' " class="flex-fill" [disabled]="true">
                <!-- (select)="selectTab( 'PTHEMES') " -->
                  <ng-template tabHeading>
                      <a class="pthemes">
                        <span class=" circle-icon img-fluid mx-auto d-block">
                          <svg-icon src="../../../assets/img/app/categories-search-box/combi-ticket.svg" [ngClass]="{'active': activeBtn === 'tickets'}"
                          [ngStyle]="{'fill': activeBtn === 'tickets' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                          (click)="changeActiveBtn('tickets')"
                         ></svg-icon></span>
                         <span><h5 class="title-categories text-uppercase">TICKETS</h5></span>
                      </a>
                  </ng-template>
                  <ng-template [ngIf]="tab==='PTHEMES' ">
                      <usb-pthemes [_close]="_close " (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event) " (origin)="_onOrigin($event) ">
                      </usb-pthemes>
                  </ng-template>
              </tab>
              <!--*************Search Car************-->
              <!--***********************************-->
              <tab (select)="selectTab( 'CAR') " [active]="tab==='CAR' ">
                <!-- (select)="selectTab( 'CAR') "  -->
                  <ng-template tabHeading >
                      <a class="cars" (click)="changeActiveBtn('rent_car')">
                        <span class="circle-icon img-fluid mx-auto d-block">
                          <svg-icon src="../../../assets/img/app/categories-search-box/car-compact.svg" [ngClass]="{'active': activeBtn === 'rent_car'}"
                          [ngStyle]="{'fill': activeBtn === 'rent_car' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                          ></svg-icon></span>
                          <span><h5 class="title-categories text-uppercase">RENT A CAR</h5></span>
                      </a>
                  </ng-template>
                  <ng-template [ngIf]="tab==='CAR' ">
                      <usb-rentcars (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event) " (origin)="_onOrigin($event) "></usb-rentcars>
                  </ng-template>
              </tab>

              <!--***********************************-->
              <!--********Search for packages********-->
              <!--***********************************-->
              <tab (select)="selectTab( 'PACKAGES') " [active]="tab==='PACKAGES' " class="flex-fill" [disabled]="true">
                <!-- (select)="selectTab( 'PACKAGES') " -->
                  <ng-template tabHeading >
                      <a class="packs">
                        <span class=" circle-icon img-fluid mx-auto d-block">
                          <svg-icon src="../../../assets/img/app/categories-search-box/maleta.svg" [ngClass]="{'active': activeBtn === 'packs'}"
                          [ngStyle]="{'fill': activeBtn === 'packs' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                          (click)="changeActiveBtn('packs')"></svg-icon></span>
                          <span><h5 class="title-categories text-uppercase">PAQUETES</h5></span>
                      </a>
                  </ng-template>
                  <ng-template [ngIf]="tab==='PACKAGES' ">
                      <usb-packages [_close]="_close " (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (origin)="_onOrigin($event) " (isLoading)="_onLoading($event) ">
                      </usb-packages>
                  </ng-template>
              </tab>
              <!-- ***********************************
              <!--********Search for Disney********-->
              <!--***********************************-->
              <!-- <tab (select)="selectTab( 'DISNEY') " [active]="tab==='DISNEY' ">
                  <ng-template tabHeading>
                      <a class="disney">
                          <i class="icon-disneys " aria-hidden="true "></i>
                          <span>
                            DISNEY
                          </span>
                      </a>
                  </ng-template>
                  <ng-template [ngIf]="tab==='DISNEY' ">
                      <usb-disney [_close]="_close " (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (origin)="_onOrigin($event) " (isLoading)="_onLoading($event) ">
                      </usb-disney>
                  </ng-template>
              </tab> -->

              <!--***********************************-->
              <!--********Search for INSURANCE*******-->
              <!--***********************************-->
              <tab (select)="selectTab( 'INSURANCE') " [active]="tab==='INSURANCE' " class="flex-fill" [disabled]="true">
                <!-- (select)="selectTab( 'INSURANCE') " -->
                  <ng-template tabHeading >
                      <a class="seguro">
                        <span class=" circle-icon img-fluid mx-auto d-block">
                          <svg-icon src="../../../assets/img/app/categories-search-box/icon-seguro.svg"  [ngClass]="{'active': activeBtn === 'insurance'}"
                          [ngStyle]="{'fill': activeBtn === 'insurance' ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
                          (click)="changeActiveBtn('insurance')"
                        ></svg-icon></span>
                          <span><h5 class="title-categories text-uppercase">ASISTENCIA</h5></span>
                      </a>
                  </ng-template>
                  <ng-template [ngIf]="tab==='INSURANCE' ">
                      <usb-insurance [_close]="_close " (modal)="openModal($event) " (payload)="_onPayload($event) " (results)="_onResults($event) " (isLoading)="_onLoading($event) " (origin)="_onOrigin($event) ">
                      </usb-insurance>
                  </ng-template>
              </tab>
          </tabset>
      </div>
  </div>
<br>
  <div class="separator-section"></div>
</div>
<div class="corte "></div>
