import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LimitToDirective } from './search-box/limit-to.directive';

// Ngx-boostrap
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NguiAutoCompleteComponent, NguiAutoCompleteModule } from '@ngui/auto-complete';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { QuoteService } from './quote.service';
import { ResultsSearchService } from './results-search.service';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { CapacitationComponent } from './capacitation/capacitation.component';
import { DestinationSmallComponent } from './destination-small/destination-small.component';
import { DestinationBigComponent } from './destination-big/destination-big.component';
import { NoteComponent } from './capacitation/note/note.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ActivitiesComponent } from './search-box/categories/activities/activities.component';
import { HotelsComponent } from './search-box/categories/hotels/hotels.component';
import { MultiDestinationComponent } from './search-box/categories/multi-destination/multi-destination.component';
import { CruiserComponent } from './search-box/categories/cruiser/cruiser.component';
import { RentcarsComponent } from './search-box/categories/rentcars/rentcars.component';
import { TransportsComponent } from './search-box/categories/transports/transports.component';
import { PackagesComponent } from './search-box/categories/packages/packages.component';
import { DisneyComponent } from './search-box/categories/disney/disney.component';
import { PthemesComponent } from './search-box/categories/pthemes/pthemes.component';
import { InsuranceComponent } from './search-box/categories/insurance/insurance.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatProgressSpinnerModule} from '@angular/material';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { ArticleModule } from './article/article.module';
import { ModalLoadingComponent } from 'app/shared/modal-loading/modal-loading.component';
import { FilterPipe } from 'ngx-filter-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap';


defineLocale('es', esLocale);

@NgModule({
  entryComponents:[
    NguiAutoCompleteComponent,
    ModalLoadingComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    NguiAutoCompleteModule,
    NgSelectModule,
    TimepickerModule,
    NgxSpinnerModule,
    NgxDaterangepickerMd,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    CarouselModule,
    NgxPaginationModule,
    ArticleModule,
    SharedModule
  ],
  declarations: [
    HomeComponent,
    ActivitiesComponent,
    ShortcutsComponent,
    CapacitationComponent,
    DestinationSmallComponent,
    DestinationBigComponent,
    NoteComponent,
    SearchBoxComponent,
    HotelsComponent,
    MultiDestinationComponent,
    LimitToDirective,
    CruiserComponent,
    RentcarsComponent,
    TransportsComponent,
    PackagesComponent,
    DisneyComponent,
    PthemesComponent,
    InsuranceComponent,

  ],
  providers: [
    QuoteService,
    ResultsSearchService,
    FilterPipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }

  ]
})
export class HomeModule { }
