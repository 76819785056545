<div class="square-main p-4 pt-2"
    [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">

  <div *ngIf="type === 'payment'" class="row">
    <div  class="col-6">
      <p class="title-reumen font-poppins">RESUMEN DE TRANSACCION</p>
      <p class="total-resumen font-poppins">TARIFA NETA: {{currencyItinerary}} {{totalPrice | number:'1.2-2'}}</p>

      <!-- icons -->
      <div class="d-flex flex-row justify-content-start align-items-center mt-5">
        <div class="d-flex ml-2">

          <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('HOTELS')">
            <i class="icons-hotel"></i>
            <span *ngIf="verifyQuantityCategory('HOTELS') > 0">{{verifyQuantityCategory('HOTELS')}}</span>
          </div>

          <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('TOURS')">
            <i class="icons-adventure"></i>
            <span *ngIf="verifyQuantityCategory('TOURS') > 0">{{verifyQuantityCategory('TOURS')}}</span>
          </div>

          <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('TRANSPORTS')">
            <i class="icons-transporte"></i>
            <span *ngIf="verifyQuantityCategory('TRANSPORTS') > 0">{{verifyQuantityCategory('TRANSPORTS')}}</span>
          </div>

          <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('RENT-A-CAR')">
            <i class="icons-car"></i>
            <span *ngIf="verifyQuantityCategory('RENT-A-CAR') > 0">{{verifyQuantityCategory('RENT-A-CAR')}}</span>
          </div>

          <div class="items-select mr-2" *ngIf="verifyCategory('PTHEMES')">
            <i class="icon-small icon-pthemes iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
            <span *ngIf="verifyQuantityCategory('PTHEMES') > 0">{{verifyQuantityCategory('PTHEMES')}}</span>
          </div>

          <div class="items-select mr-2" *ngIf="verifyCategory('INSURANCE')">
            <i class="icon-small icon-seguro iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
            <span *ngIf="verifyQuantityCategory('INSURANCE') > 0">{{verifyQuantityCategory('INSURANCE')}}</span>
          </div>

          <div class="items-select mr-2" *ngIf="verifyCategory('CRUISES')">
            <i class="icon-small icon-crucero iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
            <span *ngIf="verifyQuantityCategory('CRUISES') > 0">{{verifyQuantityCategory('CRUISES')}}</span>
          </div>

          <div class="items-select mr-2" *ngIf="verifyCategory('DISNEY')">
            <i class="icon-small icon-disney iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
            <span *ngIf="verifyQuantityCategory('DISNEY') > 0">{{verifyQuantityCategory('DISNEY')}}</span>
          </div>

        </div>
        <!-- <div class="d-flex justify-content-center align-items-center circle-icon mr-2" *ngFor="let item of items">
          <i
            [ngClass]="{'icons-hotels': item.categoryName=='HOTELS','icons-transports': item.categoryName=='TRANSPORTS','icons-cars': item.categoryName=='RENT-A-CAR','icons-adventures': item.categoryName=='TOURS'}"></i>
        </div> -->
      </div>
    </div>

    <div class="col-6 pl-5" style="border-left: solid white 1px;">

      <div class="row">

        <div class="col-12">
          <p class="title-resumen font-poppins">Detalle pago file</p>
        </div>

        <div class="d-flex flex-column pl-2 pr-0">

          <div class="d-flex flex-row align-items-center mt-2" *ngIf="amountBLickoinsItineraryPay > 0">
            <div class="d-flex flex-row align-items-center" style="width: 230px;">
              <div class="d-flex mr-2">
                <i class="icons-points-white"></i>
              </div>
              <p class="font-poppins detalle-items">PAGO POR CRÉDITO BLICKOINS</p>
            </div>
            <div class="d-flex">
              <p class="font-poppins valor-items">
                {{currencyItinerary}} {{amountBLickoinsItineraryPay | number:'1.2-2'}}</p>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2" *ngIf="amountCardItineraryPay > 0">
            <div class="d-flex flex-row align-items-center" style="width: 230px;">
              <div class="d-flex mr-2">
                <i class="icons-credit-car-white"></i>
              </div>
              <p class="font-poppins detalle-items">PAGO POR TARJETA</p>
            </div>
            <div class="d-flex">
              <p class="font-poppins valor-items">{{currencyItinerary}} {{amountCardItineraryPay | number:'1.2-2'}}
              </p>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2" *ngIf="amountDepositItineraryPay > 0">
            <div class="d-flex flex-row align-items-center" style="width: 230px;">
              <div class="d-flex mr-2">
                <i class="icons-profile-white"></i>
              </div>
              <p class="font-poppins detalle-items">PAGO POR DEPOSITO EN GARANTÍA</p>
            </div>
            <div class="d-flex">
              <p class="font-poppins valor-items">
                {{currencyItinerary}} {{amountDepositItineraryPay | number:'1.2-2'}}</p>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mt-2" *ngIf="amountTransferItineraryPay > 0">
            <div class="d-flex flex-row align-items-center" style="width: 230px;">
              <div class="d-flex mr-2">
                <i class="icons-transfer-white"></i>
              </div>
              <p class="font-poppins detalle-items">PAGO POR TRANSFERENCIA</p>
            </div>
            <div class="d-flex">
              <p class="font-poppins valor-items">
                {{currencyItinerary}} {{amountTransferItineraryPay | number:'1.2-2'}}</p>
            </div>
          </div>
          <!-- <div class="d-flex flex-row align-items-center mt-2" *ngIf="verifyAmount(5)">
            <div class="d-flex flex-row align-items-center" style="width: 230px;">
              <div class="d-flex mr-2">
                <i class="icons-credits-white"></i>
              </div>
              <p class="font-poppins detalle-items">PAGO POR CREDITOS</p>
            </div>
            <div class="d-flex">
              <p class="font-poppins valor-items">
                ${{verifyAmount(5) | number:'1.2-2'}}</p>
            </div>
          </div> -->

          <div class="d-flex">
            <p style="font-family: 'Poppins', sans-serif;font-size: 11px; font-weight: 500; padding-right: 2rem; padding-left: .4rem;margin-top: .7em;">Documento recibido. En breve administración estará validando la documentación.</p>
          </div>
        </div>
      </div>

    </div>
  </div>


  <div *ngIf="type === 'request-billed'"  class="row">
    <div class="col-6">
      <p class="title-reumen font-poppins">RESUMEN DE SOLICITUD DE FACTURACIÓN</p>
      <p class="total-resumen font-poppins">TARIFA NETA: {{currencyItinerary}} {{totalPrice | number:'1.2-2'}}</p>
    </div>
    <div class="col-6 pl-5" style="border-left: solid white 1px;">

        <div class="row">
          <div class="col-12">
            <p class="title-resumen font-poppins">Detalle solicitud</p>
          </div>
          <div class="d-flex flex-column pl-2 pr-0">
            <div class="d-flex">
              <p style="font-family: 'Poppins', sans-serif;font-size: 11px; font-weight: 500; padding-right: 2rem; padding-left: .4rem;margin-top: .7em;">
                Documentación recibida. A continuación podrá descargar la facturación solicitada</p>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<div class="row mt-3">

  <div class="col-12  d-flex justify-content-end">
    <!-- <button class="btn font-poppins btn-yellow mr-2" type="button">DESCARGAR
      OPERACIÓN
    </button> -->
    <button class="btn font-poppins btn-red mr-2" type="button" (click)="allVouchersReservationPdf()" *ngIf="downloadAllVoucher">TODOS LOS VOUCHERS</button>
    <!-- <button class="btn font-poppins btn-red" type="button">TODAS FACTURAS</button> -->
  </div>
</div>

<p *ngIf="type === 'request-billed'"> En desarrollo vista y descarga de factura </p>

<div class="d-flex flex-row mt-3 card" *ngFor="let item of items; index as i">
  <div class="d-flex flex-column justify-content-center align-items-center box-check"
        [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
    <span class="mb-0">
      <label class="custom-radio-checkbox mb-0">
        <input class="custom-radio-checkbox__input" type="checkbox" [name]="i" (change)="handleItem(item)" checked>
        <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
      </label>
    </span>
  </div>
  <div class="d-flex flex-row justify-content-start align-items-center p-3 description-item">
    <i class="mr-3"
      [ngClass]="{'icons-hotel': item.categoryName=='HOTELS','icons-transporte': item.categoryName=='TRANSPORTS','icons-car': item.categoryName=='RENT-A-CAR','icons-adventure': item.categoryName=='TOURS'}"></i>
    <div class="d-flex flex-column">
      <p class="categoria-items font-poppins">{{ item.categoryName }}</p>
      <p class="title-items font-poppins m-0">{{stingUpperCase(item.title)}}</p>
    </div>
    <!-- <p class="font-weight-bold font-poppins m-0" style="font-size: 13px;">{{item.destination.iata_code}} - US</p> -->
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center p-3 ml-auto" style="margin-right: -55px;">
    <p class="importe-items font-poppins">TARIFA NETA</p>
    <p class="importe-items font-poppins">{{currencyItinerary}} {{item.netPriceBooking | number:'1.2-2'}}</p>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center p-3 ml-auto" style="margin-right:-18px;">
    <span class="indicadores ind-pap mr-1">
      PAP
    </span>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center p-3">
   <!--- <i class="icons-pay-pdf mr-3"></i> -->
   <i class="icons-vouchers" (click)="vouchersReservationPdf(item.id)"></i>
  </div>

  <div class="usb-itinerary-pdf">
    <usb-transaction-master-voucher [item]="item" [totalPrice]="totalPrice" [itinerary]="itinerary" [i]="i">
    </usb-transaction-master-voucher>
  </div>

</div>


<div class="usb-itinerary-pdf">
  <usb-transaction-master [items]="items" [totalPrice]="totalPrice" [paymentMethods]="paymentMethods"
    [itinerary]="itinerary">
  </usb-transaction-master>
</div>


<ng-container *ngIf="selectedItems.length > 0">
  <div *ngFor="let item of selectedItems; index as i">
    <div id="vouchersReservation_{{i}}" class="usb-itinerary-pdf" style="padding: 20px;">
      <usb-transaction-master-selected [item]="item" [totalPrice]="totalPrice" [paymentMethods]="paymentMethods" [itinerary]="itinerary">
      </usb-transaction-master-selected>
    </div>
  </div>
</ng-container>

