<div *ngIf="(_actualItinerary !== undefined) || this.userService.itineraryActive.length > 0" class="nav p-0 m-0 col-12 align-items-center mt-0">
    <div class="flex-xg-row flex-lg-row flex-md-column flex-sm-column m-0 p-0 font-dark justify-content-between align-items-center card" id="band">
          <!-- <div class="justify-content-between flex-row">
        <button type="button" class="btn btn-sm btn-home my-1" data-placement="bottom" title="Back to Main"
          (click)="_backToMain()">
          <i class="icon-edit icon icon-home" aria-hidden="true"></i>
        </button>
      </div> -->

     <!--  <usb-pax-list-modal class="justify-content-center align-items-center d-flex flex-row px-3" style="max-width: 500px !important; border-right: 1px solid #dfdfe1; height: 41px !important;" *ngIf="_actualItinerary" [invoke]="1" [actualItinerary]="_actualItinerary"></usb-pax-list-modal>
 -->
      <usb-flight-modal class="align-items-center d-flex flex-row" *ngIf="_actualItinerary" [invoke]="1" [_actualItinerary]="_actualItinerary"></usb-flight-modal>

    </div>
</div>
