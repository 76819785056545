<div class="t-content" id="transport">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <div class="form-row pt-3 pb-5 position-relative">
        <div class="form-group align-items-center m-0 py-0 px-1 col-lg-5 col-xg-5 col-md-12 col-sm-12 scroll position-relative">
          <input type="text"
                  name="destino"
                  matInput
                  maxlength="50"
                  class="form-control input-search-box"
                  style="background-color: white;"
                  #destination
                  formControlName='firstOrigin'
                  placeholder="Origen (Hotel, Aeropuerto, Ciudad)"
                  [matAutocomplete]="firstOrigin"
                  [value]="displayFn"

          >
          <mat-autocomplete class="mt-2" #firstOrigin="matAutocomplete" [displayWith]="displayFn"
                            (optionSelected)="getLocationsAll($event.option.value, 'origin')">
                              <mat-option *ngFor="let name of destinationOptions | async" [value]="name">
                                <div class="row align-items-center" style="padding-top: 13px;">
                                  <div class="col-2">
                                    <img class="imag-redo" [src]="name.pic_mini">
                                  </div>
                                  <div class="col-10" style="line-height: 1.5;padding-left: 15px;">

                                      <p class="mb-0" style="text-wrap: auto;" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}" >
                                        {{name.hotel_name ? name.hotel_name : name.name}}
                                        <span class="ml-1">
                                          {{ getDescriptionLocation(name, 'origin') }}
                                        </span>

                                    </p>
                                  </div>
                                </div>
              </mat-option>
          </mat-autocomplete>
          <ng-container *ngIf="_form.get('firstOrigin').touched && mesaggeErrorVisibleOrigin">
            <p class="errorLocation">Debe seleccionar un lugar de origen</p>
          </ng-container>
        </div>

        <!-- Botón de intercambio -->
         <div class="container-button-transfer-destination">
            <div class="position-relative group-button-transfer-destination">

              <i
                role="button"
                tabindex="0"
                class="bi bi-circle-fill circle-btn"
                [ngStyle]="{
                            'text-shadow': isHovered ? '0px 0px 5px ' + getClientsSettingAppBooking?.clients_setting_global?.color_main_1 : ''
                              }"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
                (click)="swapLocations()"
              ></i>
              <i
                  role="button"
                  tabindex="0"
                  class="bi bi-arrow-left-right swap-btn"
                  (click)="swapLocations()"
                  [ngStyle]="{
                              'color': isHovered ?  getClientsSettingAppBooking?.clients_setting_global?.color_main_1 : ''
                              }"
                  (mouseenter)="isHovered = true"
                  (mouseleave)="isHovered = false"
              ></i>
            </div>
         </div>



        <div class="form-group align-items-center m-0 py-0 px-1 col-lg-5 col-xg-5 col-md-12 col-sm-12 scroll position-relative">
          <input type="text"
                  name="destino"
                  matInput
                  maxlength="20"
                  class="form-control input-search-box"
                  style="background-color: white;"
                  #destination
                  formControlName='firstDestination'
                  placeholder="Destino (Hotel, Aeropuerto, Ciudad)"
                  [matAutocomplete]="firstDestination"

          >
          <mat-autocomplete class="mt-2" #firstDestination="matAutocomplete" [displayWith]="displayFn"
                          (optionSelected)="getLocationsAll($event.option.value, 'destination')">
                            <mat-option *ngFor="let name of destinationOptions_ | async" [value]="name">
                              <div class="row align-items-center" style="padding-top: 13px;">
                                <div class="col-2">
                                  <img class="imag-redo" [src]="name.pic_mini">
                                </div>
                                <div class="col-10" style="line-height: 1.5;padding-left: 15px;">

                                    <p class="mb-0" style="text-wrap: auto;" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}" >
                                      {{name.hotel_name ? name.hotel_name : name.name}}
                                      <span class="ml-1">
                                        {{ getDescriptionLocation(name, 'destination') }}
                                      </span>

                                  </p>
                                </div>
                              </div>
                            </mat-option>
            </mat-autocomplete>
            <ng-container  *ngIf="_form.get('firstDestination').touched && mesaggeErrorVisibleDestination">
              <p class="errorLocation"> Debe seleccionar un lugar de destino</p>
            </ng-container>
        </div>

        <div class="form-group m-0 px-1 pb-1 pt-0 col-xg-4 col-lg-2 col-md-12 col-sm-12">

          <button
            type="button"
            class="form-control btn-usb-rp d-flex align-items-center justify-content-between"
            (click)="_openModal()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="false"
          >
            <p class="p-0 m-0 align-self-center align-middle font-poppins text-passenger-input"> {{ passengers }}</p>

            <span [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}" class="arrow-sort fa fa-sort"></span>
          </button>
          <span class="help-block text-danger" *ngIf="_close && isCollapsed && _form.value['firstqtyAdults'] == 0"
            >Al menos selecciona 1 adulto.</span
          >
          <div (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close" (click)="_over()">
            <div class="row">
              <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
                <div class="card">
                  <div
                  class="d-flex flex-row col-12 align-items-center justify-content-between p-2">
                  <div class="d-flex flex-row align-items-start justify-content-between p-0" style="width: 100%;">
                    <div class="d-flex flex-column p-0 m-0 mr-2 w-100">
                      <label for="adults">Adultos</label>
                      <select formControlName="firstqtyAdults" class="custom-select form-control-sm " style="height: auto;" id="adults" name="adults" [value]="1">
                        <ng-container *ngFor="let qty of  _maxPersons; let i = index">
                          <ng-container *ngIf="i !== 0">
                            <option [value]="qty">
                                  {{qty}}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                    <div class="d-flex flex-column p-0 m-0 mr-2 w-100 ">
                      <div class="container-label d-flex">
                        <label for="childs">Niños</label>
                        <div class="info-tool d-flex flex-column" style="position: right;">i
                          <span class="tooltip-info" >Los bebes (0 - 2 años sin cumplir) deben indicarse como niños</span>
                        </div>
                      </div>
                      <select formControlName="firstqtyChilds" class="custom-select form-control-sm" style="height: auto;" id="childs"
                      (ngModelChange)="_addChild('firstchildsage', 'firstqtyChilds')" name="childs" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <!-- Children age inputs -->
                    <div id="childrenAges" class="d-flex flex-column" *ngIf="formArray(_form, 'firstchildsage').length > 0">
                      <label for="age" style="text-wrap: nowrap">Edad niños</label>
                      <div class="d-flex flex-row p-0 m-0">
                        <div formArrayName="firstchildsage" *ngFor="let age of formArray(_form, 'firstchildsage'); let j = index" class="select-ages">
                          <div [formGroupName]="j" class="p-0">
                            <input formControlName="age" min=0 max=17 limit-to="14" class="form-control mr-1 p-3 input-age" style=" font-size: 1rem !important;height: 38px !important;"
                              value-formatter="0" placeholder="0" name="age" type="number">
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let age of formArray(_form, 'firstchildsage'); let j = index;">
                        <span class="help-block text-danger d-flex w-100 text-nowrap"
                          *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>La edad
                          de los niños {{j+1}} es incorrecta</span>
                      </div>
                    </div>
                  </div>
                </div>

                  <div class="d-flex justify-content-end flex-row py-2">
                    <div class="p-2 justify-content-end">
                      <button
                        (click)="_confirm()"
                        [attr.aria-expanded]="!isCollapsed"
                        aria-controls="false"
                        class="badge rounded-pill btn-choice-confirm"
                        type="button"
                        [ngStyle]="{'background-color': (isHoverConfirm ? getClientsSettingAppBooking?.clients_setting_global?.color_main_1 : 'white')} "
                      (mouseenter)="isHoverConfirm = true; updateButtonColorConfirm();"
                      (mouseleave)="isHoverConfirm = false; updateButtonColorConfirm();"
                        [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_1 }"
                      >
                        Confirmar
                      </button>
                    </div>


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>




          <div class="form-group col-xg-3 col-lg-3 col-md-6 col-sm-6 px-1 mx-0">
            <label for="passengers">&nbsp;</label>
            <input
              formControlName="firstArrivalDate"
              type="text"
              class="form-control input-search-box"
              id="departureDate"
              placeholder="Fecha de recogida"
              autocomplete="off"
              bsDatepicker
              [minDate]="minDate"
              [bsConfig]="dpConfig"
              readonly
              [ngStyle]="{':host tr.ng-star-inserted td.active.start-date.available::after': {'background': getClientsSettingAppBooking?.clients_setting_global?.color_main_1},
                          ':host tr.ng-star-inserted td.active.end-date.available::before': {'background': getClientsSettingAppBooking?.clients_setting_global?.color_main_1}}"
            />
            <span
              class="help-block text-danger"
              *ngIf="_form.get('firstArrivalDate').touched && _form.get('firstArrivalDate').hasError('required')"
              >Este campo es obligatorio</span
            >
            <small
              class="error-label"
              *ngIf="
                _form.get('firstArrivalDate').touched &&
                _form.get('secondArrivalDate').value !== null &&
                _form.get('firstArrivalDate').hasError('invalidDate')
              "
            >
              * ¡El check-in debe ser una fecha anterior al check-out!
            </small>
          </div>

          <div class="form-group col-xg-2 col-lg-2 col-md-6 col-sm-6 px-1 mx-0">
            <label for="passengers">&nbsp;</label>
            <input
                class="form-control input-search-box"
                formControlName="firstTime"
                [value]="currentTime"
                type="time">
          </div>

          <div class="form-group col-xg-3 col-lg-3 col-md-6 col-sm-6 px-1 mx-0">
            <label for="passengers">&nbsp;</label>
            <input
              formControlName="secondArrivalDate"
              type="text"
              class="form-control input-search-box"
              id="departureDate"
              placeholder="Fecha de salida"
              autocomplete="off"
              bsDatepicker
              [minDate]="_form.get('firstArrivalDate').value"
              [bsConfig]="dpConfig"
              (bsValueChange)="onDateChange($event)"
            />
            <span *ngIf="_form.get('secondArrivalDate').value === null" class="text-option" style="font-size: 12px !important">(opcional)</span>
            <span *ngIf="_form.get('secondArrivalDate').value !== null"  class="square-class-secondArrival">
              <div class="container-square-secondArrival">
                <i class="bi bi-square square-icon"
                [ngStyle]="{
                  'text-shadow': isHoveredCancelDate ? '0px 0px 5px red' : ''
                    }"
                    (mouseenter)="isHoveredCancelDate = true" (mouseleave)="isHoveredCancelDate = false"
                    tooltip="Eliminar fecha y hora de salida"
                    placement="bottom"
                    (click)="resetSecondArrivalDate()"
                    ></i>
                <i class="bi bi-x x-icon-cancel"
                    (mouseenter)="isHoveredCancelDate = true" (mouseleave)="isHoveredCancelDate = false"
                    tooltip="Eliminar fecha y hora de salida"
                    placement="bottom"
                    (click)="resetSecondArrivalDate()"
                    ></i>
              </div>
            </span>
          </div>


          <div class="form-group col-xg-2 col-lg-2 col-md-6 col-sm-6 px-1 mx-0">
            <label for="passengers">&nbsp;</label>
            <input
                class="form-control input-search-box"
                formControlName="secondTime"
                type="time">
          </div>

          <div class="form-group col-xs-12 col-lg-2 col-md-4 justify-content-start align-middle inp">
            <button
              class="btn btn-transport rounded-pill"
              [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_1 }"
              type="submit"
              [disabled]="!_form.valid"
              data-toggle="modal"
            >
              <i class="{{ _findData ? 'fa fa-spinner fa-spin fa-sm' : '' }}"></i>
              Buscar
            </button>
          </div>


        <!-- Nuevo -->

    </div>


  </form>
</div>
