<div class="row p-0 mt-2 mb-3">
  <div class="col-2">
    <div  class="conteiner-icon bg-gray p-2">
      <img src="/assets/img/app/little-icons/bus-white.svg" class="img-fluid" alt="">
    </div>
  </div>

  <div class="col-10">
    <div class="row">
      <div class="col-12">
        <p class="title font-poppins pl-2"> <!-- {{stingUpperCase(item.title)}}  -->
          {{item.title}}  
        </p>
      </div>
      <div class="col-12">
        <i class="icon-placeholder font-verde pl-2" style="color: #00d397 !important;"></i>
        <span class="font-poppins location-text">Miami Beach - US</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span class="p-0 spaced">
          <p class="pl-2 subtitle font-poppins inter">
           <span *ngIf="item.vehicles[0].maxPax !== null">{{item.vehicles[0].maxPax}} <i class="icon icon-asiento" aria-hidden="true"></i> | </span>
           <span *ngIf="item.vehicles[0].maxBag !== null">{{item.vehicles[0].maxBag}} <i class="icon icon-bag" aria-hidden="true"></i></span>
          </p>
        </span>
        <span class="p-0 spaced">
          <p class="pl-2 description font-poppins inter">
            <span class="text-uppercase">PickUp In Location:</span><br>
            {{stingUpperCase(item.origin)}} | {{stingUpperCase(arrivalDate | date:'MMM dd, yyyy')}} | {{stingUpperCase(item.pickupTime)}}
          </p>
        </span>
        <span class="p-0 spaced">
          <p class="pl-2 pt-1 description font-poppins inter">
            <span class="text-uppercase">PickUp Out Location:</span><br>
            {{stingUpperCase(item.destination?.city_iata_code)}} | {{stingUpperCase(departureDate | date:'MMM dd, yyyy')}} | {{stingUpperCase(item.dropoffTime)}}
          </p>
        </span>
      </div>
      <div class="col-12" style="margin-top: 10px !important;">
        <span class="p-0 spaced">
          <p class="description font-poppins inter pl-2">
            <span class="d-flex detail-description-car">
              <i class="icon icon-car px" style="font-size: 0.85rem !important;"></i> &nbsp;{{_type=== 1 ? " OT" : " RT"}}&nbsp;&nbsp;&nbsp;
              <i class="icon icon-ai" style="font-size: 0.85rem !important;"></i>&nbsp;AC
            </span> 
          </p>
        </span>
      </div>
      <div class="col-12">
        <p class="pl-2">
          <span class="p-0 description font-poppins">
            <ul class="nav mt-2">
              <li>
                <span style="font-size: 0.8rem;">5</span> &nbsp;<i class="icon icon-safety" aria-hidden="true">&nbsp;&nbsp;</i>&nbsp;&nbsp;&nbsp;&nbsp;
              </li>
              <li>
                <span style="font-size: 0.8rem;">3</span> &nbsp;<i style="margin-top: -12px; margin-left: 3px;" class="icon icon-maleta" aria-hidden="true">&nbsp;&nbsp;</i>&nbsp;&nbsp;&nbsp;
              </li>
              <li>
                <span style="font-size: 0.8rem;">2</span> &nbsp;<i style="margin-top: -16px; margin-left: 3px;" class="icon icon-maleta2" aria-hidden="true">&nbsp;&nbsp;</i>&nbsp;&nbsp;&nbsp;
              </li>
              <li>
                <span style="font-size: 0.8rem;">4</span> &nbsp;<i class="icon icon-car-door" aria-hidden="true">&nbsp;&nbsp;</i>
              </li>
            </ul>
            <i class=" icon icon-adults" aria-hidden="true">&nbsp;&nbsp;</i> 
            {{item.paxAdults}} 
            <i class="icon icon-child" aria-hidden="true">&nbsp;&nbsp;</i> 
            {{item.paxChildren===undefined ? 0 : item.paxChildren}}
          </span>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- <span class="p-0 d-flex flex-column align-content-center">
  <span class="title inter">
    <strong><i class="icon icon-bus">&nbsp;</i>{{item.title}}</strong>
  </span>
  <span class="p-0 spaced">
    <p class="p-0 subtitle inter">
     <span *ngIf="item.vehicles[0].maxPax !== null">{{item.vehicles[0].maxPax}} <i class="icon icon-asiento" aria-hidden="true"></i> | </span>
     <span *ngIf="item.vehicles[0].maxBag !== null">{{item.vehicles[0].maxBag}} <i class="icon icon-bag" aria-hidden="true"></i></span>
     <span class="d-flex detail-description-car">
        <i class="icon icon-ai"></i> AirCondition</span>
    </p>
  </span>
  <span class="p-0 spaced">
    <p class="p-0 description inter">
        <b>PickUp In:</b> {{item.origin}} | {{arrivalDate | date:'MMM dd, yyyy'}} | {{item.pickupTime}}
    </p>
  </span>
  <span class="p-0 spaced">
    <p class="p-0 description inter">
        <b>PickUp Out:</b> {{item.destination?.iata_code}} | {{departureDate | date:'MMM dd, yyyy'}} | {{item.dropoffTime}}
    </p>
  </span>
  <span class="p-0 spaced">
    <p class="p-0 description inter">
      <span class="d-flex detail-description-car">
        <i class="icon icon-car px"> </i> &nbsp;{{_type=== 1 ? " One Trip" : " Round Trip"}}</span> 
    </p>
  </span>
  <span class="p-0 spaced">
    <p class="p-0 description inter">
      <span class="d-flex">
        <i class=" icon icon-multiple-users-silhouette" aria-hidden="true"> </i> {{item.paxAdults+ (item.paxAdults> 1 ? ' Adults' : ' Adult')}} 
        <i class="icon icon-menor" aria-hidden="true"> </i> {{item.paxChildren===undefined ? (0 + ' Children') : (item.paxChildren + ' Children')}}
      </span>
    </p>
  </span>
</span> -->