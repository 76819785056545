<div class="row mt-3">
  <!-- <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;"> 
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | date: 'dd' }}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | date: 'MMM' }}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | date: 'yy' }}</p>
        </div>
        <div class="row mt-2" style="margin-left: 52px !important; margin-top: -53px !important;">
          <div class="icon-line icon-bg-ck-in">
            <i class="icon-transporte"></i>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center py-2">
        <img class="img-fluid rota-horizontal" style="object-fit: contain;" src="{{imgTransfer}}" alt="suggestions">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex justify-content-between py-1">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{stingUpperCase(data.title)}}</p> 
            <div style="margin-top: -5px !important;">
              <span class="sub-title" >
                {{stingUpperCase(data[0]?.payload[0]?.others?.transfers?.transferType)}}
              </span>
            </div>
            <div style="margin-top: 10px !important;">
              <div class="row">
                <div class="col-1" style="margin-top: -5px">
                  <span class="p-0 spaced">
                    <span>
                      <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                    </span>
                  </span>
                </div>
                <div class="col" style="margin-left: -15px;">
                  <span class="p-0 m-0  spaced">
                    <!-- hs -->
                    <p style="line-height: 1; font-size: 12px; font-weight: 500;"><span style="font-size: 11px !important;font-weight: bolder;">Lugar de recogida</span>&nbsp; {{data.location === 'USA - ' ? data.location + data['origin'] : (data.location !== undefined) && (data.location !== null) && (data.location !== '') ? data.location.split('/')[0] : data['origin']}} | {{data['arrivalDate'] | date:'MMM dd, yyyy'}} | {{data.pickupTime}}</p> 
                    <p style="line-height: 1; margin-top: -12px; font-size: 12px; font-weight: 500;"><span style="font-size: 11px !important; font-weight: bolder;">Lugar de entrega</span>&nbsp; {{data.location === 'USA - ' ? data.location + data['destination'] : (data.location !== undefined) && (data.location !== null) && (data.location !== '') ? data.location.split('/')[1] : data['destination']}} | {{data['departureDate'] | date:'MMM dd, yyyy'}} | {{data.dropoffTime}}</p>
                  </span>
                </div>
              </div>
              <div class="d-flex font-inline flex-row">
                <span class="check">Check-in</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="py-0" style="margin-top: 1px; width: 98px !important;">
              <span style="width: 10%" *ngIf="data.status_id === 1" class="status product-status-saved">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 2" class="status product-status-reserved">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 3" class="status product-status-locked">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 4" class="status product-status-denied">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 5" class="status product-status-cancelled">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 6" class="status product-status-paid-agency">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 7" class="status product-status-paid-provider">{{data.status}}</span>
              <span style="width: 10%" *ngIf="data.status_id === 8" class="status product-status-completed">{{data.status}}</span>
            </div>
            
            <div class="py-0" style="width: 20px !important;">
              <a type="button" style="cursor: pointer; margin-top: 3px !important;"> <!-- (click)="modalRoom.show()" -->
                <i class="icon-info"></i>
              </a>
              <div>
              </div>
            </div>
            <div class="py-0" style="width: 20px !important; margin-left: 4px; margin-top: 1px !important;">
              <a style="cursor: pointer;">
                <span class="icon-nr font-poppins">NR</span>
              </a>
            </div>
          </div>

        </div>
      </div>

      <div class="col-2 px-0 text-center bg-blue py-2 icon-accion">

        <div class="icons-item text-right" [ngStyle]="{'margin-top': !partialReserve ? '10px' : '7px'}">
          <!-- <span *ngIf="partialReserve===false||partialReserve===undefinid">
            <i class="far fa-check-circle" style="color: #00d397"></i>
          </span> -->
          <span *ngIf="partialReserve===true">
            <label class="custom-radio-checkbox">
              <input class="custom-radio-checkbox__input" type="checkbox" (change)="toPartialReserve($event, data)" ng-checked="data.status_id==3">
              <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            </label>
          </span>
          <span>
            <a style="cursor: pointer; margin-left: 5px;">
              <i class="icon-edit" aria-hidden="true"></i>
            </a>
          </span>
          <span>
            <a style="cursor: pointer;" (click)="removeItem(data)">
              <i class="icon-delete" aria-hidden="true"></i>
            </a>
          </span>
        </div>
        <div class="date-expiration pt-2 text-right" class="padding-right: 10px;" *ngIf="data.expirationDate">
          <span class="font-poppins" style="font-size:11px; font-weight: 400;">
            Expiration Day
          </span>
          <br>
          <span class="font-poppins text-uppercase" style="font-size: 11px; font-weight: bold; color: #00d397;">
            {{ subtractDays(data.expirationDate) | date:'mediumDate' }}
          </span>
        </div>
        <div class="amount pt-1 text-right" style="margin-top: 20px; padding-right: 10px;">
          <span class="title-destiny-start text-nowrap" [ngStyle]="{'font-size': fontPrice(dato?.totalPrice)}">$ {{data?.totalPrice | number:'1.2-2' }}</span>
        </div>
      </div>
    </div> 
  </div>
</div>

<div class="row mt-3">
  <!-- <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;">
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | date: 'dd' }}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | date: 'MMM' }}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | date: 'yy' }}</p>
        </div>
        <div class="row mt-2" style="margin-left: 52px !important; margin-top: -53px !important;">
          <div class="icon-line icon-bg-ck-in">
            <i class="icon-transporte-red"></i>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center py-2">
        <img class="img-fluid rota-horizontal" src="{{imgTransfer}}" alt="suggestions">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex justify-content-between py-1">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{stingUpperCase(data.title)}}</p> 
            <div style="margin-top: -5px !important;">
              <span class="sub-title" >
                {{stingUpperCase(data?.payload?.others?.transfers?.transferType)}}
              </span>
            </div>
            <div style="margin-top: 10px !important;">
              <div class="row">
                <div class="col-1" style="margin-top: -5px">
                  <span class="p-0 spaced">
                    <span>
                      <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                    </span>
                  </span>
                </div>
                <div class="col" style="margin-left: -15px;">
                  <span class="p-0 m-0  spaced">
                    <!-- hs -->
                    <p style="line-height: 1; font-size: 12px; font-weight: 500;"><span style="font-size: 11px !important; font-weight: bolder;">Lugar de Recogidaf</span>&nbsp; {{data.location === 'USA - ' ? data.location + data['origin'] : (data.location !== undefined) && (data.location !== null) && (data.location !== '') ? data.location.split('/')[0] : data['origin']}} | {{data['arrivalDate'] | date:'MMM dd, yyyy'}} | {{data.pickupTime}}</p>
                    <p style="line-height: 1; margin-top: -12px; font-size: 12px; font-weight: 500;"><span style="font-size: 11px !important; font-weight: bolder;">Lugar de Entrega</span>&nbsp; {{data.location === 'USA - ' ? data.location + data['destination'] : (data.location !== undefined) && (data.location !== null) && (data.location !== '') ? data.location.split('/')[1] : data['destination']}} | {{data['departureDate'] | date:'MMM dd, yyyy'}} | {{data.dropoffTime}}</p>
                  </span>
                </div>
              </div>
              <div class="d-flex font-inline flex-row">
                <span class="check" style="color: #ff0041;">Check-Out</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div> 
  </div>
</div>














<!--<div class="width-100 container">
  <div class="row mt-2">
      <div class="col-2 p-0 m-0 text-center">
          <span class="date-itinerary-s">
            {{ formatDate(isCheckIn?data.arrivalDate:data.departureDate) | uppercase }}
          </span>
      </div>
    <div class="col-auto mr-4">
      <span class="circle">
        <i class="icon-small icon-bus iconregular" [class.color-checkout]="!isCheckIn"></i>
      </span>
    </div>
    <div class="col-xg-9 col-lg-9 flex-column px-0 mx-0 d-flex space-items">
    <div class="d-flex ">
      <div class="col-xg-10 col-lg-10 px-0 m-0 flex-column font-descrip">
        <span class="title-destiny-start d-flex align-items-center">{{data.title}} - &nbsp;
          <span class="font-italic">{{data.payload.others.transfers.transferType}}</span>
          <button type="button" (click)="modalRoom.show()" class="btn btn-sm btn-default py-0 mb-1 ml-2">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </button>

          <div>
            <usb-modals bsModal #modalRoom="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
              [modalRoomRef]="modalRoom" [product]="data.product" [productQty]="1" [form]="null"
              [payloadUpdate]="data.payloadUpdate" [view_info]="true" [itineraryItem]="data"
              [origin]="'MULTIDESTINATION'">
            </usb-modals>
          </div> -->
       <!-- </span>
        <span class="p-0 spaced">
          <span>
            <i class="icon-placeholder" aria-hidden="true"></i>
            {{data.location}} 
          </span>
        </span>
        <span class="p-0 spaced d-flex">
          <span>5 Seating | 3 Bags | Diurno </span>
        </span>
        <span class="p-0 spaced d-flex">-->
          <!--<span>-->
            <!--<b> Pickup: </b>Key West Airport Office</span>-->
        <!--</span>-->
        <!--<span>-->
          <!--<span><b>Dropoff: </b>Key West Airport Office</span>-->
        <!--</span>-->
       <!-- <span class="p-0 spaced d-flex">
         <b> Pickup Location: </b>  {{data.location === 'USA - ' ? data.location + data['origin'] : (data.location !== undefined) && (data.location !== null) && (data.location !== '') ? data.location.split('/')[0] : data['origin']}} | {{data['arrivalDate'] | date:'MMM dd, yyyy'}} <!-- dateIni-->
       <!-- </span> 
        <span class="p-0 spaced d-flex"> 
          <b> Pickup Time: </b> {{data.pickupTime}}
        </span>
        <span class="p-0 spaced d-flex">   
          <b> DropOff Location: </b> {{data.location === 'USA - ' ? data.location + data['destination'] : (data.location !== undefined) && (data.location !== null) && (data.location !== '') ? data.location.split('/')[1] : data['destination']}} | {{data['departureDate'] | date:'MMM dd, yyyy'}} <!-- dateEnd-->
       <!-- </span>
        <span class="p-0 spaced d-flex">   
          <b> DropOff Time: </b> {{data.dropoffTime}}
        </span>
      </div>
      <div class="col-2 d-inline m-0 p-0 text-right" *ngIf="isCheckIn">
        <div *ngIf="partialReserve===true" class="pretty p-round p-thick p-bigger p-icon p-smooth">
          <input type="checkbox" (change)="toPartialReserve($event, data)" ng-checked="data.status_id==3"/>
          <div class="state">
              <i class="icon fa fa-check"></i>
              <label></label>
          </div>
        </div>
        <span>
          <i class="icon-pencil"></i>
        </span>
        <span>
            <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(data)">
                <i class="icon-papelera"></i>
            </button>
        </span>

        

      </div>
    </div>
    <div class="d-flex font-inline flex-row">
      <div class="w-100 justify-content-between d-flex flex-row">
        <div class="d-flex font-inline flex-row" *ngIf="isCheckIn">
          <span class=" spaced font-start d-flex"><b> Check-In </b></span>
        </div>
        <div class="d-flex font-inline flex-row" *ngIf="!isCheckIn">
          <span class="spaced font-end d-flex"><b> Check-Out </b></span>
        </div>
        <ng-template [ngIf]="isCheckIn">
          <span classs="align-items-center d-flex ml-5 pl-5 pr-0">
          Tarifa Neta:
          &nbsp;{{data.payload.others.transfers.paxList.adults}} Adults&nbsp;-
          &nbsp;{{data.payload.others.transfers.paxList.children.length}} Children
        </span>
          <span class="title-destiny-start pt-auto pr-4">$&nbsp;{{data.totalPrice | number:'1.2-2' }}</span>
        </ng-template>
      </div>
    </div>
  </div>

  </div>
</div>-->

<script>
  alert("transport");
</script>