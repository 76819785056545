import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestBilledFormService } from './request-billed-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';



@Component({
  selector: 'usb-request-billed-form',
  templateUrl: './request-billed-form.component.html',
  styleUrls: ['./request-billed-form.component.scss']
})
export class RequestBilledFormComponent implements OnInit {

  @Input() items: any;
  @Input() itineraryItems: any;
  @Input() currencyItinerary: any;
  @Output() payloadRequestBilling = new EventEmitter<any>();
  conditionIva: any[] = [];
  enterprises: any[] = [];
  clients: any[] = [];
  billing_data: {name: string, address: string, email: string, cuit: string};

  requestForm: FormGroup;
  formStyles: { backgroundColor: string, pointerEvents: string }[] = [];

  constructor(
                private formBuilder: FormBuilder,
                private requestBilledService: RequestBilledFormService,
                private userService: UserService
              ) {}

  ngOnInit(): void {
    this.initRequestForm();
    this.getClients();
    this.getConditionIva();
    this.getEnterprises();
  }

  // Inicialización del formulario
  initRequestForm(): void {
    this.requestForm = this.formBuilder.group({
      request: this.formBuilder.array([this.createRequestForm()])
    });

  this.subscribeChangesForm();

  }

  subscribeChangesForm(): void {
    const requestArray = this.requestForm.get('request') as FormArray;

    // Suscribirse a los cambios en cada control existente
    requestArray.controls.forEach((control, index) => {
      this.subscribeToControlChanges(control, index);
    });

    // Detectar cuando se agrega un nuevo control al FormArray
    requestArray.valueChanges.subscribe({
      next: () => {
        const newIndex = requestArray.controls.length - 1;
        const newControl: any = requestArray.at(newIndex);

        if (!newControl.valueChanges.observed) {
          this.subscribeToControlChanges(newControl, newIndex);
        }
      }
    });
  }

  subscribeToControlChanges(control: AbstractControl, index: number): void {
    control.valueChanges.subscribe({
      next: () => {

        if (control.value.enterprise_id) {
          const enterprise = this.enterprises.find(c => c.id === Number(control.value.enterprise_id));

          // Actualizar valores sin disparar el evento `valueChanges`
          control.get('iva_condition_id').setValue(enterprise.iva_condition_id, { emitEvent: false });
          control.get('cuit').setValue(enterprise.cuit_cuilt, { emitEvent: false });
          control.get('address').setValue(enterprise.address, { emitEvent: false });
          control.get('email').setValue(enterprise.email, { emitEvent: false });
        }

        if(control.value.client_id) {
          const client = this.clients.find(c => c.id === Number(control.value.client_id));

          if(client.iva_id) {
            control.get('iva_condition_id').setValue(client.iva_id, { emitEvent: false });
          }
          if(client.cuit) {
            control.get('cuit').setValue(client.cuit, { emitEvent: false });
          }
          // Actualizar valores sin disparar el evento `valueChanges`
          control.get('address').setValue(client.address, { emitEvent: false });
          control.get('email').setValue(client.email, { emitEvent: false });
        }

        this.submitForm();
      }
    });
  }

  changeBillingType(index: number): void {
    const requestArray = this.requestForm.get('request') as FormArray;
    const control = requestArray.at(index);

    // Nullificar los controles asociados al índice
    control.get('client_id').setValue(null, { emitEvent: false });
    control.get('enterprise_id').setValue(null, { emitEvent: false });
    control.get('iva_condition_id').setValue(null, { emitEvent: false });
    control.get('cuit').setValue(null, { emitEvent: false });
    control.get('address').setValue(null, { emitEvent: false });
    control.get('email').setValue(null, { emitEvent: false });
  }

  // Método para crear una nueva instancia de formulario dentro de 'request'
  createRequestForm(): FormGroup {
    return this.formBuilder.group({
      iva_condition_id: new FormControl(null),
      billing_type_id: new FormControl("1"),
      business_name: new FormControl(null),
      client_id: new FormControl(null),
      address: new FormControl(null),
      email: new FormControl(null),
      cuit: new FormControl(null),
      enterprise_id: new FormControl(null),
    });
  }

  // Método para agregar un nuevo formulario al array
  addRequest(): void {
    this.requestArray.push(this.createRequestForm());
  }

  // Método para eliminar un formulario por índice
  removeRequest(index: number): void {
    this.requestArray.removeAt(index);
  }

  // Getter para acceder al FormArray
  get requestArray(): FormArray {
    return this.requestForm.get('request') as FormArray;
  }

  // Método para enviar el formulario
  submitForm(): void {
    // Inicializamos el payload
  let payload: any[] = [];

  // Obtenemos el array de formularios del FormArray
  const requests = this.requestArray.value;

  payload.push(
   {
     itinerary_id: this.items[0].itinerary_id,
     product_quantity: this.items.length,
     total_price: this.totalPrice(),
     agent_id: this.userService._agentID,
     agency_id: this.userService._agencyID,
     items_id: this.itemsId(),
     billing_info: [],
   },
  )

  requests.forEach((request: any, index: number) => {

      payload[0].billing_info.push({
        iva_condition_id: request.iva_condition_id ? Number(request.iva_condition_id): null,
        billing_type_id: request.billing_type_id ? Number(request.billing_type_id) : null,
        client_id: request.client_id ? Number(request.client_id) : null,
        cuit: request.cuit ? Number(request.cuit) : null,
        enterprise_id: request.enterprise_id ? Number(request.enterprise_id) : null,
        billed_data: this.setBillingData(request, index)
      });

  })

  if (payload[0].billing_info.every(info => (info.enterprise_id !== null || info.client_id !== null) &&
                                             info.cuit !== null && info.iva_condition_id !== null))
                                             {
                                                this.payloadRequestBilling.emit(payload);
                                             }

  }

  setBillingData(request: any, index: number): any {
      if(request.client_id) {
        const requestArray = this.requestForm.get('request') as FormArray;
        const control = requestArray.at(index);
        const client = this.clients.find(c => c.id === Number(request.client_id));

        return this.billing_data = {
                                      name: `${client.first_name} ${client.last_name}`,
                                      email: client.email,
                                      address: client.address,
                                      cuit: client.cuit ? client.cuit : control.get('cuit').value
                                   }
      }
      if(request.enterprise_id) {
        const enterprise = this.enterprises.find(c => c.id === Number(request.enterprise_id));

        return this.billing_data = {
                                      name: enterprise.business_name,
                                      email: enterprise.email,
                                      address: enterprise.address,
                                      cuit: enterprise.cuit
                                   }
      }
  }

  itemsId(): any[] {
    const ids: any[] = [];

    this.items.forEach((item: any) => {
      ids.push(item.id);
    })

    return ids;
  }

  totalPrice(): number {
    let total: number = this.items.reduce((sum, item) => {
      return sum + (item.optionNightsNetTotalProf || 0); // Suma el valor si existe, o usa 0 si no está definido
    }, 0);

    return total;
  }

  getStyles(i: number, controlInput?: string) {

    const control = this.requestForm.get(['request', i]);

    if(control?.get('billing_type_id')?.value == 1) {
      const clientId = control?.get('client_id')?.value;
      const ivaConditionClient = control?.get('iva_condition_id')?.value;
      const cuitClient = control?.get('cuit')?.value;

      if ( clientId > 0 ) {
        if(!ivaConditionClient && controlInput === 'iva_condition_id') {
          return {
            'background-color': '',
            'pointer-events': '',
          };
        }
        if(!cuitClient && controlInput === 'cuit') {
          return {
            'background-color': '',
            'pointer-events': '',
          };
        }
      } else {
        return {
          'background-color':'#e9ecef',
          'pointer-events': 'none',
          ...this.formStyles[i]
        };
      }
    }

    return {
      'background-color':'#e9ecef',
      'pointer-events': 'none',
      ...this.formStyles[i]
    };
  }


  getConditionIva(): void {
    this.requestBilledService.getConditionIva().subscribe({
      next: (res: any) => {
        console.log(res);
        if(res && res.data && res.data.length > 0) {
          this.conditionIva = res.data;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
  }

  getEnterprises(): void {
    this.requestBilledService.getEnterprise().subscribe({
      next: (res: any) => {
        console.log(res);
        if(res && res.data && res.data.length > 0) {
          this.enterprises = res.data;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
  }

  getClients(): void {
    this.requestBilledService.getClients().subscribe({
      next: (res: any) => {
        console.log(res);
        if(res && res.data && res.data.length > 0) {
          this.clients = res.data;
        } else {
          this.clients = [];
        }

      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
  }


}
