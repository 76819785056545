import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TitleCasePipe } from "@angular/common";
import { Subject } from "rxjs/Subject";
import { RangeDateItinerary } from "./interfaz/rangeDateItinerary";
import { RestService } from "./rest.service";
import { environment } from "../../environments/environment";
import { ApiService } from "../itinerary/shared/api.service";
import { ToastrService } from "ngx-toastr";
import { Logger } from "../core/logger.service";
import { Router,NavigationExtras, NavigationEnd, NavigationStart } from "@angular/router";
import * as moment from "moment";
import { ItemSelected } from "./interfaz/itemSelected";
import { DatePipe } from "@angular/common";
import { UserService } from "../pages/com.usblick.common/auth/user.service";
import { CookieService } from "ngx-cookie";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MaterialModalAlertComponent } from "../shared/material-modal-alert/material-modal-alert.component";
import {setting} from '../../../setting';
import { MaterialModalConfirmComponent } from "./material-modal-confirm/material-modal-confirm.component";
import { map } from "rxjs/operators";

@Injectable()
export class ItineraryService {
  itinerary: any = {};
  itineraryId: any;
  itineraryPartial: Array<any> = [];
  loading = false;
  load = false;

  activeItinerarySubject = new BehaviorSubject<any>(null); // Almacena el valor actual
  activeItinerary = this.activeItinerarySubject.asObservable(); // Expone como Observable


  itineraryArrivalDate: any;
  itineraryDepartureDate: any;

  //objeto observable que usa el mapa para sincronizar cambios en los datos de los destinos
  objectMap: Observable<any>;
  objectMapSubject: Subject<any>;

  //objeto observable que usa loading
  loadingObject: Observable<boolean>;
  loadingObjectSubject: Subject<boolean>;

  //objeto observable que usa el item seleccionado de multidestino para accionar a cambios
  itemSelectedMultidestination: Observable<ItemSelected>;
  itemSelectedMultidestinationSubject: Subject<ItemSelected>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  payload: Observable<any>;
  payloadSubject: Subject<any>;

  flight: Observable<any>;
  flightSubject: Subject<any>;

  paymentConditions: any;

  dateCatchSelect$:BehaviorSubject<any> = new BehaviorSubject('');
  titleHotelUnique$:BehaviorSubject<any> = new BehaviorSubject('');
  lastItemHotel$:BehaviorSubject<any> = new BehaviorSubject('');
  lastItemHotelService$:BehaviorSubject<any> = new BehaviorSubject('');
  valueCatchBoolean$:BehaviorSubject<boolean> = new BehaviorSubject(false);
  valueUltimateDate$:BehaviorSubject<any> = new BehaviorSubject('');
  booleanModalActive$:BehaviorSubject<boolean> = new BehaviorSubject(true);
  deleteItem: BehaviorSubject<any> = new BehaviorSubject(true);
  currencySuccessEmit:BehaviorSubject<any> = new BehaviorSubject('');
  currencyRemoveEmit: BehaviorSubject<any> = new BehaviorSubject('');
  private modalClosedSubject = new Subject<boolean>();
  modalClosed$ = this.modalClosedSubject.asObservable();

  private emitUpdateItineraryPaymentModal = new Subject<void>();
  emitUpdateItineraryPaymentModal$ = this.emitUpdateItineraryPaymentModal.asObservable();


  notifyModalClosed(edit: boolean) {
    this.modalClosedSubject.next(edit);
  }

  itineraries: Observable<any>;
  itineraryItems: any = [];
  itinerariesSubject: Subject<any>;
  _activePages = 1;
  partialReserve = false; // temporal
  resultReserve: any = [];
  paxesInItems: Array<{
    idPax: any;
    itemId: string;
    document_number: string;
    first_name: string;
    last_name: string;
    email: string;
    country: string;
    birth_date: string;
    title: string;
    identificationType: string;
    state: string;
    phone: string;
    phone2: string;
    city: string;
    address: string;
    postal_code: string;
  }> = [];
  private clients: string;
  private postClient: string;
  private urlActiveItinerary: string;
  private urlUsb =setting.apiBookingUrl;

  // tslint:disable-next-line:member-ordering
  itinerayDetailAux: any = {};

  private itinerary$ = new Subject<any>();
  // tslint:disable-next-line:member-ordering
  parcialTotalPrice: any;
  parcialBlickoins: any;

  // tslint:disable-next-line:member-ordering
  public logger: Logger;

  // tslint:disable-next-line:member-ordering
  form: any;
  // tslint:disable-next-line:member-ordering
  origin: any;

  private emitUpdateCardsSource = new Subject<boolean>();
  emitUpdateCards$ = this.emitUpdateCardsSource.asObservable();


  constructor(
    public _restService: RestService,
    private apiService: ApiService,
    private http: HttpClient,
    private dialog: MatDialog,
    private _router: Router,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe,
    public userService: UserService,
    private _cookieService: CookieService,
    private ngSpinner: NgxSpinnerService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<MaterialModalConfirmComponent>,

  ) {
    this.logger = new Logger();
    this.clients = this.urlUsb + "/clients?name=";
    this.postClient = this.urlUsb + "/clients";
    this.urlActiveItinerary = this.urlUsb + "/itineraries";
    this.flightSubject = new Subject<any>();
    this.flight = this.flightSubject.asObservable();
    this.payloadSubject = new Subject<any>();
    this.payload = this.payloadSubject.asObservable();
    this.activeItinerarySubject = new BehaviorSubject<any>([]);
    this.itinerariesSubject = new Subject<any>();
    this.objectMapSubject = new Subject<any>();
    this.loadingObjectSubject = new Subject<boolean>();
    this.activeItinerary = this.activeItinerarySubject.asObservable();
    this.itineraries = this.itinerariesSubject.asObservable();
    this.objectMap = this.objectMapSubject.asObservable();
    this.loadingObject = this.loadingObjectSubject.asObservable();
    this.itemSelectedMultidestinationSubject = new Subject<any>();
    this.itemSelectedMultidestination = this.itemSelectedMultidestinationSubject.asObservable();
    this.setupRouteChangeListener();

  }

  private setupRouteChangeListener() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.parcialTotalPrice = 0;
        this.parcialBlickoins = 0;
        // console.log(this.parcialBlickoins);
        // console.log(this.parcialTotalPrice)
      }
    });


  }

  emitUpdateCards(reserve: boolean) {
    console.log(reserve)
    this.emitUpdateCardsSource.next(reserve);
  }

  /**
   *
   */
  storage() {
    let credentials = localStorage;
    if (sessionStorage.getItem("credentials") != null) {
      credentials = sessionStorage;
    }
    return credentials;
  }

  /**
   *
   */
  getItemSelectedMultidestination(): Observable<any> {
    return this.itemSelectedMultidestinationSubject.asObservable();
  }
  /**
   *
   */
  getObjectMap(): Observable<any> {
    return this.objectMapSubject.asObservable();
  }

  /**
   *
   */
  getLoadingObject(): Observable<boolean> {
    return this.loadingObjectSubject.asObservable();
  }

  /**
   *
   */
  getActiveItinerary(): Observable<any> {
    return this.activeItinerarySubject.asObservable();
  }
  /**
   *
   * @param id
   */
  select(id: any) {
    this._restService.doGet("app", "itineraries/" + id).subscribe(
      (response: any) => {
        console.log(response);
        this.itinerary = response.data;
        //this.itinerary =  this.userService.itineraryActive[0];
      },
      (error) => {
        console.log("Select-Error: " + error);
      }
    );
  }
  /**
   *
   * @param skip
   */
  createItineraryNew(skip?) {
    let validate = this.itemEmpty();
    if (skip) {
      if (validate) {
        this.save("skip");
        return false;
      }
    } else {
      if (validate) {
        this.save("save");
      }
    }
  }
  /**
   *
   */
  private createItinerary() {
    let uid = Math.round(new Date().getTime() / 1000.0);
    let itinerary = {
      id: "",
      uid: uid,
      agent_id: 1,
      client_id: 1,
      status_id: 1,
      location: "",
      origin: "",
      destination: [],
      arrivalDate: new Date(),
      departureDate: new Date(),
      status: "Draft",
      active: true,
      title: "",
      items: [],
    };
    let validateItinerary = this.validateItinerary();
    if (validateItinerary === null) {
      this.itinerary = itinerary;
    }
  }
  /**
   *
   */
  // tslint:disable-next-line:member-ordering
  validateItinerary(): void {
    // tslint:disable-next-line:typeof-compare
    if (typeof this.itinerary["active"] !== undefined) {
      return this.itinerary["uid"];
    }
    return null;
  }
  /**
   *
   * @param uid
   * @param type
   */
  // tslint:disable-next-line:member-ordering
  validateSave(uid: any, type: any) {
    if (typeof this.itinerary !== undefined && this.itinerary.uid == uid) {
      if (this.itinerary["status"] == "Saved") {
        return true;
      }
    }
    return null;
  }

  /**
   *
   */
  // tslint:disable-next-line:member-ordering
  itemEmpty() {
    if (typeof this.itinerary.items !== undefined) {
      if (this.itinerary.items.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * @param type
   * @param noCreateItinerary
   * @param title
   */
  // tslint:disable-next-line:member-ordering
  save(type: any, noCreateItinerary?, title?) {
    let validate = this.itemEmpty();
    if (validate) {
      if (this.itinerary != null) {
        if (title != null) {
          this.itinerary.title = title;
        }
        this._restService.doPost("app", "itineraries", this.itinerary).subscribe(
          (response: any) => {
            this.itinerary.id = response.id;
            if (type == "skip") {
              this.itinerary = {};
            }
            if (this.itinerary["status"] != "Saved") {
              if (type == "skip") {
                this.itinerary = {};
              } else {
                this.itinerary["active"] = false;
                this.itinerary["status"] = "Saved";
              }
              if (noCreateItinerary) {
                return false;
              }
            } else {
              this.itinerary["active"] = false;
              this.itinerary["status"] = "Saved";
            }
            this.createItinerary();
          },
          (error) => {
            console.log("Save-Error: " + error);
          }
        );
      }
    }
  }

  /**
   *
   * @param item
   */
  // tslint:disable-next-line:member-ordering
  add(item: any): Observable<any> {

    // console.log(item)
    let product;
    let validateDate = false;
    const message = '';

    /*const index = this._validateItem(item.id); // //comentado yovanny 150923
    if (item.payloadUpdate !== undefined && item.payloadUpdate.products[0] !== undefined) {
      product = item.payloadUpdate.products[0];
      this.itinerary.origin = product.origin;
      validateDate = this._validateDate(product.arrivalDate, product.departureDate);
      item.qty = product.qtyProduct;
    }
  */
    // console.log("agregar el item al itinerario push 1", item);

  // if (index == -1 || validateDate == false) { //comentado yovanny 150923
      if (this.itinerary === null) {
        this.createItinerary();
      }

   /*   for (const i in this.itinerary.items) {  //comentado yovanny 150923
        if (
          this.itinerary.items[i].arrivalDate == item.arrivalDate &&
          this.itinerary.items[i].departureDate == item.departureDate &&
           this.itinerary.items[i].id == item.id
        ) {
          return Observable.of(this.getMessageItemDuplicate(this.itinerary.items[i].categoryName));
        }
      }*/
      // console.log("agregar el item al itinerario push 2", item);
      if (this.itinerary.items === undefined) {
        return Observable.of("It is necessary to create an itinerary");
      }

      // console.log("agregar el item al itinerario push 3", item);

      this.itinerary.items.push(item);
      this.itinerary.status = 'Quote';
      // this.updateItinerary(this.itinerary);

      this.sendMessageSubscriptorItemSelected(item.destination, item.categoryName, this.itinerary);
      return Observable.of(this.itinerary);
  /*  } else {
      return Observable.of("Cannot add this item to itinerary");
    } */
  }

  /**
   *
   * @param categoryName
   */
  // tslint:disable-next-line:member-ordering
  getMessageItemDuplicate(categoryName: string) {
    let message;
    switch (categoryName) {
      case "TRANSPORTS": {
        message = "Cannot add the same transport at the same date";
        break;
      }
      case "TOURS":
        message = "Cannot add the same tours at the same date";
        break;
      case "CRUISES":
        message = "Cannot add the same cruise at the same date";
        break;
      case "PTHEMES":
        message = "Cannot add the same park theme at the same date";
        break;
      case "INSURANCE":
        message = "Cannot add the same insurance at the same date";
        break;
      default:
        message = "Cannot add the same room at the same date";
        break;
    }
    return message;
  }

  /**
   *
   * @param _item
   * @param old
   */
  // tslint:disable-next-line:member-ordering
  updateDisneyItem(_item: any, old: any) {
    if (this.itinerary !== undefined && this.itinerary !== null) {
      this.remove(old);
      this.itinerary["items"].push(_item);
    }
    if (this.itinerary["status"] == "Saved") {
      this.updateItinerary(this.itinerary);
    }
  }

  /**
   *
   * @param item
   * @param update
   */
  // tslint:disable-next-line:member-ordering
  remove(item: any, update?: any) {
    // this.ngSpinner.show();
    this.deleteItem.next(false);
  //  let isDelete = false;
    let exist = 0;
    this.itinerary = this.userService.itineraryActive[0];

    this.itinerary.items.forEach((element) => {
      if (item.id != element.id) {
        //veo cuales son los productos distintos al que borre
        if (element.destination.iata_code == item.destination.iata_code) {
          //si de los otros productos alguno tiene el mismo codigo iata digo que si existe
          exist++;
        }
      }
    });
    //si existen mas productos ademas del que borre con el mismo codigo iata
    if (exist > 0) {
      // aqui se deberia de actualizar destination con el producto que quedo
    } else {
      //si no existen mas productos con el mismo codigo iata del producto que borre.
      let destinyFound = this.itinerary.destination.find((element) => element.iata_code == item.destination.iata_code);
      if (destinyFound) {
        let index = this.itinerary.destination.indexOf(destinyFound);
        this.itinerary.destination.splice(index, 1);

      }
    }

    this.sendMessageSubscriptorLoading(true);

    let validate = this.itemEmpty();
    const index = this._validateItem(item.id);  //idProduct

    if (validate && index > -1) {
      if (this.itinerary["status_id"] != 6) {
        //if ((this.itinerary['status_id'] == 1) || (this.itinerary['status_id'] == 2)) {
        this._restService
          .doDelete(
            "app",
            "products/" +
              encodeURIComponent(item.categoryName != "DISNEY" ? item.id : item.itemInfo.id) +
              "?itinerary_id=" +
              this.itinerary.id
          )
          .subscribe(
            (response: any) => {

              this.itinerary.items.splice(index, 1);

              if (update !== null && update !== undefined) {
                this.add(update);
                this._router.navigate(['/results']);
              } else {
                this.updateItinerary(this.itinerary);
                this.sendMessageSubscriptorItemSelected(item.destination, item.categoryName, this.itinerary, true);


              }
              this.toastr.success("¡Felicitaciones! Producto eliminado con éxito.");
              setTimeout(() => {
                this.deleteItem.next(true);
              }, 1200);
           //   isDelete = true;
            },
            (error) => {
              this.toastr.error("Error !!", "There's been a problem");
              setTimeout(() => {
                this.deleteItem.next(true);
              }, 1200);
           //   isDelete = false;
            }
          );
      } else {
        this.toastr.error("Error, You cannot delete a product with a status other than saved.", "Toastr fun!");
      }
      this.sendMessageSubscriptorLoading(false);
    }

    setTimeout(() => {
      this.userService.itineraryUpdateProductsTotalPrices(this.userService.itineraryActive[0].id);

   }, 500);
  }


  // tslint:disable-next-line:member-ordering
  createReservation(roomSelect: any,  itinerary , paxesInItems): any {

    this.itineraryId = itinerary.id;
    this.resultReserve = [];
    this.resultReserve.push({
      itinerary_id: this.itineraryId,
      starDate: moment(),
      endDate: moment(),
      status_id: 4,
      qtyReservedProduct: 0,
      totalNetPrice: 0,
    });

    this.paxesInItems = paxesInItems;
    const paxesFilter = this.paxesInItems.filter((val) => val.itemId == roomSelect.id);
    if (roomSelect.categoryName === 'TOUR') {
        for (let i = 0; i < paxesFilter.length; i++) {
          const idPax = (document.getElementById("idPax-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          const document_number = "document_number-" + paxesFilter[i].idPax;
          const email = "email-" + paxesFilter[i].idPax;
          const country = "country-" + paxesFilter[i].idPax;
          if (paxesFilter[i].idPax === idPax) {
            paxesFilter[i].document_number = (document.getElementById(document_number) as HTMLInputElement).value;
            paxesFilter[i].email = (document.getElementById(email) as HTMLInputElement).value;
            paxesFilter[i].country = (document.getElementById(country) as HTMLInputElement).value;
          }
        }
    }

      const paxes = this.getPaxesInfo(paxesFilter);
      const _payload = this._payload(roomSelect, paxes);
      this.makeReserve(_payload, roomSelect, paxes);
  }


  // tslint:disable-next-line:member-ordering
  makeReserve(payload: any, item: any, paxes: any) {
    this._restService.doPost("api", "/reservation", payload).subscribe(
      (response: any) => {
        console.log(response)
        if (response[0] !== null && response[0].result !== null && response[0].status !== false) {
          //guardar en variable los resultados
          if (localStorage.getItem("nrPayment")) {
            response[0].result.currency = this.userService?.itineraryActive[0]?.currency;
            this.postMainTransaction(response[0].result);
          }

          if (item.categoryName == "HOTELS") {
            this.resultReserve.push({
              status_id: response[0].status === true ? 2 : 0,
              resultReserve: response[0].status === true ? response[0].result : response[0].message,
              category_id: item.category_id,
              providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId,
              netPrice: item.optionNightsNetTotal,
              totalPrice: item.optionNightsNetTotalProf,
              paxList: this.setPaxesReserve(paxes),
              uid: item.id,
              percentage: item.percentage,
            });
          } else if (item.categoryName == "TOURS") {
            this.resultReserve.push({
              status_id: response[0].status === true ? 2 : 0,
              resultReserve: response[0].status === true ? response[0].result : response[0].message,
              category_id: item.category_id,
              providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId,
              netPrice: item.totalPrice,
              totalPrice: item.totalPriceProf,
              paxList: this.setPaxesReserve(paxes),
              uid: item.id,
              percentage: item.percentage,
            });
          } else {
            this.resultReserve.push({
              status_id: response[0].status === true ? 2 : 0,
              resultReserve: response[0].status === true ? response[0].result : response[0].message,
              category_id: item.category_id,
              providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId,
              // netPrice: (response[0].status===true) ? item.totalPrice : 0,
              netPrice: item.totalPrice ? item.totalPrice : 0,
              paxList: this.setPaxesReserve(paxes),
              uid: item.id,
              percentage: item.percentage,
            });
          }

          if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
            //guardar en API

            this.saveReservation(this.resultReserve);
          } else {
            this.ngSpinner.hide();
          }
        } else {
          this.resultReserve.push({
            status_id: 4, // 4 Denied
            resultReserve: response.message,
            category_id: item.category_id,
            netPrice: item.totalPrice,
            providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId,
            paxList: this.setPaxesReserve(paxes),
            uid: item.id,
          });

          if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
            this.saveReservation(this.resultReserve);
          } else {
            this.ngSpinner.hide();
          }
        }
      },
      (error) => {
        this.resultReserve.push({
          status_id: 0,
          resultReserve: error,
          category_id: item.category_id,
          netPrice: item.totalPrice,
          paxList: this.setPaxesReserve(paxes),
          uid: item.id
        });
        if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
          this.saveReservation(this.resultReserve);
        } else {
          this.ngSpinner.hide();
        }
        console.log(error);
      }
    );



  }
  // tslint:disable-next-line:member-ordering
  cancelReservation(item: any): any {
    console.log('item cancel', item);
    console.log('item itinerary', this.itinerary);
    console.log('cancelData json', JSON.stringify(item));
    const data: any = this.itinerary;
    this.ngSpinner.show();
       this._restService.doPut("app", "products/cancelReservation/" + item.id + "/" + item.itinerary_id, data).subscribe(
      (response: any) => {
        console.log('response cancel', response);
        if (response.success) {
          this.ngSpinner.hide();
          const dialogRef_ = this.dialog.open(MaterialModalAlertComponent, {
            height: "auto",
            width: "350px",
            data: response.res.message,
            disableClose: true,
          });

          dialogRef_.afterClosed().subscribe(result => {
            // console.log('valor que llega del modal', result);
            if(result){
              location.reload();
            }
          });
        }
        if (!response.success) {
          this.ngSpinner.hide();
          const dialogRef_ = this.dialog.open(MaterialModalAlertComponent, {
            height: "auto",
            width: "350px",
            data: response.message,
            disableClose: true,
          });
        }
        return response;
      },
      (error) => {
        this.ngSpinner.hide();
        console.log("Save-Error: " + error);
        return false;
      }
    );
  }

  // tslint:disable-next-line:member-ordering
  _payload(item, paxes): any {
    // tslint:disable-next-line:max-line-length
    let productId =
      item.categoryName == "INSURANCE" && item.nameAdapter == "Universal_Assistance"
        ? item.title
        : item.categoryName == "DISNEY"
        ? item.id
        : item.idProduct;
    let searchId = item.categoryName == "DISNEY" ? item.arrayResults[0]["searchId"] : item.searchId;
    let providerId = item.categoryName == "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId;
    const payload = [
      {
        product: {
          searchId: searchId,
          id: item.id,
          providerId: providerId,
          providerName: item.providerName,
          title: item.title,
          description: item.description,
          categoryId: item.category_id,
          productId: productId,
          // tslint:disable-next-line:max-line-length
          origin:
            // tslint:disable-next-line:max-line-length
            item.categoryName != "DISNEY" ? (item.payloadUpdate.products[0].origin ? item.payloadUpdate.products[0].origin : null) : null,
          destination:
            item.categoryName != "DISNEY"
              ? item.payloadUpdate.products[0].destination
                ? item.payloadUpdate.products[0].destination
                : "KOA"
              : item.destination.iata_code,
          // tslint:disable-next-line:max-line-length
          dateIni:
            item.categoryName == "DISNEY"
              ? new DatePipe("en-US").transform(item.destination.destination_info.arrival, "yyyy-MM-dd")
              : item.arrivalDate,
          // tslint:disable-next-line:max-line-length
          dateEnd:
            item.categoryName == "DISNEY"
              ? new DatePipe("en-US").transform(item.destination.destination_info.departure, "yyyy-MM-dd")
              : item.departureDate,
          serviceResponse: null,
          nameAdapter: item.categoryName != "DISNEY" ? item.payloadUpdate.products[0].nameAdapter : "Majestic_Disney",
          // informacion dependiente de cada categoria a reservar
          additionalInfo: this.setAdditionalInfo(item, paxes),
        },
        id: null,
        serviceResponse: null,
      },
    ];
    return payload;
  }


  // tslint:disable-next-line:member-ordering
  getPaxesInfo(paxes: any): any {
    let paxesResult = [];

    paxes.forEach((paxFilter) => {
      let pax = paxes.find((val) => val.document_number == paxFilter.document_number);
      paxesResult.push(pax);
    });

    return paxesResult;
  }

  // tslint:disable-next-line:member-ordering
  setAdditionalInfo(item: any, paxes: any): any {
    let additionalInfo;
    switch (item.categoryName) {
      case "HOTELS":
        additionalInfo = {
          hotelId: item.hotelId,
          roomQty: 1,
          arrival: item.arrivalDate,
          departure: item.departureDate,
          rooms: [
            {
              bookParam: item.bookParam,
              roomId: item.roomId ? item.roomId : item.hotelId,
              optionId: item.optionId ? item.optionId : null,
              accomodationId: item.accomodationId ? item.accomodationId : null,
              maxPax: 1,
              netPrice: item.refPrice,
              totalPrice: item.refPriceProf,
              percentage: item.percentage,
              paxList: this.setPaxesReserve(paxes),

              /*
              paxList: {
                pax: this.setPaxesReserve(paxes)
              }
              */
            },
          ],
        };
        break;
      case "TOURS":
        additionalInfo = {
          tours: [
            {
              company: item.company ? item.company : "Agencia de pruebas",
              optionId: item.optionId,
              paxAdults: item.paxAdults,
              paxChildren: item.paxChildren,
              bookingCode: item.bookingCode ? item.bookingCode : null,
              paxList: this.setPaxesReserve(paxes),
              answers: item.questions.length > 0 ? [] : [],
              sessionCode: item.questions.length > 0 ? [] : null,
              languageCode: item.questions.length > 0 ? [] : null,
              hotel: (document.getElementById("hotel-" + item.id) as HTMLInputElement).value,
              netPrice: item.prices.adult,
              /*totalPrice: item.prices.adultProf,
              percentage: item.prices.percentage */
            },
          ],
        };

        break;
      case "TRANSPORTS":
        additionalInfo = {
          bookParam: item.bookParam,
          transfers: [
            {
              oneWay: item.payload.others.transfers.oneWay,
              contractList: item.contractList,
              pickupTime: item.pickupTime,
              dropoffTime: item.dropoffTime,
              pickupLocation: {
                id: item.payload.others.transfers.pickupLocation.id,
                type: item.payload.others.transfers.pickupLocation.type,
              },
              dropoffLocation: {
                id: item.payload.others.transfers.dropoffLocation.id,
                type: item.payload.others.transfers.dropoffLocation.type,
              },
              goingWay: {
                transfersTypeLeg: item.vehicles[0].transfersTypeLeg ? item.vehicles[0].transfersTypeLeg : "IN",
                transferCode: item.vehicles[0].transferCode ? item.vehicles[0].transferCode : "0|0|0",
                transferType: item.vehicles[0].transferType ? item.vehicles[0].transferType : "P",
                vehicleType: item.vehicles[0].transferType ? item.vehicles[0].transferType : 9,
                travelInfo: "xyz",
                travelCompanyName: "avior airline",
                travelDate: item.pickupTime,
                travelTime: item.pickupTime,
              },
              returnWay: {
                transfersTypeLeg: item.vehicles[1].transfersTypeLeg ? item.vehicles[1].transfersTypeLeg : "OUT",
                transferCode: item.vehicles[1].transferCode ? item.vehicles[1].transferCode : "1|0|0",
                transferType: item.vehicles[1].transferType ? item.vehicles[1].transferType : "P",
                vehicleType: item.vehicles[1].transferType ? item.vehicles[1].transferType : 9,
                travelInfo: "abc",
                travelCompanyName: "avianca",
                travelDate: item.dropoffTime,
                travelTime: item.dropoffTime,
              },
              paxList: this.setPaxesReserve(paxes),
              transport: (document.getElementById("transport-" + item.id) as HTMLInputElement).value,
              idTransport: (document.getElementById("idTransport-" + item.id) as HTMLInputElement).value,
              arrivalTime: null, //moment(this._form.get("arrivalTime").toString().split(",")[0]).format("HH:mm"),
              cruiseNumber:
                document.getElementById("cruiseNumber-" + item.id) != null
                  ? (document.getElementById("cruiseNumber-" + item.id) as HTMLInputElement).value
                  : "",
              netPrice: item.payload.others.transfers.totalPrice,
              /*   totalPrice: item.payload.others.transfers.totalPriceProf,
                 percentage: item.payload.others.transfers.percentage */
            },
          ],
        };
        break;
      case "INSURANCE":
        additionalInfo = {
          insurance: {
            price: item.totalPrice,
            /*  totalPrice: item.totalPriceProf,
              percentage: item.percentage, */
            paxList: this.setPaxesReserve(paxes, true),
          },
        };
        break;
      case "PTHEMES":
        additionalInfo = {
          arrival: item.arrivalDate,
          departure: item.departureDate,
          paxList: {
            pax: this.setPaxesReserve(paxes),
          },
          optionId: item.optionId,
          price: item.priceAdults,
          /*totalPrice: item.priceAdultsProf,
          percentage: item.percentage*/
        };
        break;
      case "DISNEY":
        additionalInfo = {
          searchId: item.hotel["additionalInfo"]["searchId"],
          hotelId: item.hotel.id,
          roomId: item.room.roomId,
          accId: item.room.accomodationId,
          rateId: item.plan.rateId,
          ticketTypeId: item.ticket !== undefined ? item.ticket.id : 0,
          mealPlanId: item.mealPlan !== null ? item.mealPlan.id : 0,
          arrivalDate: item.destination.destination_info.arrival,
          departureDate: item.destination.destination_info.departure,
          days: item.days,
          noExpiration: false,
          paxList: this.setPaxesReserve(paxes, true),
          price: item.room.refPrice,
          /* totalPrice: item.room.refPriceProf,
           percentage: item.room.percentage */
        };
        break;
      case "RENT-A-CAR":
        additionalInfo = {
          bookParam: item.bookParam,
          arrival: item.destination.destination_info.arrival,
          departure: item.destination.destination_info.departure,
          paxList: this.setPaxesReserve(paxes, true),
          //hotelId: item.hotel.id,
          //roomId: item.room.roomId,
          optionId: item.optionId,
          accomodationId: item.accomodationId ? item.accomodationId : null,
          maxPax: 1,
          //roomQty: 1,
          searchPax: {
            adults: 1,
            child: 0,
            childAge: {
              int: [0],
            },
          },
        };
        break;
      default:
        break;
    }
    return additionalInfo;
  }

  // tslint:disable-next-line:member-ordering
  setPaxesReserve(pax: any, insurance: boolean = false): any {
    let paxesAux = [];
    let i = 0;
    pax.forEach((pax) => {
      let Age = moment().diff(pax.birth_date, "years");
      if (insurance == true) {
        paxesAux.push({
          gender: pax.gender,
          title: pax.title,
          first_name: pax.first_name,
          last_name: pax.last_name,
          identificationType: pax.identificationType,
          type: 'AD',   //AD  CH
          identification: pax.document_number,
          birthDate: pax.birth_date,
          age: Age,
          email: pax.email,
          phone: pax.phone,
          // address: "Agencia de prueba AR",
          // country: 'AR',
          // city: "BUENOS AIRES",
          // state: "MENDOZA",
          address: pax.address,
          country: pax.county,
          city: pax.city,
          state: pax.state,
          holder: 6,
        });
      } else {
        paxesAux.push({
          title: pax.title,
          first_name: pax.first_name,
          last_name: pax.last_name,
          identificationType: pax.identificationType,
          type: 'AD',
          identification: pax.document_number,
          age: Age,
          email: pax.email,
          phone: pax.phone,
          phone2: pax.phone2,
          birthDate: pax.birth_date,
          address: pax.address,
          country: pax.country,
          city: pax.city,
          state: pax.state,
          holder: i == 0 ? "holder" : "",
          postal_code: pax.postal_code,
        });
      }
      i = i + 1;
    });
    return paxesAux;
  }


  // tslint:disable-next-line:member-ordering
  confirmationRedirect(reservationId: number) {
    if (!this.partialReserve) {
      let goToURL: string;
      let navigationExtras: NavigationExtras;

      goToURL = "DetailsSummaryItinerary";
      navigationExtras = {
        queryParams: {
          from: "save-draft-reserve",
          itineraryId: this.itineraryId,
        },
      };
      this._router.navigate([goToURL], navigationExtras);
    } else {
      let goToURL: string;
      let navigationExtras: NavigationExtras;
      goToURL = "DetailsSummaryItinerary";
      navigationExtras = {
        queryParams: {
          from: "partial-reserve",
          itineraryId: this.itineraryId,
        },
      };
      this._router.navigate([goToURL], navigationExtras);
    }
  }

  // tslint:disable-next-line:member-ordering
  postMainTransaction(result) {
    let json = JSON.parse(localStorage.getItem("nrPayment"));

    json.paymentMethods.forEach((element) => {
      element.referenceId = result.id;
    });
    console.log(json)
    this._restService.doPost("app", "transactionMaster", json).subscribe((response: any) => {
      console.log("responser transaction master", response);
    });
  }


  // tslint:disable-next-line:member-ordering
  saveReservation(reserve: any) {
    reserve["itinerario_id"] = this.itineraryId;
    this._restService.doPost("app", "reservations", reserve).subscribe(
      (response: any) => {
        this.confirmationRedirect(response.data.reservation_id);
        this.ngSpinner.hide();
      },
      (error) => {
        this.ngSpinner.hide();
      }
    );
  }


  /**
   *
   * @param id
   */
  // tslint:disable-next-line:member-ordering
  removeFlight(id: any) {
    this.sendMessageSubscriptorLoading(true);
    this._restService.doDelete("app", "fly_itineraries/" + id).subscribe(
      (response: any) => {
        this.toastr.success("Congratulations !!", "Successfully removed");
      },
      (error) => {
        console.log("Delete-Error: ", error);
      }
    );
    this.sendMessageSubscriptorLoading(false);
  }

  /**
   *
   * @param idProduct
   */
  // tslint:disable-next-line:member-ordering
  _validateItem(id: any): any {
    let index = -1;

    if (this.itinerary.items !== undefined && this.itinerary.items.length > 0) {
      // tslint:disable-next-line:forin
      for (let position in this.itinerary.items) {
        for (let position2 in this.itinerary.items[position].arrayResults) {
          if (this.itinerary.items[position].arrayResults[position2].productId == id) {  //productId
            return parseInt(position);
          }
        }
        if (this.itinerary.items[position].id == id) {  //idProduct
          return parseInt(position);
        }
      }
    } else {
      // console.log("No products in > ", this.itinerary);
    }
    return index;
  }

  /**
   *
   * @param arrivalDate
   * @param departureDate
   */
  // tslint:disable-next-line:member-ordering
  _validateDate(arrivalDate: any, departureDate: any): any {
    if (this.itinerary["arrivalDate"] == arrivalDate && this.itinerary["departureDate"] == departureDate) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param items
   */
  // tslint:disable-next-line:member-ordering
  evaluateDateItineraryRange(items: any): RangeDateItinerary {


    let dateRange: RangeDateItinerary;
    // if (items != null && items.length > 0) {
    let arrivalDate = items.arrivalDate;
    let departureDate = items.departureDate;
    // for (let item in items) {
    // 	if (items[item].departureDate > departureDate) {
    // 		departureDate = items[item].departureDate;
    // 	}
    // 	if (items[item].arrivalDate < arrivalDate) {
    // 		arrivalDate = items[item].arrivalDate;
    // 	}
    // }
    dateRange = { arrivalDate: new Date(), departureDate: new Date() };
    dateRange.arrivalDate = arrivalDate;
    dateRange.departureDate = departureDate;
    // }
    return dateRange;
  }

  /**
   *
   * @param keyword
   */
  getClients(keyword): Observable<any> {
    if (keyword) {
      return this.http.get(this.clients + keyword);
      // .map(res => {
      // 	let json = res.json().data;
      // 	return json;
      // })
    } else {
      return Observable.of([]);
    }
  }

  /**
   *
   * @param data
   * @param newItinerary
   * @param itineraryId
   */
  postDataClient(data: any, newItinerary?: Boolean, itineraryId?: Number): Observable<any> {
    data.country = null;
    data.city = null;
    data.state = null;
    data.address = null;
    //data.birth_date = "1950-01-01";
    data.birth_date = new DatePipe("en-US").transform("1950-12-13", "yyyy-MM-dd");

    return this._restService.doPost("app", "clients", data);
  }

  addClientToAgency(data: any): Observable<any> {
    return this._restService.doPost("app", "clients/agency", data);
  }

  /**
   *
   * @param data
   * @param key
   */
  updateClient(data, key?: Boolean): Observable<any> {
    console.log(data);
    let route = "/clients/" + data.id;
    return this._restService.doPut("app", route, data);
  }

  /**
   *
   * @param data
   * @param status
   * @param uid
   * @param redirect
   */
  postDataItinerary(data, status: String, uid?: number, redirect?: boolean) {

    // this.itinerary$.subscribe((data: any) => {

    data.status = status;
    data.uid = uid === undefined ? this.randomNumber(1, 100000) : uid;
    data.agent_id = this.userService._agentID;
    data.agency_id = this.userService._agencyID;
    data.status_id = "1";
    data.location = null;
    data.origin = null;
    data.arrivalDate = null;
    data.departureDate = null;
    data.currency = null;
    data.active = 1;
    data.data = null;
    this.updateItinerary(data, redirect);
  // })
  }

  /**
   *
   * @param data
   * @param redirect
   */
  // tslint:disable-next-line:member-ordering
  updateItinerary(data: any, redirect?: boolean) {
    console.log(data)
    this._restService.doPost("app", "itineraries", data).subscribe(
      (response: any) => {
        console.log(response)
        if (response.success) {
          this.consultActiveItinerary(this.userService._agentID, redirect);


          //this.consultActiveItinerary(this.userService._agentID);
        }
      },
      (error) => {
        console.log("Save-Error: " + error);
      }
    );
  }

  /**
   *
   */
  clearItinerary() {
    this.itinerary = {};
  }

  /**
   *
   * @param itinerary
   * @param update
   */
  emitActiveItinerary(itinerary: any, update: boolean) {
    //console.log(JSON.stringify(itinerary));
    if (update) {
      this.updateActiveItinerary(itinerary[0], itinerary[0].active);
    } else {
      this.itinerary = itinerary[0];
      this.sendMessageActiveItinerary(itinerary);
    }
    this.sendMessageSubscriptorMap(itinerary);
  }
  /**
   *
   * @param data
   */
  sendMessageSubscriptorMap(data: any) {
    this.objectMap = data;
    this.objectMapSubject.next(data);

  }

  /**
   *
   * @param loading
   */
  sendMessageSubscriptorLoading(loading: any) {
    this.loadingObject = loading;
    this.loadingObjectSubject.next(loading);
  }

  /**
   *
   * @param destiny
   * @param category
   * @param data
   * @param delete
   */
  sendMessageSubscriptorItemSelected(destiny?: string, category?: string, data?: any, prueba?: boolean) {

    var item: ItemSelected = this.inizializedItemSelected();
    item.data = data !== undefined && data !== null ? data : this.itinerary;
    item.category = category;
    item.destiny = destiny;
    item.delete = prueba;


    //console.log("envio itemSelectedMulti next", item);
    this.itemSelectedMultidestinationSubject.next(item);
  }

  private inizializedItemSelected(): ItemSelected {
    const item: ItemSelected = {
      destiny: undefined,
      category: undefined,
      data: undefined,
      more: undefined,
    };
    return item;
  }

  /**
   *
   * @param itinerary
   * @param active
   */
  // tslint:disable-next-line:member-ordering
  updateActiveItinerary(itinerary, active, module?: string) {

    if(module === 'client') {
      itinerary.clientModule = true;
    }
    console.log(itinerary)
    console.log(module)
    itinerary.active = active ? 1 : 0;
    // itinerary.agent_id = '1';
    itinerary.status_id = "1";
    let route = "itineraries/" + itinerary.id;
    // console.log(itinerary)
    this._restService.doPut("app", route, itinerary).subscribe({
      next: (response: any) => {
        console.log(response);
        if (response.success) {

          if (!active) {
            this.itinerary = {};
            //this.sendMessageActiveItinerary(response.success);
            this.loading = false;
          }
          //this.consultActiveItinerary(1);
          this.consultActiveItinerary(this.userService._agentID);
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log("Update-Itinerary-Error: " + error);
      }
    });
  }

  /**
   *
   * @param agent_id
   * @param redirect
   */
  // tslint:disable-next-line:member-ordering
  async consultActiveItinerary(agent_id?, redirect?: boolean) {
    //this.logger.info('---ItineraryServiceComponent consultActiveItinerary()---');
    //await this.getItineraries();
    this._activePages = 1;

    if (agent_id == undefined && localStorage.getItem("selectedAgency")) {
      let agent = JSON.parse(localStorage.getItem("selectedAgency"));
      agent_id = agent.agent_id;
    }
    let url = "itineraries?agent_id=" + agent_id + "&active=1";
    await this._restService.doGet("app", url).subscribe(
      (response) => {
        console.log(response);
        //console.log("recibo agent_id", agent_id);
        //console.log("utilizando url ", url);

        if (Object.keys(response.data).length > 0) {
          if (response.data[0].client_id !== null) {
            response.data[1] = response.data[0].clients;
            this.itinerary = response.data[0];
            this.sendMessageActiveItinerary(response.data);
          }
          //else {
          //this.sendMessageActiveItinerary(response.data);
          //}
          if (redirect) {
            // this._router.navigate(["results"]);
          }
        } else {
          this.sendMessageActiveItinerary(response.data);
        }
      },
      (error) => {
        console.log("Save-Error: " + error);
      }
    );
    // console.log('----- itinerary del get luego de hacer la consulta ----', this.itinerary);
  }

  /**
   *
   * @param data
   */
  sendMessageActiveItinerary(data: any) {
    // console.log(data);
    // this.activeItinerary = data;
    this.userService.itineraryActive = data;
    this.userService.calculateDates();
    this.itinerary$.next(data);
    this.userService.fillCart(data);
    this.activeItinerarySubject.next(data);
  }

  getItinerary$(): Observable<any> {
    return this.itinerary$.asObservable();
  }

  /**
   *
   * @param id
   */
  deleteItinerary(id: number): Observable<any> {
    return this._restService.doDelete("app", "itineraries/" + id);
  }

  /**
   *
   * @param activePages
   */
  getItineraries(activePages?: number) {
    let have = activePages === undefined ? 1 : activePages;

    if (this.userService._agentID == undefined && localStorage.getItem("selectedAgency")) {
      let agent = JSON.parse(localStorage.getItem("selectedAgency"));
      this.userService._agentID = agent.agent_id;
    }

    if (have === 1) {
      this.apiService.doGet("itineraries?page=" + have + "&agent_id=" + this.userService._agentID).subscribe(
        (Response) => {
          if (Response.length > 0) {
            console.log(Response)
            this.itineraries = Response;
            this.itinerariesSubject.next(this.itineraries);
          }
        },
        (error) => {
          console.log("Error: " + error);
        }
      );
    } else {
      let it = this.itineraries;
      this.apiService.doGet("itineraries?page=" + have + "&agent_id=" + this.userService._agentID).subscribe(
        (Response) => {
          if (Response.length > 0) {
            it = it.concat(Response);
            this.itineraries = it;
            this.itinerariesSubject.next(this.itineraries);
          }
        },
        (error) => {
          console.log("Error: " + error);
        }
      );
    }
  }

  /**
   *
   */
  public handleError = (error: Response) => {
    this.logger.error(error);
    return Observable.throw(error);
  };

  /**
   *
   */
  private get _headers(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("client-id", environment.client_id);
    headers.append("client-secret", environment.client_secret);
    if (localStorage.getItem("bearer")) {
      headers.append("Authorization", "Bearer " + localStorage.getItem("bearer"));
      //headers.append('Authorization', 'Bearer ' +  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgzNDEyMDg0OTU4NmUwZDEzOTMwZjMxODUxYjVmMDU3ZDdlYjZjNmEyNzJmYTdiZDVmNmU2OGI5YjA4MjQ2YmE2OTBlZjQzNGM3ZmZjNGQ0In0.eyJhdWQiOiIxNyIsImp0aSI6IjgzNDEyMDg0OTU4NmUwZDEzOTMwZjMxODUxYjVmMDU3ZDdlYjZjNmEyNzJmYTdiZDVmNmU2OGI5YjA4MjQ2YmE2OTBlZjQzNGM3ZmZjNGQ0IiwiaWF0IjoxNjA5MTcwNjUwLCJuYmYiOjE2MDkxNzA2NTAsImV4cCI6MTY0MDcwNjY1MCwic3ViIjoiNiIsInNjb3BlcyI6W119.NE-oOiyB11RI1pFgyPutvrdtMyzgwZW9BluwQmr1d5t3eVc5gFtjwHr4JEH5mLXTF5xRot9EXz-4B8wNemCir1-G_DDYfh5gr2TDgg62Vm5gsuepIDvRJ_xMpNEzMltQ9q1wD7Y4EbuJNs1LLBZNo6aNNNYnHkaM8U34m1E_K1foqo09X_7zGSrioUhZnHS1CBomfq3qZPKmsWxNuBF1PljEk0pnC6ikuLrMvNObQX4UXTUn0x4TySQ4k6OlX7RmCveordxDATZ9dyqQ4QsPUBEN9vlANMDu_8NYcW3Gp0yztUXFCnh6Ku1F5oBCmi8tCcuKb3Ji1Z1qL6nCF3j1UYVdVRheVnojjs5joK4CA4sw8j239Zqh-II_grzhBQuF570W8ziT-NAabfEf53u65hK0iPpr5HRYJX0OpRc9s6qe0w76RnSNdmEU4rA5M1cviTWomHKZ2bZVWfR5ExyMIvOBteH2Y8EClyfb4lYsgdcyclpCAb5jyj2UOeHeGRy5VH6DqVWnV9BkQipsIB3UoKfcnOsUvvZjqKsiNo8ERR24n6lz_fOZ2d0j1Fwsivq8FZTY5-wt8KUoSq1uxvs1UKJlK3L6zpiDPgEeAuA2aQ1S-ADO-3vRjdsEZDqaarOm4eXEqotGv1Xsw0fyPdbEb3nFoC4zSHmZJje6aqpRioM');
    }
    return headers;
  }
  /**
   *
   * @param min
   * @param max
   */
  randomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }
  /**
   *
   * @param date1
   * @param date2
   */
  _difference(date1: any, date2: any): any {
    const first = moment(date1);
    const second = moment(date2);
    //console.log(first)
    return first.diff(second, "days") === 0 ? "exactly" : first.diff(second, "days") < 0 ? "earlier" : "later";
  }
  /**
   *
   * @param itinerary
   */
  _orderSummaryDestinations(itinerary: any): Array<any> {
    const destinations = [];
    itinerary["items"].forEach((item) => {
      if (destinations.length === 0) {
        destinations.push({
          arrival: item["arrivalDate"],
          departure: item["departureDate"],
          destination: item["categoryName"] === "HOTELS" ? item["payloadUpdate"]["products"][0]["destination"] : null,
          product: [
            {
              item: item,
            },
          ],
        });
      } else {
        destinations.forEach((destination) => {
          if (item["departureDate"]) {
            //console.log(this._difference('2018-04-03', '04-25-2018'));
          }
        });
      }
    });
    return destinations;
  }


  /**
   *
   * @param form
   * @param payload
   */
  // tslint:disable-next-line:member-ordering
  getDestinationInfo(form: any, payload: any): any {
    this.itinerary = this.userService.itineraryActive[0];
console.log(form)
console.log(payload)
    let iata_code;
    let cityName;
    let child;
    let adults;
    let ages: Array<any> = [];
    let agesAdults: Array<any> = [];
    let roomsDist;
    let roomsQty;
    let object;
    let adultsRoom;
    let childrenRoom;
    let ageRoom: Array<any> = [];
    let agesAdultsRoom: Array<any> = [];
    let nights = 0;
    let departureDate: string;
    let arrivalDate: string;

    if (form) {
      if (form.destination) {
        if (payload.categoryName == "INSURANCE") {
          iata_code = form.destination.iataCode;
          // cityName = this.titleCasePipe.transform(form.destination.name);
        } else {
          iata_code = form.destination.cityIataCode;
          if (iata_code == null) {
            iata_code = payload.payloadUpdate.products[0].destination;
          }
          cityName = this.titleCasePipe.transform(form.destination.cityName);
        }
      }
    }
    // else if (payload.payloadUpdate.products[0].destination){
    // 	iata_code = payload.payloadUpdate.products[0].destination;
    // }


    if (payload) {
      switch (payload.categoryName) {
        case "PTHEMES":
          adults = payload.paxAdults;
          child = payload.paxChild;
          ages = payload.payloadUpdate.products[0].others.ages;
          departureDate = payload.departureDate;
          arrivalDate = payload.arrivalDate;
          nights = 1;
          break;
        case "TOURS":
          object = payload.payloadUpdate.products[0];
          adults = object["others"]["tours"]["adults"];
          child = object["others"]["tours"]["children"].length;
          ages = object["others"]["tours"]["children"];
          /*let parts = object.departureDate.split("-");
          //let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
          departureDate = object.departureDate;
          //parts = object.arrivalDate.split('-');
          //mydate = new Date(parts[0], parts[1] - 1, parts[2]);
          arrivalDate = object.arrivalDate;   */
          departureDate = object.departureDate;
          arrivalDate = object.arrivalDate;

          nights = 1;
          if (form) {
            if (form.destination === null || form.destination === undefined) {
              if (payload.categoryName == "INSURANCE") {
                iata_code = form.origin.iataCode;
                cityName = this.titleCasePipe.transform(form.origin.name);
              } else {
                iata_code = payload.payloadUpdate.products[0].destination;
              }
            }
          }
          break;
        case "TRANSPORTS":
          object = payload.payload[0].others;
          adults = object["transfers"]["paxList"]["adults"];
          child = object["transfers"]["paxList"]["children"].length;
          ages = object["transfers"]["paxList"]["children"];
          //departureDate = new Date(payload.payload.departureDate);
          //arrivalDate = new Date(payload.payload.arrivalDate);
          departureDate = payload.payload[0].departureDate;
          arrivalDate = payload.payload[0].arrivalDate;
          nights = moment(payload.payload.departureDate).diff(payload.payload.arrivalDate, "days");
          if (form) {
            iata_code = form.firstDestination.city_iata_code;
            cityName = this.titleCasePipe.transform(form.firstDestination.city_name);
          }
          break;
        case "INSURANCE":
          adultsRoom = 0;
          childrenRoom = 0;
          payload.form.room.forEach((room) => {
            adultsRoom += Number.parseInt(room.qtyAdults);
            childrenRoom += Number.parseInt(room.qtyChilds);
            room.adultsage.forEach((age) => {
              agesAdultsRoom.push(age.age);
            });
            room.childsage.forEach((age) => {
              ageRoom.push(age.age);
            });
          });
          adults = adultsRoom;
          child = childrenRoom;
          ages = ageRoom;
          nights = moment(payload.form.departureDate).diff(payload.form.arrivalDate, "days");
          agesAdults = agesAdultsRoom;
          //departureDate = new Date(payload.form.departureDate);
          //arrivalDate = new Date(payload.form.arrivalDate);
          departureDate = payload.form.departureDate;
          arrivalDate = payload.form.arrivalDate;
          if (form) {
            iata_code = form.destination.iataCode;
          }
          break;
        case "DISNEY":
          adultsRoom = 0;
          childrenRoom = 0;
          payload.form.room.forEach((room) => {
            adultsRoom += Number.parseInt(room.qtyAdults);
            childrenRoom += Number.parseInt(room.qtyChilds);
            room.childsage.forEach((age) => {
              ageRoom.push(age.age);
            });
          });
          adults = adultsRoom;
          child = childrenRoom;
          ages = ageRoom;
          nights = payload.room.nights;
          departureDate = payload.form.departureDate;
          arrivalDate = payload.form.arrivalDate;
          iata_code = payload.hotel.destination;
          cityName = this.titleCasePipe.transform(payload.hotel.serviceResponse.City);
          roomsDist = payload.form.room;
          roomsQty = payload.form.room ? payload.form.room.length : null;
          break;
        case "HOTELS":
          adultsRoom = 0;
          childrenRoom = 0;

          //object = payload.payloadUpdate.products ? payload.payloadUpdate.products[0] : payload.payloadUpdate;
          object = payload.payloadUpdate.products[0];

          object.others.hotels.roomConfiguration.forEach((room) => {
            adultsRoom += Number.parseInt(room.adults);
            childrenRoom += Number.parseInt(room.children.length);
            room.children.forEach((age) => {
              ageRoom.push(age);
            });
          });
          adults = adultsRoom;
          child = childrenRoom;
          ages = ageRoom;
          roomsDist = object.others.hotels.roomConfiguration;
          roomsQty = object.others.hotels.roomConfiguration ? object.others.hotels.roomConfiguration.length : null;
          nights = moment(object.departureDate).diff(object.arrivalDate, "days");
          departureDate = object.departureDate;
          arrivalDate = object.arrivalDate;
          if (form) {
            if (form.destination === null || form.destination === undefined) {
              if (payload.categoryName == "INSURANCE") {
                iata_code = form.origin.iataCode;
                // cityName = this.titleCasePipe.transform(form.origin.name);
              } else {
                iata_code =  object.destination/// payload.hotel.destination;
              }

            }
          }
          break;
        case "RENT-A-CAR":
          console.log('form', form);
          object = payload.payloadUpdate.products[0];
          //parts = object.departureDate.split('-');
          //mydate = new Date(parts[0], parts[1] - 1, parts[2]);
          //departureDate = mydate;
          departureDate = object.departureDate;
          //parts = object.arrivalDate.split('-');
          //mydate = new Date(parts[0], parts[1] - 1, parts[2]);
          nights = moment(object.departureDate).diff(object.arrivalDate, "days");
          //arrivalDate = mydate;
          arrivalDate = object.arrivalDate;
          if (form) {
            if (form.destination === null || form.destination === undefined) {
              if (payload.categoryName == "INSURANCE") {
                iata_code = form.origin.city_iata_code;
                // cityName = this.titleCasePipe.transform(form.origin.name);
              } else {
                iata_code = object.destination; //form.origin.cityIataCode
                cityName = this.titleCasePipe.transform(form.origin.cityName);
              }
            }
          }
          break;
      }
      const destination = {
        iata_code: iata_code,
        destination_info: {
          cityName: form.destination?.city_name || form.origin?.city_name || form.firstDestination.city_name,
          nights: nights,
          adults: adults,
          childs: child,
          childsAges: ages,
          agesAdults: agesAdults,
          rooms: roomsQty,
          roomDist: roomsDist,
          position: null,
          arrival: arrivalDate,
          departure: departureDate,
        },
      };
      console.log(this.itinerary)
      console.log(destination)
      if (destination.iata_code === null || destination.iata_code === undefined) {
        return "valor null";
      }
      if (this.itinerary["destination"].length > 0) {
        let i = 0;
        this.itinerary["destination"].forEach((_destination) => {
          if (_destination["iata_code"] && destination["iata_code"] === _destination["iata_code"]) {
            this.itinerary["destination"].splice(i, 1);
          }
          i++;
        });
      }
      this.itinerary["destination"].push(destination);
      // console.log(this.itinerary)
      // this.updateItinerary(this.itinerary);
      return destination;
    }
    return null;
  }

   updateParcialBlickoins(value: number) {
    this.parcialBlickoins = value;
    // console.log(this.parcialBlickoins);
    // Aquí puedes realizar cualquier otra lógica que necesites con el nuevo valor
  }

  updateItineraryPartial(item: any, remove: boolean = false) {

//     console.log(item)

//     console.log("remove", remove);

// console.log(this._router.url)
// console.log(this.parcialBlickoins)

    if (remove) {
      let validate = this.itemEmptyItemItineraryPartial();
      const index = this._getItemIndexItineraryPartial(item.idProduct);


      // Enviando datos
      let data: any;
      data = {
        partial_selected: 0,
        item_id: item.id,
      };
      let itemId = encodeURIComponent(item.id);
      this._restService.doPut("app", "products/set/partial/" + item.itinerary_id, data).subscribe(
        (response: any) => {
          if (response.success) {
            //this.itineraryPartial.push(item.idProduct);

            console.log("updateItineraryPartial", response);

            this.parcialTotalPrice = this.parcialTotalPrice - item.totalPrice;
            this.parcialBlickoins = this.parcialBlickoins - item.blickoins;


            if (validate && index > -1) {
              this.itineraryPartial.splice(index, 1);
            }

            return this.itineraryPartial;
          }
        },
        (error) => {
          console.log("Save-Error: " + error);
        }
      );
      //final de envio de datos
    } else {
      //agregamos el itinerario
      let data: any;
      data = {
        partial_selected: 1,
        item_id: item.id,
      };
      let itemId = encodeURIComponent(item.id);
      this._restService.doPut("app", "products/set/partial/" + item.itinerary_id, data).subscribe(
        (response: any) => {
          if (response.success) {
            this.itineraryPartial.push(item.idProduct);


            let priceTotal = 0;
            let blickoins = 0;

            this.itineraryPartial.forEach((element) => {
              let item = this.userService.itineraryActive[0].items.find((item) => item.idProduct == element);
              if (item) {
                priceTotal = priceTotal + item.totalPrice;
                blickoins = blickoins + item.blickoins;
              }
            });

            this.parcialTotalPrice = priceTotal;
            this.parcialBlickoins = blickoins;
            this.ngSpinner.hide();

            // console.log("   this.itineraryPartial",    this.itineraryPartial);
            return this.itineraryPartial;
          } else {
            console.log("parcial error ==> ", this.itineraryPartial);
          }
        },
        (error) => {
          this.ngSpinner.hide();
          console.log("Save-Error: " + error);
        }
      );
    }
  }

  updateRemoveItineraryPartial(itinerary: any) {
    let data: any;
    let itineraryId = itinerary;
    this._restService.doPut("app", "products/revert/partial/" + itineraryId, data).subscribe(
      (response: any) => {
        if (response.success) {
          // console.log("item removidos de partial selected", response);
        }
      },
      (error) => {
        console.log("Save-Error: " + error);
      }
    );
  }

  itemEmptyItemItineraryPartial() {
    if (typeof this.itineraryPartial !== undefined) {
      if (this.itineraryPartial.length > 0) {
        return true;
      }
    }
    return false;
  }

  _getItemIndexItineraryPartial(idProduct: any): any {
    let index = -1;
    if (this.itineraryPartial.length > 0) {
      for (let position in this.itineraryPartial) {
        for (let position2 in this.itineraryPartial[position]) {
          if (this.itineraryPartial[position] == idProduct) {
            return parseInt(position);
          }
        }
        if (this.itineraryPartial[position].idProduct == idProduct) {
          return parseInt(position);
        }
      }
    } else {
      console.log("No products in > ", this.itineraryPartial);
    }
    return index;
  }

  /* NUEVO METODOS */

  /**
   *
   * @param itinerary
   */
  // Obtener los datos del vuelo
  initFlights(itinerary: any) {
    console.log('inicio')
    let flightInfo: any;
    this._restService.doGet("app", "itinerary/" + Number(this.itinerary["id"]) + "/fly").subscribe(
      (response: any) => {
        if (response["data"] && response["data"][0]) {
          const flight = response["data"][0];
          flightInfo = {
            departureDate: new Date(flight["departure"]),
            arrivalDate: new Date(flight["arrival"]),
            airport: flight["airport"],
            airline: flight["airline"],
            flightNumber: flight["flyNumber"],
          };
        }
      },
      (error) => {
        console.log("Has no flights associated", error);
      }
    );
    return flightInfo;
  }

  /**
   *
   * @param item
   */
  // Obtener la localizacion del item
  getLocation(item: any): string {
    let location;
    switch (item.categoryName) {
      case "DISNEY":
        location = item.hotel.additionalInfo.location;
        break;
      case "INSURANCE":
        location = item.destination.iata_code;
        break;
      default:
        location = item.location;
        break;
    }
    return location;
  }
  /**
   *
   * @param item
   */
  // Obtener la descripcion del item
  getDescription(item: any): string {
    let description;
    switch (item.categoryName) {
      case "DISNEY":
        description = item.hotel.additionalInfo.description;
        break;
      default:
        description = item.description;
        break;
    }
    return description;
  }
  // Obtener la descripcion los pasajeros
  getPaxList(itinerary: any) {
    let adults = 0;
    let children = 0;
    let rooms = 0;
    let nights = 0;
    let object;
    let adultsRoom = 0;
    let childrenRoom = 0;
    let departureDate = new Date(1, 1, 1);
    let arrivalDate = new Date(3000, 1, 1);
    if (itinerary.items.length > 0) {
      itinerary.items.forEach((item) => {
        switch (item.categoryName) {
          case "PTHEMES":
            adults = this.selectGreaterValue(item.paxAdults, adults);
            children = this.selectGreaterValue(item.paxChild, children);
            rooms = this.selectGreaterValue(0, rooms);
            // nights += item.duration;
            departureDate = this.selectGreaterValue(new Date(item.departureDate), new Date(departureDate));
            arrivalDate = this.selectMinValue(new Date(item.arrivalDate), new Date(arrivalDate));
            break;
          case "TOURS":
            object = item.payloadUpdate.products[0];
            adults = this.selectGreaterValue(object["others"]["tours"]["adults"], adults);
            children = this.selectGreaterValue(object["others"]["tours"]["children"].length, children);
            rooms = this.selectGreaterValue(0, rooms);
            // nights += moment(object.departureDate).diff(object.arrivalDate, 'days');
            let parts = object.departureDate.split("-");
            let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            departureDate = this.selectGreaterValue(mydate, new Date(departureDate));
            parts = object.arrivalDate.split("-");
            mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            arrivalDate = this.selectMinValue(mydate, new Date(arrivalDate));
            break;
          case "TRANSPORTS":
            object = item.payload.others;
            adults = this.selectGreaterValue(object["transfers"]["paxList"]["adults"], adults);
            children = this.selectGreaterValue(object["transfers"]["paxList"]["children"].length, children);
            rooms = this.selectGreaterValue(0, rooms);
            // nights += moment(item.payload.departureDate).diff(item.payload.arrivalDate, 'days');
            departureDate = this.selectGreaterValue(new Date(item.payload.departureDate), new Date(departureDate));
            arrivalDate = this.selectMinValue(new Date(item.payload.arrivalDate), new Date(arrivalDate));
            break;
          case "INSURANCE":
            adultsRoom = 0;
            childrenRoom = 0;
            item.form.room.forEach((room) => {
              adultsRoom += Number.parseInt(room.qtyAdults);
              childrenRoom += Number.parseInt(room.qtyChilds);
            });
            adults = this.selectGreaterValue(adultsRoom, adults);
            children = this.selectGreaterValue(childrenRoom, children);
            rooms = this.selectGreaterValue(0, rooms);
            departureDate = this.selectGreaterValue(new Date(item.form.departureDate), new Date(departureDate));
            arrivalDate = this.selectMinValue(new Date(item.form.arrivalDate), new Date(arrivalDate));
            // nights += moment(item.departureDate).diff(item.arrivalDate, 'days');
            break;
          case "DISNEY":
            adultsRoom = 0;
            childrenRoom = 0;
            item.form.room.forEach((room) => {
              adultsRoom += Number.parseInt(room.qtyAdults);
              childrenRoom += Number.parseInt(room.qtyChilds);
              rooms++;
            });
            adults = this.selectGreaterValue(adultsRoom, adults);
            children = this.selectGreaterValue(childrenRoom, children);
            rooms = this.selectGreaterValue(item.form.room.length, rooms);
            nights += item.room.nights;
            departureDate = this.selectGreaterValue(new Date(item.form.departureDate), new Date(departureDate));
            arrivalDate = this.selectMinValue(new Date(item.form.arrivalDate), new Date(arrivalDate));
            break;
          case "HOTELS":
            adultsRoom = 0;
            childrenRoom = 0;
            object = item.payloadUpdate.products[0];
            object.others.hotels.roomConfiguration.forEach((room) => {
              adultsRoom += Number.parseInt(room.adults);
              childrenRoom += room.children.length;
            });
            adults = this.selectGreaterValue(adultsRoom, adults);
            children = this.selectGreaterValue(childrenRoom, children);
            rooms = this.selectGreaterValue(object.others.hotels.roomConfiguration.length, rooms);
            nights += moment(object.departureDate).diff(object.arrivalDate, "days");
            departureDate = this.selectGreaterValue(new Date(object.departureDate), new Date(departureDate));
            arrivalDate = this.selectMinValue(new Date(object.arrivalDate), new Date(arrivalDate));
            break;
          case "RENT-A-CAR":
            object = item.payloadUpdate.products[0];
            parts = object.departureDate.split("-");
            mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            departureDate = this.selectGreaterValue(mydate, new Date(departureDate));
            parts = object.arrivalDate.split("-");
            mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            arrivalDate = this.selectMinValue(mydate, new Date(arrivalDate));
            break;
        }
      });
    }
    if (departureDate.getFullYear() === 1901) {
      departureDate = undefined;
    }
    if (arrivalDate.getFullYear() === 3000) {
      arrivalDate = undefined;
    } //this.getDatesRange(departureDate, arrivalDate) +
    return adults > 0 || children > 0 || nights > 0 || rooms > 0
      ? //' | ' +
        children > 0 || nights > 0 || rooms > 0
        ? (adults > 0 ? adults + (adults > 1 ? " adults, " : " adult, ") : "") +
          (nights > 0 || rooms > 0
            ? (children > 0 ? children + (children > 1 ? " children, " : " child, ") : "") +
              (rooms > 0
                ? (nights > 0 ? nights + (nights > 1 ? " nights, " : " night, ") : "") +
                  (rooms > 0 ? rooms + (rooms > 1 ? " rooms, " : " room. ") : "")
                : nights > 0
                ? nights + (nights > 1 ? " nights. " : " night. ")
                : "")
            : children > 0
            ? children + (children > 1 ? " children. " : " child. ")
            : "")
        : adults > 0
        ? adults + (adults > 1 ? " adults. " : " adult. ")
        : ""
      : ".";
  }
  selectGreaterValue(newValue: any, oldValue: any): any {
    if (newValue > oldValue) {
      return newValue;
    }
    return oldValue;
  }
  selectMinValue(newValue: any, oldValue: any): any {
    if (newValue < oldValue) {
      return newValue;
    }
    return oldValue;
  }

  /**
   *
   * @param itinerary
   */
  // Obtener fecha del viaje
  getDatesRangen(itinerary: any) {
    const destination = itinerary.destination;
    if (destination.length > 0) {
      const arrival = itinerary.arrivalDate
        ? this.datePipe.transform(itinerary.arrivalDate.toString(), "MMM dd, yyyy")
        : this.datePipe.transform(itinerary.arrivalDate, "MMM dd, yyyy");
      const departure = destination.length === 0 ? "" : " to " + this.datePipe.transform(itinerary.departureDate, "MMM dd, yyyy");
      return arrival + departure;
    } else {
      return "Dates not defined";
    }
  }

  // compartiveItineraryCancel(item:any){
  //   console.log('item comparative ->', item);
  //   for (const itinerary of this.itinerary.items) {
  //     console.log('itineary comparative', itinerary);
  //     if (item.id == itinerary.id) {
  //         console.log('itinerary', itinerary);
  //         return itinerary;
  //     }
  //   }
  // }

  destinationsPerItinerary(itinerary: any): string {
    let destinations = "";
    if (itinerary.destination.length > 0) {
      itinerary.destination.forEach((destination, index) => {
        destinations = destinations.concat(
          index === 0
            ? destination.destination_info.cityName
              ? destination.destination_info.cityName
              : destination.iata_code
            : " - " + (destination.destination_info.cityName ? destination.destination_info.cityName : destination.iata_code)
        );
      });
    } else {
      destinations = "Any destination";
    }
    return destinations;
  }
  /**
   *
   * @param itinerary
   */
  // Obtener total price
  getNetPrice(itinerary: any) {
    let price = 0;
    if (itinerary.items.length > 0) {
      itinerary.items.forEach((item) => {
        price += item.optionNightsNetTotal
          ? item.optionNightsNetTotal
          : item.totalPrice
          ? Number.parseInt(item.totalPrice)
          : item.optionTotal
          ? Number.parseInt(item.optionTotal)
          : item.prices
          ? item.prices.total
          : item.netPrice
          ? item.netPrice
          : 0;
      });
    }
    return price;
  }

  /**
   *
   * @param itinerary
   */
  // Obtener los datos del cliente
  getClientData(itinerary: any) {
    const client = itinerary.clients;
    let clientData: any;
    if (client == null) {
      const name = "";
      const email = "";
      const phone = "";
      return "Personal information not available";
    }
    clientData = {
      name: client.first_name !== "false" ? client.first_name + " " + (client.last_name == null ? "" : client.last_name) : "",
      email: (client.email = client.email ? client.email : null),
      phone: client.phone ? client.phone : null,
    };

    return clientData;
  }

  /**
   *
   *  @param itinerary
   */
  // obtener los datos de la agencia
  getAgentData(itinerary: any) {
    let agentData: any;
    agentData = {
      name: "",
      logo: "",
      email: "",
      phone: "",
    };
    this._restService.doGet("app", "agents/" + itinerary.agent_id).subscribe(
      (response: any) => {
        if (response["data"] && response["data"][0]) {
          agentData = {
            name: response["data"][0]["name_agency"],
            logo: response["data"][0]["imagen_logotype"],
            email: response["data"][0]["email"],
            phone: response["data"][0]["phone"],
          };
        }
      },
      (Error) => {
        console.log("Error", Error);
      }
    );
    return agentData;
  }

  /**
   *
   * @param itinerary
   * @param obj
   */
  postSendEmail(itinerary: any, obj: any) {
    var mailOptions = {
      from: "notice@usblick.com", // notice@usblick.com yovannyjvv2@gmail.com
      to: this.getClientData(itinerary).email,
      bcc: "notice@usblick.com", // 'Federico@usblick.com'
      subject: "✈️ Itinerary # " + itinerary.id,
      title: "Agencia",
      body: obj,
    };
    return this._restService.doPost("app", "sendemail", mailOptions);
  }

  /**
   *
   * @param netPrice
   * @param categoryId
   * @param providerId
   */

  getProfitability(netPrice: any, categoryId: any, providerId: any): any {
    let url = "/profitabilityCategoryProvider/calculate/" + netPrice + "/" + categoryId + "/" + providerId;
    return this._restService.doPost("app", url, {}).map(
      (response: any) => {
        return response;
      },
      (error) => {
        console.log("Error", error);
        return netPrice + " ";
      }
    );
  }


  // Update detalle de itinerario al momento de cerrar el modal de pago
  emitCloseModalPaymentUpdate() {
    this.emitUpdateItineraryPaymentModal.next();
  }

  public getCategories(): Observable<string[]> {
    let url = 'products/category';
    return this._restService.doGet('app', url)
    .pipe(map(res => res['data']));
  }

  showLoader(): void {
    this.loadingSubject.next(true);
  }

  hideLoader(): void {
    this.loadingSubject.next(false);
  }

}
