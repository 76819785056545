<div id="modal-transport">
  <div class="d-flex my-3 justify-content-between">
    <button [ngClass]="{'btn-tabs-active': tab==='rooms'}" class="btn-tabs" (click)="tab='rooms'">VEHÍCULO</button>
    <button [ngClass]="{'btn-tabs-active': tab==='info'}" class="btn-tabs" (click)="tab='info'">INFO</button>
    <button [ngClass]="{'btn-tabs-active': tab==='photos'}" class="btn-tabs" (click)="tab='photos'; ">FOTOS</button>
  </div>
</div>


<div id="modal-transport"class="card card-info d-flex flex-row my-3 ">
  <div class="flex-column p-2 d-flex type-vehicle">
    <span class="flex-column">
      <strong>Tipo de vehiculo</strong>
    </span>
    <span class="flex-column">{{vehicle.vehicleType}}</span>
    <span class="pt-2">
      <strong>Tipo de traslado</strong>
    </span>
    <span>{{vehicle.transferType}}</span>
  </div>
  <div *ngIf="vehicle.maxPax !== null" class="flex-column p-2 d-flex max-people">
    <span class="strong">
      <strong>Capacidad</strong>
    </span>
    <i class="bi bi-people-fill" aria-hidden="true"></i>
    <span class="strong">{{vehicle.maxPax + ' ' + 'Person(s)'}}</span>
  </div>
  <div class="flex-column p-2 d-flex vehicles-price">
    <span>
      <strong>Precio por viaje</strong>
    </span>
    <span>
     <!-- ${{vehicle['price'] | number:'1.2-2'}}- -->
     ${{vehicle.totalPrice | number:'1.2-2'}} -
    </span>
    <span class="pt-2">
        <strong>Tarifa Neta</strong>
    </span>
    <span>
      <strong></strong>
    </span>
    <span>
      ${{vehicle.totalPrice | number:'1.2-2'}} -
  <!--  ${{vehicle.price | number:'1.2-2'}}- -->
    </span>
  </div>

  <div class="flex-column col-1 py-2 d-flex max-people radio text-center">
    <span class="strong">
      <strong>SELECCIONAR</strong>
    </span>
    <label class="d-flex justify-content-center text-center pt-2">
      <input #widget class="form-check-input" type="radio" name="itemGroup" (change)="selectVehicle(vehicle)"/>
      <span class="cr ">
        <i class="cr-icon fa fa-circle"></i>
      </span>
    </label>

  </div>

</div>
