import { Component, OnInit, NgModule, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';
import { FormGroup } from '@angular/forms';
import { ItineraryService } from '../../itinerary.service';

import { DatePipe } from '@angular/common';
import { RestService } from '../../rest.service';
// import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { isValidOwener, fillOwnerMessage } from '../../../utils/Functions';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'usb-modal-transports',
  templateUrl: './modal-transports.component.html',
  styleUrls: ['./modal-transports.component.scss'],
  providers: [
    DatePipe, 
    //NgbAccordionConfig
  ]
})

export class ModalTransportsComponent implements OnInit {

  @Input() productQty: number;
  @Input() product: any;
  @Input() service: any;
  @Input() result: any;
  @Input() form: FormGroup;
  @Input() payload?: any;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() _item?: any;
  @Input() view_info: any;
  @Output() refModal = new EventEmitter<any>();

  disableButton = false;
  max: number;
  rate = 4;
  ratereview = 4.5;
  isReadonly: boolean;
  x = 5;
  y = 2;
  select: any[] = [];
  totalQty = 0;
  single: any[];
  view: any[] = [200, 150];
  categories = [];
  price: any;
  // options
  rates = [];
  ticketsPrice: any;
  days = [];
  daysArray = [];
  ticketResult: any;
  ticket: any;
  _days: any;
  mealResult: any;
  _rated: any;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = '.';
  showYAxisLabel = true;
  item: any;
  yAxisLabel = '%';
  selected: any;
  _resultsQuery: any;
  colorScheme = {
    domain: ['#00a680', '#00a680', '#00a680', '#00a680', '#00a680']
  };


  public options = {
    position: ['top', 'right'],
    timeOut: 460,
    lastOnBottom: true,
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    maxLength: 60
  };

  constructor(
    
    private datePipe: DatePipe,
    public vcr: ViewContainerRef,
    private _restService: RestService,
    private _itineraryService: ItineraryService,
    //private config: NgbAccordionConfig,
    private _cookieService: CookieService) {
    //this.toastr.setRootViewContainerRef(this.vcr);


    
    //config.type = 'dark';


    this.single = [
      {
        'name': 'Excelente',
        'value': 74
      },
      {
        'name': 'Muy Bueno',
        'value': 19
      },
      {
        'name': 'Regular',
        'value': 6
      },
      {
        'name': 'Malo',
        'value': 1
      },
      {
        'name': 'Pésimo',
        'value': 0
      }
    ];
  }


  ngOnInit() {
  }

  /**
   * Metodo que crea los select que se veran reflejados en las habitaciones.
   * @param qty Indica la cantidad de select que se crearan (se crea 1 por cada room del service).
   * @param maxProductSelect Indica los valores del array (se le agrega 1 mas por el valor 0).
   */
  createSelectsOfRoom(qty: number, maxProductSelect: number) {
    for (let i = 0; i < qty; i++) {
      this.select.push({
        selectArray: Array.from(Array(maxProductSelect).keys()),
        selectValue: 0,
      });
    }
  }
  selectRoom(room: any) {
    this.selected = room;
    this.totalQty = this.selected['totalPrice'];
    this.price = {
      optionNightsNetTotal: this.selected['netPrice']
    };
    console.log('Selected', this.selected);

  }
  getRates(room: any) {
    this._restService.doPost('api', '/disney/step/rates', this._payload(0)).subscribe(
      Response => {
        console.log('Recibido', Response);
        this._resultsQuery = Response.results;
        this._resultsQuery.forEach(meal => {
          meal['additionalInfo']['type'].forEach(type => {
            this.rates.push({
              name: meal['additionalInfo']['name'] + ' - ' + type['type'].split('T')[0] + ' Tickets',
              rateId: type['rateId']
            });
          });
        });
        console.log('Rates', this.rates);
      },
      Error => {
        console.log('Error', Error)
      }
    );
  }
  showDays(ticket: any) {
    this.ticket = ticket;
    let id = 0;
    this.ticket['additionalInfo']['days'].forEach(day => {

      this.days.push(day.split(' ')[0] + ' days');
      this.daysArray.push({
        id: id,
        name: day.split(' ')[0] + ' days'
      });
      id++;
    });
    this._days = 'Days';
  }
  setDay(event: any) {
    console.log('Evento', event);
    this._days = event['name'];
  }
  getPrice() {
    this._restService.doPost('api', '/disney/step/tickets/price', this._payload(3)).subscribe(
      Response => {
        console.log('Recibido precio', Response);
        if (Response['message'] === 'ERROR: looks like we got no XML document') {
          this.ticketsPrice = {
            additionalInfo: {
              netPrice: 0,
              totalPrice: 0
            }
          };
          //this.toastr.error('This ticket plan is not available at this moment.');
        } else {
          this.ticketsPrice = Response['results'];
        }
        this.totalQty += this.ticketsPrice['additionalInfo']['totalPrice'];
      },
      Error => {
        console.log('Error', Error)
      }
    );
    this._restService.doPost('api', '/disney/step/price', this._payload(4)).subscribe(
      Response => {
        console.log('Recibido precio total', Response);
        this.price = Response['results'];
        this.totalQty = this.price['optionNightsNetTotal'];
      },
      Error => {
        console.log('Error', Error)
      }
    );
  }
  callRates(rate: any) {
    this._rated = rate;
    if (this._rated['name'].split(' - ')[1] === 'Con Tickets') {
      this._restService.doPost('api', '/disney/step/tickets', this._payload(1)).subscribe(
        Response => {
          console.log('Recibido ticket', Response);
          this.ticketResult = Response['results'];
        },
        Error => {
          console.log('Error', Error)
        }
      );
    }
    if (this._rated['name'].split(' - ')[0] !== 'Solo Habitación') {
      this._restService.doPost('api', '/disney/step/meals', this._payload(2)).subscribe(
        Response => {
          console.log('Recibido meal', Response);
          this.mealResult = Response['results'][0];
          this.totalQty += this.mealResult['additionalInfo']['totalPrice'];
        },
        Error => {
          console.log('Error', Error);
          //this.toastr.error('There are not meal plans available at this moment.');
          this.mealResult = {
            additionalInfo: {
              netPrice: 0,
              totalPrice: 0
            }
          };
        }
      );
    }
  }
  _payload(type: number): Object {
    if (type === 0) {
      const payload = {
        arrivalDate: this.datePipe.transform(this.form.value['arrivalDate'], 'yyyy-MM-dd'),
        categoriesId: [12],
        departureDate: this.datePipe.transform(this.form.value['departureDate'], 'yyyy-MM-dd'),
        serviceId: this.service['additionalInfo']['id'],
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        priceFrom: null,
        priceTo: null,
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        roomId: this.selected['roomId'],
        searchId: this.arrayResults[0]['searchId']
      };
      return payload;
    } else if (type === 1) {
      const payload = {
        arrivalDate: this.datePipe.transform(this.form.value['arrivalDate'], 'yyyy-MM-dd'),
        categoriesId: [12],
        departureDate: this.datePipe.transform(this.form.value['departureDate'], 'yyyy-MM-dd'),
        serviceId: this.service['additionalInfo']['id'],
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        priceFrom: null,
        priceTo: null,
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        rateId: this._rated['rateId'],
        roomId: this.selected['roomId'],
        searchId: this.service['additionalInfo']['searchId']
      };
      return payload;
    } else if (type === 2) {
      const payload = {
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        categoriesId: [12],
        searchId: this.service['additionalInfo']['searchId'],
        serviceId: this.service['additionalInfo']['id'],
        roomId: this.selected['roomId'],
        priceFrom: null,
        priceTo: null,
        arrivalDate: this.datePipe.transform(this.form.value['arrivalDate'], 'yyyy-MM-dd'),
        departureDate: this.datePipe.transform(this.form.value['departureDate'], 'yyyy-MM-dd'),
        rateId: this._rated['rateId']
      };
      return payload;
    } else if (type === 3) {
      const payload = {
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        typeId: this.ticket['additionalInfo']['id'],
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        categoriesId: [12],
        searchId: this.service['additionalInfo']['searchId'],
        serviceId: this.service['additionalInfo']['id'],
        roomId: this.selected['roomId'],
        priceFrom: null,
        priceTo: null,
        arrivalDate: this.datePipe.transform(this.form.value['arrivalDate'], 'yyyy-MM-dd'),
        departureDate: this.datePipe.transform(this.form.value['departureDate'], 'yyyy-MM-dd'),
        rateId: this._rated['rateId'],
        days: this._days
      };
      return payload;
    } else {
      const payload = {
        others: {
          rateId: this._rated['rateId'],
          maxRequestTimeOnSeconds: 60,
          serialized: false,
          days: this._days
        },
        mealPlanId: (this.mealResult !== null && this.mealResult !== undefined) ? this.mealResult['additionalInfo']['id'] : 0,
        ticketTypeId: this.ticket['additionalInfo']['id'],
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        categoriesId: [12],
        searchId: this.service['additionalInfo']['searchId'],
        serviceId: this.service['additionalInfo']['id'],
        roomId: this.selected['roomId'],
        accId: this.selected['accomodationId'],
        priceFrom: null,
        priceTo: null,
        arrivalDate: this.datePipe.transform(this.form.value['arrivalDate'], 'yyyy-MM-dd'),
        departureDate: this.datePipe.transform(this.form.value['departureDate'], 'yyyy-MM-dd'),
        rateId: this._rated['rateId']
      };
      return payload;
    }
  }
  cancel() {
    setTimeout(() => {
      this.selected = null;
      this._days = null;
      this.rates = [];
      this.daysArray = [];
      this.days = [];
      this._rated = null;
      this._resultsQuery = [];
      this.mealResult = null;
      this.ticketsPrice = null;
      this.ticketResult = null;
      this.ticket = null;
      this.totalQty = 0;
    }, 30);
  }
  calculateTotalPrice() {
    this.totalQty = 0;
    this.select.forEach((obj, index) => {
      this.totalQty += this.service.additionalInfo.rooms[index].optionNightsNetTotal * (+obj.selectValue);
      this.service.additionalInfo.rooms[index].reservations = +obj.selectValue;
      this.service.additionalInfo.rooms[index].reserved = +obj.selectValue > 0 ? true : false;
    });
  }

  modalRomms() {
    this.max = 10;
    this.isReadonly = true;
  }
  modalRoom() {
    this.isReadonly = true;
  }
  setCategories(): Array<any> {
    const _categories = [];
    if (this.service['serviceResponse'] !== null) {
      if (this.service['serviceResponse']['RoomTypes']['clsRoomTypesDisney'].length === undefined) {
        _categories.push({
          categorie: this.service['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['RateType'],
          rooms: [{
            roomId: this.service['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['RoomID'],
            room: null,
            AccID: this.service['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['AccID']

          }]
        });
        this.service['additionalInfo']['rooms'].forEach(_room => {
          if (_room['roomId'] == this.service['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['RoomID']) {
            _categories[0]['rooms'][0]['room'] = _room;
          }
        });
      } else {
        let j = 0;
        this.service['serviceResponse']['RoomTypes']['clsRoomTypesDisney'].forEach(room => {
          if (_categories.length === 0) {
            _categories.push({
              categorie: room['RateType'],
              rooms: [{
                roomId: room['RoomID'],
                room: null,
                AccID: room['AccID']
              }]
            });
            this.service['additionalInfo']['rooms'].forEach(_room => {
              if (_room['roomId'] == room['RoomID']) {
                _categories[0]['rooms'][0]['room'] = _room;
              }
            });
          } else {
            let newOne = true;
            _categories.forEach(categorie => {
              if (categorie['categorie'] === room['RateType']) {
                categorie['rooms'].push({
                  roomId: room['RoomID'],
                  room: null,
                  AccID: room['AccID']
                });
                this.service['additionalInfo']['rooms'].forEach(_room => {
                  if (_room['roomId'] == room['RoomID']) {
                    _categories[j]['rooms'].forEach(element => {
                      if (element['room'] === null) {
                        element['room'] = _room;
                      }
                    });
                  }
                });
                newOne = false;
              }
            });
            if (newOne) {
              j++;
              _categories.push({
                categorie: room['RateType'],
                rooms: [{
                  roomId: room['RoomID'],
                  room: null,
                  AccID: room['AccID']
                }]
              });
              this.service['additionalInfo']['rooms'].forEach(_room => {
                if (_room['roomId'] == room['RoomID']) {
                  _categories[j]['rooms'][0]['room'] = _room;
                }
              });
            }
          }
        });
      }
    }
    return _categories;
  }

  _payloadUpdate(payload: any): Object { 
    const form = JSON.parse(localStorage.getItem('form'));


    console.log(this.origin);
    return {
      products: [{
        providerId: this.service.providerId,
        nameAdapter: this.service.nameAdapter,
        categoryId: this.service.categoryId,
        productId: this.service.id,
        productName: this.service.title,
        origin: form.firstOrigin.city_iata_code,
        destination: form.firstDestination.city_iata_code,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.service.dateIni,
        departureDate: this.service.dateEnd,
        qtyProduct: 1,
        qtyPax: 0,
        others: {
          transport: ''
        }
      }]
    };
  }


  addToItinerary(item: any, result: any) {

    // validar si owner esta lleno
    if ( !isValidOwener(this._cookieService.get('clientActive'))){
      //this.toastr.warning(fillOwnerMessage());
      return;
    }
    item.categoryName = this.origin;
    // item.form = this.form !== undefined ? this.form.value : undefined,
    // item.payload =  this.__payloadForm, // this.payload,

    const payload = JSON.parse(localStorage.getItem('payload'));
    console.log('payload addItinerary', payload);
    console.log('results add item', result);
    console.log('selected item productId', this.selected);
    
    item.payload =  payload,
    item.id = result.id + '-' + item.id,
    item.idProduct = this.selected.productId,
    item.destination = result.destination,
    item.origin = result.origin,
    item.searchId = this.arrayResults[0].searchId,
    item.providerId = result.providerId,
    item.providerName = result.providerName,
    item.title = result.title,
    item.location = result.additionalInfo.location,
    item.arrivalDate = result.arrivalDate,
    item.departureDate = result.departureDate,//result.dateEnd
    item.updateDate = new Date(),
    item.payloadUpdate = this._payloadUpdate(this.payload),
    item.productKey = this.getRandomUniqueId();
    item.totalPrice = 0;
    item.blickoins = 0;
    item.creditBlickoins = 0;
    item.additionalInfo = result.additionalInfo.transfers.contractList;
    item.serviceResponse = null;
    item.transferId = result.transferId;


    item.amount = result.amount;
    item.expirationDate = result.expirationDate;
    item.bookParam = result.bookParam;

    console.log('Res 0: ', item);
    // se adiciona destination_info
    item.destination = this._itineraryService.getDestinationInfo(this.form, item);
    this._itineraryService.sendMessageSubscriptorLoading(true);
    this._itineraryService.add(item).subscribe(
      (response:any) => {
        console.log('Product added successfully', response);
        if (typeof response === 'string') {
          //this.toastr.error(response);
        } else {
          //this.toastr.success('Product added successfully to your itinerary');
        }
        this._itineraryService.sendMessageSubscriptorLoading(false);
      },
      error => {
        console.log('Product not added', error);
        this._itineraryService.sendMessageSubscriptorLoading(false);
        //this.toastr.error('An error occurred, product was not added', 'Error');
      }
    );
  }


  continue() {
   /* console.log('---ModalRoomsComponet--- continue()---');
    this.addItinerary();
    this._emitRefModal();*/


    const optionToReserved = this.selected;
  //   console.log("this.pax, this.payload['qtyPax']", this.pax, this.payload['qtyPax'])
    //if (this.pax + this.__payloadForm['qtyPax'] <= this.payload['qtyPax']) {  

    // try {
    //  if (this.pax <= this.payload['qtyPax']) {
        // validar que no se agregue mas servicios que pax
        console.log ("optionToReserved", optionToReserved);
        this.addToItinerary(optionToReserved, this.service);
       // this.addItinerary();
        this._emitRefModal();

        this._itineraryService.updateItinerary(this._itineraryService.itinerary);
 //     } else {
    //    this.hideModal('error - Error - Item was not added to your itinerary');
  //    }
    //} catch (error) {
      // this.hideModal('error - Error - Item was not added to your itinerary');
    //}

  }

  hideModal(status: string) {
    const stat = status.split(' - ');
    const type = stat[0];
    const title = stat[1];
    const message = stat[2];

    switch (type) {
      case 'success': this.options.timeOut = 360; //this.toastr.success(title, message);
        break;
      case 'error': this.options.timeOut = 1060;  //this.toastr.error(title, message);
        break;
      case 'warning': //this.toastr.warning(title, message);
        break;
    }
  }
  addItinerary() {
    //validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      //this.toastr.warning(fillOwnerMessage());
      return;
    }
    console.log('ModalRoomsComponent--- addItinerary()');
    console.log(this.origin);
    console.log(this.arrayResults);
    this.item = {
      id: this.service['id'] , ///+ ' - ' + this.selected['roomId'],
      categoryName: 'TRANSPORT',
      payload: this.payloadUpdate,
      payloadUpdate: this.payloadUpdate,
      form: this.form.value === undefined ? this.form : this.form.value,
      arrayResults: this.arrayResults,
      service: this.service,
      room: this.selected,
      plan: this._rated,
      mealPlan: this.mealResult,
      ticket: this.ticket,
      days: this._days,
      prices: {
        tickets: this.ticketsPrice,
        package: this.price,
        total: this.totalQty
      }
    };
    console.log('harakiri', this.item);
    if ((this._item !== null) && (this._item !== null)) {
      // this._itineraryService.updateDisneyItem(this.item, this._item);
    } else {
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(this.item).subscribe(
        (response:any) => {
          if (typeof response === 'string') {
            //this.toastr.error(response);
          } else {
            //this.toastr.success('Product added successfully to your itinerary');
          }
          this._itineraryService.sendMessageSubscriptorLoading(false);
        },
        error => {
          console.log('Product not added', error);
          //this.toastr.error('An error occurred, product was not added', 'Error');
          this._itineraryService.sendMessageSubscriptorLoading(false);
        }
      );
    }
  }

  getRandomUniqueId(){
    return Math.floor(Math.random() * 20000000000000) + Date.now();
  }

  _emitRefModal() {
    this.refModal.emit('pers');
  }
}
