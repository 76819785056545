import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { setting } from '../../../../../setting';

@Injectable({
  providedIn: 'root'
})
export class RequestBilledFormService {

  constructor(private _http: HttpClient) { }


  getConditionIva(): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.set('ApiUser', setting.abacoApiUser);
    headers = headers.set('apiKey', setting.abacoApiKey);

    const url = `${setting.apiAbacoUrl}ivaConditions`;

    return this._http.get<any>(url, { headers: headers });
  }

  getEnterprise(): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.set('ApiUser', setting.abacoApiUser);
    headers = headers.set('apiKey', setting.abacoApiKey);

    const url = `${setting.apiAbacoUrl}enterprises`;

    return this._http.get<any>(url, { headers: headers });
  }

  getClients(): Observable<any> {

    const url = `${setting.apiBookingUrl}clientsAllBilled`;

    return this._http.get<any>(url);
  }
}
