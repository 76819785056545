<!-- <simple-notifications [options]="options"></simple-notifications> -->
<div id="hotel-result-component">
  <div class="d-flex col-12 m-0 p-0 pos-div">
    <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12 hotel-image">
      <div class="col-12 m-0 p-0">
        <img
          class="img-hotel"
          *ngIf="result?.additionalInfo?.transfers?.vehicles[0]?.images[0].thumbnail"
          src="{{ result?.additionalInfo?.transfers?.vehicles[0]?.images[0].thumbnail }}"
          alt="Transport"
        />
        <!-- <img class="img-hotel" *ngIf="!result['additionalInfo']['transfers']['vehicles'][0]['images'][0]" src="assets/img/app/tranports-default.png" alt="Transport"> -->
        <div class="description-car d-flex pl-2 justify-content-between align-items-center">
          <div *ngIf="result['additionalInfo']['transfers']['vehicles'][0]['maxPax'] !== null" class="flex-column d-flex text-center pl-2">
            <span class="capacity p-0 m-0"
              >{{ result["additionalInfo"]["transfers"]["vehicles"][0]["maxPax"] }}<i class="icon icon-asiento p-0 m-0"></i
            ></span>
            <span class="detail-description-car">Seating</span>
          </div>
          <div *ngIf="result['additionalInfo']['transfers']['vehicles'][0]['maxBag'] !== null" class="flex-column d-flex text-center">
            <span class="capacity">{{ result["additionalInfo"]["transfers"]["vehicles"][0]["maxBag"] }}<i class="icon icon-bag"></i></span>
            <span class="detail-description-car">Bag</span>
          </div>
          <div class="flex-column d-flex justify-content-end align-items-end">
            <!---<i class="icon icon-ai"></i>
                  <span class="d-flex detail-description-car">AirCondition</span>-->
            <img class="img-logo" *ngIf="result?.logo" src="{{ result.logo }}" alt="logo" style="padding-left: 144px" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-xg-7 col-lg-7 col-md- 5 col-sm-12 hotel-details py-2 pl-2 d-flex flex-column justify-content-between">
            <div class="description p-0 m-0 d-flex flex-column">
              <h4>{{result.title}}</h4>
              <p>{{result.description | truncate : 250 : "  "}}
                <a (click)="ModalRooms.show()" class="more">...More</a>         
                  <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" [modalRoomRef]="ModalRooms"
                  [product]="result" [productQty]="payload.qtyProduct" [form]="form" [origin]="origin" [arrayResults]="arrayResults"></usb-modals>
              </p>
            </div>
            <div class="d-flex flex-column pr-2 pl-1 pt-5 location">
                <span class="d-flex"><b class="pr-2">Pickup In Location:</b>
                  {{result.additionalInfo.location === 'USA - ' ? result.additionalInfo.location + result['origin'] : (result.additionalInfo.location !== undefined) && (result.additionalInfo.location !== null) && (result.additionalInfo.location !== '') ? result.additionalInfo.location.split('/')[0] : result['origin']}} | {{result['dateIni'] | date:'MMM dd, yyyy'}}</span>
                <span class="d-flex"><b class="pr-2">Pickup In Time: {{result.additionalInfo.transfers.pickupTime}}</b></span>
                <span class="d-flex"><b class="pr-2">Pickup Out Location:</b>{{result.additionalInfo.location === 'USA - ' ? result.additionalInfo.location + result['destination'] : (result.additionalInfo.location !== undefined) && (result.additionalInfo.location !== null) && (result.additionalInfo.location !== '') ? result.additionalInfo.location.split('/')[1] : result['destination']}} | {{result['dateEnd'] | date:'MMM dd, yyyy'}}</span>
                <span class="d-flex"><b class="pr-2">Pickup Out Time: {{result.additionalInfo.transfers.dropoffTime}}</b></span>
            </div>
            <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12">
              <div class="col-12 m-1 p-0">
                <img class="img-logo" *ngIf="result?.logo" src="{{result.logo }}" alt="logo">
              </div>
            </div>
        </div> -->

    <div class="col-xg-7 col-lg-7 col-md- 5 col-sm-12 hotel-details py-2 pl-3 pr-3 pt-3">
      <div class="row">
        <div class="col-xg-7 col-lg-5 col-md-5 col-sm-12">
          <h4>{{ result.title }}</h4>
        </div>
        <!-- <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 font-view text-center">
                      <i class="icon icon-view"></i>
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 font-view text-center">
                      <span >2500</span>
                      <p style="line-height:2px"> views</p>
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center">
                      <i class="icon icon-video-1"></i> 
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center">
                      <i class="icon icon-video-2"></i> 
                  </div> -->
        <div class="col-lg-7 col-lg-3 col-md-3 col-sm-12 pl-5 text-center">
          <button class="numberDay">{{ numberDaysTrip }}</button>
          <span class="font-days pl-0"> DÍAS</span>
        </div>
      </div>
      <div class="description-location pt-1 d-flex flex-column">
        <p>{{ result.description | truncate : 130 : "  " }}</p>
      </div>
      <div class="d-flex flex-column pr-2 pl-3 pt-3">
        <span class="d-flex description-location"
          ><b class="pr-2 location">Lugar de recogida </b>
          {{
            result.additionalInfo.location === "USA - "
              ? result.additionalInfo.location + result["origin"]
              : result.additionalInfo.location !== undefined &&
                result.additionalInfo.location !== null &&
                result.additionalInfo.location !== ""
              ? result.additionalInfo.location.split("/")[0]
              : result["origin"]
          }}
          | {{ result["dateIni"] | date : "MMM dd, yyyy" }} &nbsp;&nbsp; {{ result.additionalInfo.transfers.pickupTime }}
        </span>

        <span class="d-flex description-location">
          <b class="pr-2 location">Lugar de entrega </b
          >{{
            result.additionalInfo.location === "USA - "
              ? result.additionalInfo.location + result["destination"]
              : result.additionalInfo.location !== undefined &&
                result.additionalInfo.location !== null &&
                result.additionalInfo.location !== ""
              ? result.additionalInfo.location.split("/")[1]
              : result["destination"]
          }}
          | {{ result["dateEnd"] | date : "MMM dd, yyyy" }} &nbsp;&nbsp; {{ result.additionalInfo.transfers.dropoffTime }}
        </span>
      </div>
      <div class="row pt-3">
        <div class="col-xg-7 col-lg-10 col-md-10 col-sm-12 text-center"></div>
        <div *ngIf="discountBoolean" class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center pr-0">
          <span class="font-desc">15</span>
        </div>
        <div *ngIf="discountBoolean" class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center pl-0">
          <i class="icon icon-descuento"></i>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view-number pl-4">
          <span class="font-number"> {{ result["additionalInfo"]["transfers"]["vehicles"][0]["maxPax"] }} </span>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-3">
          <i class="icon icon-user2"></i>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-0">
          <span class="font-desc-2">Personas</span>
          <p class="font-desc-2" style="line-height: 2px">X&nbsp;Vehiculos</p>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view-number pl-4">
          <span class="font-number pl-3"> {{ result["additionalInfo"]["transfers"]["vehicles"][0]["maxBag"] }} </span>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-3">
          <i class="icon icon-maleta"></i>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-3">
          <span class="font-desc-2">Valijas</span>
          <p class="font-desc-2" style="line-height: 2px">X&nbsp;Vehiculos</p>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view-number pl-4">
          <span class="font-number pl-3"> {{ result["additionalInfo"]["transfers"]["vehicles"][0]["entryMaximumWaitingTime"] }} </span>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-3">
          <i class="icon icon-time"></i>
        </div>
        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-3">
          <span class="font-desc-2">Tiempo</span>
          <p class="font-desc-2" style="line-height: 2px">X&nbsp;Espera</p>
        </div>
        <!-- <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view-number pl-4">
                  <span class="font-number pl-3"> 10 </span>
                </div>
                <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-left font-view pl-3">
                  <i class="icon icon-maleta2"></i>
                </div> -->
        <!-- <div class="col-xg-7 col-lg-2 col-md-2 col-sm-12 text-left font-view pl-1 pr-0">
                  <span class="font-desc-2">Valijas de Mano</span>
                  <p class="font-desc-2" style="line-height:2px">X&nbsp;Vehiculos</p>
                </div> -->
        <div class="col-xg-7 col-lg-2 col-md-2 col-sm-12 text-left font-view pl-2">
          <i class="icon icon-medal" style="font-size: 1.5rem !important">
            <span class="font-point" style="padding-left: 33px"> {{ result["additionalInfo"]["transfers"]["blickoins"] }}&nbsp;Bks. </span>
          </i>
        </div>
      </div>
    </div>
    <div class="col-xg-2 col-lg-2 col-md-3 col-sm-12 flex-column align-items-middle d-flex continue">
      <div class="d-flex mt-auto flex-column pt-4 pl-3">
        <span class="text-price">{{ result["additionalInfo"]["transfers"]["totalPriceFlag"] }}Desde</span>
        <div *ngIf="!result.additionalInfo.transfers.vehicles[0].totalPrice ? true : false">
          <a class="loader text-center justify-content-center">
            <i class="fa fa-spinner fa-spin"></i>
          </a>
        </div>
        
        <span *ngIf="result['additionalInfo']['transfers']['vehicles'][0]['totalPrice'] ? true : false" class="search-price text-right">
          <span class="usd text-right"> USD </span
            >{{ result["additionalInfo"]["transfers"]["vehicles"][0]["totalPrice"] | number : "1.2-2" }}
           
        </span>
        <span>Total Viaje</span>
        <p style="font-size: 11px; font-weight: 600">Imp. incluidos</p>
        <span style="color: black; font-size: 9px;">Pol. de cancelación
          <p style="font-size: 11px; font-weight: 600; color: red">
            {{ result.additionalInfo.transfers.vehicles[0].cancellation_policy.dateFrom | date : "dd-MM-YY" }}
            <br />
          </span>
            <!-- <span style="font-size: 13px; color: red;  font-weight: bold;">P. Costo:&nbsp;{{result['additionalInfo']['transfers']['totalPrice'] | number:'1.2-2'}}</span> -->
      </div>
      <div class="d-flex mt-auto flex-column pl-3">
        <!-- <span class="text-price">{{result['additionalInfo']['transfers']['vehicles'].length === 1 ? "One Trip" : "Round Trip"}}</span> -->
        <span class="text-price">{{ typeTrip === "oneTrip" ? "Un viaje" : "Ida y Vuelta" }}</span>
      </div>
      <div class="mt-auto py-2 text-center">
        <button
          type="button"
          (click)="openModalTransfer(ModalTranfer, 'TRANSPORTS')"
          class="btn btn-usblick-pink btn-green mb-3 justify-content-center w-100 py-1 font-weight-bold"
        >
          Agregar
        </button>
        <!-- <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" 
              tabindex="-1" role="dialog" aria-hidden="true" 
              [modalRoomRef]="ModalRooms"
              [product]="result"
              [payload]="payload"
               [productQty]="payload.qtyProduct" 
              [form]="form" [origin]="origin"
               [arrayResults]="arrayResults"></usb-modals> -->

        <!--<button type="button" (click)="add()" class="btn btn-usblick-pink btn-green mb-3 justify-content-center w-100 py-1 font-weight-bold"
              [disabled]="!(result['additionalInfo']['transfers']['totalPriceProf'] ? true : false)">
                ADD
              </button> -->
      </div>
    </div>
  </div>
</div>
