import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { setting } from '../../../../../setting';
import { RestService } from 'app/shared/rest.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'usb-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss']
})
export class SelectItemsComponent implements OnInit {

  @Input() items:any;
  @Input() currencyItinerary: string;

  @Output() nextStep = new EventEmitter<any>();
  @Output() selectItem = new EventEmitter<any>();
  @Output() paymentCreditCardPending = new EventEmitter<any>();
  @Output() errorQueryCreditCardPending = new EventEmitter<any>();
  getClientsSettingAppBooking: any;
  paymentCreditCards: any;

  rate

  selectAllMode = false;
  constructor(private _restService: RestService) {

  }

  ngOnInit() {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }

  deSelectAll(){
    this.selectAllMode = !this.selectAllMode;
    for (let index = 0; index < this.items.length; index++) {
      if(this.items[index].selected == true){
        this.items[index].selected = false;
        this.selectItem.emit(this.items[index]);
      }
    }
  }

  selectAll(){
    this.selectAllMode = !this.selectAllMode;
    for (let index = 0; index < this.items.length; index++) {
      if(this.items[index].selected == false){
        this.items[index].selected = true;
        this.selectItem.emit(this.items[index]);
      }
    }
  }

  addItem(item, i){
    this.selectItem.emit(item);
  }
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

}
