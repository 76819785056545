<div style="position: relative;">


  <div class="d-flex flex-row justify-content-xl-between header-pay"
       [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
    <p *ngIf="type === 'payment'" class="font-poppins text-white">PROCESO DE PAGO </p>
    <p *ngIf="type === 'request-billed'" class="font-poppins text-white">SOLICITUD DE FACTURACIÓN</p>
    <button type="button" class="close btn-close" title="Close" (click)="closeTop()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- step by step numeros -->
  <div class="row py-4 container-step">
    <!-- <div class="line-step"></div> -->
    <div class="col-3 d-flex justify-content-center position-relative first-step"
    [ngClass]="{'first-step-active': step === 2}"
    style="text-align: center; flex-direction: column;">
      <div class="circle font-poppins d-flex justify-content-center align-items-center "
      [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"
        [ngClass]="{'circle-step': step == 1}" style="margin: 0 auto;">
        01
      </div>
      <div>
        <p style="font-size: 14px;">SELECCIONAR</p>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center position-relative two-step"
    [ngClass]="{'two-step-active-after': step === 2,
                'two-step-active-before': step === 3}"
    style="text-align: center; flex-direction: column;">
      <div class="circle font-poppins d-flex justify-content-center align-items-center"
      [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"
        [ngClass]="{'circle-step': step == 2}" style="margin: 0 auto;">
        02
      </div>
      <div>
        <p style="font-size: 14px;">CONFIRMAR</p>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center position-relative three-step"
    [ngClass]="{'three-step-active-after': step === 3,
                'three-step-active-before': step === 4}"
    style="text-align: center; flex-direction: column;">
      <div class="circle font-poppins d-flex justify-content-center align-items-center"
      [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"
        [ngClass]="{'circle-step': step == 3}" style="margin: 0 auto;">
        03
      </div>
      <div>
        <p *ngIf="type === 'payment'" style="font-size: 14px;">PAGAR</p>
        <p *ngIf="type === 'request-billed'" style="font-size: 14px;">FORMULARIO</p>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center position-relative four-step"
    [ngClass]="{'four-step-active': step === 4}"
    style="text-align: center; flex-direction: column;">
      <div class="circle font-poppins d-flex justify-content-center align-items-center"
      [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"
        [ngClass]="{'circle-step': step == 4}" style="margin: 0 auto;">
        04
      </div>
      <div>
        <p *ngIf="type === 'payment'" style="font-size: 14px;">DOCUMENTACION</p>
        <p *ngIf="type === 'request-billed'" style="font-size: 14px;">SOLICITUD</p>
      </div>
    </div>

  </div>

  <!-- <div class="row justify-content-between mt-4 mt-2">

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 1}">
      SELECCIONAR
    </div>

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 2}">
      CONFIRMAR
    </div>

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 3}">
      PAGO
    </div>

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 4}">
      DOCUMENTACIÓN
    </div>


  </div> -->

  <div class="row mt-3 mb-3">

    <ng-container *ngIf="step == 1">
      <usb-select-items class="col-12 px-0"
          [items]="allItems"
          [currencyItinerary]="currencyItinerary"
          (selectItem)="handleItem($event)"
      ></usb-select-items>
    </ng-container>

    <ng-container *ngIf="step == 2">
      <usb-confirm-items class="col-12 px-0"  [items]="selectedItems" [currencyItinerary]="currencyItinerary"></usb-confirm-items>
    </ng-container>

    <ng-container *ngIf="type === 'payment' && step == 3">
      <usb-payment-items class="col-12 px-0"
        [items]="selectedItems"
        [itineraryItems]="itinerary.items"
        [totalPrice]="totalPrice"
        [paymentMethodsArr]="paymentMethods"
        [currencyItinerary]="currencyItinerary"
        [creditCardPending]="creditCardPending"
        [creditCardPayPartial]="creditCardPayPartial"
        [creditCardDataPending]="creditCardDataPending"
        (leaveTotal)="handleLeaveTotal($event)">
      </usb-payment-items>
    </ng-container>

    <ng-container *ngIf="type === 'request-billed' && step == 3">
      <usb-request-billed-form class="col-12 px-0"
        [items]="selectedItems"
        [itineraryItems]="itinerary.items"
        [currencyItinerary]="currencyItinerary"
        (payloadRequestBilling)="submitFormRequestBilling($event)"
        (leaveTotal)="handleLeaveTotal($event)">
      </usb-request-billed-form>
    </ng-container>

    <ng-container *ngIf="step == 4 && consultingPay">
      <usb-documentation class="col-12 px-0"
                        [items]="selectedItems"
                        [totalPrice]="totalPrice"
                        [paymentMethods]="paymentMethods"
                        [currencyItinerary]="currencyItinerary"
                        [amountBLickoinsItineraryPay]="amountBLickoinsItineraryPay"
                        [amountCardItineraryPay]="amountCardItineraryPay"
                        [amountDepositItineraryPay]="amountDepositItineraryPay"
                        [amountTransferItineraryPay]="amountTransferItineraryPay"
                        [itinerary]="itinerary"
                        [type]="type"
      >
      </usb-documentation>
    </ng-container>

    <ng-container *ngIf="step == 4 && type === 'request-billed'">
      <usb-documentation class="col-12 px-0"
                        [items]="selectedItems"
                        [totalPrice]="totalPrice"
                        [paymentMethods]="paymentMethods"
                        [currencyItinerary]="currencyItinerary"
                        [itinerary]="itinerary"
                        [type]="type"
      >
      </usb-documentation>
    </ng-container>


  </div>


  <!-- btn back next -->
  <div  class="row d-flex justify-content-between mt-4 bt-4" style="padding-bottom: 30px;">
    <!-- <div class="d-flex flex-row align-items-center justify-content-center mb-2 ml-auto faltante"
      *ngIf="step==3 && leaveTotal != null">
      <p class="m-0 font-poppins" [ngClass]="{'color-red': leaveTotal < 0}">
        RESTAN:</p>
      <div class="d-flex justify-content-center ml-3 monto-faltante">
        <p class="m-0 font-poppins">$ {{this.leaveTotal | number:'1.2-2'}}</p>
      </div>
    </div> -->
    <div *ngIf="type === 'payment'"
          class="col-12 card p-3 mb-4"
         style="color: white;"
         [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <div class="d-flex flex-row align-items-center">

            <p class="m-0 p-0 text-total font-poppins">MONTO TRANSACCIÓN</p>

            <!-- <div class="d-flex ml-2" *ngIf="step == 1 || step == 2 || step == 3"> -->
              <!--
            <i class="icon-small icon-alojamiento iconstart text-center pl-1" style="font-size: 25px;"
              *ngIf="verifyCategory('HOTELS')"></i>
            <i class="icon-small icon-actividades_exterior iconstart text-center pl-1" style="font-size: 25px;"
              *ngIf="verifyCategory('TOURS')"></i>
            <i class="icon-small icon-bus iconstart text-center pl-1" style="font-size: 25px;"
              *ngIf="verifyCategory('TRANSPORTS')"></i> -->

              <!-- <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('HOTELS')">
                <i class="icons-hotel"></i>
                <span *ngIf="verifyQuantityCategory('HOTELS') > 0">{{verifyQuantityCategory('HOTELS')}}</span>
              </div>

              <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('TOURS')">
                <i class="icons-adventure"></i>
                <span *ngIf="verifyQuantityCategory('TOURS') > 0">{{verifyQuantityCategory('TOURS')}}</span>
              </div>

              <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('TRANSPORTS')">
                <i class="icons-transporte"></i>
                <span *ngIf="verifyQuantityCategory('TRANSPORTS') > 0">{{verifyQuantityCategory('TRANSPORTS')}}</span>
              </div>

              <div class="items-select d-flex align-items-center mr-2" *ngIf="verifyCategory('RENT-A-CAR')">
                <i class="icons-car"></i>
                <span *ngIf="verifyQuantityCategory('RENT-A-CAR') > 0">{{verifyQuantityCategory('RENT-A-CAR')}}</span>
              </div> -->

              <!-- <i class="icon-small icon-alojamiento iconstart text-center pl-1 ml-3" style="font-size: 25px;" *ngIf="verifyCategory('HOTELS')"></i> -->

              <!-- <i class="icon-small icon-actividades_exterior iconstart text-center pl-1 ml-3" style="font-size: 25px;" *ngIf="verifyCategory('TOURS')"></i>  -->

              <!-- <i class="icon-small icon-bus iconstart text-center pl-1 ml-3" style="font-size: 25px;" *ngIf="verifyCategory('TRANSPORTS')"></i> -->

              <!-- <div class="items-select mr-2" *ngIf="verifyCategory('PTHEMES')">
                <i class="icon-small icon-pthemes iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
                <span *ngIf="verifyQuantityCategory('PTHEMES') > 0">{{verifyQuantityCategory('PTHEMES')}}</span>
              </div> -->

              <!-- <i class="icon-small icon-car iconstart text-center pl-1 ml-3" style="font-size: 25px;" *ngIf="verifyCategory('RENT-A-CAR')"></i> -->

              <!-- <div class="items-select mr-2" *ngIf="verifyCategory('INSURANCE')">
                <i class="icon-small icon-seguro iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
                <span *ngIf="verifyQuantityCategory('INSURANCE') > 0">{{verifyQuantityCategory('INSURANCE')}}</span>
              </div>

              <div class="items-select mr-2" *ngIf="verifyCategory('CRUISES')">
                <i class="icon-small icon-crucero iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
                <span *ngIf="verifyQuantityCategory('CRUISES') > 0">{{verifyQuantityCategory('CRUISES')}}</span>
              </div>

              <div class="items-select mr-2" *ngIf="verifyCategory('DISNEY')">
                <i class="icon-small icon-disney iconstart text-center pl-1 ml-3" style="font-size: 25px;"></i>
                <span *ngIf="verifyQuantityCategory('DISNEY') > 0">{{verifyQuantityCategory('DISNEY')}}</span>
              </div>

            </div> -->
          </div>


        </div>

        <div class="col-6">
          <div class="text-right ">
            <p *ngIf="creditCardPayPartial" class="m-0 price-total font-poppins">TOTAL {{currencyItinerary}} {{partialLeftCreditCard | number:'1.2-2'}}</p>
            <p *ngIf="creditCardDataPending.length > 0 && !creditCardPayPartial" class="m-0 price-total font-poppins">TOTAL {{currencyItinerary}} {{amountFilterNull | number:'1.2-2'}}</p>
            <p *ngIf="creditCardDataPending.length === 0" class="m-0 price-total font-poppins">TOTAL {{currencyItinerary}} {{this.totalPrice | number:'1.2-2'}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex ml-auto justify-content-end px-2">
      <!-- <button class="btn btn-action btn-close ml-2" (click)="close()" *ngIf="step == 1">
      CLOSE
    </button> -->

      <button class="btn btn-action rounded-pill btn-back ml-2"
              (click)="goBack()"
              *ngIf="step > 1 && step < 4 && creditCardDataPending.length === 0"
              [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"
              [disabled]="paymentSync">
        VOLVER
      </button>

      <div class="d-flex justify-content-end">
        <button class="btn btn-action rounded-pill btn-continue ml-2"
                (click)="next()"
                *ngIf="step < 4 && type === 'payment'"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                [disabled]="verifyNext() || paymentSync">
          CONTINUAR
        </button>

        <button class="btn btn-action rounded-pill btn-continue ml-2"
                (click)="next()"
                *ngIf="step < 4 && type === 'request-billed'"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                [disabled]="verifyNext()">
            CONTINUAR
        </button>

        <button class="btn btn-action rounded-pill btn-continue ml-2" style="font-size: 16px;" (click)="close()" *ngIf="step == 4"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
          CERRAR
        </button>
      </div>

    </div>

  </div>

</div>
