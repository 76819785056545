
import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { formatArrivalDate } from '../../../utils/Functions';
import { ItineraryService } from '../../../shared/itinerary.service';
import {Router, NavigationExtras} from "@angular/router";
import { CookieService } from 'ngx-cookie';
import { RestService } from '../../../shared/rest.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../pages/com.usblick.common/auth/user.service';
import { MatDialog } from '@angular/material/dialog';
import { FidelityService } from '../../../fidelity/fidelity.service';
import { ApiService } from '../../../itinerary/shared/api.service';
import { setting } from '../../../../../setting';
import * as moment from 'moment';
import { DynamicFontSizePipe } from 'app/shared/dynamic-font-size.pipe';

interface IItemItinerary {
  date: Date;
  position: number;
  checkout: boolean;
}
@Component({
  selector: 'usb-yourtrip-summary',
  templateUrl: './yourtrip-summary.component.html',
  styleUrls: ['./yourtrip-summary.component.scss'],
  providers: [
    DynamicFontSizePipe
  ]
})
export class YourtripSummaryComponent implements OnInit {

  @Input() actualItinerary: any;
  @Output() partialReserveEmitter = new EventEmitter<boolean>();
  @Input() qtyItemsToPartial: number;
  @Output() resetItemToPartial = new EventEmitter<number>();

  formatDate = formatArrivalDate;
  flyQty: string;
  nameItinerary: string;
  arrival: string;
  departure: string;
  totalPrice: number;
  destinySecuency: string;
  nigths: number = 0;
  adults: number = 0;
  children: number = 0;
  products: any;
  partialReserve: boolean = false;
  _cookieClient: any = undefined;
  status: string;
  continueToReserve:boolean = false;
  enablePartialReserve:boolean = false;
  public getClientsSettingAppBooking: any;
  nightsTotalDiff:number = 0;
  getOrigin:string = '';
  appLoading: boolean = false;
  fontSizeAdjustments = { 4: 0, 6: -2, 9: -6 }; // Define los ajustes en el componente


  totalNetPriceBooking = 0;
  totalBlickoins = 0;
  daysValue = 0;
  nightValue = 0;
  arrivalDateValue:string;
  departureDateValue:string;
  public totalAdults: number;
  public totalChildren: number;

  totalTaxPrice: number = 0;
  totalNetPriceBookingFreeTax: number = 0;
  optionNightsNetTotalProf: number = 0;
  currencyItinerary: string;
  qtyNights:number = 0;
  qtyDays:number = 0;
  totalProviderTransports:number = 0;

  public items: any[] = [];

  constructor(
    private itineraryService: ItineraryService,
    private router: Router,
    public vcr: ViewContainerRef,
    private _cookieService: CookieService,
//
    private spinner: NgxSpinnerService,
    private restService: RestService,
    public userService:UserService,
    private dialog:MatDialog,
    public fidelityService:FidelityService,
    private apiService:ApiService,
    )
    {
      this.status = 'false';
      //this.toastr.setRootViewContainerRef(this.vcr);
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    }

  ngOnInit() {
    this.getOrigin = localStorage.getItem('origin');
    console.log('origin obtenido ->', this.getOrigin);

    this.initComponent();
    this._loadCheckout();

    console.log('actualItinerary yourtrip ->', this.actualItinerary);

    // console.log('actualItinerary items ->', this.actualItinerary.items);



    let suscription = this.itineraryService.getItinerary$();
    suscription.subscribe((data:any) => {
      if (data) {
        this.actualItinerary = data[0];
        if (this.actualItinerary) {
          this.totalNetPriceBooking =  this.actualItinerary.totalNetPriceBooking;
          this.totalBlickoins =  this.actualItinerary.totalBlickoins;
          this.totalTaxPrice = this.actualItinerary.totalTaxPrice;
          this.optionNightsNetTotalProf = this.actualItinerary.optionNightsNetTotalProf;
          this.totalNetPriceBookingFreeTax = this.actualItinerary.totalNetPriceBookingFreeTax;
          this.currencyItinerary = this.actualItinerary.currency;
        }
        this._loadCheckout();
      }

    });
    this.totalNetPriceBooking =  this.actualItinerary.totalNetPriceBooking;
    this.totalBlickoins =  this.actualItinerary.totalBlickoins;
    this.totalTaxPrice = this.actualItinerary.totalTaxPrice;
    this.totalNetPriceBookingFreeTax = this.actualItinerary.totalNetPriceBookingFreeTax;
    this.totalProviderTransports = this.actualItinerary.totalNetPriceCategory
    this.currencyItinerary = this.actualItinerary.currency;
    // this.getDaysDif();
    const passengerData = this.restService.getPassengersData();
    let booleanTimer = this.restService.getBooleanTimer();

    console.log('passegerData ->', passengerData);

    if(passengerData != null && booleanTimer){
        this.getDataForm(passengerData);
    }

    this.getDaysAndNights();
  }

   /**
   * construye un array de formato IItemItinerary con:
   * checkout false con arrivalDate.
   * checkout true con departureDate.
   * Luego se ordena el array en base a la fecha y por ultimo
   * crea un nuevo objeto con el orden correcto, y el flag checkout.
   * @private
   */
   private _loadCheckout() {

    this.items = [];


    if(this.actualItinerary !== undefined && this.actualItinerary.length > 0) {

      this.items = (<IItemItinerary[]>[
        ...this.actualItinerary.items.map((x, i) => (
          {
            date: new Date(x.arrivalDate),
            position: i,
            checkout: false
          }
        )),
        ...this.actualItinerary.items.map((x, i) => ({ date: new Date(x.departureDate), position: i, checkout: true }))
      ]).sort((a, b) => a.date.valueOf() - b.date.valueOf())
        .map(x => ({ ...this.actualItinerary.items[x.position], ckeckout: x.checkout }));




    if(this.items.length == 0){
      this.router.navigateByUrl('/home');
    }
    }

    // console.log(this.actualItinerary)

    if (this.actualItinerary && this.actualItinerary.items && Array.isArray(this.actualItinerary.items)) {
      // Calcula la suma de paxAdults y paxChildren
      this.totalAdults = this.actualItinerary.items.reduce((sum, item) => sum + (item.paxAdults || 0), 0);
      this.totalChildren = this.actualItinerary.items.reduce((sum, item) => sum + (item.paxChildren || 0), 0);
    }
  }


  initComponent() {
    if (this.actualItinerary) {
      this.nameItinerary = this.actualItinerary.title;
      let arrivalDates = [];
      let departureDates = [];
      let origin: string;

      if(this.actualItinerary.items.length > 1){
        this.enablePartialReserve = true;
      }

      this.actualItinerary.items.forEach(date => {

        if(date.status_id == 1){
          this.continueToReserve=false;
        }

        arrivalDates.push(date.arrivalDate);
        departureDates.push(date.departureDate);
        if (date.categoryName === 'INSURANCE') {
          origin = date.origin;
        }
      });

      arrivalDates.sort();
      departureDates.sort().reverse();

      this.arrival = this.actualItinerary.arrivalDate ;
      this.departure = this.actualItinerary.departureDate ;
      this.destinySecuency = this.getDestinySecuency(this.actualItinerary.destination, origin);
      // this.totalPrice = this.getTotalPrice(this.actualItinerary.items);
      this.totalPrice = this.actualItinerary.totalPrice;
      this.getFlyItinerary();

      let suscription = this.itineraryService.getItinerary$();
      suscription.subscribe((data:any) => {
        if (data && Object.keys(data).length > 0) {
          this.actualItinerary = data[0];
          this.destinySecuency = this.getDestinySecuency(this.actualItinerary.destination, origin);
        }
      });

    }

  }

  getFlyItinerary(){

    //console.log('calculo vuelo',this.actualItinerary);
    this.apiService.doGet('itinerary/' +Number(this.actualItinerary.id) +'/fly').subscribe(
    //this.apiService.doGet('fly_itineraries').subscribe(
      (response:any) => {
        //console.log('Reservas', response);

        if(response == undefined){
          this.flyQty = '0';
        }else{
          this.flyQty = '1';
        }
      },
      error => {
        console.log("vuelo error", error);
        this.flyQty = '0';
      }
    );

  }

  /**
   * get string secuency destinys
   * @param destinys
   */
  getDestinySecuency(destinys: Array<any>, origin?: string): string {
    let secuency = '';
    if (origin) {
      if (destinys.length === 1) {
        secuency = origin + ' > ' + destinys[0].iata_code;
      } else {
        secuency = origin;
      }
    }
    if (destinys) {
      for (let i = 0; i < destinys.length; i++) {
        let depar1 = new Date(destinys[i].destination_info.departure);
        let depar2 = new Date(this.departure);
        this.nigths += parseInt(destinys[i].destination_info.nights);
        this.adults = destinys[i].destination_info.adults > this.adults ?
        destinys[i].destination_info.adults : this.adults;
        this.children = destinys[i].destination_info.childs > this.children ?
        destinys[i].destination_info.childs : this.children;
        //this.departure = depar1 > depar2 ? depar1.toString() : depar2.toString();
        if (!origin && i === 0) {
          secuency = secuency.concat((destinys[i] && destinys[i].destination_info.cityName) ?
            destinys[i].destination_info.cityName : '');
        } else if (destinys[i] && destinys[i].destination_info.cityName) {
          if(destinys[i].destination_info.cityName !== ''){
            secuency = secuency.concat(' > ' + destinys[i].destination_info.cityName);
          }
        }
      }
    }
    return secuency.toUpperCase();
  }

  _partialReserve(){
    //siempre mandar a limpiar todos los items
   // this.itineraryService.updateRemoveItineraryPartial(this.actualItinerary.id);

    if(this.partialReserve==true){
      this.partialReserve = false;
      this.resetItemToPartial.emit(0);
      this.itineraryService.parcialTotalPrice = 0;
      this.itineraryService.itineraryPartial = [];
    } else {
      this.partialReserve = true;
    }
    this.partialReserveEmitter.emit(this.partialReserve);

  }

  goToItinerary() {

    // this.spinner.show();
    setTimeout(() => { this.appLoading = false; }, 1999);


    setTimeout(() => {

      if(this.partialReserve && this.qtyItemsToPartial>0){


        const navigationExtras: NavigationExtras = {
          queryParams: {
            'id': this.actualItinerary.id,
            'stat': this.status,
            'screen': 'addFee',
            'goTo' : 'partialReserve',
          }
        };

        this.router.navigate(['/itinerary-detail'], navigationExtras);

      }else{


        const navigationExtras: NavigationExtras = {
          // queryParams: {
          //   'id': this.actualItinerary.id,
          //   'stat': this.status,
          //   'screen': 'addFee',
          //   'goTo' : 'reserve',
          // }
          queryParams: {
            'id': this.actualItinerary.id,
            'stat': this.status,
            'screen': 'detail',
            'goTo' : 'reserve',
          }
        };
        this.router.navigate(['/itinerary-detail'], navigationExtras);

      }

    }, 2000);

  }

  goToSaveDraft(){
    this.spinner.show();

    if(localStorage.getItem('selectedAgency')){

      const navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.actualItinerary.id,
          'stat': this.status,
          'screen': 'addFee',
          'goTo' : 'saveDraft',
          //'process': 'addFee'
        }
      };

      this.spinner.hide();
      this.router.navigate(['/itinerary-detail'], navigationExtras);

    }

  }

  goToReserve(onlySaveDraft: boolean = false){
    this.appLoading = true;
    //validar client owner
    let invalidOwner = false;

    this._cookieClient = (this._cookieService.get('clientActive') != undefined) ?  this._cookieService.get('clientActive') : undefined;

    if (this._cookieClient !== undefined) {
      let data = this._cookieClient.split("||");
      if(data[2].trim()=='' || data[2].trim()=='false' || data[4].trim()=='' || data[3].trim()==''){
        invalidOwner = true;
        //this.toastr.warning('Please complete all information for owner!');
      }
    } else {
      invalidOwner = true;
      //this.toastr.warning('Please complete all information for owner!');
    }


    if (!invalidOwner) {

      this.goToItinerary(); // Llama al la vista de Fee

     /* let navigationExtras: NavigationExtras;
      let goToURL: string;
      if(onlySaveDraft){
        goToURL = 'DetailsSummaryItinerary';
        navigationExtras = {
          queryParams: {
              "from": 'save-draft-reserve',
              "itineraryId":this.actualItinerary.id
          }
        };
      } else {
        goToURL = (this.partialReserve==true) ? 'DetailsSummaryItinerary' : 'itinerary-reservation';
        if(this.partialReserve==true){
          navigationExtras = {
            queryParams: {
                "from": 'partial-reserve',
                "itineraryId":this.actualItinerary.id
            }
          };
        } else {
          navigationExtras = {
            queryParams: {
                "from": 'total-reserve',
                "step": 'two',
                "itineraryId":this.actualItinerary.id
            }
          };
        }
      }
      this.router.navigate([goToURL], navigationExtras); */
    }
  }


  foundSavedItems(){
    let data = [];
    this.userService.itineraryActive[0].items.forEach(item => {
      if(item.status_id == 1){
        data.push(item);
      }
    });
    if(data.length>1){
      return true;
    }else{
      return false;
    }
  }

  existHotel(){
    if(this.userService.itineraryActive.length > 0){
      if(this.userService.itineraryActive[0].items.length>0){
        let exist = this.userService.itineraryActive[0].items.find( element => element.categoryName == "HOTELS" );

        if(exist){
          return true;
        }else{
          return false;
        }
      }
    }
  }

  getDataForm(passengerData:any){
    console.log(passengerData);
    let paxData = [];

    paxData.push(passengerData);

    console.log('paxdata yourtrip ->', paxData);

    paxData[0] = paxData[0].paxes.map((pax) => {
      const { relation_ship, state_id, country_id, ...rest } = pax;  // Desestructurar el objeto, excluyendo relation_ship, state_id, country_id
      return {
        ...rest,
        birth_date: moment(pax.birth_date, ['YYYY-DD-MM', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY']).format('YYYY-MM-DD'),
        relation_ship_catalogue_id: relation_ship,
        state_id: (state_id && state_id) ? state_id : null,
        country_id: (country_id && country_id) ? country_id : null,
      };
    });

    console.log('paxdata w/ relationship ->', paxData);

    const payload = {
      paxList: paxData[0],
    };

    console.log("payload pax", JSON.stringify(payload));

        this.restService.doPut("app", "itineraries/update/paxlist/" + this.actualItinerary.id, payload).subscribe(
          (response: any) => {
            console.log(response);
            if (response.success) {
                console.log(paxData);
                this.actualItinerary.paxList = paxData[0];
            }
          })
    console.log('this.actualitinerary', this.actualItinerary);
   }
   

   getDaysAndNights():void{
    console.log('this.actualItinerary', this.actualItinerary);
    this.qtyNights = this.actualItinerary.nights;
    this.qtyDays = this.actualItinerary.nights + 1;

    // console.log('qtyNights', this.qtyNights);

   }

}
