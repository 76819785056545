<div class="card p-0" style="background-color:  #f4f4f4">


  <div class="d-flex p-4 justify-content-between" style="align-items: center">
    <h5 class="font-poppins m-0" style="font-size: 18px;">FORMULARIO DE SOLICITUD DE FACTURACIÓN</h5>

    <div class="d-flex" style="align-items: center;gap: 1em;">
      <p class="mb-0">Agregar formulario</p>
        <i
          (click)="addRequest()"
          class="bi bi-plus-circle-fill"
          style="font-size: 2rem; cursor: pointer;"
          tooltip="Agregar formulario"
          placement="bottom">
        </i>
    </div>

    <!-- <p *ngIf="creditCardDataPending.length > 0 && !creditCardPayPartial" class="p-0 m-0 font-poppins" style="font-size: 24px; font-weight: 600;">Total importe: {{currencyItinerary}} {{amountCreditCard | number:'1.2-2'}}</p> -->
  </div>



  <form [formGroup]="requestForm" class="mx-auto" style="width:95%"  autocomplete="random-string">
      <div formArrayName="request">
        <div *ngFor="let request of requestArray.controls; let i = index"
              [formGroupName]="i"
              class="mb-3 font-poppins"
              [ngClass]="{'form-base': requestArray.controls?.length > 1 && i !== requestArray.controls.length - 1}">

          <div *ngIf="requestArray.controls?.length > 1"  class="d-flex mb-4 justify-content-between" style="align-items: center">
            <h5 class="font-poppins m-0" style="font-size: 18px;">FORMULARIO {{ i + 1 }}</h5>

            <div *ngIf="i !== 0" class="d-flex" style="align-items: center;gap: 1em;">
              <p class="mb-0">Eliminar formulario</p>
              <i
                  (click)="removeRequest(i)"
                  class="bi bi-x-circle-fill"
                  style="font-size: 2rem; color: #d20358; cursor: pointer;"
                  tooltip="Eliminar formulario"
                  placement="bottom">
              </i>
            </div>
          </div>

          <div class="sm-gutters" style="display: flex; gap: 1em;">
            <div class="form-group w-100">
              <label
                class="font-weight-bold font-poppins"
                style="font-size: small"
              >
                Persona
                <sup class="required-marker"> * </sup>
              </label>
              <fieldset style="display: flex; gap: 1em;">
                <div style="display:flex; align-items: center; gap: 10px;">
                  <label
                  class="font-weight-bold font-poppins"
                  style="font-size: 14px; margin-bottom: 0px;"
                >
                  Física
                </label>
                  <input type="radio"
                          formControlName="billing_type_id"
                          style="width: 20px; height: 20px;"
                          (change)="changeBillingType(i)"
                          value="1">
                </div>
                <div style="display:flex; align-items: center; gap: 10px;">
                  <label
                  class="font-weight-bold font-poppins"
                  style="font-size: 14px; margin-bottom: 0px;"
                >
                  Jurídica
                </label>
                  <input type="radio"
                          formControlName="billing_type_id"
                          style="width: 20px; height: 20px;"
                          (change)="changeBillingType(i)"
                          value="2">
                </div>
              </fieldset>
            </div>

            <div class="form-group w-100">
              <label
                for="title"
                class="font-weight-bold font-poppins"
                style="font-size: small"
              >
                <span *ngIf="requestForm.get(['request', i, 'billing_type_id'])?.value === '1'">Cliente</span>
                <span *ngIf="requestForm.get(['request', i, 'billing_type_id'])?.value === '2'">Empresa</span>
              <sup class="required-marker"> * </sup>
              </label>
              <select
                  *ngIf="requestForm.get(['request', i, 'billing_type_id'])?.value === '1'"
                  id="client_id"
                  name="client_id"
                  class="form-control custom-select"
                  [ngClass]="{
                              'is-invalid':
                                          requestForm.get(['request', i, 'client_id'])?.value === '' ||
                                          requestForm.get(['request', i, 'client_id'])?.value === null,
                              'is-valid':
                                            (requestForm.get(['request', i, 'client_id'])?.value !== '' ||
                                            requestForm.get(['request', i, 'client_id'])?.value !== null)
                            }"
                  type="text"
                  formControlName="client_id"
                  aria-label="client_id"
                  aria-required="true"
                  placeholder="Cliente"
                  required
              >
                  <option [value]="null" selected disabled>Cliente</option>
                  <ng-container *ngFor="let client of clients">
                    <option [value]="client.id">{{client.first_name}} {{client.last_name}}</option>
                  </ng-container>
              </select>
              <select
                  *ngIf="requestForm.get(['request', i, 'billing_type_id'])?.value === '2'"
                  id="enterprise_id"
                  name="enterprise_id"
                  class="form-control custom-select"
                  [ngClass]="{
                              'is-invalid':
                                          requestForm.get(['request', i, 'enterprise_id'])?.value === '' ||
                                          requestForm.get(['request', i, 'enterprise_id'])?.value === null,
                              'is-valid':
                                            (requestForm.get(['request', i, 'enterprise_id'])?.value !== '' ||
                                            requestForm.get(['request', i, 'enterprise_id'])?.value !== null)
                            }"
                  type="text"
                  formControlName="enterprise_id"
                  aria-label="enterprise_id"
                  aria-required="true"
                  placeholder="Empresa"
                  required
              >
               <option [value]="null" selected disabled>Empresa</option>
                <ng-container *ngFor="let enterprise of enterprises">
                  <option [value]="enterprise.id">{{enterprise.business_name}}</option>
                </ng-container>
              </select>
          </div>

          </div>

          <div class="sm-gutters" style="display: flex; gap: 1em;">
            <div class="form-group w-100">
              <label
                for="title"
                class="font-weight-bold font-poppins"
                style="font-size: small;"
              >
                Condición IVA
              <sup class="required-marker"> * </sup>
              </label>
                <select
                    id="iva_condition_id"
                    name="iva_condition_id"
                    class="form-control custom-select"
                    [ngClass]="{
                                'is-invalid':
                                            requestForm.get(['request', i, 'iva_condition_id'])?.value === '' ||
                                            requestForm.get(['request', i, 'iva_condition_id'])?.value === null,
                                'is-valid':
                                              (requestForm.get(['request', i, 'iva_condition_id'])?.value !== '' ||
                                              requestForm.get(['request', i, 'iva_condition_id'])?.value !== null)
                              }"
                    [ngStyle]="getStyles(i, 'iva_condition_id')"
                    type="text"
                    formControlName="iva_condition_id"
                    aria-label="iva_condition_id"
                    aria-required="true"
                    placeholder="Razón social"
                    required
                >
                <option [value]="null" selected disabled>Condición de IVA</option>
                <ng-container *ngFor="let condition of conditionIva">
                  <option [value]="condition.id">{{condition.name}}</option>
                </ng-container>
                </select>
            </div>

            <div class="form-group w-100">
              <label
                for="title"
                class="font-weight-bold font-poppins"
                style="font-size: small"
              >
                CUIT/CUIL
              <sup class="required-marker"> * </sup>
              </label>
              <input
                  id="cuit"
                  name="cuit"
                  class="form-control"
                  [ngClass]="{
                              'is-invalid':
                                          requestForm.get(['request', i, 'cuit'])?.value === '' ||
                                          requestForm.get(['request', i, 'cuit'])?.value === null,
                              'is-valid':
                                            (requestForm.get(['request', i, 'cuit'])?.value !== '' ||
                                            requestForm.get(['request', i, 'cuit'])?.value !== null)
                            }"
                  [ngStyle]="getStyles(i, 'cuit')"
                  type="text"
                  formControlName="cuit"
                  aria-label="cuit"
                  aria-required="true"
                  autocapitalize="none"
                  autocomplete="random-string"
                  placeholder="CUIT/CUIL"
                  required
              />
          </div>

              <!-- <div class="form-group w-100">
                <label
                  for="title"
                  class="font-weight-bold font-poppins"
                  style="font-size: small;"
                >
                  Nombre
                <sup class="required-marker"> * </sup>
                </label>
                  <input
                      id="business_name"
                      name="business_name"
                      class="form-control"
                      [ngClass]="{
                                  'is-invalid':
                                              requestForm.get(['request', i, 'business_name'])?.value === '' ||
                                              requestForm.get(['request', i, 'business_name'])?.value === null,
                                  'is-valid':
                                                (requestForm.get(['request', i, 'business_name'])?.value !== '' ||
                                                requestForm.get(['request', i, 'business_name'])?.value !== null)
                                }"
                      [ngStyle]="getStyles(i)"
                      type="text"
                      formControlName="business_name"
                      aria-label="business_name"
                      aria-required="true"
                      autocapitalize="none"
                      autocomplete="random-string"
                      placeholder="Razón social"
                      required
                  />
              </div> -->


          </div>

          <div class="sm-gutters" style="display: flex; gap: 1em;">
                <!-- <div class="form-group w-100">
                  <label
                    for="title"
                    class="font-weight-bold font-poppins"
                    style="font-size: small"
                  >
                    CUIT/CUIL
                  <sup class="required-marker"> * </sup>
                  </label>
                  <input
                      id="cuit"
                      name="cuit"
                      class="form-control"
                      [ngClass]="{
                                  'is-invalid':
                                              requestForm.get(['request', i, 'cuit'])?.value === '' ||
                                              requestForm.get(['request', i, 'cuit'])?.value === null,
                                  'is-valid':
                                                (requestForm.get(['request', i, 'cuit'])?.value !== '' ||
                                                requestForm.get(['request', i, 'cuit'])?.value !== null)
                                }"
                      [ngStyle]="getStyles(i)"
                      type="text"
                      formControlName="cuit"
                      aria-label="cuit"
                      aria-required="true"
                      autocapitalize="none"
                      autocomplete="random-string"
                      placeholder="Razón social"
                      required
                  />
              </div> -->
              <div class="form-group w-100">
                <label
                  for="title"
                  class="font-weight-bold font-poppins"
                  style="font-size: small"
                >
                  Dirección
                <sup class="required-marker"> * </sup>
                </label>
                <input
                    id="address"
                    name="address"
                    class="form-control"
                    [ngClass]="{
                                'is-invalid':
                                            requestForm.get(['request', i, 'address'])?.value === '' ||
                                            requestForm.get(['request', i, 'address'])?.value === null,
                                'is-valid':
                                              (requestForm.get(['request', i, 'address'])?.value !== '' ||
                                              requestForm.get(['request', i, 'address'])?.value !== null)
                              }"
                    [ngStyle]="getStyles(i)"
                    type="text"
                    formControlName="address"
                    aria-label="address"
                    aria-required="true"
                    autocapitalize="none"
                    autocomplete="random-string"
                    placeholder="Dirección"
                    required
                />
              </div>
              <div class="form-group w-100">
                <label
                  for="title"
                  class="font-weight-bold font-poppins"
                  style="font-size: small"
                >
                  Email
                <sup class="required-marker"> * </sup>
                </label>
                  <input
                      id="email"
                      name="email"
                      class="form-control"
                      [ngClass]="{
                                  'is-invalid':
                                              requestForm.get(['request', i, 'email'])?.value === '' ||
                                              requestForm.get(['request', i, 'email'])?.value === null,
                                  'is-valid':
                                                (requestForm.get(['request', i, 'email'])?.value !== '' ||
                                                requestForm.get(['request', i, 'email'])?.value !== null)
                                }"
                      [ngStyle]="getStyles(i)"
                      type="text"
                      formControlName="email"
                      aria-label="email"
                      aria-required="true"
                      autocapitalize="none"
                      autocomplete="random-string"
                      placeholder="Email"
                      required
                  />
              </div>
            </div>

          </div>
        </div>
  </form>
</div>
